import { combineReducers } from 'redux';
import appeals from './Appeal';
import archivedCases from './ArchivedCase';
import auth from './Auth';
import clients from './Client';
import organizations from './Organization';
import contacts from './Contact';
import cases from './Litigation';
import tasks from './Task';
import personalTasks from './PersonalTask';
import starredCases from './StarredCase';
import users from './User';
import documents from './Document';
import notifications from './Notification';

import { AppState } from '../../interfaces/redux/state/App';

export default combineReducers<AppState>({
  appeals,
  archivedCases,
  auth,
  clients,
  organizations,
  contacts,
  cases,
  tasks,
  personalTasks,
  starredCases,
  users,
  documents,
  notifications
});
