import React from 'react';
import DatePickerInput from '../../form/DatePickerInput';
import Form from 'antd/lib/form';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from '../../../redux/selectors/Auth';
import { User } from '../../../interfaces/entities/User';
import { isAdvancedUser } from '../../../utility/user/privileges';
import TextAreaInput from '../../form/TextAreaInput';

const CaseUpdateComponent: React.FC = () => {
  const user = useSelector(selectAuthenticatedUser) as User;
  return (
    <>
      <DatePickerInput
        name='hearingDate'
        label='Hearing Date'
        placeholder='Hearing Date'
      />

      <TextAreaInput
        name='privateDescription'
        label='Company Update'
        placeholder='Company Update'
        message='Please provide an update'
        required={true}
      />

      <DatePickerInput
        name='nextHearingDate'
        label='Next Hearing Date'
        placeholder='Next Hearing Date'
        disableFutureDates={false}
        required={false}
      />
      {isAdvancedUser(user) && (
        <Form.Item name='notifyClient' valuePropName='checked'>
          <Checkbox>Notify Client</Checkbox>
        </Form.Item>
      )}
      {isAdvancedUser(user) && (
        <Form.Item name='includeAttachments' valuePropName='checked'>
          <Checkbox>Share Attachments With Client</Checkbox>
        </Form.Item>
      )}
    </>
  );
};

export default CaseUpdateComponent;
