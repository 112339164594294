import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../../../redux/selectors/Auth';
import { makeDownloadRequest, makeRequest } from '../../../utility/api';
import { Button, Card, Descriptions, Table, Tabs } from 'antd';
import { capitalize } from '../../../utility/stringFormatter';
import { Contact } from '../../../interfaces/entities/Contact';
import { selectAllContacts } from '../../../redux/selectors/Contact';
import ViewAllContactsActionColumn from '../../contact/ViewAllAction';

const { TabPane } = Tabs;

const ContactReportView = () => {
  const [summary, setSummary] = useState<any>({});
  const [sortedContacts, setSortedContacts] = useState<any>([]);
  const authorization = useSelector(selectAuthToken);
  const contacts = useSelector(selectAllContacts);

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Court',
      dataIndex: 'court',
      shouldSort: false,
      shouldSearch: false,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      shouldSort: false,
      shouldSearch: false,
      render: (text: any, record: Contact) => {
        if (record.countryCode !== undefined) {
          return <p>{`${record.countryCode}${record.phoneNumber}`}</p>;
        }
        return <></>;
      },
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
      shouldSort: false,
      shouldSearch: false,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, record: Contact) => {
        const selectedContact = contacts.find(({ id }) => id === record.id);
        return (
          <ViewAllContactsActionColumn record={selectedContact as Contact} />
        );
      },
    },
  ];

  const downloadReport = () => {
    makeDownloadRequest({
      url: 'admin/reports/contact/download',
      type: 'GET',
      mime: 'application/vnd.ms-excel',
      filename: 'Contact Report.xlsx',
      authorization,
    });
  };

  useEffect(() => {
    makeRequest({
      url: 'admin/reports/contact',
      type: 'GET',
      successCallback: (response) => {
        const { summary, sortedContacts } = response.data;
        setSummary(summary);
        setSortedContacts(sortedContacts);
      },
      authorization,
    });
  }, [authorization]);

  const downloadButton = (
    <Button type='primary' ghost onClick={downloadReport}>
      Download
    </Button>
  );

  return (
    <Card>
      <Tabs
        defaultActiveKey='1'
        size='middle'
        tabBarExtraContent={downloadButton}
      >
        <TabPane tab='Summary' key='1'>
          <Descriptions bordered>
            {Object.keys(summary).map((status, index) => (
              <Descriptions.Item
                label={capitalize(status)}
                span={3}
                key={index}
              >
                {summary[status]}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </TabPane>
        {Object.keys(sortedContacts).map((classification, index) => (
          <TabPane
            tab={`${capitalize(classification)} contacts`}
            key={index + 2}
          >
            <Table
              bordered
              rowKey='id'
              columns={tableColumns}
              dataSource={sortedContacts[classification]}
            />
          </TabPane>
        ))}
      </Tabs>
    </Card>
  );
};

export default ContactReportView;
