import { NotificationState } from '../../interfaces/redux/state/App';
import { getGenericStateObject } from '../../utility/redux/state';
import { AppNotificationAction } from '../../interfaces/redux/actions/Notification';
import { edit, update } from '../../utility/redux/reducer';

const initialState: NotificationState = getGenericStateObject();

const reducer = (
  state: NotificationState = initialState,
  action: AppNotificationAction
): NotificationState => {
  switch (action.type) {
    case 'UPDATE_NOTIFICATION':
      return edit(state, action.payload);
    case 'UPDATE_NOTIFICATIONS':
      return update(state, action.payload);
    case 'CLEAR_NOTIFICATIONS':
      return initialState;
    default:
      return state;
  }
};

export default reducer;