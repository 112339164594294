import React from 'react';
import { Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';

interface TokenInputProps {
  tokenPrefix: string;
  showPrefix?: boolean;
}
const TokenInput: React.FC<TokenInputProps> = ({ tokenPrefix, showPrefix }) => {
  return (
    <Form.Item
      name='token'
      hasFeedback
      rules={[
        { required: true, message: 'Please provide your OTP' },
        () => ({
          validator(rule, value) {
            if (value.match(new RegExp(`${tokenPrefix}\\d+`))) {
              return Promise.resolve();
            }
            return Promise.reject(
              `Please provide a valid OTP in the format ${tokenPrefix}XXXXXX`
            );
          },
        }),
      ]}
    >
      <Input
        prefix={
          showPrefix ? (
            <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
          ) : null
        }
        placeholder='OTP'
      />
    </Form.Item>
  );
};

export default TokenInput;
