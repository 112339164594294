import { AppState } from '../../interfaces/redux/state/App';
import { selectAllItems, selectItem, selectCount } from './Generic';
import { Organization } from '../../interfaces/entities/Organization';

export const selectAllOrganizations = ({
  organizations,
}: AppState): Organization[] => selectAllItems(organizations);

export const selectOrganization = (
  { organizations }: AppState,
  id: number
): Organization | null => selectItem(id, organizations);

export const selectOrganizationCount = ({ organizations }: AppState): number =>
  selectCount(organizations);
