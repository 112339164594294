import React from 'react';
import { Form, DatePicker } from 'antd';
import moment from 'moment';

interface Props {
  required?: boolean;
  placeholder?: string;
  name: string;
  requiredMessage?: string;
  label: string;
  initialValue?: moment.Moment;
  disableFutureDates?: boolean;
}
const DatePickerInput: React.FC<Props> = ({
  required = true,
  placeholder = 'Please select a date',
  name,
  requiredMessage = 'Please select a date',
  label,
  initialValue,
  disableFutureDates = true,
}) => {
  const disabledDate = (current: any) => {
    return disableFutureDates && current && current > moment().endOf('day');
  };

  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      label={label}
      rules={[{ required, message: requiredMessage }]}
    >
      <DatePicker
        disabledDate={disabledDate}
        placeholder={placeholder}
        style={{ width: '100%' }}
        format={'LL'}
      />
    </Form.Item>
  );
};

export default DatePickerInput;
