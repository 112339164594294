import React, { useState } from 'react';
import { Litigation } from '../../interfaces/entities/Litigation';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthToken,
  selectAuthenticatedUser,
} from '../../redux/selectors/Auth';
import { selectAllStarredCases } from '../../redux/selectors/StarredCase';
import { makeRequest, makeDownloadRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import ArchivedCase from '../../redux/actions/ArchivedCase';
import StarredCase from '../../redux/actions/StarredCase';
import LitigationActions from '../../redux/actions/Litigation';
import { Space, Tooltip, Button } from 'antd';
import {
  DownloadOutlined,
  DeleteOutlined,
  StarFilled,
  StarOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  UndoOutlined,
  PlusOutlined,
  DiffOutlined,
} from '@ant-design/icons';
import ModalForm from '../form/ModalForm';
import { Link } from 'react-router-dom';
import { EDIT_CASE, UPDATE_CASE } from '../../utility/routing/routes';
import TableDrawer from '../table/Drawer';
import { getDrawerContent } from './DrawerContent';
import { User } from '../../interfaces/entities/User';
import { isAdvancedUser } from '../../utility/user/privileges';
import AddClientForm from './AddClient';
import StatusUpdateForm from './StatusUpdateForm';

interface ActionColumnProps {
  record: Litigation;
}

const ViewAllCasesActionColumn: React.FC<ActionColumnProps> = ({ record }) => {
  const [shouldShowDrawer, setShouldShowDrawer] = useState<boolean>(false);
  const [renderModal, setRenderModal] = useState<boolean>(false);
  const [renderClientModal, setRenderClientModal] = useState<boolean>(false);
  const [shouldShowForm, setShouldShowForm] = useState<boolean>(false);
  const dispatch = useDispatch();
  const authorization = useSelector(selectAuthToken);
  const starredCases = useSelector(selectAllStarredCases);
  const user = useSelector(selectAuthenticatedUser) as User;
  const hasAdvancedPrivileges = isAdvancedUser(user);

  const closeModal = () => {
    setRenderModal(false);
  };

  const closeClientModal = () => {
    setRenderClientModal(false);
  };

  const updateArchivedCaseState = (data: Litigation[]) => {
    dispatch(ArchivedCase.update(data));
  };

  const updateActiveCaseState = (data: Litigation[]) => {
    dispatch(LitigationActions.update(data));
  };

  const updateStarredCaseState = (data: Litigation[]) => {
    dispatch(StarredCase.update(data));
  };

  const isStarred = !!starredCases.find(({ id }) => id === record.id);

  const archiveCase = () => {
    makeRequest({
      url: `case/archive/${record.id}`,
      type: 'POST',
      authorization,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        dispatch(LitigationActions.editMany(data.active));
        updateArchivedCaseState(data.archived);
        updateActiveCaseState(data.active);
      },
    });
  };

  const starCase = () => {
    makeRequest({
      url: 'case/star',
      values: {
        id: record.id,
      },
      authorization,
      type: 'POST',
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateStarredCaseState(data);
      },
    });
  };

  const downloadCaseUpdateFile = () => {
    makeDownloadRequest({
      url: `case/update/${record.id}`,
      type: 'GET',
      authorization,
      filename: `${record.suitNumber}.pdf`,
      mime: 'application/pdf',
    });
  };


  const dismissUpdateForm = (): void => {
    setShouldShowForm(false);
  };

  const showUpdateForm = (): void => {
    setShouldShowForm(true);
  };

  return (
    <>
      <Space size='small'>
        <Tooltip placement='bottom' title={`View Case`}>
          <Button
            type='text'
            onClick={() => {
              setShouldShowDrawer(true);
            }}
          >
            <EyeOutlined />
          </Button>
        </Tooltip>
        <Tooltip placement='bottom' title={`Edit Case`}>
          <Button type='text'>
            <Link to={`${EDIT_CASE}/${record.id}`}>
              <EditOutlined />
            </Link>
          </Button>
        </Tooltip>
        <Tooltip placement='bottom' title='Provide Case Update'>
          <Button type='text'>
            <Link to={`${UPDATE_CASE}/${record.id}`}>
              <CheckCircleOutlined />
            </Link>
          </Button>
        </Tooltip>
        <Tooltip placement='bottom' title='Add Client'>
          <Button
            type='text'
            onClick={() => {
              setRenderClientModal(true);
            }}
          >
            <PlusOutlined />
          </Button>
        </Tooltip>
      </Space>
      <Space size='small'>
        {hasAdvancedPrivileges && (
          <Tooltip
            placement='bottom'
            title={`${isStarred ? 'Unstar' : 'Star'} Case`}
          >
            <Button type='text' onClick={starCase}>
              {isStarred ? (
                <StarFilled style={{ color: 'red' }} />
              ) : (
                <StarOutlined />
              )}
            </Button>
          </Tooltip>
        )}
        <Tooltip placement='bottom' title='Download Case Update File'>
          <Button type='text' onClick={downloadCaseUpdateFile}>
            <DownloadOutlined />
          </Button>
        </Tooltip>
        <Tooltip placement='bottom' title='Update Case Status'>
          <Button type='text' onClick={() => {showUpdateForm()}}>
            <DiffOutlined />
          </Button>
        </Tooltip>
        {hasAdvancedPrivileges && (
          <Tooltip
            placement='bottom'
            title={`${
              record.status === 'Archived' ? 'Restore' : 'Archive'
            } Case`}
          >
            <Button type='text' onClick={archiveCase}>
              {record.status === 'Archived' ? (
                <UndoOutlined />
              ) : (
                <DeleteOutlined />
              )}
            </Button>
          </Tooltip>
        )}
      </Space>
      <AddClientForm
        litigation={record}
        showForm={renderClientModal}
        successCallback={closeClientModal}
        cancel={closeClientModal}
      />
      <ModalForm
        showModal={renderModal}
        formToRender={'invoice'}
        showOrganizationDropdown={false}
        successCallback={closeModal}
        cancel={closeModal}
        resource={record}
      />
      <TableDrawer
        title={`Suit ${record.suitNumber}`}
        content={getDrawerContent(record, authorization, dispatch)}
        shouldShowDrawer={shouldShowDrawer}
        closeDrawer={() => {
          setShouldShowDrawer(false);
        }}
        width={650}
      />
      <StatusUpdateForm
        {...{
          showForm: shouldShowForm,
          dismissForm: dismissUpdateForm,
          id: record.id,
          currentStatus: record.status,
        }}
      />
    </>
  );
};

export default ViewAllCasesActionColumn;
