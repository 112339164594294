import React, { useState } from 'react';
import { Organization } from '../../interfaces/entities/Organization';
import { Redirect } from 'react-router-dom';
import { VIEW_ALL_ORGANIZATIONS } from '../../utility/routing/routes';
import BaseOrganizationForm from './Base';
import { Card } from 'antd';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from '../../redux/selectors/Auth';
import { User } from '../../interfaces/entities/User';

interface OrganizationFormProps {
  organization?: Organization;
}

const OrganizationForm: React.FC<OrganizationFormProps> = ({
                                                             organization
                                                           }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const isSuperAdmin = (useSelector(selectAuthenticatedUser) as User).isSuperAdmin;

  const successCallback = () => {
    setShouldRedirect(true);
  };

  return shouldRedirect ? (
    <Redirect to={isSuperAdmin ? VIEW_ALL_ORGANIZATIONS : ''} />
  ) : (
    <Card
      title={
        organization ? `Edit ${organization.name}` : 'Add New Organization'
      }
      bordered
    >
      <BaseOrganizationForm {...{ organization, successCallback }} />
    </Card>
  );
};

export default OrganizationForm;
