import { AppState } from '../../interfaces/redux/state/App';
import { selectAllItems, selectItem, selectCount } from './Generic';
import { Litigation } from '../../interfaces/entities/Litigation';

export const selectAllStarredCases = ({
  starredCases,
}: AppState): Litigation[] => selectAllItems(starredCases);

export const selectStarredCase = (
  { starredCases }: AppState,
  id: number
): Litigation | null => selectItem(id, starredCases);

export const selectStarredCaseCount = ({ starredCases }: AppState): number =>
  selectCount(starredCases);
