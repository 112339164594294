import React, { useState } from 'react';
import { VIEW_ALL_DOCUMENTS } from '../../utility/routing/routes';
import { Redirect } from 'react-router-dom';
import { Card } from 'antd';
import BaseDocumentForm from './Base';

interface DocumentProps {
  document?: Document;
}
const Document: React.FC<DocumentProps> = ({ document }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const successCallback = () => {
    setShouldRedirect(true);
  };

  return shouldRedirect ? (
    <Redirect to={VIEW_ALL_DOCUMENTS} />
  ) : (
    <Card
      title={document ? `Edit ${document.title}` : 'Add New Document'}
      bordered
    >
      <BaseDocumentForm successCallback={successCallback} />
    </Card>
  );
};

export default Document;
