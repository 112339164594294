import React, { useState } from 'react';
import { Button, Col, Divider, Drawer, Row, Space, Tooltip } from 'antd';
import { AppNotification } from '../../interfaces/entities/Notification';
import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { makeRequest } from '../../utility/api';
import Notification from '../../redux/actions/Notification';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../utility/notification/toast';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { selectAllCases } from '../../redux/selectors/Litigation';
import { selectAllTasks } from '../../redux/selectors/Task';
import { capitalize } from '../../utility/stringFormatter';

interface NotificationItemProps {
  notification: AppNotification;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ notification }) => {

  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);
  const dispatch = useDispatch();
  const authorization = useSelector(selectAuthToken);
  const cases = useSelector(selectAllCases);
  const tasks = useSelector(selectAllTasks);

  const capitalizeKey = (input: string) =>
    input.split(/(?=[A-Z])/).map(word => capitalize(word)).join(' ');

  const getCaseNotificationDetails = () => {
    const selectedCase = cases.find(({ id }) => id === +notification.resourceId)!;
    return {
      'title': selectedCase.suitName,
      'suitNumber': selectedCase.suitNumber,
      'nextHearingDate': selectedCase.nextHearingDate,
      // 'assignedUsers': selectedCase.assignedUsers
    };
  };

  const getTaskNotificationDetails = () => {
    const selectedTask = tasks.find(({ id }) => id === +notification.resourceId)!;
    return {
      'title': selectedTask.name,
      'additionalInformation': selectedTask.additionalInformation,
      'dueDate': selectedTask.dueDate,
      // 'assignedUsers': selectedTask.assignedUsers
    };
  };

  const notificationDetails: any = notification.isTaskNotification ?
    getTaskNotificationDetails() : getCaseNotificationDetails();


  const markNotificationAsRead = () => {
    makeRequest({
      url: `/notification/${notification.id}/status`,
      successCallback: response => {
        showToast('Notification updated successfully');
        dispatch(Notification.edit(response.data));
      },
      authorization,
      type: 'PATCH'
    });
  };

  return (
    <>
      <Row>
        <Col span={20}>
          <p>{notification.message}</p>
        </Col>
        <Col>
          <Space size='small'>
            <Tooltip placement='bottom' title={'View Details'}>
              <Button type='text' onClick={() => {
                setShowDetailsDrawer(true);
              }}>
                <EyeOutlined />
              </Button>
            </Tooltip>
            <Tooltip placement='bottom' title={'Mark as read'}>
              <Button type='text' onClick={markNotificationAsRead}>
                <CheckOutlined />
              </Button>
            </Tooltip>
          </Space>
        </Col>
      </Row>
      <Divider orientation={'left'} />
      <Drawer
        placement={'right'}
        title={notificationDetails.title}
        visible={showDetailsDrawer}
        closable={true}
        onClose={() => {
          setShowDetailsDrawer(false);
        }}
      >
        {
          Object.keys(notificationDetails).map((objectKey: any, index) => {
            return <div key={index}>
              <p style={{ marginTop: '10px' }}>
                <b>{capitalizeKey(objectKey)}</b>
              </p>
              <p>{notificationDetails[objectKey]}</p>
              <Divider orientation={'left'} />
            </div>;
          })
        }
      </Drawer>
    </>
  );

};

export default NotificationItem;