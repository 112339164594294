import { BaseObject } from '../../interfaces/entities/Base';
import { GenericState } from '../../interfaces/redux/state/Generic';

export const formatUpdateArray = <O extends BaseObject>(
  input: O[]
): GenericState<O> => ({
  allIds: input.map(({ id }) => id),
  byIds: input.reduce((record: Record<number, O>, item: O) => {
    record[item.id] = item;
    return record;
  }, {} as Record<number, O>),
});

export const update = <O extends BaseObject>(
  state: GenericState<O>,
  input: O[]
): GenericState<O> => ({
  ...state,
  ...formatUpdateArray(input),
});

export const add = <O extends BaseObject>(
  state: GenericState<O>,
  input: O
): GenericState<O> => ({
  ...state,
  allIds: [...state.allIds, input.id],
  byIds: { ...state.byIds, [input.id]: input },
});

export const remove = <O extends BaseObject>(
  state: GenericState<O>,
  id: O['id']
): GenericState<O> => {
  const newState = {
    ...state,
    byIds: { ...state.byIds },
    allIds: state.allIds.filter((storedId) => storedId !== id),
  };
  delete newState.byIds[id];
  return newState;
};

export const removeMany = <O extends BaseObject>(
  state: GenericState<O>,
  ids: O['id'][]
): GenericState<O> => {
  const newState = {
    ...state,
    byIds: { ...state.byIds },
    allIds: state.allIds.filter((storedId) => !ids.includes(storedId)),
  };
  ids.forEach((id) => {
    delete newState.byIds[id];
  });
  return newState;
};

export const edit = <O extends BaseObject>(
  state: GenericState<O>,
  input: O
): GenericState<O> => {
  const { id } = input;
  return {
    ...state,
    byIds: { ...state.byIds, [id]: input },
  };
};

export const bulkUpdate = <O extends BaseObject>(
  state: GenericState<O>,
  input: O[]
): GenericState<O> => {
  const { byIds } = formatUpdateArray(input);
  return {
    ...state,
    byIds: { ...state.byIds, ...byIds },
  };
};

export const addMany = <O extends BaseObject>(
  state: GenericState<O>,
  input: O[]
): GenericState<O> => {
  const { allIds, byIds } = formatUpdateArray(input);
  return {
    ...state,
    allIds: [...state.allIds, ...allIds],
    byIds: { ...state.byIds, ...byIds },
  };
};
