import { OrganizationState } from '../../interfaces/redux/state/App';
import { OrganizationAction } from '../../interfaces/redux/actions/Organization';
import {
  UPDATE_ORGANIZATIONS,
  ADD_ORGANIZATION,
  REMOVE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  CLEAR_ORGANIZATIONS,
  BULK_EDIT_ORGANIZATIONS,
} from '../ActionTypes';
import {
  update,
  add,
  remove,
  edit,
  bulkUpdate,
} from '../../utility/redux/reducer';
import { getGenericStateObject } from '../../utility/redux/state';

const initialState: OrganizationState = getGenericStateObject();

const reducer = (
  state: OrganizationState = initialState,
  action: OrganizationAction
): OrganizationState => {
  switch (action.type) {
    case UPDATE_ORGANIZATIONS:
      return update(state, action.payload);
    case BULK_EDIT_ORGANIZATIONS:
      return bulkUpdate(state, action.payload);
    case ADD_ORGANIZATION:
      return add(state, action.payload);
    case REMOVE_ORGANIZATION:
      return remove(state, action.payload);
    case UPDATE_ORGANIZATION:
      return edit(state, action.payload);
    case CLEAR_ORGANIZATIONS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
