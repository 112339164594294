import { AppState } from '../../interfaces/redux/state/App';
import { selectAllItems, selectItem, selectCount } from './Generic';
import { Litigation } from '../../interfaces/entities/Litigation';

export const selectAllArchivedCases = ({
  archivedCases,
}: AppState): Litigation[] => selectAllItems(archivedCases);

export const selectArchivedCase = (
  { archivedCases }: AppState,
  id: number
): Litigation | null => selectItem(id, archivedCases);

export const selectArchivedCaseCount = ({ archivedCases }: AppState): number =>
  selectCount(archivedCases);
