import {
  ADD_TASK,
  ADD_USER,
  VIEW_ALL_USERS,
  IMPORT_USERS,
  VIEW_USER,
  UPDATE_CASE,
  VIEW_ALL_ORGANIZATIONS,
  ADD_ORGANIZATION,
  EDIT_ORGANIZATION,
  VIEW_ORGANIZATION_REPORT,
  ADD_CLIENT,
  EDIT_CLIENT,
  IMPORT_CLIENTS,
  IMPORT_CONTACTS,
  IMPORT_CASES,
  EDIT_TASK,
  IMPORT_TASKS,
  VIEW_DELETED_USERS,
  VIEW_CASE_REPORT,
  VIEW_TASK_REPORT,
  VIEW_USER_REPORT,
  VIEW_CLIENT_REPORT,
  VIEW_CONTACT_REPORT,
  VIEW_ALL_CONTACTS,
} from './routes';
import { getPrivilege } from '../user/privileges';
import { User } from '../../interfaces/entities/User';

export const canAccessSpecialRoute = (route: string, user: User) => {
  const userPrivilege = getPrivilege(user.privilege) || '';

  const baseRoute = route.replace(/\/\d+/, ''); //get base route from special routes like edit

  switch (baseRoute) {
    case VIEW_ALL_ORGANIZATIONS:
    case ADD_ORGANIZATION:
    case VIEW_ORGANIZATION_REPORT:
      return userPrivilege === 'super';
    case EDIT_ORGANIZATION:
    case ADD_USER:
    case VIEW_ALL_USERS:
    case VIEW_DELETED_USERS:
    case IMPORT_USERS:
    case VIEW_USER:
    case UPDATE_CASE:
    case ADD_TASK:
    case EDIT_TASK:
    case IMPORT_TASKS:
    case VIEW_CASE_REPORT:
    case VIEW_TASK_REPORT:
    case VIEW_USER_REPORT:
    case VIEW_CLIENT_REPORT:
    case VIEW_CONTACT_REPORT:
      return ['super', 'admin', 'advanced'].includes(userPrivilege);
    case ADD_CLIENT:
    case EDIT_CLIENT:
    case IMPORT_CONTACTS:
    case IMPORT_CASES:
    case IMPORT_CLIENTS:
      return ['super', 'admin', 'advanced', 'assistant'].includes(
        userPrivilege
      );
    case VIEW_ALL_CONTACTS:
      return ['super', 'admin', 'advanced'].includes(userPrivilege);
  }
};
