import React, { useState } from 'react';
import { ImportedItem } from '../../interfaces/components/form/FileUpload';
import { Redirect } from 'react-router-dom';
import { VIEW_ALL_USERS } from '../../utility/routing/routes';
import ImportInput from '../form/ImportInput';
import { getPrivilege } from '../../utility/user/privileges';
import UserActions from '../../redux/actions/User';

const ImportUsersForm: React.FC = () => {
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const successCallback = () => {
    setShouldRedirect(true);
  };

  const getUserFromArray = (array: any[]) => ({
    firstName: array[0],
    lastName: array[1],
    emailAddress: array[2],
    countryCode: array[3],
    phoneNumber: array[4],
    privilege: getPrivilege(array[5]),
  });

  const formatFileUpload = (importedItems: any[]): ImportedItem[] =>
    importedItems.map((importedItem, index) => ({
      ...getUserFromArray(importedItem),
      id: index,
    }));

  const tableColumns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
    },
    {
      title: 'Country Code',
      dataIndex: 'countryCode',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Privilege',
      dataIndex: 'privilege',
    },
  ];

  return shouldRedirect ? (
    <Redirect to={VIEW_ALL_USERS} />
  ) : (
    <ImportInput
      url='admin/user/import'
      importedItemName='Users'
      {...{
        formatFileUpload,
        tableColumns,
        successCallback,
        successAction: UserActions.addMany,
      }}
    />
  );
};

export default ImportUsersForm;
