import {
  UpdateStarredCasesAction,
  ClearStarredCasesAction,
} from '../../interfaces/redux/actions/StarredCase';
import { Litigation } from '../../interfaces/entities/Litigation';
import { UPDATE_STARS, CLEAR_STARS } from '../ActionTypes';

const update = (cases: Litigation[]): UpdateStarredCasesAction => ({
  type: UPDATE_STARS,
  payload: cases,
});

const clear = (): ClearStarredCasesAction => ({
  type: CLEAR_STARS,
});

export default {
  update,
  clear,
};
