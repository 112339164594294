export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const CLEAR_USER = 'CLEAR_USER';

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';

export const UPDATE_CLIENTS = 'UPDATE_CLIENTS';
export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_CLIENTS = 'ADD_CLIENTS';
export const REMOVE_CLIENT = 'REMOVE_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const CLEAR_CLIENTS = 'CLEAR_CLIENTS';
export const BULK_DELETE_CLIENTS = 'BULK_DELETE_CLIENTS';

export const UPDATE_CONTACTS = 'UPDATE_CONTACTS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const ADD_CONTACTS = 'ADD_CONTACTS';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const CLEAR_CONTACTS = 'CLEAR_CONTACTS';
export const BULK_DELETE_CONTACTS = 'BULK_DELETE_CONTACTS';

export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const ADD_DOCUMENTS = 'ADD_DOCUMENTS';
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const CLEAR_DOCUMENTS = 'CLEAR_DOCUMENTS';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';

export const UPDATE_CASES = 'UPDATE_CASES';
export const ADD_CASE = 'ADD_CASE';
export const ADD_CASES = 'ADD_CASES';
export const BULK_EDIT_CASES = 'BULK_EDIT_CASES';
export const REMOVE_CASE = 'REMOVE_CASE';
export const UPDATE_CASE = 'UPDATE_CASE';
export const CLEAR_CASES = 'CLEAR_CASES';

export const UPDATE_APPEALS = 'UPDATE_APPEALS';
export const ADD_APPEAL = 'ADD_APPEAL';
export const ADD_APPEALS = 'ADD_APPEALS';
export const BULK_EDIT_APPEALS = 'BULK_EDIT_APPEALS';
export const REMOVE_APPEAL = 'REMOVE_APPEAL';
export const UPDATE_APPEAL = 'UPDATE_APPEAL';
export const CLEAR_APPEALS = 'CLEAR_APPEALS';

export const UPDATE_ORGANIZATIONS = 'UPDATE_ORGANIZATIONS';
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const BULK_EDIT_ORGANIZATIONS = 'BULK_EDIT_ORGANIZATIONS';
export const REMOVE_ORGANIZATION = 'REMOVE_ORGANIZATION';
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const CLEAR_ORGANIZATIONS = 'CLEAR_ORGANIZATIONS';

export const ADD_PERSONAL_TASKS = 'ADD_PERSONAL_TASKS';
export const ADD_PERSONAL_TASK = 'ADD_PERSONAL_TASK';
export const EDIT_PERSONAL_TASK = 'EDIT_PERSONAL_TASK';
export const REMOVE_PERSONAL_TASK = 'REMOVE_PERSONAL_TASK';
export const BULK_EDIT_PERSONAL_TASKS = 'BULK_EDIT_PERSONAL_TASKS';
export const UPDATE_PERSONAL_TASKS = 'UPDATE_PERSONAL_TASKS';
export const CLEAR_PERSONAL_TASKS = 'CLEAR_PERSONAL_TASKS';

export const UPDATE_STARS = 'UPDATE_STARRED_CASES';
export const CLEAR_STARS = 'CLEAR_STARRED_CASES';

export const UPDATE_ARCHIVE = 'UPDATE_ARCHIVED_CASES';
export const CLEAR_ARCHIVE = 'CLEAR_ARCHIVED_CASES';

export const BULK_EDIT_TASKS = 'BULK_EDIT_TASKS';
export const UPDATE_TASKS = 'UPDATE_TASKS';
export const ADD_TASK = 'ADD_TASK';
export const ADD_TASKS = 'ADD_TASKS';
export const REMOVE_TASK = 'REMOVE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const CLEAR_TASKS = 'CLEAR_TASKS';

export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USERS = 'CLEAR_USERS';
export const ADD_USER = 'ADD_USER';
export const ADD_USERS = 'ADD_USERS';
export const REMOVE_USER = 'REMOVE_USER';
export const UPDATE_USERS = 'UPDATE_USERS';
export const BULK_EDIT_USERS = 'BULK_EDIT_USERS';

export const UPDATE_CALENDAR = 'UPDATE_CALENDAR';
export const CLEAR_CALENDAR = 'CLEAR_CALENDAR';
