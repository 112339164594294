import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../interfaces/redux/state/App';
import { selectTask } from '../../redux/selectors/Task';
import { selectLitigation } from '../../redux/selectors/Litigation';
import { getDrawerContent as getLitigationDrawerContent } from '../litigation/DrawerContent';
import { getDrawerContent as getTaskDrawerContent } from '../task/DrawerContent';
import { Task } from '../../interfaces/entities/Task';
import { Litigation } from '../../interfaces/entities/Litigation';
import TableDrawer from '../table/Drawer';
import { selectAuthToken } from '../../redux/selectors/Auth';

interface Props {
  resourceType: 'task' | 'hearing';
  resourceId: number;
  visibility: boolean;
  closeDrawer: () => void;
  title: string;
}

const TimeTableDrawer: React.FC<Props> = ({
  resourceType,
  resourceId,
  visibility,
  closeDrawer,
  title,
}) => {
  const authorization = useSelector(selectAuthToken);
  const selectedResource = useSelector((appState) => {
    if (resourceType === 'task') {
      return selectTask(appState as AppState, resourceId);
    }
    return selectLitigation(appState as AppState, resourceId);
  });
  const dispatch = useDispatch();

  const drawerContent =
    resourceType === 'task'
      ? getTaskDrawerContent(selectedResource as Task)
      : getLitigationDrawerContent(
          selectedResource as Litigation,
          authorization,
          dispatch
        );

  return (
    <TableDrawer
      title={title}
      content={drawerContent}
      shouldShowDrawer={visibility}
      closeDrawer={closeDrawer}
      width={650}
    />
  );
};

export default TimeTableDrawer;
