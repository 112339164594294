import { ClientState } from '../../interfaces/redux/state/App';
import { getGenericStateObject } from '../../utility/redux/state';
import {
  UPDATE_CLIENTS,
  ADD_CLIENT,
  REMOVE_CLIENT,
  UPDATE_CLIENT,
  CLEAR_CLIENTS,
  ADD_CLIENTS,
  BULK_DELETE_CLIENTS,
} from '../ActionTypes';
import { ClientAction } from '../../interfaces/redux/actions/Client';
import {
  update,
  add,
  remove,
  edit,
  addMany,
  removeMany,
} from '../../utility/redux/reducer';

const initialState: ClientState = getGenericStateObject();

const reducer = (
  state: ClientState = initialState,
  action: ClientAction
): ClientState => {
  switch (action.type) {
    case UPDATE_CLIENTS:
      return update(state, action.payload);
    case ADD_CLIENT:
      return add(state, action.payload);
    case REMOVE_CLIENT:
      return remove(state, action.payload);
    case UPDATE_CLIENT:
      return edit(state, action.payload);
    case ADD_CLIENTS:
      return addMany(state, action.payload);
    case BULK_DELETE_CLIENTS:
      return removeMany(state, action.payload);
    case CLEAR_CLIENTS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
