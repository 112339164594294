import { getGenericStateObject } from '../../utility/redux/state';
import {
  update,
  add,
  remove,
  edit,
  addMany,
  bulkUpdate,
} from '../../utility/redux/reducer';
import { AppealState } from '../../interfaces/redux/state/App';
import { AppealAction } from '../../interfaces/redux/actions/Appeal';
import {
  UPDATE_APPEALS,
  ADD_APPEAL,
  REMOVE_APPEAL,
  UPDATE_APPEAL,
  ADD_APPEALS,
  BULK_EDIT_APPEALS,
  CLEAR_APPEALS,
} from '../ActionTypes';

const initialState: AppealState = getGenericStateObject();

const reducer = (
  state: AppealState = initialState,
  action: AppealAction
): AppealState => {
  switch (action.type) {
    case UPDATE_APPEALS:
      return update(state, action.payload);
    case ADD_APPEAL:
      return add(state, action.payload);
    case REMOVE_APPEAL:
      return remove(state, action.payload);
    case UPDATE_APPEAL:
      return edit(state, action.payload);
    case ADD_APPEALS:
      return addMany(state, action.payload);
    case BULK_EDIT_APPEALS:
      return bulkUpdate(state, action.payload);
    case CLEAR_APPEALS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
