import React, { useState } from 'react';
import { Task } from '../../interfaces/entities/Task';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthenticatedUser, selectAuthToken } from '../../redux/selectors/Auth';
import PersonalTaskActions from '../../redux/actions/PersonalTask';
import TaskActions from '../../redux/actions/Task';
import { makeRequest } from '../../utility/api';
import { showDeleteWarning, showToast } from '../../utility/notification/toast';
import Space from 'antd/lib/space';
import Tooltip from 'antd/lib/tooltip';
import { EDIT_TASK, UPDATE_TASK } from '../../utility/routing/routes';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  StarFilled,
  StarOutlined,
  UndoOutlined
} from '@ant-design/icons';
import { selectAllPersonalTasks } from '../../redux/selectors/PersonalTask';
import TableDrawer from '../table/Drawer';
import { getDrawerContent } from './DrawerContent';
import { User } from '../../interfaces/entities/User';
import { isAdvancedUser } from '../../utility/user/privileges';

interface ActionColumnProps {
  record: Task;
}

const ViewAllTasksActionColumn: React.FC<ActionColumnProps> = ({ record }) => {
  const [shouldShowDrawer, setShouldShowDrawer] = useState<boolean>(false);
  const dispatch = useDispatch();
  const authorization = useSelector(selectAuthToken);
  const personalTasks = useSelector(selectAllPersonalTasks);

  const user = useSelector(selectAuthenticatedUser) as User;
  const hasSpecialPrivileges = isAdvancedUser(user);

  const isPersonalTask = !!personalTasks.find(({ id }) => id === record.id);

  const updateTaskState = (data: Task) => {
    dispatch(TaskActions.edit(data));
    dispatch(PersonalTaskActions.edit(data));
  };

  const deleteTaskFromState = (data: Task) => {
    dispatch(TaskActions.remove(data.id));
    dispatch(PersonalTaskActions.remove(data.id));
  };

  const assignTaskToSelf = () => {
    const values = { task: record.id };
    makeRequest({
      url: 'task/assign',
      values,
      type: 'POST',
      authorization,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateTaskState(data);
      }
    });
  };

  const markTaskAsCompleted = () => {
    makeRequest({
      url: `task/complete/${record.id}`,
      type: 'POST',
      authorization,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateTaskState(data);
      }
    });
  };


  const deleteTask = () => {
    const { id } = record;
    const confirmCallback = () => {
      makeRequest({
        url: `task/${id}`,
        type: 'DELETE',
        successCallback: (response) => {
          showToast(response.message);
          deleteTaskFromState(record);
        },
        authorization
      });
    };
    showDeleteWarning(`Delete ${record.name}?`, confirmCallback);
  };

  return (
    <>
      <Space size='small'>
        <Tooltip placement='bottom' title={`View Task`}>
          <Button
            type='text'
            onClick={() => {
              setShouldShowDrawer(true);
            }}
          >
            <EyeOutlined />
          </Button>
        </Tooltip>

        {hasSpecialPrivileges && (
          <Tooltip placement='bottom' title='Assign Task To Self'>
            <Button type='text' onClick={assignTaskToSelf}>
              {isPersonalTask ? (
                <StarFilled style={{ color: 'green' }} />
              ) : (
                <StarOutlined />
              )}
            </Button>
          </Tooltip>
        )}

        {hasSpecialPrivileges && (
          <Tooltip placement='bottom' title={`Edit Task`}>
            <Button type='text'>
              <Link to={`${EDIT_TASK}/${record.id}`}>
                <EditOutlined />
              </Link>
            </Button>
          </Tooltip>
        )}
        {hasSpecialPrivileges && record.isCompleted && (
          <Tooltip placement='bottom' title='Reset to "In progress"'>
            <Button type='text' onClick={markTaskAsCompleted}>
              <UndoOutlined />
            </Button>
          </Tooltip>
        )}
      </Space>

      <Space size='small'>
        {isPersonalTask && !record.isCompleted && (
          <>
            <Tooltip placement='bottom' title='Provide Task Update'>
              <Button type='text'>
                <Link to={`${UPDATE_TASK}/${record.id}`}>
                  <CloudUploadOutlined />
                </Link>
              </Button>
            </Tooltip>

            <Tooltip placement='bottom' title='Mark as "Complete"'>
              <Button type='text' onClick={markTaskAsCompleted}>
                <CheckCircleOutlined />
              </Button>
            </Tooltip>
          </>
        )}
        <Tooltip placement='bottom' title='Delete Task'>
          <Button type='text' onClick={deleteTask}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      </Space>
      <TableDrawer
        title={`${record.name}`}
        content={getDrawerContent(record)}
        shouldShowDrawer={shouldShowDrawer}
        closeDrawer={() => {
          setShouldShowDrawer(false);
        }}
        width={650}
      />
    </>
  );
};

export default ViewAllTasksActionColumn;
