import { AppState } from '../../interfaces/redux/state/App';
import { selectAllItems, selectItem, selectCount } from './Generic';
import { Task } from '../../interfaces/entities/Task';

export const selectAllTasks = ({ tasks }: AppState): Task[] =>
  selectAllItems(tasks);

export const selectTask = ({ tasks }: AppState, id: number): Task | null =>
  selectItem(id, tasks);

export const selectTaskCount = ({ tasks }: AppState): number =>
  selectCount(tasks);
