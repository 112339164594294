import React, { useState } from 'react';
import { Client } from '../../interfaces/entities/Client';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthToken,
  selectAuthenticatedUser,
} from '../../redux/selectors/Auth';
import { makeRequest } from '../../utility/api';
import { showToast, showDeleteWarning } from '../../utility/notification/toast';
import ClientActions from '../../redux/actions/Client';
import { Typography, Space, Tooltip, Button } from 'antd';
import { EDIT_CLIENT } from '../../utility/routing/routes';
import { DeleteOutlined, EyeOutlined, EditFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import TableDrawer from '../table/Drawer';
import { userIsGrantedPrivilege } from '../../utility/user/privileges';
import { User } from '../../interfaces/entities/User';

interface ActionColumnProps {
  record: Client;
}

const { Paragraph } = Typography;

const ViewAllClientsActionColumn: React.FC<ActionColumnProps> = ({
  record,
}) => {
  const [shouldShowDrawer, setShouldShowDrawer] = useState<boolean>(false);
  const dispatch = useDispatch();
  const authorization = useSelector(selectAuthToken);
  const phoneNumber = `${record.countryCode}${record.phoneNumber}`;

  const user = useSelector(selectAuthenticatedUser) as User;

  const deleteClient = () => {
    const { id } = record;
    const confirmCallback = () => {
      makeRequest({
        url: `client/delete`,
        values: { id },
        type: 'DELETE',
        successCallback: (response) => {
          showToast(response.message);
          dispatch(ClientActions.remove(id));
        },
        authorization,
      });
    };
    showDeleteWarning(`Delete ${record.name}?`, confirmCallback);
  };

  const openDrawer = () => {
    setShouldShowDrawer(true);
  };

  const closeDrawer = () => {
    setShouldShowDrawer(false);
  };

  const drawerContent = (
    <>
      <p style={{ marginTop: '10px' }}>
        <b>Client Type</b>
      </p>
      <p>{`${record.type === 'IND' ? 'Individual' : 'Corporate'}`}</p>
      <p style={{ marginTop: '10px' }}>
        <b>Unique Reference</b>
      </p>
      <p>{`${record.reference}`}</p>
      {record.dateOfBirth && (
        <>
          <p style={{ marginTop: '10px' }}>
            <b>Date of Birth</b>
          </p>
          <p>{`${record.dateOfBirth}`}</p>
        </>
      )}
      {record.address && (
        <>
          <p style={{ marginTop: '10px' }}>
            <b>Address</b>
          </p>
          <p>{`${record.address}`}</p>
        </>
      )}
      {record.phoneNumber && (
        <>
          <p style={{ marginTop: '10px' }}>
            <b>Phone Number</b>
          </p>
          <Paragraph copyable={{ text: phoneNumber }}>
            <a href={`tel:${phoneNumber}`}>{`${phoneNumber}`}</a>
          </Paragraph>
        </>
      )}
      {record.emailAddress && (
        <>
          <p style={{ marginTop: '10px' }}>
            <b>Email Address</b>
          </p>
          <Paragraph copyable={{ text: record.emailAddress }}>
            <a
              href={`mailto:${record.emailAddress}`}
            >{`${record.emailAddress}`}</a>
          </Paragraph>
        </>
      )}
      <p style={{ marginTop: '10px' }}>
        <b>Organization</b>
      </p>
      <p>{`${record.organization.name}`}</p>
    </>
  );
  return (
    <>
      <Space size='middle'>
        <Tooltip placement='bottom' title={'View Client'}>
          <Button type='text' onClick={openDrawer}>
            <EyeOutlined />
          </Button>
        </Tooltip>
        {!userIsGrantedPrivilege(user, 'default') && (
          <Tooltip placement='bottom' title={'Edit Client'}>
            <span>
              <Link to={`${EDIT_CLIENT}/${record.id}`}>
                <EditFilled />
              </Link>
            </span>
          </Tooltip>
        )}
        {!(
          userIsGrantedPrivilege(user, 'assistant') ||
          userIsGrantedPrivilege(user, 'default')
        ) && (
          <Tooltip placement='bottom' title='Delete Client'>
            <Button
              type='text'
              onClick={() => {
                deleteClient();
              }}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        )}
      </Space>
      <TableDrawer
        title={`${record.name}`}
        shouldShowDrawer={shouldShowDrawer}
        closeDrawer={closeDrawer}
        content={drawerContent}
      />
    </>
  );
};

export default ViewAllClientsActionColumn;
