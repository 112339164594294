import { getGenericStateObject } from '../../utility/redux/state';
import { TaskState } from '../../interfaces/redux/state/App';
import { TaskAction } from '../../interfaces/redux/actions/Task';
import {
  UPDATE_TASKS,
  ADD_TASK,
  ADD_TASKS,
  REMOVE_TASK,
  UPDATE_TASK,
  CLEAR_TASKS,
  BULK_EDIT_TASKS,
} from '../ActionTypes';
import {
  update,
  add,
  addMany,
  remove,
  edit,
  bulkUpdate,
} from '../../utility/redux/reducer';

const initialState: TaskState = getGenericStateObject();

const reducer = (
  state: TaskState = initialState,
  action: TaskAction
): TaskState => {
  switch (action.type) {
    case UPDATE_TASKS:
      return update(state, action.payload);
    case ADD_TASK:
      return add(state, action.payload);
    case ADD_TASKS:
      return addMany(state, action.payload);
    case REMOVE_TASK:
      return remove(state, action.payload);
    case UPDATE_TASK:
      return edit(state, action.payload);
    case BULK_EDIT_TASKS:
      return bulkUpdate(state, action.payload);
    case CLEAR_TASKS:
      return initialState;
    default:
      return state;
  }
};
export default reducer;
