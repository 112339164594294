import React from 'react';
import { useParams } from 'react-router-dom';
import BaseUpdateForm from './Base';
import { Store } from 'antd/lib/form/interface';
import {
  VIEW_ALL_TASKS,
  VIEW_PERSONAL_TASKS,
} from '../../utility/routing/routes';
import { useDispatch } from 'react-redux';
import Task from '../../redux/actions/Task';
import PersonalTask from '../../redux/actions/PersonalTask';

const TaskUpdateForm: React.FC = () => {
  const { id } = useParams();

  const formatRequest = (values: Store) => {
    values.task = +id;
    return values;
  };

  const dispatch = useDispatch();

  return (
    <BaseUpdateForm
      formatRequest={formatRequest}
      submitUrl='task/update'
      updateType='task'
      redirectUrl={VIEW_PERSONAL_TASKS}
      onSuccess={(data) => {
        dispatch(Task.edit(data));
        dispatch(PersonalTask.edit(data));
      }}
    />
  );
};

export default TaskUpdateForm;
