import React from 'react';
import { Button, Form } from 'antd';

interface ButtonProps {
  buttonText: string;
  isLoading: boolean;
}
const SubmitButton: React.FC<ButtonProps> = ({ buttonText, isLoading }) => {
  return (
    <Form.Item>
      <Button danger type='primary' htmlType='submit' block loading={isLoading}>
        {buttonText}
      </Button>
    </Form.Item>
  );
};

export default SubmitButton;
