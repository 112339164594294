import { Litigation } from '../../interfaces/entities/Litigation';
import {
  UPDATE_CASES,
  ADD_CASE,
  ADD_CASES,
  REMOVE_CASE,
  UPDATE_CASE,
  CLEAR_CASES,
  BULK_EDIT_CASES,
} from '../ActionTypes';
import {
  UpdateCasesAction,
  AddCaseAction,
  ImportCasesAction,
  RemoveCaseAction,
  EditCaseAction,
  ClearCasesAction,
  BulkEditCasesAction,
} from '../../interfaces/redux/actions/Litigation';

const update = (cases: Litigation[]): UpdateCasesAction => ({
  type: UPDATE_CASES,
  payload: cases,
});

const add = (litigation: Litigation): AddCaseAction => ({
  type: ADD_CASE,
  payload: litigation,
});

const addMany = (cases: Litigation[]): ImportCasesAction => ({
  type: ADD_CASES,
  payload: cases,
});

const editMany = (cases: Litigation[]): BulkEditCasesAction => ({
  type: BULK_EDIT_CASES,
  payload: cases,
});

const remove = (litigation: Litigation['id']): RemoveCaseAction => ({
  type: REMOVE_CASE,
  payload: litigation,
});

const edit = (litigation: Litigation): EditCaseAction => ({
  type: UPDATE_CASE,
  payload: litigation,
});

const clear = (): ClearCasesAction => ({ type: CLEAR_CASES });

export default { update, add, addMany, remove, edit, clear, editMany };
