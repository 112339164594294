import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotPermitted from '../utility/NotPermitted';
import { VIEW_ALL_CASES } from '../../utility/routing/routes';
import AppealForm from './Appeal';
import { selectAuthenticatedUser } from '../../redux/selectors/Auth';
import { User } from '../../interfaces/entities/User';
import { userIsGrantedPrivilege } from '../../utility/user/privileges';
import { selectAppeal } from '../../redux/selectors/Appeal';
import { AppState } from '../../interfaces/redux/state/App';

const EditAppealForm: React.FC = () => {
  const { id } = useParams();
  const appeal = useSelector((appState) =>
    selectAppeal(appState as AppState, +id)
  );

  const authorizedUser = useSelector(selectAuthenticatedUser) as User;

  const notPermittedPage = (
    status: '404' | '403',
    message: string
  ): JSX.Element => (
    <NotPermitted
      returnUrl={VIEW_ALL_CASES}
      status={status}
      message={message}
    />
  );

  if (userIsGrantedPrivilege(authorizedUser, 'assistant')) {
    return notPermittedPage('403', 'You are not authorized to view this page.');
  }
  if (!appeal) {
    return notPermittedPage(
      '404',
      'Could not find a case with the specified ID.'
    );
  }
  return <AppealForm appeal={appeal} />;
};

export default EditAppealForm;
