import {
  Resource,
  ExcludeLitigationResource
} from '../interfaces/entities/Base';
import { AutoCompleteOption } from '../interfaces/components/form/AutoComplete';
import { Litigation } from '../interfaces/entities/Litigation';
import { trimSuitName } from './stringFormatter';

const random = (a: number, b: number = 1): number => {
  // if only 1 argument is provided, we need to swap the values of a and b
  if (b === 1) {
    [a, b] = [b, a];
  }
  return Math.floor((b - a + 1) * Math.random()) + a;
};

export const shuffle = <O extends Object>(array: O[]): O[] => {
  for (let i = array.length; i; i--) {
    let j = random(i) - 1;
    [array[i - 1], array[j]] = [array[j], array[i - 1]];
  }
  return array;
};

export const getObjectId = (
  objectName: string,
  array: any[],
  searchParameterName = 'name'
) => {
  const matchingObject = array.find(
    (object) => {
      let relevantValue = object[searchParameterName];
      if (searchParameterName === 'suitName') {
        relevantValue = trimSuitName(object[searchParameterName]);
      }
      return relevantValue === objectName;
    }
  );
  return matchingObject ? matchingObject.id : null;
};

export const getOptions = (
  inputArray: Resource[],
  isLitigationArray = false
): AutoCompleteOption[] => {
  if (isLitigationArray) {
    return inputArray.map((resource) => ({
      value: trimSuitName((resource as Litigation).suitName) as string
    }));
  }
  return inputArray.map((resource) => ({
    value: (resource as ExcludeLitigationResource).name
  }));
};

export const getGenericOptions = (inputArray: string[]): AutoCompleteOption[] =>
  inputArray.map((input) => ({ value: input }));

export const filterByOrganization = (
  inputArray: any[],
  organizationName: string
) =>
  inputArray.filter((input) => input.organization?.name === organizationName);
