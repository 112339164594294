import { Client } from '../../interfaces/entities/Client';
import {
  UpdateClientsAction,
  AddClientAction,
  RemoveClientAction,
  EditClientAction,
  ClearClientsAction,
  ImportClientsAction,
  DeleteManyClientsAction,
} from '../../interfaces/redux/actions/Client';
import {
  UPDATE_CLIENTS,
  ADD_CLIENT,
  REMOVE_CLIENT,
  UPDATE_CLIENT,
  CLEAR_CLIENTS,
  ADD_CLIENTS,
  BULK_DELETE_CLIENTS,
} from '../ActionTypes';

const update = (clients: Client[]): UpdateClientsAction => ({
  type: UPDATE_CLIENTS,
  payload: clients,
});

const add = (client: Client): AddClientAction => ({
  type: ADD_CLIENT,
  payload: client,
});

const remove = (id: Client['id']): RemoveClientAction => ({
  type: REMOVE_CLIENT,
  payload: id,
});

const edit = (client: Client): EditClientAction => ({
  type: UPDATE_CLIENT,
  payload: client,
});

const clear = (): ClearClientsAction => ({
  type: CLEAR_CLIENTS,
});

const addMany = (clients: Client[]): ImportClientsAction => ({
  type: ADD_CLIENTS,
  payload: clients,
});

const removeMany = (clients: Client['id'][]): DeleteManyClientsAction => ({
  type: BULK_DELETE_CLIENTS,
  payload: clients,
});

export default {
  clear,
  update,
  addMany,
  add,
  edit,
  remove,
  removeMany,
};
