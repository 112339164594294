import React, { useState, useEffect } from 'react';
import { Tabs, Button, Card, Radio, Descriptions } from 'antd';
import { useSelector } from 'react-redux';
import {
  selectAuthToken,
  selectAuthenticatedUser,
} from '../../../redux/selectors/Auth';
import { makeRequest, makeDownloadRequest } from '../../../utility/api';
import TaskView from './TaskView';
import HearingView from '../HearingView';
import { User } from '../../../interfaces/entities/User';
import { isAdvancedUser } from '../../../utility/user/privileges';

const { TabPane } = Tabs;

const ReportView: React.FC = () => {
  const [statisticsBasis, setStatisticsBasis] = useState<'weekly' | 'overall'>(
    'weekly'
  );
  const [overallStatistics, setOverallStatistics] = useState<any>({});
  const [statisticsForTheWeek, setStatisticsForTheWeek] = useState<any>({});
  const [overdueTasks, setOverdueTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [unassignedTasks, setUnassignedTasks] = useState([]);
  const [tasksInProgress, setTasksInProgress] = useState([]);
  const [upcomingHearings, setUpcomingHearings] = useState([]);
  const authorization = useSelector(selectAuthToken);
  const user = useSelector(selectAuthenticatedUser) as User;

  const downloadReport = () => {
    makeDownloadRequest({
      url: 'admin/reports/task/download',
      type: 'GET',
      mime: 'application/vnd.ms-excel',
      filename: 'Compliance Report.xlsx',
      authorization,
    });
  };

  useEffect(() => {
    makeRequest({
      url: 'admin/reports/task',
      successCallback: (response) => {
        const { data } = response;
        const {
          summary,
          completed,
          overdue,
          unassigned,
          inProgress,
          upcomingHearings,
        } = data;
        const { thisWeek, overall } = summary;
        setStatisticsForTheWeek(thisWeek);
        setOverallStatistics(overall);
        setOverdueTasks(overdue);
        setCompletedTasks(completed);
        setUnassignedTasks(unassigned);
        setTasksInProgress(inProgress);
        setUpcomingHearings(upcomingHearings);
      },
      type: 'GET',
      authorization,
    });
  }, [authorization]);

  const downloadButton = (
    <Button type='primary' ghost onClick={downloadReport}>
      Download
    </Button>
  );

  return (
    <Card>
      <Tabs
        defaultActiveKey='1'
        size='middle'
        tabBarExtraContent={isAdvancedUser(user) ? downloadButton : null}
      >
        <TabPane tab='Summary' key='1'>
          <div style={{ width: '40%', margin: 'auto', marginBottom: '10px' }}>
            <Radio.Group
              defaultValue='weekly'
              buttonStyle='solid'
              size='large'
              onChange={(e) => {
                setStatisticsBasis(e.target.value);
              }}
            >
              <Radio.Button value='weekly'>This Week</Radio.Button>
              <Radio.Button value='overall'>Overall</Radio.Button>
            </Radio.Group>
          </div>
          <Descriptions bordered>
            <Descriptions.Item label='Tasks Completed' span={4}>
              {(statisticsBasis === 'weekly'
                ? statisticsForTheWeek
                : overallStatistics
              ).completed || 0}
            </Descriptions.Item>
            <Descriptions.Item label='Tasks Created' span={4}>
              {(statisticsBasis === 'weekly'
                ? statisticsForTheWeek
                : overallStatistics
              ).created || 0}
            </Descriptions.Item>
            <Descriptions.Item label='Overdue Tasks' span={4}>
              {(statisticsBasis === 'weekly'
                ? statisticsForTheWeek
                : overallStatistics
              ).overdue || 0}
            </Descriptions.Item>
            <Descriptions.Item label='Compliance' span={4}>
              {(statisticsBasis === 'weekly'
                ? statisticsForTheWeek
                : overallStatistics
              ).compliance || 0}
              %
            </Descriptions.Item>
          </Descriptions>
        </TabPane>
        <TabPane tab='Overdue Tasks' key='2'>
          <TaskView isCompleted={false} tasks={overdueTasks} />
        </TabPane>
        <TabPane tab='Completed Tasks' key='3'>
          <TaskView isCompleted={true} tasks={completedTasks} />
        </TabPane>
        <TabPane tab='Unassigned Tasks' key='4'>
          <TaskView isCompleted={false} tasks={unassignedTasks} />
        </TabPane>
        <TabPane tab='Tasks In Progress' key='5'>
          <TaskView isCompleted={false} tasks={tasksInProgress} />
        </TabPane>
        <TabPane tab='Upcoming Hearings' key='6'>
          <HearingView hearings={upcomingHearings} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default ReportView;
