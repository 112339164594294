import React, { useState } from 'react';
import { Litigation } from '../../interfaces/entities/Litigation';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthToken,
  selectAuthenticatedUser,
} from '../../redux/selectors/Auth';
import { selectAllStarredCases } from '../../redux/selectors/StarredCase';
import { makeRequest, makeDownloadRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import ArchivedCase from '../../redux/actions/ArchivedCase';
import StarredCase from '../../redux/actions/StarredCase';
import LitigationActions from '../../redux/actions/Litigation';
import { Space, Tooltip, Button } from 'antd';
import {
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import ModalForm from '../form/ModalForm';
import { Link } from 'react-router-dom';
import { UPDATE_APPEAL, EDIT_APPEAL } from '../../utility/routing/routes';
import TableDrawer from '../table/Drawer';
import { getDrawerContent } from './DrawerContent';
import { User } from '../../interfaces/entities/User';
import { isAdvancedUser } from '../../utility/user/privileges';
import { Appeal } from '../../interfaces/entities/Appeal';

interface ActionColumnProps {
  record: Appeal;
}

const ViewAllAppealsActionColumn: React.FC<ActionColumnProps> = ({
  record,
}) => {
  const [shouldShowDrawer, setShouldShowDrawer] = useState<boolean>(false);
  const [renderModal, setRenderModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const authorization = useSelector(selectAuthToken);
  const starredCases = useSelector(selectAllStarredCases);
  const user = useSelector(selectAuthenticatedUser) as User;

  const closeModal = () => {
    setRenderModal(false);
  };

  const updateArchivedCaseState = (data: Litigation[]) => {
    dispatch(ArchivedCase.update(data));
  };

  const updateStarredCaseState = (data: Litigation[]) => {
    dispatch(StarredCase.update(data));
  };

  const isStarred = !!starredCases.find(({ id }) => id === record.id);

  const archiveCase = () => {
    makeRequest({
      url: `case/archive/${record.id}`,
      type: 'POST',
      authorization,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        dispatch(LitigationActions.editMany(data));
        updateArchivedCaseState(data);
      },
    });
  };

  const starCase = () => {
    makeRequest({
      url: 'case/star',
      values: {
        id: record.id,
      },
      authorization,
      type: 'POST',
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateStarredCaseState(data);
      },
    });
  };

  const downloadCaseUpdateFile = () => {
    makeDownloadRequest({
      url: `appeal/update/${record.id}`,
      type: 'GET',
      authorization,
      filename: `${record.number}.pdf`,
      mime: 'application/pdf',
    });
  };

  return (
    <>
      <Space size='middle'>
        <Tooltip placement='bottom' title={`View Appeal`}>
          <Button
            type='text'
            onClick={() => {
              setShouldShowDrawer(true);
            }}
          >
            <EyeOutlined />
          </Button>
        </Tooltip>
        <Tooltip placement='bottom' title={`Edit Appeal`}>
          <Button type='text'>
            <Link to={`${EDIT_APPEAL}/${record.id}`}>
              <EditOutlined />
            </Link>
          </Button>
        </Tooltip>
        {/* {hasAdvancedPrivileges && (
          <Tooltip placement='bottom' title={`Generate Invoice`}>
            <Button
              type='text'
              onClick={() => {
                setRenderModal(true);
              }}
            >
              <SnippetsOutlined />
            </Button>
          </Tooltip>
        )} */}
        <Tooltip placement='bottom' title='Provide Appeal Update'>
          <Button type='text'>
            <Link to={`${UPDATE_APPEAL}/${record.id}`}>
              <CheckCircleOutlined />
            </Link>
          </Button>
        </Tooltip>
      </Space>
      <Space size='small'>
        {/* {hasAdvancedPrivileges && (
          <Tooltip
            placement='bottom'
            title={`${isStarred ? 'Unstar' : 'Star'} Case`}
          >
            <Button type='text' onClick={starCase}>
              {isStarred ? (
                <StarFilled style={{ color: 'red' }} />
              ) : (
                <StarOutlined />
              )}
            </Button>
          </Tooltip>
        )} */}
        <Tooltip placement='bottom' title='Download Appeal Update File'>
          <Button type='text' onClick={downloadCaseUpdateFile}>
            <DownloadOutlined />
          </Button>
        </Tooltip>
        {/* {hasAdvancedPrivileges && (
          <Tooltip placement='bottom' title='Archive Case'>
            <Button type='text' onClick={archiveCase}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
        )} */}
      </Space>
      <ModalForm
        showModal={renderModal}
        formToRender={'invoice'}
        showOrganizationDropdown={false}
        successCallback={closeModal}
        cancel={closeModal}
        resource={record}
      />
      <TableDrawer
        title={`Suit ${record.number}`}
        content={getDrawerContent(record)}
        shouldShowDrawer={shouldShowDrawer}
        closeDrawer={() => {
          setShouldShowDrawer(false);
        }}
        width={650}
      />
    </>
  );
};

export default ViewAllAppealsActionColumn;
