import React, { useState } from 'react';
import { Client } from '../../interfaces/entities/Client';
import { Redirect } from 'react-router-dom';
import { VIEW_ALL_CLIENTS } from '../../utility/routing/routes';
import { Card } from 'antd';
import BaseClientForm from './Base';

interface Props {
  client?: Client;
}

const ClientForm: React.FC<Props> = ({ client }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const successCallback = () => {
    setShouldRedirect(true);
  };

  return shouldRedirect ? (
    <Redirect to={VIEW_ALL_CLIENTS} />
  ) : (
    <Card title={client ? `Edit ${client.name}` : 'Add New Client'} bordered>
      <BaseClientForm client={client} successCallback={successCallback} />
    </Card>
  );
};

export default ClientForm;
