import React, { useState } from 'react';
import { Space, Tooltip, Button, Typography } from 'antd';
import {
  EyeOutlined,
  EditFilled,
  CloseCircleOutlined,
  PlusCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import TableDrawer from '../table/Drawer';
import { Link } from 'react-router-dom';
import { EDIT_ORGANIZATION } from '../../utility/routing/routes';
import { Organization } from '../../interfaces/entities/Organization';
import { makeDownloadRequest, makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import OrganizationActions from '../../redux/actions/Organization';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '../../redux/selectors/Auth';

interface ActionColumnProps {
  record: Organization;
}

const { Paragraph } = Typography;

const ViewAllOrganizationsActionColumn: React.FC<ActionColumnProps> = ({
  record,
}) => {
  const [shouldShowDrawer, setShouldShowDrawer] = useState<boolean>(false);
  const authorization = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const phoneNumber = `${record.countryCode}${record.phoneNumber}`;

  const updateIsEnabled = ({ id, status }: Organization) => {
    makeRequest({
      url: 'organization/status/enabled/update',
      values: {
        id,
        isEnabled: !(status === 'Enabled'),
      },
      successCallback: (response) => {
        showToast(response.message);
        dispatch(OrganizationActions.edit(response.data));
      },
      authorization,
      type: 'POST',
    });
  };

  const downloadReport = (id: number) => {
    makeDownloadRequest({
      url: `/organization/report/${id ? `single/${id}` : 'download'}`,
      type: 'GET',
      mime: 'application/vnd.ms-excel',
      filename: 'Organization Usage Report.xlsx',
      authorization,
    });
  };

  const openDrawer = () => {
    setShouldShowDrawer(true);
  };

  const closeDrawer = () => {
    setShouldShowDrawer(false);
  };

  const drawerContent = (
    <>
      <div style={{ width: '50%', margin: 'auto' }}>
        <img
          src={`${process.env.REACT_APP_API_URL}/logo/${
            record.logo ?? 'logo.jpeg'
          }`}
          alt={`${record.name} logo`}
          style={{ width: '100%' }}
        />
      </div>
      <p style={{ marginTop: '10px' }}>
        <b>Address</b>
      </p>
      <p>{`${record.address}`}</p>
      <p style={{ marginTop: '10px' }}>
        <b>Phone Number</b>
      </p>
      <Paragraph copyable={{ text: phoneNumber }}>
        <a href={`tel:${phoneNumber}`}>{`${phoneNumber}`}</a>
      </Paragraph>
      <p style={{ marginTop: '10px' }}>
        <b>Email Address</b>
      </p>
      <Paragraph copyable={{ text: record.emailAddress }}>
        <a href={`mailto:${record.emailAddress}`}>{`${record.emailAddress}`}</a>
      </Paragraph>
      <p style={{ marginTop: '10px' }}>
        <b>Status</b>
      </p>
      <p>{`${record.status}`}</p>
      <p style={{ marginTop: '10px' }}>
        <b>VAT Rate</b>
      </p>
      <p>{`${record.vatRate}%`}</p>
    </>
  );

  return (
    <>
      <Space size='middle'>
        <Tooltip placement='bottom' title={'Download Usage'}>
          <Button
            type='text'
            onClick={() => {
              downloadReport(record.id);
            }}
          >
            <DownloadOutlined />
          </Button>
        </Tooltip>
        <Tooltip placement='bottom' title={'View Organization'}>
          <Button type='text' onClick={openDrawer}>
            <EyeOutlined />
          </Button>
        </Tooltip>
        <Tooltip placement='bottom' title={'Edit Organization'}>
          <span>
            <Link to={`${EDIT_ORGANIZATION}/${record.id}`}>
              <EditFilled />
            </Link>
          </span>
        </Tooltip>
        <Tooltip
          placement='bottom'
          title={`${
            record.status === 'Enabled' ? 'Disable' : 'Enable'
          } Organization`}
        >
          <Button
            type='text'
            onClick={() => {
              if (record) {
                updateIsEnabled(record);
              }
            }}
          >
            {record.status === 'Enabled' ? (
              <CloseCircleOutlined />
            ) : (
              <PlusCircleOutlined />
            )}
          </Button>
        </Tooltip>
      </Space>
      <TableDrawer
        title={`${record.name}`}
        shouldShowDrawer={shouldShowDrawer}
        closeDrawer={closeDrawer}
        content={drawerContent}
      />
    </>
  );
};

export default ViewAllOrganizationsActionColumn;
