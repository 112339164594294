import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { makeRequest, makeDownloadRequest } from '../../utility/api';
import { Skeleton, Button, Space, Tooltip } from 'antd';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import ViewOrganizationUsersModal from './ViewUsers';
import TableView from '../table/ViewAll';
import ViewAllOrganizationsActionColumn from './ViewAllAction';
import { showToast } from '../../utility/notification/toast';
import {
  Organization,
  OrganizationDetail,
} from '../../interfaces/entities/Organization';
import OrganizationActions from '../../redux/actions/Organization';
import { DownloadOutlined } from '@ant-design/icons';

const OrganizationReport: React.FC = () => {
  const authorization = useSelector(selectAuthToken);
  const organizations = useSelector(selectAllOrganizations);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [organizationData, setOrganizationData] = useState<any>({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedOrganization, setSelectedOrganization] = useState<string>('');

  const downloadReport = (id: number | null = null) => {
    makeDownloadRequest({
      url: `/organization/report/${id ? `single/${id}` : 'download'}`,
      type: 'GET',
      mime: 'application/vnd.ms-excel',
      filename: 'Organization Usage Report.xlsx',
      authorization,
    });
  };

  const dispatch = useDispatch();

  const bulkUpdateOrganizations = (organizationArray: Organization['id'][]) => {
    const values = {
      organizations: organizationArray.map((organization) => ({
        id: organization,
        isEnabled: !(
          organizations.find(({ id }) => id === organization)?.status ===
          'Enabled'
        ),
      })),
    };
    makeRequest({
      url: 'organization/bulk/status/enabled/update',
      values,
      successCallback: (response) => {
        showToast(response.message);
        dispatch(OrganizationActions.bulkEdit(response.data));
      },
      authorization,
    });
  };

  const getOrganization = (organizationName: string) =>
    organizations.find(({ name }) => name === organizationName);

  const formatResponse = (data: object): OrganizationDetail[] => {
    const formattedData: OrganizationDetail[] = [];
    for (const [key, value] of Object.entries(data)) {
      const organization = getOrganization(key);
      const id = organization ? organization.id : 0;
      formattedData.push({
        id,
        name: key,
        numberOfUsers: value.length,
      });
    }
    return formattedData;
  };

  useEffect(() => {
    makeRequest({
      url: '/organization/report',
      type: 'GET',
      authorization,
      successCallback: (response) => {
        const { data } = response;
        setOrganizationData(data);
        setIsLoading(false);
      },
    });
  }, [authorization]);

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      shouldSort: true,
      shouldSearch: true,
      width: 200,
    },
    {
      title: 'Number of Users',
      dataIndex: 'numberOfUsers',
      shouldSort: true,
      shouldSearch: false,
    },
    {
      title: '',
      dataIndex: 'name',
      shouldSearch: false,
      shouldSort: false,
      render: (text: any, record: OrganizationDetail) => (
        <Button
          onClick={() => {
            setSelectedOrganization(record.name);
            setShowModal(true);
          }}
        >
          View Users
        </Button>
      ),
    },
  ];

  return isLoading ? (
    <Skeleton loading={isLoading} active />
  ) : (
    <>
      <TableView
        actionColumn={{
          render: (text: any, record: OrganizationDetail) => {
            const organization = getOrganization(record.name) as Organization;
            return organization ? (
              <ViewAllOrganizationsActionColumn record={organization} />
            ) : (
              <Space size='middle'>
                <Tooltip placement='bottom' title={'Download Usage'}>
                  <Button
                    type='text'
                    onClick={() => {
                      downloadReport(-1);
                    }}
                  >
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
              </Space>
            );
          },
        }}
        additionalMenuItem={
          <Button
            type='primary'
            ghost
            onClick={() => {
              downloadReport();
            }}
          >
            Download Report
          </Button>
        }
        columns={tableColumns}
        data={formatResponse(organizationData)}
        bulkAction={bulkUpdateOrganizations}
        resourceType={'organization'}
        bulkActionText='Enable/Disable Selection'
      />

      <ViewOrganizationUsersModal
        users={organizationData[selectedOrganization]}
        isVisible={showModal}
        organizationName={selectedOrganization}
        close={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

export default OrganizationReport;
