import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VIEW_ALL_USERS } from '../../utility/routing/routes';
import { selectUser } from '../../redux/selectors/User';
import { AppState } from '../../interfaces/redux/state/App';
import NotPermitted from '../utility/NotPermitted';
import UserForm from './User';

const EditUserForm: React.FC = () => {
  const { id } = useParams();
  const user = useSelector((appState) => selectUser(appState as AppState, +id));

  if (!user) {
    return (
      <NotPermitted
        returnUrl={VIEW_ALL_USERS}
        message='Could not find a user with the specified ID'
      />
    );
  }
  return <UserForm user={user} />;
};

export default EditUserForm;
