import React from 'react';
import { Store } from 'antd/lib/form/interface';
import Form from 'antd/lib/form/Form';
import SubmitButton from '../form/SubmitButton';
import TextInput from '../form/TextInput';
import DatePickerInput from '../form/DatePickerInput';
import NumberInput from '../form/NumberInput';

interface Props {
  handleFinish: (values: Store) => void;
  isLoading: boolean;
}

const InvoiceFormContainer: React.FC<Props> = ({ handleFinish, isLoading }) => {
  return (
    <Form onFinish={handleFinish}>
      <TextInput
        name='invoiceNumber'
        label='Invoice Number'
        placeholder='Invoice Number'
        message='Please provide an invoice number'
      />
      <DatePickerInput
        name='dueDate'
        label='Due Date'
        required={true}
        requiredMessage='Please select a date'
        disableFutureDates={false}
      />
      {/* <NumberInput
        name='invoiceAmount'
        label='Invoice Amount'
        placeholder='Invoice Amount'
        required={true}
        requiredMessage='Please enter invoice amount'
        formatter={(value) =>
          value === '' ? '' : `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        parser={(value) => value.replace(/[a-zA-Z\W,]+/g, '')}
      /> */}
      <SubmitButton isLoading={isLoading} buttonText={'Generate Invoice'} />
    </Form>
  );
};

export default InvoiceFormContainer;
