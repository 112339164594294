import { UPDATE_TOKEN, SET_LOGGED_IN_USER, CLEAR_USER } from '../ActionTypes';
import { AuthState } from '../../interfaces/redux/state/Auth';
import { AuthenticationAction } from '../../interfaces/redux/actions/Auth';

const initialState: AuthState = {
  token: '',
  refreshToken: '',
  user: {},
  lastUpdatedTime: '',
};

const reducer = (
  state: AuthState = initialState,
  action: AuthenticationAction
): AuthState => {
  switch (action.type) {
    case UPDATE_TOKEN:
      const { token, refresh_token: refreshToken, updateTime } = action.payload;
      return {
        ...state,
        token,
        refreshToken,
        lastUpdatedTime: updateTime,
      };

    case SET_LOGGED_IN_USER:
      return {
        ...state,
        user: action.payload,
      };

    case CLEAR_USER:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
