export const getIntervalBetweenDates = (
  date1: Date,
  date2: Date
): number | null => {
  const utc1 = getUTCFromDate(date1);
  const utc2 = getUTCFromDate(date2);
  const interval = Math.floor(Math.abs(utc2 - utc1));
  return isNaN(interval) ? null : interval;
};

const getUTCFromDate = (date: Date): number =>
  Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );

export const convertMomentDateToString = (momentDate: any) =>
  momentDate ? momentDate.format('DD/MM/YYYY') : null;

export const formatAPIDate = (inputDate: string) => {
  const [day, month, year] = inputDate.split(' ').slice(1);
  return `${month.replace(',', '')} ${Number.parseInt(day)}, ${year}`;
};
