import React from 'react';
import TextInput from '../../form/TextInput';
import { Client } from '../../../interfaces/entities/Client';

interface Props {
  client?: Client;
}
const CorporateClientForm: React.FC<Props> = ({ client }) => {
  return (
    <TextInput
      name='name'
      message="Please enter the client's name"
      label='Corporate Name'
      placeholder='Corporate Name'
      initialValue={client?.name}
    />
  );
};

export default CorporateClientForm;
