import React from 'react';
import { Drawer } from 'antd';

interface TableDrawerProps {
  title: string;
  content: JSX.Element;
  shouldShowDrawer: boolean;
  closeDrawer: () => void;
  width?: number;
}

const TableDrawer: React.FC<TableDrawerProps> = ({
  title,
  content,
  shouldShowDrawer,
  closeDrawer,
  width,
}) => {
  return (
    <Drawer
      width={width}
      title={title}
      placement={'right'}
      closable={false}
      onClose={closeDrawer}
      visible={shouldShowDrawer}
      key={'right'}
    >
      {content}
    </Drawer>
  );
};

export default TableDrawer;
