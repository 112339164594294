import React from 'react';
import TextInput from '../../form/TextInput';
import DatePickerInput from '../../form/DatePickerInput';
import { Client } from '../../../interfaces/entities/Client';
import { formatAPIDate } from '../../../utility/date';
import moment from 'moment';

interface Props {
  client?: Client;
}

const getInitialDateOfBirth = (client: Client | undefined) => {
  if (client && client.dateOfBirth) {
    return moment(formatAPIDate(client.dateOfBirth));
  }
};

const IndividualClientForm: React.FC<Props> = ({ client }) => {
  return (
    <>
      <TextInput
        label='First Name'
        placeholder='First Name'
        name='firstName'
        message="Please enter the client's first name"
        initialValue={client?.firstName}
      />
      <TextInput
        label='Middle Name'
        placeholder='Middle Name'
        name='middleName'
        required={false}
        initialValue={client?.middleName}
      />
      <TextInput
        label='Last Name'
        placeholder='Last Name'
        name='lastName'
        message="Please enter the client's last name"
        initialValue={client?.lastName}
      />
      <DatePickerInput
        name='dateOfBirth'
        label='Date of Birth'
        placeholder='Date of Birth'
        initialValue={getInitialDateOfBirth(client)}
        required={false}
      />
    </>
  );
};

export default IndividualClientForm;
