import React, { useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import { Task } from '../../interfaces/entities/Task';
import { Organization } from '../../interfaces/entities/Organization';
import { User } from '../../interfaces/entities/User';
import { Litigation } from '../../interfaces/entities/Litigation';
import { useForm } from 'antd/lib/form/Form';
import { filterByOrganization, getOptions } from '../../utility/array';
import moment from 'moment';
import { formatAPIDate } from '../../utility/date';
import { ResourceType } from '../../interfaces/entities/Base';
import ModalForm from '../form/ModalForm';
import Form from 'antd/lib/form';
import AutoCompleteInput from '../form/AutoCompleteInput';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';
import TextInput from '../form/TextInput';
import DatePickerInput from '../form/DatePickerInput';
import TextAreaInput from '../form/TextAreaInput';
import SubmitButton from '../form/SubmitButton';
import { trimSuitName } from '../../utility/stringFormatter';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FileInput, { UploadMedium } from '../form/FileInput';

interface Props {
  isLoading: boolean;
  handleFinish: (values: Store) => void;
  task?: Task;
  organizations: Organization[];
  cases: Litigation[];
  users: User[];
  authenticatedUser: User;
  updateUploadMedium: (value: UploadMedium) => void;
  removeSelectedFile: (file: any) => void;
  addSelectedFile: (file: any) => void;
  uploadMedium: UploadMedium;
}

const TaskBaseFormContainer: React.FC<Props> = (
  {
    isLoading,
    handleFinish,
    task,
    organizations,
    cases,
    users,
    updateUploadMedium,
    removeSelectedFile,
    addSelectedFile,
    uploadMedium,
    authenticatedUser
  }) => {
  const [form] = useForm();
  const userIsSuperAdmin = authenticatedUser.isSuperAdmin;
  const authenticatedOrganization = (authenticatedUser.organization as Organization)
    ?.name;
  const [selectedOrganization, setSelectedOrganization] = useState(
    task ? task.organization.name : authenticatedOrganization
  );
  const [renderModal, setRenderModal] = useState(false);
  const [formToRender, setFormToRender] = useState('');
  const [userOptions, setUserOptions] = useState(
    getOptions(
      filterByOrganization(
        users,
        task ? task.organization.name : authenticatedOrganization
      )
    )
  );
  const [caseOptions, setCaseOptions] = useState(
    getOptions(
      filterByOrganization(
        cases,
        task ? task.organization.name : authenticatedOrganization
      ),
      true
    )
  );

  const [assignedUsers, setAssignedUsers] = useState(task?.assignedUsers || [null] as unknown as User[]);

  const getFormattedUsers = (values: Store) => {
    const formattedUsers = [];
    for (
      let index = 0, stopIndex = assignedUsers.length;
      index < stopIndex;
      index++
    ) {
      formattedUsers.push(values[`assignee[${index}]`]);
    }
    return formattedUsers;
  };

  const getInitialDueDate = (task: Task | undefined) => {
    if (task && task.dueDate) {
      return moment(formatAPIDate(task.dueDate));
    }
  };

  const updateOptions = (selectedOrganization: string) => {
    setSelectedOrganization(selectedOrganization);
    setCaseOptions(
      getOptions(
        filterByOrganization(cases, selectedOrganization),
        true
      )
    );
    setUserOptions(
      getOptions(filterByOrganization(users, selectedOrganization))
    );

    form.setFieldsValue({
      case: '',
      assignee: '',
      subAssignee: ''
    });
  };

  const showModalForm = (resourceType: ResourceType) => {
    setFormToRender(resourceType);
    setRenderModal(true);
  };

  const closeModal = () => {
    setRenderModal(false);
  };

  return (
    <>
      <ModalForm
        formToRender={formToRender as ResourceType}
        showModal={renderModal}
        showOrganizationDropdown={userIsSuperAdmin}
        successCallback={closeModal}
        cancel={closeModal}
      />
      <Form
        form={form}
        onFinish={values => {
          values.assignedUsers = getFormattedUsers(values);
          handleFinish(values);
        }}
      >
        {userIsSuperAdmin && (
          <AutoCompleteInput
            name='organization'
            label='Organization'
            placeholder='Organization'
            options={getOptions(organizations)}
            onSelect={updateOptions}
            initialValue={selectedOrganization}
          />
        )}

        <Row>
          <Col span={20}>
            <AutoCompleteInput
              name='litigation'
              label='Case'
              placeholder='Case'
              options={caseOptions}
              initialValue={trimSuitName(task?.case.suitName)}
            />
          </Col>
          <Col span={4}>
            <Button
              onClick={() => {
                showModalForm('case');
              }}
            >
              Add Case
            </Button>
          </Col>
        </Row>

        <TextInput
          name='name'
          label='Task Name'
          placeholder='Task Name'
          message="Please enter the task's name"
          initialValue={task?.name}
        />

        <DatePickerInput
          name='dueDate'
          label='Due Date'
          initialValue={getInitialDueDate(task)}
          placeholder='Due Date'
          disableFutureDates={false}
        />

        {
          assignedUsers.map((user, index) => (<Row>
            <Col span={16}>
              <AutoCompleteInput
                name={`assignee[${index}]`}
                label={`Assigned User ${index + 1}`}
                placeholder={`Assigned User ${index + 1}`}
                options={userOptions}
                required={false}
                initialValue={user?.name || undefined}
              />
            </Col>
            <Col span={2}>
              <Button
                onClick={() => {
                  setAssignedUsers(assignedUsers => [...assignedUsers, '' as unknown as User]);
                }}
              >
                <PlusOutlined />
              </Button>
              <Button
                onClick={() => {
                  if (index !== 0) {
                    setAssignedUsers(assignedUsers =>
                      assignedUsers.filter((_, filterIndex) => filterIndex !== index));
                  }
                }}
              >
                <DeleteOutlined />
              </Button>
            </Col>
            <Col span={2}>
              <Button
                onClick={() => {
                  showModalForm('user');
                }}
              >
                Create User
              </Button>
            </Col>
          </Row>))}

        <TextAreaInput
          name='additionalInformation'
          label='Additional Information'
          placeholder='Additional Information'
          required={false}
          initialValue={task?.additionalInformation}
          message='Please enter additional information'
        />
        {!task && (<FileInput
          updateUploadMedium={updateUploadMedium}
          addSelectedFile={addSelectedFile}
          uploadMedium={uploadMedium}
          removeSelectedFile={removeSelectedFile}
        />)}
        <SubmitButton
          isLoading={isLoading}
          buttonText={task ? 'Save' : 'Submit'}
        />
      </Form>
    </>
  );
};

export default TaskBaseFormContainer;
