import { Contact } from '../../interfaces/entities/Contact';
import {
  EditContactAction,
  UpdateContactsAction,
  AddContactAction,
  ImportContactsAction,
  RemoveContactAction,
  ClearContactsAction,
  DeleteManyContactsAction,
} from '../../interfaces/redux/actions/Contact';
import {
  UPDATE_CONTACTS,
  UPDATE_CONTACT,
  ADD_CONTACT,
  ADD_CONTACTS,
  REMOVE_CONTACT,
  CLEAR_CONTACTS,
  BULK_DELETE_CONTACTS,
} from '../ActionTypes';

const update = (contacts: Contact[]): UpdateContactsAction => ({
  type: UPDATE_CONTACTS,
  payload: contacts,
});

const add = (contact: Contact): AddContactAction => ({
  type: ADD_CONTACT,
  payload: contact,
});

const addMany = (contacts: Contact[]): ImportContactsAction => ({
  type: ADD_CONTACTS,
  payload: contacts,
});

const remove = (id: Contact['id']): RemoveContactAction => ({
  type: REMOVE_CONTACT,
  payload: id,
});

const edit = (contact: Contact): EditContactAction => ({
  type: UPDATE_CONTACT,
  payload: contact,
});

const clear = (): ClearContactsAction => ({
  type: CLEAR_CONTACTS,
});

const removeMany = (contacts: Contact['id'][]): DeleteManyContactsAction => ({
  type: BULK_DELETE_CONTACTS,
  payload: contacts,
});

export default {
  update,
  add,
  addMany,
  remove,
  removeMany,
  edit,
  clear,
};
