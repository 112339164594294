import React, { useState } from 'react';
import { Client } from '../../interfaces/entities/Client';
import ClientBaseFormContainer from './BaseContainer';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import {
  selectAuthenticatedUser,
  selectAuthToken,
} from '../../redux/selectors/Auth';
import { User } from '../../interfaces/entities/User';
import { Store } from 'antd/lib/form/interface';
import { Organization } from '../../interfaces/entities/Organization';
import { convertMomentDateToString } from '../../utility/date';
import ClientActions from '../../redux/actions/Client';
import { makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import {
  formatPhoneNumber,
  formatSelectedCountryCode,
} from '../../utility/constants/phoneNumber';

interface Props {
  client?: Client;
  successCallback: () => void;
}

const BaseClientForm: React.FC<Props> = ({ client, successCallback }) => {
  const organizations = useSelector(selectAllOrganizations);
  const authenticatedUser = useSelector(selectAuthenticatedUser) as User;
  const authorization = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formatRequest = (input: Store) => {
    const organizationName = input.organization;
    if (input.countryCode) {
      const newCode = formatSelectedCountryCode(input.countryCode);
      input.countryCode = newCode;
    }
    if (input.phoneNumber) {
      const newPhoneNumber = formatPhoneNumber(input.phoneNumber);
      input.phoneNumber = newPhoneNumber;
    }
    if (client) input.id = client.id;
    input.organization = authenticatedUser.isSuperAdmin
      ? organizations.find(({ name }) => name === organizationName)?.id
      : (authenticatedUser.organization as Organization).id;
    if (input.dateOfBirth) {
      input.dateOfBirth = convertMomentDateToString(input.dateOfBirth);
    }
    return input;
  };

  const updateClientState = (data: Client) => {
    dispatch(client ? ClientActions.edit(data) : ClientActions.add(data));
  };

  const handleFinish = (values: Store) => {
    setIsLoading(true);
    values = formatRequest(values);

    makeRequest({
      url: `client/${client ? 'edit' : 'add'}`,
      values,
      type: 'POST',
      authorization,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateClientState(data);
        successCallback();
      },
      failureCallback: () => {
        setIsLoading(false);
      },
    });
  };

  return <ClientBaseFormContainer {...{ isLoading, handleFinish, client }} />;
};

export default BaseClientForm;
