export const CASE_TABLE_COLUMNS = [
  {
    title: 'Suit Name',
    dataIndex: 'suitName',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Suit Number',
    dataIndex: 'suitNumber',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Hearing Date',
    dataIndex: 'nextHearing',
  },
];

export const PERSON_TABLE_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
  },
  {
    title: 'Email Address',
    dataIndex: 'emailAddress',
  },
];

export const TASK_TABLE_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: 'Due Date',
    dataIndex: 'dueDate',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
];
