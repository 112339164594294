import { UserState } from '../../interfaces/redux/state/App';
import { getGenericStateObject } from '../../utility/redux/state';
import { UserAction } from '../../interfaces/redux/actions/User';
import {
  UPDATE_USER,
  CLEAR_USERS,
  ADD_USER,
  ADD_USERS,
  REMOVE_USER,
  UPDATE_USERS,
  BULK_EDIT_USERS,
} from '../ActionTypes';
import {
  edit,
  add,
  addMany,
  remove,
  update,
  bulkUpdate,
} from '../../utility/redux/reducer';

const initialState: UserState = getGenericStateObject();

const reducer = (
  state: UserState = initialState,
  action: UserAction
): UserState => {
  switch (action.type) {
    case UPDATE_USER:
      return edit(state, action.payload);
    case ADD_USER:
      return add(state, action.payload);
    case ADD_USERS:
      return addMany(state, action.payload);
    case REMOVE_USER:
      return remove(state, action.payload);
    case UPDATE_USERS:
      return update(state, action.payload);
    case BULK_EDIT_USERS:
      return bulkUpdate(state, action.payload);
    case CLEAR_USERS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
