import React, { useState } from 'react';
import { Litigation } from '../../interfaces/entities/Litigation';
import { Store } from 'antd/lib/form/interface';
import { Form } from 'antd';
import AutoCompleteInput from '../form/AutoCompleteInput';
import { AutoCompleteOption } from '../../interfaces/components/form/AutoComplete';
import SubmitButton from '../form/SubmitButton';
import FileInput, { UploadMedium } from '../form/FileInput';

interface Props {
  document?: Document;
  savedCases: Litigation[];
  handleFinish: (values: Store) => void;
  updateUploadMedium: (value: UploadMedium) => void;
  removeSelectedFile: (file: any) => void;
  addSelectedFile: (file: any) => void;
  uploadMedium: UploadMedium;
  userIsSuperAdmin: boolean;
  isLoading: boolean;
  organizationOptions: AutoCompleteOption[];
  prepareCaseOptions: (input: Litigation[]) => AutoCompleteOption[];
  filterCasesByOrganization: (organization: string) => Litigation[];
}

const DocumentFormContainer: React.FC<Props> = ({
                                                  savedCases,
                                                  updateUploadMedium,
                                                  removeSelectedFile,
                                                  addSelectedFile,
                                                  uploadMedium,
                                                  handleFinish,
                                                  isLoading,
                                                  organizationOptions,
                                                  prepareCaseOptions,
                                                  filterCasesByOrganization,
                                                  userIsSuperAdmin
                                                }) => {
  const [form] = Form.useForm();
  const [caseOptions, setCaseOptions] = useState<AutoCompleteOption[]>(
    prepareCaseOptions(savedCases)
  );

  const updateCases = (selectedOrganization: any) => {
    const filteredCases = filterCasesByOrganization(selectedOrganization);
    setCaseOptions(prepareCaseOptions(filteredCases));
    form.setFieldsValue({
      litigation: ''
    });
  };

  return (
    <Form onFinish={handleFinish} form={form}>
      {userIsSuperAdmin && (
        <AutoCompleteInput
          name='organization'
          label='Organization'
          placeholder='Organization'
          options={organizationOptions}
          onSelect={updateCases}
        />
      )}
      <AutoCompleteInput
        name='litigation'
        label='Associated Case'
        placeholder='Associated Case'
        options={caseOptions}
      />
      <FileInput
        updateUploadMedium={updateUploadMedium}
        addSelectedFile={addSelectedFile}
        uploadMedium={uploadMedium}
        removeSelectedFile={removeSelectedFile}
      />
      <SubmitButton buttonText='Submit' isLoading={isLoading} />
    </Form>
  );
};

export default DocumentFormContainer;
