import React, { useState } from 'react';
import { Typography, Space, Tooltip, Button } from 'antd';
import { User } from '../../interfaces/entities/User';
import {
  EyeOutlined,
  EditFilled,
  CloseCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import TableDrawer from '../table/Drawer';
import { Organization } from '../../interfaces/entities/Organization';
import { EDIT_USER } from '../../utility/routing/routes';
import {
  selectAuthenticatedUser,
  selectAuthToken,
} from '../../redux/selectors/Auth';
import { useSelector, useDispatch } from 'react-redux';
import { makeRequest } from '../../utility/api';
import { showToast, showDeleteWarning } from '../../utility/notification/toast';
import UserActions from '../../redux/actions/User';

interface ActionColumnProps {
  record: User;
  isDeletedUser?: boolean;
  updateIsEnabled?: (record: User) => void;
}

const { Paragraph } = Typography;

const ViewAllUsersActionColumn: React.FC<ActionColumnProps> = ({
  record,
  updateIsEnabled = () => {},
  isDeletedUser = false,
}) => {
  const [shouldShowDrawer, setShouldShowDrawer] = useState<boolean>(false);

  const organization = record.organization as Organization;

  const phoneNumber = `${record.countryCode}${record.phoneNumber}`;

  const authorization = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const { isSuperAdmin } = useSelector(selectAuthenticatedUser) as User;

  const canBeDeleted = isSuperAdmin || !record.isOrganizationAdmin;

  const deleteUser = () => {
    const { id } = record;
    const confirmCallback = () => {
      makeRequest({
        url: `admin/user/delete/${id}`,
        type: 'DELETE',
        successCallback: (response) => {
          showToast(response.message);
          if (!isDeletedUser) {
            dispatch(UserActions.remove(id));
          }
        },
        authorization,
      });
    };
    showDeleteWarning(`Delete ${record.name}?`, confirmCallback);
  };

  const openDrawer = () => {
    setShouldShowDrawer(true);
  };

  const closeDrawer = () => {
    setShouldShowDrawer(false);
  };

  const drawerContent = (
    <>
      <p>
        <b>Phone Number</b>
      </p>
      <Paragraph copyable={{ text: phoneNumber }}>
        <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
      </Paragraph>
      <p style={{ marginTop: '10px' }}>
        <b>Email Address</b>
      </p>
      <Paragraph copyable={{ text: record.emailAddress }}>
        <a href={`mailto:${record.emailAddress}`}>{`${record.emailAddress}`}</a>
      </Paragraph>
      {organization && (
        <>
          <p style={{ marginTop: '10px' }}></p>
          <p style={{ marginTop: '10px' }}>
            <b>Organization</b>
          </p>
          <p>{`${organization.name}`}</p>
        </>
      )}
      <p style={{ marginTop: '10px' }}>
        <b>Privilege</b>
      </p>
      <p>{`${record.privilege}`}</p>
      <p style={{ marginTop: '10px' }}>
        <b>Status</b>
      </p>
      <p>{`${record.status}`}</p>
    </>
  );
  return (
    <>
      <Space size='middle'>
        <Tooltip placement='bottom' title={'View User'}>
          <Button type='text' onClick={openDrawer}>
            <EyeOutlined />
          </Button>
        </Tooltip>
        {!isDeletedUser && (
          <>
            <Tooltip placement='bottom' title={'Edit User'}>
              <span>
                <Link to={`${EDIT_USER}/${record.id}`}>
                  <EditFilled />
                </Link>
              </span>
            </Tooltip>
            <Tooltip
              placement='bottom'
              title={`${
                record.status === 'Enabled' ? 'Disable' : 'Enable'
              } User`}
            >
              <Button
                type='text'
                onClick={() => {
                  updateIsEnabled(record);
                }}
              >
                {record.isEnabled ? (
                  <CloseCircleOutlined />
                ) : (
                  <PlusCircleOutlined />
                )}
              </Button>
            </Tooltip>
          </>
        )}
        {canBeDeleted && (
          <Tooltip placement='bottom' title='Delete User'>
            <Button
              type='text'
              onClick={() => {
                deleteUser();
              }}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        )}
      </Space>
      <TableDrawer
        title={`${record.name}`}
        shouldShowDrawer={shouldShowDrawer}
        closeDrawer={closeDrawer}
        content={drawerContent}
      />
    </>
  );
};

export default ViewAllUsersActionColumn;
