import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Card from 'antd/lib/card';
import { Task } from '../../interfaces/entities/Task';
import { VIEW_ALL_TASKS } from '../../utility/routing/routes';
import BaseTaskForm from './Base';

interface Props {
  task?: Task;
}

const TaskForm: React.FC<Props> = ({ task }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const successCallback = () => {
    setShouldRedirect(true);
  };

  return shouldRedirect ? (
    <Redirect to={VIEW_ALL_TASKS} />
  ) : (
    <Card title={task ? `Edit ${task.name}` : 'Add New Task'} bordered>
      <BaseTaskForm successCallback={successCallback} task={task} />
    </Card>
  );
};

export default TaskForm;
