import React from 'react';
import { Document } from '../../interfaces/entities/Document';
import { makeRequest, makeDownloadRequest } from '../../utility/api';
import DocumentActions from '../../redux/actions/Document';
import { showToast, showDeleteWarning } from '../../utility/notification/toast';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { Space, Tooltip, Button } from 'antd';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';

interface ActionColumnProps {
  record: Document;
}

const ViewAllDocumentsActionColumn: React.FC<ActionColumnProps> = ({
  record,
}) => {
  const dispatch = useDispatch();
  const authorization = useSelector(selectAuthToken);

  const deleteDocument = () => {
    const { id } = record;
    const confirmCallback = () => {
      makeRequest({
        url: `document/delete/${id}`,
        type: 'DELETE',
        successCallback: (response) => {
          showToast(response.message);
          dispatch(DocumentActions.remove(id));
        },
        authorization,
      });
    };
    showDeleteWarning(`Delete ${record.title}?`, confirmCallback);
  };

  const downloadDocument = () => {
    makeDownloadRequest({
      url: `document/${record.location}`,
      type: 'GET',
      authorization,
      filename: `${record.title}.${record.location?.split('.')[1]}`,
    });
  };
  return (
    <>
      <Space size='middle'>
        <Tooltip
          placement='bottom'
          title={`${record.location ? 'Download' : 'View'} Document`}
        >
          {record.location ? (
            <Button type='text' onClick={downloadDocument}>
              <DownloadOutlined />
            </Button>
          ) : (
            <a
              style={{ color: 'inherit', margin: '17px' }}
              href={record.url as string}
              target='_blank'
              rel='noopener noreferrer'
            >
              <DownloadOutlined />
            </a>
          )}
        </Tooltip>
        <Tooltip placement='bottom' title='Delete Document'>
          <Button type='text' onClick={deleteDocument}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      </Space>
    </>
  );
};

export default ViewAllDocumentsActionColumn;
