import { Litigation } from '../../interfaces/entities/Litigation';
import { UPDATE_ARCHIVE, CLEAR_ARCHIVE } from '../ActionTypes';
import {
  UpdateArchivedCasesAction,
  ClearArchivedCasesAction,
} from '../../interfaces/redux/actions/ArchivedCase';

const update = (cases: Litigation[]): UpdateArchivedCasesAction => ({
  type: UPDATE_ARCHIVE,
  payload: cases,
});

const clear = (): ClearArchivedCasesAction => ({
  type: CLEAR_ARCHIVE,
});

export default {
  update,
  clear,
};
