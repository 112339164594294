import { AppState } from '../../interfaces/redux/state/App';
import { selectAllItems, selectItem, selectCount } from './Generic';
import { Document } from '../../interfaces/entities/Document';

export const selectAllDocuments = ({ documents }: AppState): Document[] =>
  selectAllItems(documents);

export const selectDocument = (
  { documents }: AppState,
  id: number
): Document | null => selectItem(id, documents);

export const selectDocumentCount = ({ documents }: AppState): number =>
  selectCount(documents);
