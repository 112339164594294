import React from 'react';
import Form from 'antd/lib/form';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const TaskUpdateComponent: React.FC = () => {
  return (
    <Form.Item label='Task Status' name='isCompleted' valuePropName='checked'>
      <Checkbox>Task is completed</Checkbox>
    </Form.Item>
  );
};

export default TaskUpdateComponent;
