import { AppState } from '../../interfaces/redux/state/App';
import { selectAllItems, selectItem, selectCount } from './Generic';
import { Contact } from '../../interfaces/entities/Contact';

export const selectAllContacts = ({ contacts }: AppState): Contact[] =>
  selectAllItems(contacts);

export const selectContact = (
  { contacts }: AppState,
  id: number
): Contact | null => selectItem(id, contacts);

export const selectContactCount = ({ contacts }: AppState): number =>
  selectCount(contacts);
