import { ContactState } from '../../interfaces/redux/state/App';
import { getGenericStateObject } from '../../utility/redux/state';
import { ContactAction } from '../../interfaces/redux/actions/Contact';
import {
  UPDATE_CONTACTS,
  ADD_CONTACT,
  REMOVE_CONTACT,
  UPDATE_CONTACT,
  ADD_CONTACTS,
  CLEAR_CONTACTS,
  BULK_DELETE_CONTACTS,
} from '../ActionTypes';
import {
  update,
  add,
  remove,
  edit,
  addMany,
  removeMany,
} from '../../utility/redux/reducer';

const initialState: ContactState = getGenericStateObject();

const reducer = (
  state: ContactState = initialState,
  action: ContactAction
): ContactState => {
  switch (action.type) {
    case UPDATE_CONTACTS:
      return update(state, action.payload);
    case ADD_CONTACT:
      return add(state, action.payload);
    case REMOVE_CONTACT:
      return remove(state, action.payload);
    case UPDATE_CONTACT:
      return edit(state, action.payload);
    case ADD_CONTACTS:
      return addMany(state, action.payload);
    case BULK_DELETE_CONTACTS:
      return removeMany(state, action.payload);
    case CLEAR_CONTACTS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
