import React from 'react';
import './css/App.css';
import { useSelector } from 'react-redux';
import { getIntervalBetweenDates } from './utility/date';
import { TOKEN_REFRESH_INTERVAL } from './utility/constants/constants';
import Authentication from './components/authentication/Index';
import { selectLastUpdateTime } from './redux/selectors/Auth';
import Dashboard from './components/dashboard/Index';

const isStillValid = (lastUpdate: string) => {
  const interval = getIntervalBetweenDates(new Date(lastUpdate), new Date());
  return interval !== null && interval < TOKEN_REFRESH_INTERVAL;
};

const App: React.FC = () => {
  const lastUpdatedTime = useSelector(selectLastUpdateTime);

  return isStillValid(lastUpdatedTime) ? <Dashboard /> : <Authentication />;
};

export default App;
