import React, { useEffect, useRef, useState } from 'react';
import '../../css/dashboard.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  USER_ACTIVITY_EVENTS,
  TOKEN_REFRESH_INTERVAL,
} from '../../utility/constants/constants';
import { refreshToken } from '../../utility/auth/authentication';
import { selectRefreshToken } from '../../redux/selectors/Auth';
import { Layout, Typography, BackTop, Row, Col, Button } from 'antd';
import DashboardHeader from '../menu/Header';
import DashboardSidebar from '../menu/Sidebar';
import Auth from '../../redux/actions/Auth';
import Routes from '../routing/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import PrivacyPolicy from '../privacy/PrivacyPolicy';

const { Content, Footer } = Layout;
const { Title } = Typography;
const Dashboard: React.FC = () => {
  const dispatch = useDispatch();

  const refresh_token = useSelector(selectRefreshToken);
  const logout = () => {
    dispatch(Auth.logout());
  };
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false);

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  let inactivityDuration = useRef(0);

  const resetIdleTimeDuration = () => {
    inactivityDuration.current = 0;
  };

  const increaseIdleTimeDuration = () => {
    inactivityDuration.current++;
    if (inactivityDuration.current > 59) {
      logout();
    }
  };

  let tokenRefreshTimer: NodeJS.Timeout = setInterval(
    increaseIdleTimeDuration,
    60000
  );

  let inactivityTimer: NodeJS.Timeout = setInterval(() => {
    refreshToken({ dispatch, refresh_token });
  }, TOKEN_REFRESH_INTERVAL);

  useEffect(() => {
    USER_ACTIVITY_EVENTS.forEach((eventName) => {
      document.addEventListener(eventName, resetIdleTimeDuration, true);
    });

    return () => {
      clearInterval(tokenRefreshTimer);
      clearInterval(inactivityTimer);
    };
  }, [inactivityTimer, tokenRefreshTimer]);

  return (
    <Router>
      <Layout>
        <BackTop />
        <DashboardHeader logout={logout} />
        <Content style={{ marginTop: '30px', position: 'relative' }}>
          <Layout
            style={{
              background: '#fff',
              minHeight: '100vh',
            }}
          >
            <DashboardSidebar {...{ collapsed, onCollapse }} />
            <Layout>
              <Content
                style={{
                  background: '#fff',
                  minHeight: 280,
                  paddingTop: '20px',
                }}
              >
                <div
                  style={{
                    padding: '40px',
                    background: '#fff',
                    minHeight: 360,
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    <Row
                      justify='center'
                      align='middle'
                      style={{ textAlign: 'center' }}
                    >
                      <Col style={{ width: '100%' }}>
                        <Routes />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Content>
              <Footer style={{ textAlign: 'center', background: '#fff' }}>
                <Title level={4}>
                  ©{new Date().getFullYear()} GliderTech. All Rights Reserved
                </Title>
                <Button
                  type='link'
                  onClick={() => {
                    setShowPrivacyPolicy(true);
                  }}
                >
                  Privacy Policy
                </Button>
                <PrivacyPolicy
                  showPolicy={showPrivacyPolicy}
                  updateShowPolicy={setShowPrivacyPolicy}
                />
              </Footer>
            </Layout>
          </Layout>
        </Content>
      </Layout>
    </Router>
  );
};

export default Dashboard;
