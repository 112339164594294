import React, { useState } from 'react';
import { Litigation } from '../../interfaces/entities/Litigation';
import { Store } from 'antd/lib/form/interface';
import {
  selectAuthenticatedUser,
  selectAuthToken,
} from '../../redux/selectors/Auth';
import { useSelector, useDispatch } from 'react-redux';
import { getObjectId } from '../../utility/array';
import { User } from '../../interfaces/entities/User';
import { convertMomentDateToString } from '../../utility/date';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import { selectAllClients } from '../../redux/selectors/Client';
import { selectAllUsers } from '../../redux/selectors/User';
import { makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import LitigationActions from '../../redux/actions/Litigation';
import CaseBaseFormContainer from './BaseContainer';
import { AutoCompleteOption } from '../../interfaces/components/form/AutoComplete';

interface Props {
  litigation?: Litigation;
  successCallback: () => void;
}

const BaseCaseForm: React.FC<Props> = ({ litigation, successCallback }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authorization = useSelector(selectAuthToken);
  const authenticatedUser = useSelector(selectAuthenticatedUser) as User;
  const organizations = useSelector(selectAllOrganizations);
  const clients = useSelector(selectAllClients);
  const users = useSelector(selectAllUsers);
  const dispatch = useDispatch();

  const formatRequest = (values: Store) => {
    const {
      organization,
      filingDate,
      nextHearingDate,
      users: assignedUsers,
      clients: associatedClients,
    } = values;
    if (litigation) values.id = litigation.id;
    values.organization = authenticatedUser.isSuperAdmin
      ? getObjectId(organization, organizations)
      : authenticatedUser.organization?.id;
    values.clients = associatedClients
      ? associatedClients.map((client: string) => {
          return { id: getObjectId(client, clients) };
        })
      : null;
    values.users = assignedUsers
      ? assignedUsers.map((user: string) => {
          return {
            id: getObjectId(user, users),
          };
        })
      : null;
    values.filingDate = convertMomentDateToString(filingDate);
    values.nextHearingDate = convertMomentDateToString(nextHearingDate);
    return values;
  };

  const updateLitigationState = (data: Litigation) => {
    dispatch(
      litigation ? LitigationActions.edit(data) : LitigationActions.add(data)
    );
  };

  const handleFinish = (values: Store) => {
    values = formatRequest(values);
    setIsLoading(true);
    makeRequest({
      url: `case/${litigation ? 'edit' : 'add'}`,
      values,
      type: 'POST',
      authorization,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateLitigationState(data);
        setIsLoading(false);
        successCallback();
      },
      failureCallback: () => {
        setIsLoading(false);
      },
    });
  };

  const getStatedFirmOptions = (
    cases: Litigation[],
    selectedOrganization: string = ''
  ): AutoCompleteOption[] => {
    let firmOptions = new Set<string>();
    cases.forEach(({ statedFirm }) => {
      if (statedFirm) {
        firmOptions.add(statedFirm);
      }
      if (selectedOrganization) {
        firmOptions.add(selectedOrganization);
      }
    });
    return Array.from(firmOptions)
      .sort((a, b) => a.localeCompare(b))
      .map((item) => ({ value: item }));
  };

  return (
    <CaseBaseFormContainer
      {...{
        handleFinish,
        litigation,
        authenticatedUser,
        authorization,
        organizations,
        clients,
        users,
        isLoading,
        getStatedFirmOptions,
      }}
    />
  );
};

export default BaseCaseForm;
