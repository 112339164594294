import React from 'react';
import { Hearing } from '../../interfaces/entities/Hearing';
import { Table } from 'antd';
import { Client } from '../../interfaces/entities/Client';

interface HearingViewProps {
  hearings: Hearing[];
}

const HearingView: React.FC<HearingViewProps> = ({ hearings }) => {

  console.log(hearings);
  const tableColumns = [
    {
      title: 'Suit Name',
      dataIndex: 'suitName',
    },
    {
      title: 'Suit Number',
      dataIndex: 'suitNumber',
    },
    {
      title: 'Court',
      dataIndex: 'court',
    },
    {
      title: 'Hearing Date',
      dataIndex: 'nextHearing',
    },
    {
      title: 'Client(s)',
      render: (text:any, record:any) => <ol>{record.client.map((client: Client) => <li>{client.name}</li>)}</ol>
    },
  ];
  return <Table dataSource={hearings} columns={tableColumns} rowKey={'id'} />;
};

export default HearingView;
