import React, { useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import LitigationActions from '../../redux/actions/Litigation';
import { Litigation } from '../../interfaces/entities/Litigation';
import SubmitButton from '../form/SubmitButton';
const { Option } = Select;

interface UpdateFormProps {
  showForm: boolean;
  dismissForm: () => void;
  id: number;
  currentStatus: string;
}

const StatusUpdateForm: React.FC<UpdateFormProps> = ({
  showForm,
  dismissForm,
  id,
  currentStatus,
}) => {
  const authorization = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const successCallback = (data:Litigation) => {
    dispatch(LitigationActions.edit(data));
    dismissForm();
  };

  const updateStatus = (values: any) => {
    setIsLoading(true);
    makeRequest({
      url: `case/${id}/status`,
      successCallback: (data) => {
        setIsLoading(false);
        showToast('Status updated successfully');
        successCallback(data.data);
      },
      failureCallback: () => {
        setIsLoading(false);
      },
      values,
      authorization,
      type: 'PATCH',
    });
  };
  return (
    <Modal
      visible={showForm}
      onCancel={dismissForm}
      footer={null}
      title={'Select new status'}
    >
      <Form onFinish={updateStatus}>
        <Form.Item
          name='status'
          label='New Status'
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={currentStatus}
        >
          <Select placeholder='Select a status' allowClear>
            <Option value='Filed'>Filed</Option>
            <Option value='Pre-trial'>Pre-trial</Option>
            <Option value='Trial'>Trial</Option>
            <Option value='Written Address'>Written Address</Option>
            <Option value='Judgement'>Judgement</Option>
          </Select>
        </Form.Item>
        <SubmitButton buttonText='Save' isLoading={isLoading} />
      </Form>
      <Button block onClick={dismissForm}>
        Cancel
      </Button>
    </Modal>
  );
};

export default StatusUpdateForm;
