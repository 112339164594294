import { AppState } from '../../interfaces/redux/state/App';
import { selectAllItems, selectItem, selectCount } from './Generic';
import { Client } from '../../interfaces/entities/Client';

export const selectAllClients = ({ clients }: AppState): Client[] =>
  selectAllItems(clients);

export const selectClient = (
  { clients }: AppState,
  id: number
): Client | null => selectItem(id, clients);

export const selectClientCount = ({ clients }: AppState): number =>
  selectCount(clients);
