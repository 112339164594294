import React from 'react';
import { getCountryCodes } from '../../utility/constants/phoneNumber';
import { AutoComplete, Form, Input } from 'antd';

const countryCodes = getCountryCodes();
const getCountryCodeInput = (
  countryCodeName: string,
  initialCountryCodeValue?: string,
  isRequired = true
) => {
  const options = countryCodes.map(({ name, dial_code }) => ({
    value: `${dial_code} (${name})`,
  }));
  return (
    <Form.Item
      name={countryCodeName}
      initialValue={initialCountryCodeValue}
      noStyle
      rules={[{ required: isRequired, message: 'Please select country code' }]}
    >
      <AutoComplete
        style={{ width: '30%' }}
        options={options}
        placeholder='Country Code'
        filterOption={(inputValue, option) =>
          option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      />
    </Form.Item>
  );
};

interface PhoneInputProps {
  label?: string;
  isRequired?: boolean;
  name?: string;
  countryCodeName?: string;
  initialValue?: string;
  initialCountryCodeValue?: string;
}

const PhoneNumberInput: React.FC<PhoneInputProps> = ({
  label = 'Phone Number',
  isRequired = true,
  name = 'phoneNumber',
  countryCodeName = 'countryCode',
  initialValue,
  initialCountryCodeValue,
}) => {
  const validatePhoneNumber = (rule: any, value: string, callback: any) => {
    let errors = [];

    if (!(value.length === 7 || value.length === 11) && isRequired) {
      errors.push(new Error('Please enter a valid phone number'));
    }
    callback(errors);
  };

  return (
    <Form.Item label={label} required={isRequired}>
      <Input.Group compact>
        {getCountryCodeInput(
          countryCodeName,
          initialCountryCodeValue,
          isRequired
        )}
        <Form.Item
          name={name}
          initialValue={initialValue ? `0${initialValue}` : ''}
          noStyle
          rules={[
            {
              required: isRequired,
              message: "Please enter the organization's phone number",
            },
            {
              validator: validatePhoneNumber,
            },
          ]}
        >
          <Input style={{ width: '70%' }} placeholder={label} />
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );
};

export default PhoneNumberInput;
