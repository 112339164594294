import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

interface NotFoundProps {
  message?: string;
  returnUrl: string;
  status?: '404' | '403';
}

const NotPermitted: React.FC<NotFoundProps> = ({
  message = 'Could not find the requested page',
  returnUrl,
  status = '404',
}) => {
  return (
    <Result
      status={status}
      title={message}
      extra={
        <Link to={returnUrl}>
          <Button type='primary' danger>
            Back
          </Button>
        </Link>
      }
    />
  );
};

export default NotPermitted;
