import React, { useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import OrganizationFormContainer from './BaseContainer';
import { Organization } from '../../interfaces/entities/Organization';
import {
  formatPhoneNumber,
  formatSelectedCountryCode,
} from '../../utility/constants/phoneNumber';
import { makeRequest } from '../../utility/api';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { showToast } from '../../utility/notification/toast';
import OrganizationActions from '../../redux/actions/Organization';

interface OrganizationProps {
  organization?: Organization;
  successCallback: () => void;
}

const BaseOrganizationForm: React.FC<OrganizationProps> = ({
  organization = null,
  successCallback,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const authorization = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const formatAdminUserInRequest = (values: Store) => {
    const {
      adminFirstName,
      adminLastName,
      adminEmailAddress,
      adminCountryCode,
      adminPhoneNumber,
    } = values;

    delete values.adminFirstName;
    delete values.adminLastName;
    delete values.adminEmailAddress;
    delete values.adminCountryCode;
    delete values.adminPhoneNumber;

    values.admin = {
      firstName: adminFirstName,
      lastName: adminLastName,
      emailAddress: adminEmailAddress,
      countryCode: formatSelectedCountryCode(adminCountryCode),
      phoneNumber: formatPhoneNumber(adminPhoneNumber),
      privilege: 'admin',
    };
    return values;
  };

  const formatRequest = async (values: Store) => {
    const { countryCode, logo, phoneNumber } = values;
    values.countryCode = formatSelectedCountryCode(countryCode);
    if (showUserForm) {
      values = formatAdminUserInRequest(values);
    }
    if (organization) {
      values.id = organization.id;
    }
    if (logo) {
      values.logo = await getBase64(fileList[0].originFileObj);
    } else {
      delete values.logo;
    }
    values.phoneNumber = formatPhoneNumber(phoneNumber);
    return values;
  };

  const updateOrganizationState = (data: Organization) => {
    dispatch(
      organization
        ? OrganizationActions.edit(data)
        : OrganizationActions.add(data)
    );
  };

  const handleFinish = async (values: Store) => {
    setIsLoading(true);
    values = await formatRequest(values);
    makeRequest({
      url: `organization/${organization ? 'edit' : 'add'}`,
      values,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateOrganizationState(data);
        successCallback();
      },

      authorization,
      failureCallback: () => {
        setIsLoading(false);
      },
    });
  };

  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [fileList, setFileList] = useState<any[]>([]);
  const [showUserForm, setShowUserForm] = useState<boolean>(false);

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handleChange = ({ fileList }: any) => {
    setFileList(fileList);
    if (organization) {
      organization.logo = null;
    }
  };

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  return (
    <OrganizationFormContainer
      {...{
        organization,
        handleFinish,
        handlePreview,
        handleChange,
        handleCancel,
        fileList,
        showUserForm,
        setShowUserForm,
        previewImage,
        previewVisible,
        isLoading,
      }}
    />
  );
};

export default BaseOrganizationForm;
