import React from 'react';
import { Form, Upload, Button, Modal } from 'antd';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { Organization } from '../../interfaces/entities/Organization';
import PhoneNumberInput from '../form/PhoneNumberInput';
import { PlusOutlined } from '@ant-design/icons';
import { Store } from 'antd/lib/form/interface';
import EmailInput from '../form/EmailInput';
import NumberInput from '../form/NumberInput';
import SubmitButton from '../form/SubmitButton';
import TextInput from '../form/TextInput';
import AddressInput from '../form/AddressInput';

interface ContainerProps {
  fileList: UploadFile[];
  handleChange: (fileList: UploadChangeParam<UploadFile>) => void;
  handlePreview: (file: UploadFile) => void;
  organization: Organization | null;
  showUserForm: boolean;
  setShowUserForm: (shouldShowUserForm: boolean) => void;
  previewVisible: boolean;
  previewImage: string;
  handleCancel: () => void;
  handleFinish: (values: Store) => void;
  isLoading: boolean;
}

const OrganizationFormContainer: React.FC<ContainerProps> = ({
  fileList,
  handleChange,
  handlePreview,
  organization,
  showUserForm,
  setShowUserForm,
  previewVisible,
  previewImage,
  handleCancel,
  handleFinish,
  isLoading,
}) => {
  return (
    <Form onFinish={handleFinish}>
      <Form.Item name='logo'>
        <div style={{ margin: 'auto', width: '10%' }}>
          <Upload
            listType='picture-card'
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {!(organization && organization.logo) ? (
              fileList.length >= 1 ? null : (
                <div>
                  <PlusOutlined />
                  <div className='ant-upload-text'>Upload Logo</div>
                </div>
              )
            ) : (
              <img
                style={{ width: '100px' }}
                src={`${process.env.REACT_APP_API_URL}/logo/${organization.logo}`}
                alt={`${organization.name} Logo`}
              />
            )}
          </Upload>
        </div>
      </Form.Item>
      <TextInput
        label='Name'
        name='name'
        initialValue={organization?.name}
        message='Please enter the organization name'
        placeholder='Name'
      />
      <EmailInput
        initialValue={organization?.emailAddress}
        label='Email Address'
        requiredMessage='Please enter the organization email'
      />
      <PhoneNumberInput
        isRequired={true}
        initialValue={organization?.phoneNumber}
        initialCountryCodeValue={organization?.countryCode}
      />
      <AddressInput
        initialAddressValue={organization?.address}
        initialCityValue={organization?.city}
        initialStateValue={organization?.state}
        initialCountryValue={organization?.country}
      />
      <NumberInput
        initialValue={organization?.vatRate}
        label='VAT Rate'
        name='vatRate'
        placeholder='VAT Rate'
        requiredMessage="Please enter the organization's VAT rate"
        max={100}
        step={0.1}
      />
      {showUserForm && (
        <>
          <TextInput
            label='Admin First Name'
            name='adminFirstName'
            message="Please enter the user's first name"
            placeholder='First Name'
          />

          <TextInput
            label='Admin Last Name'
            name='adminLastName'
            message="Please enter the user's last name"
            placeholder='Last Name'
          />

          <EmailInput
            label='Admin Email Address'
            initialValue={organization?.emailAddress}
            name='adminEmailAddress'
            requiredMessage="Please enter the user's email address"
          />
          <PhoneNumberInput
            isRequired={true}
            label='Admin Phone Number'
            countryCodeName='adminCountryCode'
            name='adminPhoneNumber'
          />
          <Button
            type='default'
            onClick={() => {
              setShowUserForm(false);
            }}
            block
            style={{ marginBottom: '20px' }}
          >
            Remove Admin User
          </Button>
        </>
      )}

      {!showUserForm && !organization && (
        <Button
          type='dashed'
          onClick={() => {
            setShowUserForm(true);
          }}
          block
          style={{ marginBottom: '20px' }}
        >
          <PlusOutlined />
          Add Administrative User
        </Button>
      )}
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <SubmitButton
        buttonText={organization ? 'Save' : 'Submit'}
        isLoading={isLoading}
      />
    </Form>
  );
};

export default OrganizationFormContainer;
