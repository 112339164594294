import { Skeleton, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { User } from '../../interfaces/entities/User';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { makeRequest } from '../../utility/api';
import TableView from '../table/ViewAll';
import ViewAllUsersActionColumn from './ViewAllAction';

const ViewDeletedUsers: React.FC = () => {
  const [deletedUsers, setDeletedUsers] = useState([]);
  const authorization = useSelector(selectAuthToken);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    makeRequest({
      url: '/admin/user/deleted',
      type: 'GET',
      authorization,
      successCallback: (response) => {
        const { data } = response;
        setDeletedUsers(data);
        setIsLoading(false);
      },
    });
  }, []);

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      shouldSearch: true,
      shouldSort: true,
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
      indexName: 'email address',
      shouldSearch: true,
      shouldSort: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      indexName: 'phone number',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Organization',
      dataIndex: 'organizationName',
      indexName: 'organization',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      shouldSearch: true,
      shouldSort: true,
      render: (text: any, record: User) => (
        <Tag color={record.isEnabled ? 'green' : 'volcano'}>
          {record.status}
        </Tag>
      ),
    },
  ];
  return isLoading ? (
    <Skeleton loading={isLoading} active />
  ) : (
    <TableView
      actionColumn={{
        render: (text: any, record: User) => (
          <ViewAllUsersActionColumn {...{ record, isDeletedUser: true }} />
        ),
      }}
      columns={tableColumns}
      data={deletedUsers}
      resourceType='user'
    />
  );
};

export default ViewDeletedUsers;
