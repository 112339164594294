import { DocumentState } from '../../interfaces/redux/state/App';
import { getGenericStateObject } from '../../utility/redux/state';
import { DocumentAction } from '../../interfaces/redux/actions/Document';
import {
  UPDATE_DOCUMENTS,
  ADD_DOCUMENT,
  ADD_DOCUMENTS,
  REMOVE_DOCUMENT,
  CLEAR_DOCUMENTS,
  UPDATE_DOCUMENT,
} from '../ActionTypes';
import {
  update,
  add,
  addMany,
  remove,
  edit,
} from '../../utility/redux/reducer';

const initialState: DocumentState = getGenericStateObject();

const reducer = (
  state: DocumentState = initialState,
  action: DocumentAction
): DocumentState => {
  switch (action.type) {
    case UPDATE_DOCUMENTS:
      return update(state, action.payload);
    case ADD_DOCUMENT:
      return add(state, action.payload);
    case ADD_DOCUMENTS:
      return addMany(state, action.payload);
    case REMOVE_DOCUMENT:
      return remove(state, action.payload);
    case CLEAR_DOCUMENTS:
      return initialState;
    case UPDATE_DOCUMENT:
      return edit(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
