import { Country, State } from '../../interfaces/utility/country';
import { AutoCompleteOption } from '../../interfaces/components/form/AutoComplete';

export const countries: Country[] = [
  {
    name: 'Afghanistan',
    states: [
      { name: 'Badakhshān' },
      { name: 'Baghlān' },
      { name: 'Balkh' },
      { name: 'Bādghīs' },
      { name: 'Bāmyān' },
      { name: 'Dāykundī' },
      { name: 'Farāh' },
      { name: 'Fāryāb' },
      { name: 'Ghaznī' },
      { name: 'Ghōr' },
      { name: 'Helmand' },
      { name: 'Herāt' },
      { name: 'Jowzjān' },
      { name: 'Kandahār' },
      { name: 'Khōst' },
      { name: 'Kunar' },
      { name: 'Kunduz' },
      { name: 'Kābul' },
      { name: 'Kāpīsā' },
      { name: 'Laghmān' },
      { name: 'Lōgar' },
      { name: 'Nangarhār' },
      { name: 'Nīmrōz' },
      { name: 'Nūristān' },
      { name: 'Paktiyā' },
      { name: 'Paktīkā' },
      { name: 'Panjshayr' },
      { name: 'Parwān' },
      { name: 'Samangān' },
      { name: 'Sar-e Pul' },
      { name: 'Takhār' },
      { name: 'Uruzgān' },
      { name: 'Wardak' },
      { name: 'Zābul' },
    ],
  },
  { name: 'Åland Islands', states: [] },
  {
    name: 'Albania',
    states: [
      { name: 'Berat' },
      { name: 'Dibër' },
      { name: 'Durrës' },
      { name: 'Elbasan' },
      { name: 'Fier' },
      { name: 'Gjirokastër' },
      { name: 'Korçë' },
      { name: 'Kukës' },
      { name: 'Lezhë' },
      { name: 'Shkodër' },
      { name: 'Tiranë' },
      { name: 'Vlorë' },
    ],
  },
  {
    name: 'Algeria',
    states: [
      { name: 'Adrar' },
      { name: 'Alger' },
      { name: 'Annaba' },
      { name: 'Aïn Defla' },
      { name: 'Aïn Témouchent' },
      { name: 'Batna' },
      { name: 'Biskra' },
      { name: 'Blida' },
      { name: 'Bordj Bou Arréridj' },
      { name: 'Bouira' },
      { name: 'Boumerdès' },
      { name: 'Béchar' },
      { name: 'Béjaïa' },
      { name: 'Chlef' },
      { name: 'Constantine' },
      { name: 'Djelfa' },
      { name: 'El Bayadh' },
      { name: 'El Oued' },
      { name: 'El Tarf' },
      { name: 'Ghardaïa' },
      { name: 'Guelma' },
      { name: 'Illizi' },
      { name: 'Jijel' },
      { name: 'Khenchela' },
      { name: 'Laghouat' },
      { name: 'Mascara' },
      { name: 'Mila' },
      { name: 'Mostaganem' },
      { name: 'Msila' },
      { name: 'Médéa' },
      { name: 'Naama' },
      { name: 'Oran' },
      { name: 'Ouargla' },
      { name: 'Oum el Bouaghi' },
      { name: 'Relizane' },
      { name: 'Saïda' },
      { name: 'Sidi Bel Abbès' },
      { name: 'Skikda' },
      { name: 'Souk Ahras' },
      { name: 'Sétif' },
      { name: 'Tamanghasset' },
      { name: 'Tiaret' },
      { name: 'Tindouf' },
      { name: 'Tipaza' },
      { name: 'Tissemsilt' },
      { name: 'Tizi Ouzou' },
      { name: 'Tlemcen' },
      { name: 'Tébessa' },
    ],
  },
  { name: 'American Samoa', states: [] },
  {
    name: 'Andorra',
    states: [
      { name: 'Andorra la Vella' },
      { name: 'Canillo' },
      { name: 'Encamp' },
      { name: 'Escaldes-Engordany' },
      { name: 'La Massana' },
      { name: 'Ordino' },
      { name: 'Sant Julià de Lòria' },
    ],
  },
  {
    name: 'Angola',
    states: [
      { name: 'Bengo' },
      { name: 'Benguela' },
      { name: 'Bié' },
      { name: 'Cabinda' },
      { name: 'Cunene' },
      { name: 'Huambo' },
      { name: 'Huíla' },
      { name: 'Kuando Kubango' },
      { name: 'Kwanza Norte' },
      { name: 'Kwanza Sul' },
      { name: 'Luanda' },
      { name: 'Lunda Norte' },
      { name: 'Lunda Sul' },
      { name: 'Malange' },
      { name: 'Moxico' },
      { name: 'Namibe' },
      { name: 'Uíge' },
      { name: 'Zaire' },
    ],
  },
  { name: 'Anguilla', states: [] },
  { name: 'Antarctica', states: [] },
  {
    name: 'Antigua and Barbuda',
    states: [
      { name: 'Barbuda' },
      { name: 'Redonda' },
      { name: 'Saint George' },
      { name: 'Saint John' },
      { name: 'Saint Mary' },
      { name: 'Saint Paul' },
      { name: 'Saint Peter' },
      { name: 'Saint Philip' },
    ],
  },
  {
    name: 'Argentina',
    states: [
      { name: 'Buenos Aires' },
      { name: 'Catamarca' },
      { name: 'Chaco' },
      { name: 'Chubut' },
      { name: 'Ciudad Autónoma de Buenos Aires' },
      { name: 'Corrientes' },
      { name: 'Córdoba' },
      { name: 'Entre Ríos' },
      { name: 'Formosa' },
      { name: 'Jujuy' },
      { name: 'La Pampa' },
      { name: 'La Rioja' },
      { name: 'Mendoza' },
      { name: 'Misiones' },
      { name: 'Neuquén' },
      { name: 'Río Negro' },
      { name: 'Salta' },
      { name: 'San Juan' },
      { name: 'San Luis' },
      { name: 'Santa Cruz' },
      { name: 'Santa Fe' },
      { name: 'Santiago del Estero' },
      { name: 'Tierra del Fuego' },
      { name: 'Tucumán' },
    ],
  },
  {
    name: 'Armenia',
    states: [
      { name: 'Aragac̣otn' },
      { name: 'Ararat' },
      { name: 'Armavir' },
      { name: 'Erevan' },
      { name: "Geġark'unik'" },
      { name: "Kotayk'" },
      { name: 'Loṙi' },
      { name: "Syunik'" },
      { name: 'Tavuš' },
      { name: 'Vayoć Jor' },
      { name: 'Širak' },
    ],
  },
  { name: 'Aruba', states: [] },
  {
    name: 'Australia',
    states: [
      { name: 'Australian Capital Territory' },
      { name: 'New South Wales' },
      { name: 'Northern Territory' },
      { name: 'Queensland' },
      { name: 'South Australia' },
      { name: 'Tasmania' },
      { name: 'Victoria' },
      { name: 'Western Australia' },
    ],
  },
  {
    name: 'Austria',
    states: [
      { name: 'Burgenland' },
      { name: 'Kärnten' },
      { name: 'Niederösterreich' },
      { name: 'Oberösterreich' },
      { name: 'Salzburg' },
      { name: 'Steiermark' },
      { name: 'Tirol' },
      { name: 'Vorarlberg' },
      { name: 'Wien' },
    ],
  },
  { name: 'Azerbaijan', states: [{ name: 'Naxçıvan' }] },
  {
    name: 'The Bahamas',
    states: [
      { name: 'Acklins' },
      { name: 'Berry Islands' },
      { name: 'Bimini' },
      { name: 'Black Point' },
      { name: 'Cat Island' },
      { name: 'Central Abaco' },
      { name: 'Central Andros' },
      { name: 'Central Eleuthera' },
      { name: 'City of Freeport' },
      { name: 'Crooked Island and Long Cay' },
      { name: 'East Grand Bahama' },
      { name: 'Exuma' },
      { name: 'Grand Cay' },
      { name: 'Harbour Island' },
      { name: 'Hope Town' },
      { name: 'Inagua' },
      { name: 'Long Island' },
      { name: 'Mangrove Cay' },
      { name: 'Mayaguana' },
      { name: 'Moores Island' },
      { name: 'North Abaco' },
      { name: 'North Andros' },
      { name: 'North Eleuthera' },
      { name: 'Ragged Island' },
      { name: 'Rum Cay' },
      { name: 'San Salvador' },
      { name: 'South Abaco' },
      { name: 'South Andros' },
      { name: 'South Eleuthera' },
      { name: 'Spanish Wells' },
      { name: 'West Grand Bahama' },
    ],
  },
  {
    name: 'Bahrain',
    states: [
      { name: 'Al Janūbīyah' },
      { name: 'Al Manāmah' },
      { name: 'Al Muḩarraq' },
      { name: 'Al Wusţá' },
      { name: 'Ash Shamālīyah' },
    ],
  },
  {
    name: 'Bangladesh',
    states: [
      { name: 'Barisal' },
      { name: 'Chittagong' },
      { name: 'Dhaka' },
      { name: 'Khulna' },
      { name: 'Rajshahi' },
      { name: 'Rangpur' },
      { name: 'Sylhet' },
    ],
  },
  {
    name: 'Barbados',
    states: [
      { name: 'Christ Church' },
      { name: 'Saint Andrew' },
      { name: 'Saint George' },
      { name: 'Saint James' },
      { name: 'Saint John' },
      { name: 'Saint Joseph' },
      { name: 'Saint Lucy' },
      { name: 'Saint Michael' },
      { name: 'Saint Peter' },
      { name: 'Saint Philip' },
      { name: 'Saint Thomas' },
    ],
  },
  {
    name: 'Belarus',
    states: [
      { name: "Brestskaya voblasts'" },
      { name: "Homyel'skaya voblasts'" },
      { name: 'Horad Minsk' },
      { name: "Hrodzenskaya voblasts'" },
      { name: "Mahilyowskaya voblasts'" },
      { name: "Minskaya voblasts'" },
      { name: "Vitsyebskaya voblasts'" },
    ],
  },
  {
    name: 'Belgium',
    states: [
      { name: 'Brussels Hoofdstedelijk Gewest' },
      { name: 'Région Wallonne' },
      { name: 'Vlaams Gewest' },
    ],
  },
  {
    name: 'Belize',
    states: [
      { name: 'Belize' },
      { name: 'Cayo' },
      { name: 'Corozal' },
      { name: 'Orange Walk' },
      { name: 'Stann Creek' },
      { name: 'Toledo' },
    ],
  },
  {
    name: 'Benin',
    states: [
      { name: 'Alibori' },
      { name: 'Atakora' },
      { name: 'Atlantique' },
      { name: 'Borgou' },
      { name: 'Collines' },
      { name: 'Donga' },
      { name: 'Kouffo' },
      { name: 'Littoral' },
      { name: 'Mono' },
      { name: 'Ouémé' },
      { name: 'Plateau' },
      { name: 'Zou' },
    ],
  },
  { name: 'Bermuda', states: [] },
  {
    name: 'Bhutan',
    states: [
      { name: 'Bumthang' },
      { name: 'Chhukha' },
      { name: 'Dagana' },
      { name: 'Gasa' },
      { name: 'Ha' },
      { name: 'Lhuentse' },
      { name: 'Monggar' },
      { name: 'Paro' },
      { name: 'Pemagatshel' },
      { name: 'Punakha' },
      { name: 'Samdrup Jongkha' },
      { name: 'Samtse' },
      { name: 'Sarpang' },
      { name: 'Thimphu' },
      { name: 'Trashi Yangtse' },
      { name: 'Trashigang' },
      { name: 'Trongsa' },
      { name: 'Tsirang' },
      { name: 'Wangdue Phodrang' },
      { name: 'Zhemgang' },
    ],
  },
  {
    name: 'Bolivia',
    states: [
      { name: 'Chuquisaca' },
      { name: 'Cochabamba' },
      { name: 'El Beni' },
      { name: 'La Paz' },
      { name: 'Oruro' },
      { name: 'Pando' },
      { name: 'Potosí' },
      { name: 'Santa Cruz' },
      { name: 'Tarija' },
    ],
  },
  { name: 'Bonaire', states: [] },
  {
    name: 'Bosnia and Herzegovina',
    states: [
      { name: 'Brčko distrikt' },
      { name: 'Federacija Bosna i Hercegovina' },
      { name: 'Republika Srpska' },
    ],
  },
  {
    name: 'Botswana',
    states: [
      { name: 'Central' },
      { name: 'Chobe' },
      { name: 'Francistown' },
      { name: 'Gaborone' },
      { name: 'Ghanzi' },
      { name: 'Jwaneng' },
      { name: 'Kgalagadi' },
      { name: 'Kgatleng' },
      { name: 'Kweneng' },
      { name: 'Lobatse' },
      { name: 'North-East' },
      { name: 'North-West' },
      { name: 'Selibe Phikwe' },
      { name: 'South-East' },
      { name: 'Southern' },
      { name: 'Sowa Town' },
    ],
  },
  { name: 'Bouvet Island', states: [] },
  {
    name: 'Brazil',
    states: [
      { name: 'Acre' },
      { name: 'Alagoas' },
      { name: 'Amapá' },
      { name: 'Amazonas' },
      { name: 'Bahia' },
      { name: 'Ceará' },
      { name: 'Distrito Federal' },
      { name: 'Espírito Santo' },
      { name: 'Goiás' },
      { name: 'Maranhão' },
      { name: 'Mato Grosso' },
      { name: 'Mato Grosso do Sul' },
      { name: 'Minas Gerais' },
      { name: 'Paraná' },
      { name: 'Paraíba' },
      { name: 'Pará' },
      { name: 'Pernambuco' },
      { name: 'Piauí' },
      { name: 'Rio Grande do Norte' },
      { name: 'Rio Grande do Sul' },
      { name: 'Rio de Janeiro' },
      { name: 'Rondônia' },
      { name: 'Roraima' },
      { name: 'Santa Catarina' },
      { name: 'Sergipe' },
      { name: 'São Paulo' },
      { name: 'Tocantins' },
    ],
  },
  { name: 'British Indian Ocean Territory', states: [] },
  {
    name: 'United States Minor Outlying Islands',
    states: [
      { name: 'Baker Island' },
      { name: 'Howland Island' },
      { name: 'Jarvis Island' },
      { name: 'Johnston Atoll' },
      { name: 'Kingman Reef' },
      { name: 'Midway Islands' },
      { name: 'Navassa Island' },
      { name: 'Palmyra Atoll' },
      { name: 'Wake Island' },
    ],
  },
  { name: 'Virgin Islands (British)', states: [] },
  { name: 'Virgin Islands (U.S.)', states: [] },
  {
    name: 'Brunei',
    states: [
      { name: 'Belait' },
      { name: 'Brunei-Muara' },
      { name: 'Temburong' },
      { name: 'Tutong' },
    ],
  },
  {
    name: 'Bulgaria',
    states: [
      { name: 'Blagoevgrad' },
      { name: 'Burgas' },
      { name: 'Dobrich' },
      { name: 'Gabrovo' },
      { name: 'Haskovo' },
      { name: 'Kardzhali' },
      { name: 'Kyustendil' },
      { name: 'Lovech' },
      { name: 'Montana' },
      { name: 'Pazardzhik' },
      { name: 'Pernik' },
      { name: 'Pleven' },
      { name: 'Plovdiv' },
      { name: 'Razgrad' },
      { name: 'Ruse' },
      { name: 'Shumen' },
      { name: 'Silistra' },
      { name: 'Sliven' },
      { name: 'Smolyan' },
      { name: 'Sofia' },
      { name: 'Sofia-Grad' },
      { name: 'Stara Zagora' },
      { name: 'Targovishte' },
      { name: 'Varna' },
      { name: 'Veliko Tarnovo' },
      { name: 'Vidin' },
      { name: 'Vratsa' },
      { name: 'Yambol' },
    ],
  },
  {
    name: 'Burkina Faso',
    states: [
      { name: 'Boucle du Mouhoun' },
      { name: 'Cascades' },
      { name: 'Centre' },
      { name: 'Centre-Est' },
      { name: 'Centre-Nord' },
      { name: 'Centre-Ouest' },
      { name: 'Centre-Sud' },
      { name: 'Est' },
      { name: 'Hauts-Bassins' },
      { name: 'Nord' },
      { name: 'Plateau-Central' },
      { name: 'Sahel' },
      { name: 'Sud-Ouest' },
    ],
  },
  {
    name: 'Burundi',
    states: [
      { name: 'Bubanza' },
      { name: 'Bujumbura Mairie' },
      { name: 'Bujumbura Rural' },
      { name: 'Bururi' },
      { name: 'Cankuzo' },
      { name: 'Cibitoke' },
      { name: 'Gitega' },
      { name: 'Karuzi' },
      { name: 'Kayanza' },
      { name: 'Kirundo' },
      { name: 'Makamba' },
      { name: 'Muramvya' },
      { name: 'Muyinga' },
      { name: 'Mwaro' },
      { name: 'Ngozi' },
      { name: 'Rutana' },
      { name: 'Ruyigi' },
    ],
  },
  {
    name: 'Cambodia',
    states: [
      { name: 'Baat Dambang' },
      { name: 'Banteay Mean Chey' },
      { name: 'Kampong Chaam' },
      { name: 'Kampong Chhnang' },
      { name: 'Kampong Spueu' },
      { name: 'Kampong Thum' },
      { name: 'Kampot' },
      { name: 'Kandaal' },
      { name: 'Kaoh Kong' },
      { name: 'Kracheh' },
      { name: 'Krong Kaeb' },
      { name: 'Krong Pailin' },
      { name: 'Krong Preah Sihanouk' },
      { name: 'Mondol Kiri' },
      { name: 'Otdar Mean Chey' },
      { name: 'Phnom Penh' },
      { name: 'Pousaat' },
      { name: 'Preah Vihear' },
      { name: 'Prey Veaeng' },
      { name: 'Rotanak Kiri' },
      { name: 'Siem Reab' },
      { name: 'Stueng Traeng' },
      { name: 'Svaay Rieng' },
      { name: 'Taakaev' },
    ],
  },
  {
    name: 'Cameroon',
    states: [
      { name: 'Adamaoua' },
      { name: 'Centre' },
      { name: 'East' },
      { name: 'Far North' },
      { name: 'Littoral' },
      { name: 'North' },
      { name: 'North-West' },
      { name: 'South' },
      { name: 'South-West' },
      { name: 'West' },
    ],
  },
  {
    name: 'Canada',
    states: [
      { name: 'Alberta' },
      { name: 'British Columbia' },
      { name: 'Manitoba' },
      { name: 'New Brunswick' },
      { name: 'Newfoundland and Labrador' },
      { name: 'Nova Scotia' },
      { name: 'Ontario' },
      { name: 'Prince Edward Island' },
      { name: 'Quebec' },
      { name: 'Saskatchewan' },
      { name: 'Northwest Territories' },
      { name: 'Nunavut' },
      { name: 'Yukon' },
    ],
  },
  {
    name: 'Cape Verde',
    states: [{ name: 'Ilhas de Barlavento' }, { name: 'Ilhas de Sotavento' }],
  },
  { name: 'Cayman Islands', states: [] },
  {
    name: 'Central African Republic',
    states: [
      { name: 'Bamingui-Bangoran' },
      { name: 'Bangui' },
      { name: 'Basse-Kotto' },
      { name: 'Gribingui' },
      { name: 'Haut-Mbomou' },
      { name: 'Haute-Kotto' },
      { name: 'Haute-Sangha / Mambéré-Kadéï' },
      { name: 'Kémo-Gribingui' },
      { name: 'Lobaye' },
      { name: 'Mbomou' },
      { name: 'Nana-Mambéré' },
      { name: 'Ombella-Mpoko' },
      { name: 'Ouaka' },
      { name: 'Ouham' },
      { name: 'Ouham-Pendé' },
      { name: 'Sangha' },
      { name: 'Vakaga' },
    ],
  },
  {
    name: 'Chad',
    states: [
      { name: 'Al Baṭḩah' },
      { name: 'Al Buḩayrah' },
      { name: 'Baḩr al Ghazāl' },
      { name: 'Būrkū' },
      { name: 'Innīdī' },
      { name: 'Kānim' },
      { name: 'Lūqūn al Gharbī' },
      { name: 'Lūqūn ash Sharqī' },
      { name: 'Madīnat Injamīnā' },
      { name: 'Māndūl' },
      { name: 'Māyū Kībbī al Gharbī' },
      { name: 'Māyū Kībbī ash Sharqī' },
      { name: 'Qīrā' },
      { name: 'Salāmāt' },
      { name: 'Shārī Bāqirmī' },
      { name: 'Shārī al Awsaṭ' },
      { name: 'Sīlā' },
      { name: 'Tibastī' },
      { name: 'Tānjilī' },
      { name: 'Waddāy' },
      { name: 'Wādī Fīrā' },
      { name: 'Ḥajjar Lamīs' },
    ],
  },
  {
    name: 'Chile',
    states: [
      { name: 'Aisén del General Carlos Ibañez del Campo' },
      { name: 'Antofagasta' },
      { name: 'Araucanía' },
      { name: 'Arica y Parinacota' },
      { name: 'Atacama' },
      { name: 'Bío-Bío' },
      { name: 'Coquimbo' },
      { name: "Libertador General Bernardo O'Higgins" },
      { name: 'Los Lagos' },
      { name: 'Los Ríos' },
      { name: 'Magallanes' },
      { name: 'Maule' },
      { name: 'Región Metropolitana de Santiago' },
      { name: 'Tarapacá' },
      { name: 'Valparaíso' },
    ],
  },
  {
    name: 'China',
    states: [
      { name: 'Guangxi' },
      { name: 'Nei Mongol' },
      { name: 'Ningxia' },
      { name: 'Xinjiang' },
      { name: 'Xizang' },
      { name: 'Beijing' },
      { name: 'Chongqing' },
      { name: 'Shanghai' },
      { name: 'Tianjin' },
      { name: 'Anhui' },
      { name: 'Fujian' },
      { name: 'Gansu' },
      { name: 'Guangdong' },
      { name: 'Guizhou' },
      { name: 'Hainan' },
      { name: 'Hebei' },
      { name: 'Heilongjiang' },
      { name: 'Henan' },
      { name: 'Hubei' },
      { name: 'Hunan' },
      { name: 'Jiangsu' },
      { name: 'Jiangxi' },
      { name: 'Jilin' },
      { name: 'Liaoning' },
      { name: 'Qinghai' },
      { name: 'Shaanxi' },
      { name: 'Shandong' },
      { name: 'Shanxi' },
      { name: 'Sichuan' },
      { name: 'Taiwan' },
      { name: 'Yunnan' },
      { name: 'Zhejiang' },
      { name: 'Hong Kong' },
      { name: 'Macao' },
      { name: 'Hong Kong Island' },
      { name: 'Kowloon' },
      { name: 'New Territories' },
    ],
  },
  { name: 'Christmas Island', states: [] },
  { name: 'Cocos (Keeling) Islands', states: [] },
  {
    name: 'Colombia',
    states: [
      { name: 'Amazonas' },
      { name: 'Antioquia' },
      { name: 'Arauca' },
      { name: 'Atlántico' },
      { name: 'Bolívar' },
      { name: 'Boyacá' },
      { name: 'Caldas' },
      { name: 'Caquetá' },
      { name: 'Casanare' },
      { name: 'Cauca' },
      { name: 'Cesar' },
      { name: 'Chocó' },
      { name: 'Cundinamarca' },
      { name: 'Córdoba' },
      { name: 'Distrito Capital de Bogotá' },
      { name: 'Guainía' },
      { name: 'Guaviare' },
      { name: 'Huila' },
      { name: 'La Guajira' },
      { name: 'Magdalena' },
      { name: 'Meta' },
      { name: 'Nariño' },
      { name: 'Norte de Santander' },
      { name: 'Putumayo' },
      { name: 'Quindío' },
      { name: 'Risaralda' },
      { name: 'San Andrés, Providencia y Santa Catalina' },
      { name: 'Santander' },
      { name: 'Sucre' },
      { name: 'Tolima' },
      { name: 'Valle del Cauca' },
      { name: 'Vaupés' },
      { name: 'Vichada' },
    ],
  },
  {
    name: 'Comoros',
    states: [
      { name: 'Anjouan' },
      { name: 'Grande Comore' },
      { name: 'Mohéli' },
    ],
  },
  {
    name: 'Republic of the Congo',
    states: [
      { name: 'Bouenza' },
      { name: 'Brazzaville' },
      { name: 'Cuvette' },
      { name: 'Cuvette-Ouest' },
      { name: 'Kouilou' },
      { name: 'Likouala' },
      { name: 'Lékoumou' },
      { name: 'Niari' },
      { name: 'Plateaux' },
      { name: 'Pointe-Noire' },
      { name: 'Pool' },
      { name: 'Sangha' },
    ],
  },
  {
    name: 'Democratic Republic of the Congo',
    states: [
      { name: 'Bandundu' },
      { name: 'Bas-Congo' },
      { name: 'Kasai-Occidental' },
      { name: 'Kasai-Oriental' },
      { name: 'Katanga' },
      { name: 'Kinshasa' },
      { name: 'Maniema' },
      { name: 'Nord-Kivu' },
      { name: 'Orientale' },
      { name: 'Sud-Kivu' },
      { name: 'Équateur' },
    ],
  },
  { name: 'Cook Islands', states: [] },
  {
    name: 'Costa Rica',
    states: [
      { name: 'Alajuela' },
      { name: 'Cartago' },
      { name: 'Guanacaste' },
      { name: 'Heredia' },
      { name: 'Limón' },
      { name: 'Puntarenas' },
      { name: 'San José' },
    ],
  },
  {
    name: 'Croatia',
    states: [
      { name: 'Bjelovarsko-bilogorska županija' },
      { name: 'Brodsko-posavska županija' },
      { name: 'Dubrovačko-neretvanska županija' },
      { name: 'Grad Zagreb' },
      { name: 'Istarska županija' },
      { name: 'Karlovačka županija' },
      { name: 'Koprivničko-križevačka županija' },
      { name: 'Krapinsko-zagorska županija' },
      { name: 'Ličko-senjska županija' },
      { name: 'Međimurska županija' },
      { name: 'Osječko-baranjska županija' },
      { name: 'Požeško-slavonska županija' },
      { name: 'Primorsko-goranska županija' },
      { name: 'Sisačko-moslavačka županija' },
      { name: 'Splitsko-dalmatinska županija' },
      { name: 'Varaždinska županija' },
      { name: 'Virovitičko-podravska županija' },
      { name: 'Vukovarsko-srijemska županija' },
      { name: 'Zadarska županija' },
      { name: 'Zagrebačka županija' },
      { name: 'Šibensko-kninska županija' },
    ],
  },
  {
    name: 'Cuba',
    states: [
      { name: 'Artemisa' },
      { name: 'Camagüey' },
      { name: 'Ciego de Ávila' },
      { name: 'Cienfuegos' },
      { name: 'Granma' },
      { name: 'Guantánamo' },
      { name: 'Holguín' },
      { name: 'Isla de la Juventud' },
      { name: 'La Habana' },
      { name: 'Las Tunas' },
      { name: 'Matanzas' },
      { name: 'Mayabeque' },
      { name: 'Pinar del Río' },
      { name: 'Sancti Spíritus' },
      { name: 'Santiago de Cuba' },
      { name: 'Villa Clara' },
    ],
  },
  { name: 'Curaçao', states: [] },
  {
    name: 'Cyprus',
    states: [
      { name: 'Ammochostos' },
      { name: 'Keryneia' },
      { name: 'Larnaka' },
      { name: 'Lefkosia' },
      { name: 'Lemesos' },
      { name: 'Pafos' },
    ],
  },
  {
    name: 'Czech Republic',
    states: [
      { name: 'Jihomoravský kraj' },
      { name: 'Jihočeský kraj' },
      { name: 'Karlovarský kraj' },
      { name: 'Královéhradecký kraj' },
      { name: 'Liberecký kraj' },
      { name: 'Moravskoslezský kraj' },
      { name: 'Olomoucký kraj' },
      { name: 'Pardubický kraj' },
      { name: 'Plzeňský kraj' },
      { name: 'Praha, hlavní město' },
      { name: 'Středočeský kraj' },
      { name: 'Vysočina' },
      { name: 'Zlínský kraj' },
      { name: 'Ústecký kraj' },
    ],
  },
  {
    name: 'Denmark',
    states: [
      { name: 'Hovedstaden' },
      { name: 'Midtjylland' },
      { name: 'Nordjylland' },
      { name: 'Sjælland' },
      { name: 'Syddanmark' },
    ],
  },
  {
    name: 'Djibouti',
    states: [
      { name: 'Ali Sabieh' },
      { name: 'Arta' },
      { name: 'Dikhil' },
      { name: 'Djibouti' },
      { name: 'Obock' },
      { name: 'Tadjourah' },
    ],
  },
  {
    name: 'Dominica',
    states: [
      { name: 'Saint Andrew' },
      { name: 'Saint David' },
      { name: 'Saint George' },
      { name: 'Saint John' },
      { name: 'Saint Joseph' },
      { name: 'Saint Luke' },
      { name: 'Saint Mark' },
      { name: 'Saint Patrick' },
      { name: 'Saint Paul' },
      { name: 'Saint Peter' },
    ],
  },
  {
    name: 'Dominican Republic',
    states: [
      { name: 'Cibao Nordeste' },
      { name: 'Cibao Noroeste' },
      { name: 'Cibao Norte' },
      { name: 'Cibao Sur' },
      { name: 'El Valle' },
      { name: 'Enriquillo' },
      { name: 'Higuamo' },
      { name: 'Ozama' },
      { name: 'Valdesia' },
      { name: 'Yuma' },
    ],
  },
  {
    name: 'Ecuador',
    states: [
      { name: 'Azuay' },
      { name: 'Bolívar' },
      { name: 'Carchi' },
      { name: 'Cañar' },
      { name: 'Chimborazo' },
      { name: 'Cotopaxi' },
      { name: 'El Oro' },
      { name: 'Esmeraldas' },
      { name: 'Galápagos' },
      { name: 'Guayas' },
      { name: 'Imbabura' },
      { name: 'Loja' },
      { name: 'Los Ríos' },
      { name: 'Manabí' },
      { name: 'Morona-Santiago' },
      { name: 'Napo' },
      { name: 'Orellana' },
      { name: 'Pastaza' },
      { name: 'Pichincha' },
      { name: 'Santa Elena' },
      { name: 'Santo Domingo de los Tsáchilas' },
      { name: 'Sucumbíos' },
      { name: 'Tungurahua' },
      { name: 'Zamora-Chinchipe' },
    ],
  },
  {
    name: 'Egypt',
    states: [
      { name: 'Ad Daqahlīyah' },
      { name: 'Al Baḩr al Aḩmar' },
      { name: 'Al Buḩayrah' },
      { name: 'Al Fayyūm' },
      { name: 'Al Gharbīyah' },
      { name: 'Al Iskandarīyah' },
      { name: 'Al Ismāٰīlīyah' },
      { name: 'Al Jīzah' },
      { name: 'Al Minyā' },
      { name: 'Al Minūfīyah' },
      { name: 'Al Qalyūbīyah' },
      { name: 'Al Qāhirah' },
      { name: 'Al Uqşur' },
      { name: 'Al Wādī al Jadīd' },
      { name: 'As Suways' },
      { name: 'As Sādis min Uktūbar' },
      { name: 'Ash Sharqīyah' },
      { name: 'Aswān' },
      { name: 'Asyūţ' },
      { name: 'Banī Suwayf' },
      { name: 'Būr Saٰīd' },
      { name: 'Dumyāţ' },
      { name: "Janūb Sīnā'" },
      { name: 'Kafr ash Shaykh' },
      { name: 'Maţrūḩ' },
      { name: 'Qinā' },
      { name: "Shamāl Sīnā'" },
      { name: 'Sūhāj' },
      { name: 'Ḩulwān' },
    ],
  },
  {
    name: 'El Salvador',
    states: [
      { name: 'Ahuachapán' },
      { name: 'Cabañas' },
      { name: 'Chalatenango' },
      { name: 'Cuscatlán' },
      { name: 'La Libertad' },
      { name: 'La Paz' },
      { name: 'La Unión' },
      { name: 'Morazán' },
      { name: 'San Miguel' },
      { name: 'San Salvador' },
      { name: 'San Vicente' },
      { name: 'Santa Ana' },
      { name: 'Sonsonate' },
      { name: 'Usulután' },
    ],
  },
  {
    name: 'Equatorial Guinea',
    states: [{ name: 'Región Continental' }, { name: 'Región Insular' }],
  },
  {
    name: 'Eritrea',
    states: [
      { name: 'Al Awsaţ' },
      { name: 'Al Janūbĩ' },
      { name: 'Ansabā' },
      { name: 'Janūbī al Baḩrī al Aḩmar' },
      { name: 'Qāsh-Barkah' },
      { name: 'Shimālī al Baḩrī al Aḩmar' },
    ],
  },
  {
    name: 'Estonia',
    states: [
      { name: 'Harjumaa' },
      { name: 'Hiiumaa' },
      { name: 'Ida-Virumaa' },
      { name: 'Järvamaa' },
      { name: 'Jõgevamaa' },
      { name: 'Lääne-Virumaa' },
      { name: 'Läänemaa' },
      { name: 'Pärnumaa' },
      { name: 'Põlvamaa' },
      { name: 'Raplamaa' },
      { name: 'Saaremaa' },
      { name: 'Tartumaa' },
      { name: 'Valgamaa' },
      { name: 'Viljandimaa' },
      { name: 'Võrumaa' },
    ],
  },
  {
    name: 'Ethiopia',
    states: [
      { name: 'Bīnshangul Gumuz' },
      { name: 'Dirē Dawa' },
      { name: 'Gambēla Hizboch' },
      { name: 'Hārerī Hizb' },
      { name: 'Oromīya' },
      { name: 'Sumalē' },
      { name: 'Tigray' },
      { name: 'YeDebub Bihēroch Bihēreseboch na Hizboch' },
      { name: 'Ādīs Ābeba' },
      { name: 'Āfar' },
      { name: 'Āmara' },
    ],
  },
  { name: 'Falkland Islands', states: [] },
  { name: 'Faroe Islands', states: [] },
  {
    name: 'Fiji',
    states: [
      { name: 'Central' },
      { name: 'Eastern' },
      { name: 'Northern' },
      { name: 'Rotuma' },
      { name: 'Western' },
    ],
  },
  {
    name: 'Finland',
    states: [
      { name: 'Ahvenanmaan maakunta' },
      { name: 'Etelä-Karjala' },
      { name: 'Etelä-Pohjanmaa' },
      { name: 'Etelä-Savo' },
      { name: 'Kainuu' },
      { name: 'Kanta-Häme' },
      { name: 'Keski-Pohjanmaa' },
      { name: 'Keski-Suomi' },
      { name: 'Kymenlaakso' },
      { name: 'Lappi' },
      { name: 'Pirkanmaa' },
      { name: 'Pohjanmaa' },
      { name: 'Pohjois-Karjala' },
      { name: 'Pohjois-Pohjanmaa' },
      { name: 'Pohjois-Savo' },
      { name: 'Päijät-Häme' },
      { name: 'Satakunta' },
      { name: 'Uusimaa' },
      { name: 'Varsinais-Suomi' },
    ],
  },
  {
    name: 'France',
    states: [
      { name: 'Alsace' },
      { name: 'Aquitaine' },
      { name: 'Auvergne' },
      { name: 'Brittany' },
      { name: 'Burgundy' },
      { name: 'Centre-Val de Loire' },
      { name: 'Champagne-Ardenne' },
      { name: 'Corsica' },
      { name: 'Franche-Comté' },
      { name: 'Languedoc-Roussillon' },
      { name: 'Limousin' },
      { name: 'Lorraine' },
      { name: 'Lower Normandy' },
      { name: 'Midi-Pyrénées' },
      { name: 'Nord-Pas-de-Calais' },
      { name: 'Pays de la Loire' },
      { name: 'Picardy' },
      { name: 'Poitou-Charentes' },
      { name: "Provence-Alpes-Côte d'Azur" },
      { name: 'Rhône-Alpes' },
      { name: 'Upper Normandy' },
      { name: 'Île-de-France' },
    ],
  },
  { name: 'French Guiana', states: [] },
  { name: 'French Polynesia', states: [] },
  { name: 'French Southern and Antarctic Lands', states: [] },
  {
    name: 'Gabon',
    states: [
      { name: 'Estuaire' },
      { name: 'Haut-Ogooué' },
      { name: 'Moyen-Ogooué' },
      { name: 'Ngounié' },
      { name: 'Nyanga' },
      { name: 'Ogooué-Ivindo' },
      { name: 'Ogooué-Lolo' },
      { name: 'Ogooué-Maritime' },
      { name: 'Woleu-Ntem' },
    ],
  },
  {
    name: 'The Gambia',
    states: [
      { name: 'Banjul' },
      { name: 'Central River' },
      { name: 'Lower River' },
      { name: 'North Bank' },
      { name: 'Upper River' },
      { name: 'Western' },
    ],
  },
  {
    name: 'Georgia',
    states: [
      { name: 'Abkhazia' },
      { name: 'Ajaria' },
      { name: 'Guria' },
      { name: 'Imereti' },
      { name: "K'akheti" },
      { name: 'Kvemo Kartli' },
      { name: 'Mtskheta-Mtianeti' },
      { name: "Rach'a-Lechkhumi-Kvemo Svaneti" },
      { name: 'Samegrelo-Zemo Svaneti' },
      { name: 'Samtskhe-Javakheti' },
      { name: 'Shida Kartli' },
      { name: 'Tbilisi' },
    ],
  },
  {
    name: 'Germany',
    states: [
      { name: 'Baden-Württemberg' },
      { name: 'Bayern' },
      { name: 'Berlin' },
      { name: 'Brandenburg' },
      { name: 'Bremen' },
      { name: 'Hamburg' },
      { name: 'Hessen' },
      { name: 'Mecklenburg-Vorpommern' },
      { name: 'Niedersachsen' },
      { name: 'Nordrhein-Westfalen' },
      { name: 'Rheinland-Pfalz' },
      { name: 'Saarland' },
      { name: 'Sachsen' },
      { name: 'Sachsen-Anhalt' },
      { name: 'Schleswig-Holstein' },
      { name: 'Thüringen' },
    ],
  },
  {
    name: 'Ghana',
    states: [
      { name: 'Ashanti' },
      { name: 'Brong-Ahafo' },
      { name: 'Central' },
      { name: 'Eastern' },
      { name: 'Greater Accra' },
      { name: 'Northern' },
      { name: 'Upper East' },
      { name: 'Upper West' },
      { name: 'Volta' },
      { name: 'Western' },
    ],
  },
  { name: 'Gibraltar', states: [] },
  {
    name: 'Greece',
    states: [
      { name: 'Anatoliki Makedonia kai Thraki' },
      { name: 'Attiki' },
      { name: 'Dytiki Ellada' },
      { name: 'Dytiki Makedonia' },
      { name: 'Ionia Nisia' },
      { name: 'Ipeiros' },
      { name: 'Kentriki Makedonia' },
      { name: 'Kriti' },
      { name: 'Notio Aigaio' },
      { name: 'Peloponnisos' },
      { name: 'Sterea Ellada' },
      { name: 'Thessalia' },
      { name: 'Voreio Aigaio' },
    ],
  },
  {
    name: 'Greenland',
    states: [
      { name: 'Kommune Kujalleq' },
      { name: 'Kommuneqarfik Sermersooq' },
      { name: 'Qaasuitsup Kommunia' },
      { name: 'Qeqqata Kommunia' },
    ],
  },
  {
    name: 'Grenada',
    states: [
      { name: 'Saint Andrew' },
      { name: 'Saint David' },
      { name: 'Saint George' },
      { name: 'Saint John' },
      { name: 'Saint Mark' },
      { name: 'Saint Patrick' },
      { name: 'Southern Grenadine Islands' },
    ],
  },
  { name: 'Guadeloupe', states: [] },
  { name: 'Guam', states: [] },
  {
    name: 'Guatemala',
    states: [
      { name: 'Alta Verapaz' },
      { name: 'Baja Verapaz' },
      { name: 'Chimaltenango' },
      { name: 'Chiquimula' },
      { name: 'El Progreso' },
      { name: 'Escuintla' },
      { name: 'Guatemala' },
      { name: 'Huehuetenango' },
      { name: 'Izabal' },
      { name: 'Jalapa' },
      { name: 'Jutiapa' },
      { name: 'Petén' },
      { name: 'Quetzaltenango' },
      { name: 'Quiché' },
      { name: 'Retalhuleu' },
      { name: 'Sacatepéquez' },
      { name: 'San Marcos' },
      { name: 'Santa Rosa' },
      { name: 'Sololá' },
      { name: 'Suchitepéquez' },
      { name: 'Totonicapán' },
      { name: 'Zacapa' },
    ],
  },
  { name: 'Guernsey', states: [] },
  {
    name: 'Guinea',
    states: [
      { name: 'Boké' },
      { name: 'Conakry' },
      { name: 'Faranah' },
      { name: 'Kankan' },
      { name: 'Kindia' },
      { name: 'Labé' },
      { name: 'Mamou' },
      { name: 'Nzérékoré' },
    ],
  },
  {
    name: 'Guinea-Bissau',
    states: [{ name: 'Leste' }, { name: 'Norte' }, { name: 'Sul' }],
  },
  {
    name: 'Guyana',
    states: [
      { name: 'Barima-Waini' },
      { name: 'Cuyuni-Mazaruni' },
      { name: 'Demerara-Mahaica' },
      { name: 'East Berbice-Corentyne' },
      { name: 'Essequibo Islands-West Demerara' },
      { name: 'Mahaica-Berbice' },
      { name: 'Pomeroon-Supenaam' },
      { name: 'Potaro-Siparuni' },
      { name: 'Upper Demerara-Berbice' },
      { name: 'Upper Takutu-Upper Essequibo' },
    ],
  },
  {
    name: 'Haiti',
    states: [
      { name: 'Artibonite' },
      { name: 'Centre' },
      { name: 'Grande-Anse' },
      { name: 'Nippes' },
      { name: 'Nord' },
      { name: 'Nord-Est' },
      { name: 'Nord-Ouest' },
      { name: 'Ouest' },
      { name: 'Sud' },
      { name: 'Sud-Est' },
    ],
  },
  { name: 'Heard Island and McDonald Islands', states: [] },
  { name: 'Holy See', states: [] },
  {
    name: 'Honduras',
    states: [
      { name: 'Atlántida' },
      { name: 'Choluteca' },
      { name: 'Colón' },
      { name: 'Comayagua' },
      { name: 'Copán' },
      { name: 'Cortés' },
      { name: 'El Paraíso' },
      { name: 'Francisco Morazán' },
      { name: 'Gracias a Dios' },
      { name: 'Intibucá' },
      { name: 'Islas de la Bahía' },
      { name: 'La Paz' },
      { name: 'Lempira' },
      { name: 'Ocotepeque' },
      { name: 'Olancho' },
      { name: 'Santa Bárbara' },
      { name: 'Valle' },
      { name: 'Yoro' },
    ],
  },
  { name: 'Hong Kong', states: [] },
  {
    name: 'Hungary',
    states: [
      { name: 'Baranya' },
      { name: 'Borsod-Abaúj-Zemplén' },
      { name: 'Budapest' },
      { name: 'Bács-Kiskun' },
      { name: 'Békés' },
      { name: 'Békéscsaba' },
      { name: 'Csongrád' },
      { name: 'Debrecen' },
      { name: 'Dunaújváros' },
      { name: 'Eger' },
      { name: 'Fejér' },
      { name: 'Győr' },
      { name: 'Győr-Moson-Sopron' },
      { name: 'Hajdú-Bihar' },
      { name: 'Heves' },
      { name: 'Hódmezővásárhely' },
      { name: 'Jász-Nagykun-Szolnok' },
      { name: 'Kaposvár' },
      { name: 'Kecskemét' },
      { name: 'Komárom-Esztergom' },
      { name: 'Miskolc' },
      { name: 'Nagykanizsa' },
      { name: 'Nyíregyháza' },
      { name: 'Nógrád' },
      { name: 'Pest' },
      { name: 'Pécs' },
      { name: 'Salgótarján' },
      { name: 'Somogy' },
      { name: 'Sopron' },
      { name: 'Szabolcs-Szatmár-Bereg' },
      { name: 'Szeged' },
      { name: 'Szekszárd' },
      { name: 'Szolnok' },
      { name: 'Szombathely' },
      { name: 'Székesfehérvár' },
      { name: 'Tatabánya' },
      { name: 'Tolna' },
      { name: 'Vas' },
      { name: 'Veszprém' },
      { name: 'Veszprém' },
      { name: 'Zala' },
      { name: 'Zalaegerszeg' },
      { name: 'Érd' },
    ],
  },
  {
    name: 'Iceland',
    states: [
      { name: 'Austurland' },
      { name: 'Höfuðborgarsvæði utan Reykjavíkur' },
      { name: 'Norðurland eystra' },
      { name: 'Norðurland vestra' },
      { name: 'Reykjavík' },
      { name: 'Suðurland' },
      { name: 'Suðurnes' },
      { name: 'Vestfirðir' },
      { name: 'Vesturland' },
    ],
  },
  {
    name: 'India',
    states: [
      { name: 'Andaman and Nicobar Islands' },
      { name: 'Chandigarh' },
      { name: 'Dadra and Nagar Haveli' },
      { name: 'Daman and Diu' },
      { name: 'Delhi' },
      { name: 'Lakshadweep' },
      { name: 'Puducherry' },
      { name: 'Andhra Pradesh' },
      { name: 'Arunachal Pradesh' },
      { name: 'Assam' },
      { name: 'Bihar' },
      { name: 'Chhattisgarh' },
      { name: 'Goa' },
      { name: 'Gujarat' },
      { name: 'Haryana' },
      { name: 'Himachal Pradesh' },
      { name: 'Jammu and Kashmir' },
      { name: 'Jharkhand' },
      { name: 'Karnataka' },
      { name: 'Kerala' },
      { name: 'Madhya Pradesh' },
      { name: 'Maharashtra' },
      { name: 'Manipur' },
      { name: 'Meghalaya' },
      { name: 'Mizoram' },
      { name: 'Nagaland' },
      { name: 'Odisha' },
      { name: 'Punjab' },
      { name: 'Rajasthan' },
      { name: 'Sikkim' },
      { name: 'Tamil Nadu' },
      { name: 'Telangana' },
      { name: 'Tripura' },
      { name: 'Uttar Pradesh' },
      { name: 'Uttarakhand' },
      { name: 'West Bengal' },
    ],
  },
  {
    name: 'Indonesia',
    states: [
      { name: 'Jawa' },
      { name: 'Kalimantan' },
      { name: 'Maluku' },
      { name: 'Nusa Tenggara' },
      { name: 'Papua' },
      { name: 'Sulawesi' },
      { name: 'Sumatera' },
    ],
  },
  {
    name: 'Ivory Coast',
    states: [
      { name: '18 Montagnes' },
      { name: 'Agnébi' },
      { name: 'Bafing' },
      { name: 'Bas-Sassandra' },
      { name: 'Denguélé' },
      { name: 'Fromager' },
      { name: 'Haut-Sassandra' },
      { name: 'Lacs' },
      { name: 'Lagunes' },
      { name: 'Marahoué' },
      { name: 'Moyen-Cavally' },
      { name: 'Moyen-Comoé' },
      { name: 'Nzi-Comoé' },
      { name: 'Savanes' },
      { name: 'Sud-Bandama' },
      { name: 'Sud-Comoé' },
      { name: 'Vallée du Bandama' },
      { name: 'Worodougou' },
      { name: 'Zanzan' },
    ],
  },
  {
    name: 'Iran',
    states: [
      { name: 'Alborz' },
      { name: 'Ardabīl' },
      { name: 'Būshehr' },
      { name: 'Chahār Maḩāll va Bakhtīārī' },
      { name: 'Eşfahān' },
      { name: 'Fārs' },
      { name: 'Golestān' },
      { name: 'Gīlān' },
      { name: 'Hamadān' },
      { name: 'Hormozgān' },
      { name: 'Kermān' },
      { name: 'Kermānshāh' },
      { name: 'Khorāsān-e Janūbī' },
      { name: 'Khorāsān-e Razavī' },
      { name: 'Khorāsān-e Shemālī' },
      { name: 'Khūzestān' },
      { name: 'Kohgīlūyeh va Būyer Aḩmad' },
      { name: 'Kordestān' },
      { name: 'Lorestān' },
      { name: 'Markazī' },
      { name: 'Māzandarān' },
      { name: 'Qazvīn' },
      { name: 'Qom' },
      { name: 'Semnān' },
      { name: 'Sīstān va Balūchestān' },
      { name: 'Tehrān' },
      { name: 'Yazd' },
      { name: 'Zanjān' },
      { name: 'Āz̄arbāyjān-e Gharbī' },
      { name: 'Āz̄arbāyjān-e Sharqī' },
      { name: 'Īlām' },
    ],
  },
  {
    name: 'Iraq',
    states: [
      { name: 'Al Anbār' },
      { name: 'Al Başrah' },
      { name: 'Al Muthanná' },
      { name: 'Al Qādisīyah' },
      { name: 'An Najaf' },
      { name: 'Arbīl' },
      { name: 'As Sulaymānīyah' },
      { name: "At Ta'mīm" },
      { name: 'Baghdād' },
      { name: 'Bābil' },
      { name: 'Dahūk' },
      { name: 'Dhī Qār' },
      { name: 'Diyālá' },
      { name: "Karbalā'" },
      { name: 'Maysān' },
      { name: 'Nīnawá' },
      { name: 'Wāsiţ' },
      { name: 'Şalāḩ ad Dīn' },
    ],
  },
  {
    name: 'Republic of Ireland',
    states: [
      { name: 'Connaught' },
      { name: 'Leinster' },
      { name: 'Munster' },
      { name: 'Ulster' },
    ],
  },
  { name: 'Isle of Man', states: [] },
  {
    name: 'Israel',
    states: [
      { name: 'HaDarom' },
      { name: 'HaMerkaz' },
      { name: 'HaTsafon' },
      { name: 'H̱efa' },
      { name: 'Tel-Aviv' },
      { name: 'Yerushalayim' },
    ],
  },
  {
    name: 'Italy',
    states: [
      { name: 'Abruzzo' },
      { name: 'Basilicata' },
      { name: 'Calabria' },
      { name: 'Campania' },
      { name: 'Emilia-Romagna' },
      { name: 'Friuli-Venezia Giulia' },
      { name: 'Lazio' },
      { name: 'Liguria' },
      { name: 'Lombardia' },
      { name: 'Marche' },
      { name: 'Molise' },
      { name: 'Piemonte' },
      { name: 'Puglia' },
      { name: 'Sardegna' },
      { name: 'Sicilia' },
      { name: 'Toscana' },
      { name: 'Trentino-Alto Adige' },
      { name: 'Umbria' },
      { name: "Valle d'Aosta" },
      { name: 'Veneto' },
    ],
  },
  {
    name: 'Jamaica',
    states: [
      { name: 'Clarendon' },
      { name: 'Hanover' },
      { name: 'Kingston' },
      { name: 'Manchester' },
      { name: 'Portland' },
      { name: 'Saint Andrew' },
      { name: 'Saint Ann' },
      { name: 'Saint Catherine' },
      { name: 'Saint Elizabeth' },
      { name: 'Saint James' },
      { name: 'Saint Mary' },
      { name: 'Saint Thomas' },
      { name: 'Trelawny' },
      { name: 'Westmoreland' },
    ],
  },
  {
    name: 'Japan',
    states: [
      { name: 'Aiti' },
      { name: 'Akita' },
      { name: 'Aomori' },
      { name: 'Ehime' },
      { name: 'Gihu' },
      { name: 'Gunma' },
      { name: 'Hirosima' },
      { name: 'Hokkaidô' },
      { name: 'Hukui' },
      { name: 'Hukuoka' },
      { name: 'Hukusima' },
      { name: 'Hyôgo' },
      { name: 'Ibaraki' },
      { name: 'Isikawa' },
      { name: 'Iwate' },
      { name: 'Kagawa' },
      { name: 'Kagosima' },
      { name: 'Kanagawa' },
      { name: 'Kumamoto' },
      { name: 'Kyôto' },
      { name: 'Kôti' },
      { name: 'Mie' },
      { name: 'Miyagi' },
      { name: 'Miyazaki' },
      { name: 'Nagano' },
      { name: 'Nagasaki' },
      { name: 'Nara' },
      { name: 'Niigata' },
      { name: 'Okayama' },
      { name: 'Okinawa' },
      { name: 'Saga' },
      { name: 'Saitama' },
      { name: 'Siga' },
      { name: 'Simane' },
      { name: 'Sizuoka' },
      { name: 'Tiba' },
      { name: 'Tokusima' },
      { name: 'Totigi' },
      { name: 'Tottori' },
      { name: 'Toyama' },
      { name: 'Tôkyô' },
      { name: 'Wakayama' },
      { name: 'Yamagata' },
      { name: 'Yamaguti' },
      { name: 'Yamanasi' },
      { name: 'Ôita' },
      { name: 'Ôsaka' },
    ],
  },
  { name: 'Jersey', states: [] },
  {
    name: 'Jordan',
    states: [
      { name: "Al Balqā'" },
      { name: 'Al ʽAqabah' },
      { name: "Az Zarqā'" },
      { name: 'Aţ Ţafīlah' },
      { name: 'Irbid' },
      { name: 'Jerash' },
      { name: 'Karak' },
      { name: "Ma'ān" },
      { name: 'Mafraq' },
      { name: 'Mādabā' },
      { name: 'ʽAjlūn' },
      { name: '‘Ammān' },
    ],
  },
  {
    name: 'Kazakhstan',
    states: [
      { name: 'Almaty' },
      { name: 'Almaty oblysy' },
      { name: 'Aqmola oblysy' },
      { name: 'Aqtöbe oblysy' },
      { name: 'Astana' },
      { name: 'Atyraū oblysy' },
      { name: 'Batys Qazaqstan oblysy' },
      { name: 'Mangghystaū oblysy' },
      { name: 'Ongtüstik Qazaqstan oblysy' },
      { name: 'Pavlodar oblysy' },
      { name: 'Qaraghandy oblysy' },
      { name: 'Qostanay oblysy' },
      { name: 'Qyzylorda oblysy' },
      { name: 'Shyghys Qazaqstan oblysy' },
      { name: 'Soltüstik Qazaqstan oblysy' },
      { name: 'Zhambyl oblysy' },
    ],
  },
  {
    name: 'Kenya',
    states: [
      { name: 'Central' },
      { name: 'Coast' },
      { name: 'Eastern' },
      { name: 'Nairobi' },
      { name: 'North-Eastern' },
      { name: 'Nyanza' },
      { name: 'Rift Valley' },
      { name: 'Western' },
    ],
  },
  {
    name: 'Kiribati',
    states: [
      { name: 'Gilbert Islands' },
      { name: 'Line Islands' },
      { name: 'Phoenix Islands' },
    ],
  },
  {
    name: 'Kuwait',
    states: [
      { name: 'Al Aḩmadi' },
      { name: 'Al Farwānīyah' },
      { name: 'Al Jahrā’' },
      { name: 'Al Kuwayt' },
      { name: 'Mubārak al Kabīr' },
      { name: 'Ḩawallī' },
    ],
  },
  {
    name: 'Kyrgyzstan',
    states: [
      { name: 'Batken' },
      { name: 'Bishkek' },
      { name: 'Chü' },
      { name: 'Jalal-Abad' },
      { name: 'Naryn' },
      { name: 'Osh' },
      { name: 'Talas' },
      { name: 'Ysyk-Köl' },
    ],
  },
  {
    name: 'Laos',
    states: [
      { name: 'Attapu' },
      { name: 'Bokèo' },
      { name: 'Bolikhamxai' },
      { name: 'Champasak' },
      { name: 'Houaphan' },
      { name: 'Khammouan' },
      { name: 'Louang Namtha' },
      { name: 'Louangphabang' },
      { name: 'Oudômxai' },
      { name: 'Phôngsali' },
      { name: 'Salavan' },
      { name: 'Savannakhét' },
      { name: 'Vientiane' },
      { name: 'Vientiane' },
      { name: 'Xaignabouli' },
      { name: 'Xaisômboun' },
      { name: 'Xiangkhoang' },
      { name: 'Xékong' },
    ],
  },
  {
    name: 'Latvia',
    states: [
      { name: 'Aglonas novads' },
      { name: 'Aizkraukles novads' },
      { name: 'Aizputes novads' },
      { name: 'Aknīstes novads' },
      { name: 'Alojas novads' },
      { name: 'Alsungas novads' },
      { name: 'Alūksnes novads' },
      { name: 'Amatas novads' },
      { name: 'Apes novads' },
      { name: 'Auces novads' },
      { name: 'Babītes novads' },
      { name: 'Baldones novads' },
      { name: 'Baltinavas novads' },
      { name: 'Balvu novads' },
      { name: 'Bauskas novads' },
      { name: 'Beverīnas novads' },
      { name: 'Brocēnu novads' },
      { name: 'Burtnieku novads' },
      { name: 'Carnikavas novads' },
      { name: 'Cesvaines novads' },
      { name: 'Ciblas novads' },
      { name: 'Cēsu novads' },
      { name: 'Dagdas novads' },
      { name: 'Daugavpils' },
      { name: 'Daugavpils novads' },
      { name: 'Dobeles novads' },
      { name: 'Dundagas novads' },
      { name: 'Durbes novads' },
      { name: 'Engures novads' },
      { name: 'Garkalnes novads' },
      { name: 'Grobiņas novads' },
      { name: 'Gulbenes novads' },
      { name: 'Iecavas novads' },
      { name: 'Ikšķiles novads' },
      { name: 'Ilūkstes novads' },
      { name: 'Inčukalna novads' },
      { name: 'Jaunjelgavas novads' },
      { name: 'Jaunpiebalgas novads' },
      { name: 'Jaunpils novads' },
      { name: 'Jelgava' },
      { name: 'Jelgavas novads' },
      { name: 'Jēkabpils' },
      { name: 'Jēkabpils novads' },
      { name: 'Jūrmala' },
      { name: 'Kandavas novads' },
      { name: 'Kocēnu novads' },
      { name: 'Kokneses novads' },
      { name: 'Krimuldas novads' },
      { name: 'Krustpils novads' },
      { name: 'Krāslavas novads' },
      { name: 'Kuldīgas novads' },
      { name: 'Kārsavas novads' },
      { name: 'Lielvārdes novads' },
      { name: 'Liepāja' },
      { name: 'Limbažu novads' },
      { name: 'Lubānas novads' },
      { name: 'Ludzas novads' },
      { name: 'Līgatnes novads' },
      { name: 'Līvānu novads' },
      { name: 'Madonas novads' },
      { name: 'Mazsalacas novads' },
      { name: 'Mālpils novads' },
      { name: 'Mārupes novads' },
      { name: 'Mērsraga novads' },
      { name: 'Naukšēnu novads' },
      { name: 'Neretas novads' },
      { name: 'Nīcas novads' },
      { name: 'Ogres novads' },
      { name: 'Olaines novads' },
      { name: 'Ozolnieku novads' },
      { name: 'Preiļu novads' },
      { name: 'Priekules novads' },
      { name: 'Priekuļu novads' },
      { name: 'Pārgaujas novads' },
      { name: 'Pāvilostas novads' },
      { name: 'Pļaviņu novads' },
      { name: 'Raunas novads' },
      { name: 'Riebiņu novads' },
      { name: 'Rojas novads' },
      { name: 'Ropažu novads' },
      { name: 'Rucavas novads' },
      { name: 'Rugāju novads' },
      { name: 'Rundāles novads' },
      { name: 'Rēzekne' },
      { name: 'Rēzeknes novads' },
      { name: 'Rīga' },
      { name: 'Rūjienas novads' },
      { name: 'Salacgrīvas novads' },
      { name: 'Salas novads' },
      { name: 'Salaspils novads' },
      { name: 'Saldus novads' },
      { name: 'Saulkrastu novads' },
      { name: 'Siguldas novads' },
      { name: 'Skrundas novads' },
      { name: 'Skrīveru novads' },
      { name: 'Smiltenes novads' },
      { name: 'Stopiņu novads' },
      { name: 'Strenču novads' },
      { name: 'Sējas novads' },
      { name: 'Talsu novads' },
      { name: 'Tukuma novads' },
      { name: 'Tērvetes novads' },
      { name: 'Vaiņodes novads' },
      { name: 'Valkas novads' },
      { name: 'Valmiera' },
      { name: 'Varakļānu novads' },
      { name: 'Vecpiebalgas novads' },
      { name: 'Vecumnieku novads' },
      { name: 'Ventspils' },
      { name: 'Ventspils novads' },
      { name: 'Viesītes novads' },
      { name: 'Viļakas novads' },
      { name: 'Viļānu novads' },
      { name: 'Vārkavas novads' },
      { name: 'Zilupes novads' },
      { name: 'Ādažu novads' },
      { name: 'Ērgļu novads' },
      { name: 'Ķeguma novads' },
      { name: 'Ķekavas novads' },
    ],
  },
  {
    name: 'Lebanon',
    states: [
      { name: 'Aakkâr' },
      { name: 'Baalbek-Hermel' },
      { name: 'Beyrouth' },
      { name: 'Béqaa' },
      { name: 'Liban-Nord' },
      { name: 'Liban-Sud' },
      { name: 'Mont-Liban' },
      { name: 'Nabatîyé' },
    ],
  },
  {
    name: 'Lesotho',
    states: [
      { name: 'Berea' },
      { name: 'Butha-Buthe' },
      { name: 'Leribe' },
      { name: 'Mafeteng' },
      { name: 'Maseru' },
      { name: "Mohale's Hoek" },
      { name: 'Mokhotlong' },
      { name: "Qacha's Nek" },
      { name: 'Quthing' },
      { name: 'Thaba-Tseka' },
    ],
  },
  {
    name: 'Liberia',
    states: [
      { name: 'Bomi' },
      { name: 'Bong' },
      { name: 'Gbarpolu' },
      { name: 'Grand Bassa' },
      { name: 'Grand Cape Mount' },
      { name: 'Grand Gedeh' },
      { name: 'Grand Kru' },
      { name: 'Lofa' },
      { name: 'Margibi' },
      { name: 'Maryland' },
      { name: 'Montserrado' },
      { name: 'Nimba' },
      { name: 'River Gee' },
      { name: 'Rivercess' },
      { name: 'Sinoe' },
    ],
  },
  {
    name: 'Libya',
    states: [
      { name: 'Al Buţnān' },
      { name: 'Al Jabal al Akhḑar' },
      { name: 'Al Jabal al Gharbī' },
      { name: 'Al Jifārah' },
      { name: 'Al Jufrah' },
      { name: 'Al Kufrah' },
      { name: 'Al Marj' },
      { name: 'Al Marqab' },
      { name: 'Al Wāḩāt' },
      { name: 'An Nuqaţ al Khams' },
      { name: 'Az Zāwiyah' },
      { name: 'Banghāzī' },
      { name: 'Darnah' },
      { name: 'Ghāt' },
      { name: 'Mişrātah' },
      { name: 'Murzuq' },
      { name: 'Nālūt' },
      { name: 'Sabhā' },
      { name: 'Surt' },
      { name: 'Wādī al Ḩayāt' },
      { name: 'Wādī ash Shāţiʾ' },
      { name: 'Ţarābulus' },
    ],
  },
  {
    name: 'Liechtenstein',
    states: [
      { name: 'Balzers' },
      { name: 'Eschen' },
      { name: 'Gamprin' },
      { name: 'Mauren' },
      { name: 'Planken' },
      { name: 'Ruggell' },
      { name: 'Schaan' },
      { name: 'Schellenberg' },
      { name: 'Triesen' },
      { name: 'Triesenberg' },
      { name: 'Vaduz' },
    ],
  },
  {
    name: 'Lithuania',
    states: [
      { name: 'Alytaus Apskritis' },
      { name: 'Kauno Apskritis' },
      { name: 'Klaipėdos Apskritis' },
      { name: 'Marijampolės Apskritis' },
      { name: 'Panevėžio Apskritis' },
      { name: 'Tauragės Apskritis' },
      { name: 'Telšių Apskritis' },
      { name: 'Utenos Apskritis' },
      { name: 'Vilniaus Apskritis' },
      { name: 'Šiaulių Apskritis' },
    ],
  },
  {
    name: 'Luxembourg',
    states: [
      { name: 'Diekirch' },
      { name: 'Grevenmacher' },
      { name: 'Luxembourg' },
    ],
  },
  { name: 'Macau', states: [] },
  {
    name: 'Republic of Macedonia',
    states: [
      { name: 'Aerodrom' },
      { name: 'Aračinovo' },
      { name: 'Berovo' },
      { name: 'Bitola' },
      { name: 'Bogdanci' },
      { name: 'Bogovinje' },
      { name: 'Bosilovo' },
      { name: 'Brvenica' },
      { name: 'Butel' },
      { name: 'Centar' },
      { name: 'Centar Župa' },
      { name: 'Debar' },
      { name: 'Debarca' },
      { name: 'Delčevo' },
      { name: 'Demir Hisar' },
      { name: 'Demir Kapija' },
      { name: 'Dojran' },
      { name: 'Dolneni' },
      { name: 'Drugovo' },
      { name: 'Gazi Baba' },
      { name: 'Gevgelija' },
      { name: 'Gjorče Petrov' },
      { name: 'Gostivar' },
      { name: 'Gradsko' },
      { name: 'Ilinden' },
      { name: 'Jegunovce' },
      { name: 'Karbinci' },
      { name: 'Karpoš' },
      { name: 'Kavadarci' },
      { name: 'Kisela Voda' },
      { name: 'Kičevo' },
      { name: 'Konče' },
      { name: 'Kočani' },
      { name: 'Kratovo' },
      { name: 'Kriva Palanka' },
      { name: 'Krivogaštani' },
      { name: 'Kruševo' },
      { name: 'Kumanovo' },
      { name: 'Lipkovo' },
      { name: 'Lozovo' },
      { name: 'Makedonska Kamenica' },
      { name: 'Makedonski Brod' },
      { name: 'Mavrovo i Rostuša' },
      { name: 'Mogila' },
      { name: 'Negotino' },
      { name: 'Novaci' },
      { name: 'Novo Selo' },
      { name: 'Ohrid' },
      { name: 'Oslomej' },
      { name: 'Pehčevo' },
      { name: 'Petrovec' },
      { name: 'Plasnica' },
      { name: 'Prilep' },
      { name: 'Probištip' },
      { name: 'Radoviš' },
      { name: 'Rankovce' },
      { name: 'Resen' },
      { name: 'Rosoman' },
      { name: 'Saraj' },
      { name: 'Sopište' },
      { name: 'Staro Nagoričane' },
      { name: 'Struga' },
      { name: 'Strumica' },
      { name: 'Studeničani' },
      { name: 'Sveti Nikole' },
      { name: 'Tearce' },
      { name: 'Tetovo' },
      { name: 'Valandovo' },
      { name: 'Vasilevo' },
      { name: 'Veles' },
      { name: 'Vevčani' },
      { name: 'Vinica' },
      { name: 'Vraneštica' },
      { name: 'Vrapčište' },
      { name: 'Zajas' },
      { name: 'Zelenikovo' },
      { name: 'Zrnovci' },
      { name: 'Čair' },
      { name: 'Čaška' },
      { name: 'Češinovo-Obleševo' },
      { name: 'Čučer Sandevo' },
      { name: 'Štip' },
      { name: 'Šuto Orizari' },
      { name: 'Želino' },
    ],
  },
  {
    name: 'Madagascar',
    states: [
      { name: 'Antananarivo' },
      { name: 'Antsiranana' },
      { name: 'Fianarantsoa' },
      { name: 'Mahajanga' },
      { name: 'Toamasina' },
      { name: 'Toliara' },
    ],
  },
  {
    name: 'Malawi',
    states: [
      { name: 'Central Region' },
      { name: 'Northern Region' },
      { name: 'Southern Region' },
    ],
  },
  {
    name: 'Malaysia',
    states: [
      { name: 'Wilayah Persekutuan Kuala Lumpur' },
      { name: 'Wilayah Persekutuan Labuan' },
      { name: 'Wilayah Persekutuan Putrajaya' },
      { name: 'Johor' },
      { name: 'Kedah' },
      { name: 'Kelantan' },
      { name: 'Melaka' },
      { name: 'Negeri Sembilan' },
      { name: 'Pahang' },
      { name: 'Perak' },
      { name: 'Perlis' },
      { name: 'Pulau Pinang' },
      { name: 'Sabah' },
      { name: 'Sarawak' },
      { name: 'Selangor' },
      { name: 'Terengganu' },
    ],
  },
  {
    name: 'Maldives',
    states: [
      { name: 'Central' },
      { name: 'Male' },
      { name: 'North' },
      { name: 'North Central' },
      { name: 'South' },
      { name: 'South Central' },
      { name: 'Upper North' },
      { name: 'Upper South' },
    ],
  },
  {
    name: 'Mali',
    states: [
      { name: 'Bamako' },
      { name: 'Gao' },
      { name: 'Kayes' },
      { name: 'Kidal' },
      { name: 'Koulikoro' },
      { name: 'Mopti' },
      { name: 'Sikasso' },
      { name: 'Ségou' },
      { name: 'Tombouctou' },
    ],
  },
  {
    name: 'Malta',
    states: [
      { name: 'Attard' },
      { name: 'Balzan' },
      { name: 'Birgu' },
      { name: 'Birkirkara' },
      { name: 'Birżebbuġa' },
      { name: 'Bormla' },
      { name: 'Dingli' },
      { name: 'Fgura' },
      { name: 'Floriana' },
      { name: 'Fontana' },
      { name: 'Gudja' },
      { name: 'Għajnsielem' },
      { name: 'Għarb' },
      { name: 'Għargħur' },
      { name: 'Għasri' },
      { name: 'Għaxaq' },
      { name: 'Gżira' },
      { name: 'Iklin' },
      { name: 'Isla' },
      { name: 'Kalkara' },
      { name: 'Kerċem' },
      { name: 'Kirkop' },
      { name: 'Lija' },
      { name: 'Luqa' },
      { name: 'Marsa' },
      { name: 'Marsaskala' },
      { name: 'Marsaxlokk' },
      { name: 'Mdina' },
      { name: 'Mellieħa' },
      { name: 'Mosta' },
      { name: 'Mqabba' },
      { name: 'Msida' },
      { name: 'Mtarfa' },
      { name: 'Munxar' },
      { name: 'Mġarr' },
      { name: 'Nadur' },
      { name: 'Naxxar' },
      { name: 'Paola' },
      { name: 'Pembroke' },
      { name: 'Pietà' },
      { name: 'Qala' },
      { name: 'Qormi' },
      { name: 'Qrendi' },
      { name: 'Rabat Għawdex' },
      { name: 'Rabat Malta' },
      { name: 'Safi' },
      { name: 'San Lawrenz' },
      { name: 'San Pawl il-Baħar' },
      { name: 'San Ġiljan' },
      { name: 'San Ġwann' },
      { name: 'Sannat' },
      { name: 'Santa Luċija' },
      { name: 'Santa Venera' },
      { name: 'Siġġiewi' },
      { name: 'Sliema' },
      { name: 'Swieqi' },
      { name: "Ta' Xbiex" },
      { name: 'Tarxien' },
      { name: 'Valletta' },
      { name: 'Xagħra' },
      { name: 'Xewkija' },
      { name: 'Xgħajra' },
      { name: 'Ħamrun' },
      { name: 'Żabbar' },
      { name: 'Żebbuġ Għawdex' },
      { name: 'Żebbuġ Malta' },
      { name: 'Żejtun' },
      { name: 'Żurrieq' },
    ],
  },
  {
    name: 'Marshall Islands',
    states: [{ name: 'Ralik chain' }, { name: 'Ratak chain' }],
  },
  { name: 'Martinique', states: [] },
  {
    name: 'Mauritania',
    states: [
      { name: 'Adrar' },
      { name: 'Assaba' },
      { name: 'Brakna' },
      { name: 'Dakhlet Nouâdhibou' },
      { name: 'Gorgol' },
      { name: 'Guidimaka' },
      { name: 'Hodh ech Chargui' },
      { name: 'Hodh el Gharbi' },
      { name: 'Inchiri' },
      { name: 'Nouakchott' },
      { name: 'Tagant' },
      { name: 'Tiris Zemmour' },
      { name: 'Trarza' },
    ],
  },
  {
    name: 'Mauritius',
    states: [
      { name: 'Agalega Islands' },
      { name: 'Beau Bassin-Rose Hill' },
      { name: 'Black River' },
      { name: 'Cargados Carajos Shoals' },
      { name: 'Curepipe' },
      { name: 'Flacq' },
      { name: 'Grand Port' },
      { name: 'Moka' },
      { name: 'Pamplemousses' },
      { name: 'Plaines Wilhems' },
      { name: 'Port Louis' },
      { name: 'Port Louis' },
      { name: 'Quatre Bornes' },
      { name: 'Rivière du Rempart' },
      { name: 'Rodrigues Island' },
      { name: 'Savanne' },
      { name: 'Vacoas-Phoenix' },
    ],
  },
  { name: 'Mayotte', states: [] },
  {
    name: 'Mexico',
    states: [
      { name: 'Distrito Federal' },
      { name: 'Aguascalientes' },
      { name: 'Baja California' },
      { name: 'Baja California Sur' },
      { name: 'Campeche' },
      { name: 'Chiapas' },
      { name: 'Chihuahua' },
      { name: 'Coahuila' },
      { name: 'Colima' },
      { name: 'Durango' },
      { name: 'Guanajuato' },
      { name: 'Guerrero' },
      { name: 'Hidalgo' },
      { name: 'Jalisco' },
      { name: 'Michoacán' },
      { name: 'Morelos' },
      { name: 'México' },
      { name: 'Nayarit' },
      { name: 'Nuevo León' },
      { name: 'Oaxaca' },
      { name: 'Puebla' },
      { name: 'Querétaro' },
      { name: 'Quintana Roo' },
      { name: 'San Luis Potosí' },
      { name: 'Sinaloa' },
      { name: 'Sonora' },
      { name: 'Tabasco' },
      { name: 'Tamaulipas' },
      { name: 'Tlaxcala' },
      { name: 'Veracruz' },
      { name: 'Yucatán' },
      { name: 'Zacatecas' },
    ],
  },
  {
    name: 'Federated States of Micronesia',
    states: [
      { name: 'Chuuk' },
      { name: 'Kosrae' },
      { name: 'Pohnpei' },
      { name: 'Yap' },
    ],
  },
  {
    name: 'Moldova',
    states: [
      { name: 'Anenii Noi' },
      { name: 'Basarabeasca' },
      { name: 'Briceni' },
      { name: 'Bălţi' },
      { name: 'Cahul' },
      { name: 'Cantemir' },
      { name: 'Chişinău' },
      { name: 'Cimişlia' },
      { name: 'Criuleni' },
      { name: 'Călăraşi' },
      { name: 'Căuşeni' },
      { name: 'Donduşeni' },
      { name: 'Drochia' },
      { name: 'Dubăsari' },
      { name: 'Edineţ' },
      { name: 'Floreşti' },
      { name: 'Făleşti' },
      { name: 'Glodeni' },
      { name: 'Găgăuzia, Unitatea teritorială autonomă' },
      { name: 'Hînceşti' },
      { name: 'Ialoveni' },
      { name: 'Leova' },
      { name: 'Nisporeni' },
      { name: 'Ocniţa' },
      { name: 'Orhei' },
      { name: 'Rezina' },
      { name: 'Rîşcani' },
      { name: 'Soroca' },
      { name: 'Străşeni' },
      { name: 'Stînga Nistrului, unitatea teritorială din' },
      { name: 'Sîngerei' },
      { name: 'Taraclia' },
      { name: 'Teleneşti' },
      { name: 'Tighina' },
      { name: 'Ungheni' },
      { name: 'Şoldăneşti' },
      { name: 'Ştefan Vodă' },
    ],
  },
  {
    name: 'Monaco',
    states: [
      { name: 'Fontvieille' },
      { name: 'Jardin Exotique' },
      { name: 'La Colle' },
      { name: 'La Condamine' },
      { name: 'La Gare' },
      { name: 'La Source' },
      { name: 'Larvotto' },
      { name: 'Malbousquet' },
      { name: 'Monaco-Ville' },
      { name: 'Moneghetti' },
      { name: 'Monte-Carlo' },
      { name: 'Moulins' },
      { name: 'Port-Hercule' },
      { name: 'Saint-Roman' },
      { name: 'Sainte-Dévote' },
      { name: 'Spélugues' },
      { name: 'Vallon de la Rousse' },
    ],
  },
  {
    name: 'Mongolia',
    states: [
      { name: 'Arhangay' },
      { name: 'Bayan-Ölgiy' },
      { name: 'Bayanhongor' },
      { name: 'Bulgan' },
      { name: 'Darhan uul' },
      { name: 'Dornod' },
      { name: 'Dornogovĭ' },
      { name: 'Dundgovĭ' },
      { name: 'Dzavhan' },
      { name: 'Govĭ-Altay' },
      { name: 'Govĭ-Sümber' },
      { name: 'Hentiy' },
      { name: 'Hovd' },
      { name: 'Hövsgöl' },
      { name: 'Orhon' },
      { name: 'Selenge' },
      { name: 'Sühbaatar' },
      { name: 'Töv' },
      { name: 'Ulaanbaatar' },
      { name: 'Uvs' },
      { name: 'Ömnögovĭ' },
      { name: 'Övörhangay' },
    ],
  },
  {
    name: 'Montenegro',
    states: [
      { name: 'Andrijevica' },
      { name: 'Bar' },
      { name: 'Berane' },
      { name: 'Bijelo Polje' },
      { name: 'Budva' },
      { name: 'Cetinje' },
      { name: 'Danilovgrad' },
      { name: 'Gusinje' },
      { name: 'Herceg-Novi' },
      { name: 'Kolašin' },
      { name: 'Kotor' },
      { name: 'Mojkovac' },
      { name: 'Nikšić' },
      { name: 'Petnjica' },
      { name: 'Plav' },
      { name: 'Pljevlja' },
      { name: 'Plužine' },
      { name: 'Podgorica' },
      { name: 'Rožaje' },
      { name: 'Tivat' },
      { name: 'Ulcinj' },
      { name: 'Šavnik' },
      { name: 'Žabljak' },
    ],
  },
  { name: 'Montserrat', states: [] },
  {
    name: 'Morocco',
    states: [
      { name: 'Chaouia-Ouardigha' },
      { name: 'Doukhala-Abda' },
      { name: 'Fès-Boulemane' },
      { name: 'Gharb-Chrarda-Beni Hssen' },
      { name: 'Grand Casablanca' },
      { name: 'Guelmim-Es Smara' },
      { name: "L'Oriental" },
      { name: 'Laâyoune-Boujdour-Sakia el Hamra' },
      { name: 'Marrakech-Tensift-Al Haouz' },
      { name: 'Meknès-Tafilalet' },
      { name: 'Oued ed Dahab-Lagouira' },
      { name: 'Rabat-Salé-Zemmour-Zaer' },
      { name: 'Souss-Massa-Drâa' },
      { name: 'Tadla-Azilal' },
      { name: 'Tanger-Tétouan' },
      { name: 'Taza-Al Hoceima-Taounate' },
    ],
  },
  {
    name: 'Mozambique',
    states: [
      { name: 'Cabo Delgado' },
      { name: 'Gaza' },
      { name: 'Inhambane' },
      { name: 'Manica' },
      { name: 'Maputo' },
      { name: 'Maputo' },
      { name: 'Nampula' },
      { name: 'Niassa' },
      { name: 'Sofala' },
      { name: 'Tete' },
      { name: 'Zambézia' },
    ],
  },
  {
    name: 'Myanmar',
    states: [
      { name: 'Ayeyarwady' },
      { name: 'Bago' },
      { name: 'Chin' },
      { name: 'Kachin' },
      { name: 'Kayah' },
      { name: 'Kayin' },
      { name: 'Magway' },
      { name: 'Mandalay' },
      { name: 'Mon' },
      { name: 'Rakhine' },
      { name: 'Sagaing' },
      { name: 'Shan' },
      { name: 'Tanintharyi' },
      { name: 'Yangon' },
    ],
  },
  {
    name: 'Namibia',
    states: [
      { name: 'Erongo' },
      { name: 'Hardap' },
      { name: 'Karas' },
      { name: 'Kavango East' },
      { name: 'Kavango West' },
      { name: 'Khomas' },
      { name: 'Kunene' },
      { name: 'Ohangwena' },
      { name: 'Omaheke' },
      { name: 'Omusati' },
      { name: 'Oshana' },
      { name: 'Oshikoto' },
      { name: 'Otjozondjupa' },
      { name: 'Zambezi' },
    ],
  },
  {
    name: 'Nauru',
    states: [
      { name: 'Aiwo' },
      { name: 'Anabar' },
      { name: 'Anetan' },
      { name: 'Anibare' },
      { name: 'Baiti' },
      { name: 'Boe' },
      { name: 'Buada' },
      { name: 'Denigomodu' },
      { name: 'Ewa' },
      { name: 'Ijuw' },
      { name: 'Meneng' },
      { name: 'Nibok' },
      { name: 'Uaboe' },
      { name: 'Yaren' },
    ],
  },
  {
    name: 'Nepal',
    states: [
      { name: 'Madhya Pashchimanchal' },
      { name: 'Madhyamanchal' },
      { name: 'Pashchimanchal' },
      { name: 'Purwanchal' },
      { name: 'Sudur Pashchimanchal' },
    ],
  },
  {
    name: 'Netherlands',
    states: [
      { name: 'Drenthe' },
      { name: 'Flevoland' },
      { name: 'Fryslân' },
      { name: 'Gelderland' },
      { name: 'Groningen' },
      { name: 'Limburg' },
      { name: 'Noord-Brabant' },
      { name: 'Noord-Holland' },
      { name: 'Overijssel' },
      { name: 'Utrecht' },
      { name: 'Zeeland' },
      { name: 'Zuid-Holland' },
      { name: 'Aruba' },
      { name: 'Curaçao' },
      { name: 'Sint Maarten' },
      { name: 'Bonaire' },
      { name: 'Saba' },
      { name: 'Sint Eustatius' },
    ],
  },
  { name: 'New Caledonia', states: [] },
  {
    name: 'New Zealand',
    states: [
      { name: 'North Island' },
      { name: 'South Island' },
      { name: 'Auckland' },
      { name: 'Bay of Plenty' },
      { name: 'Canterbury' },
      { name: "Hawke's Bay" },
      { name: 'Manawatu-Wanganui' },
      { name: 'Northland' },
      { name: 'Otago' },
      { name: 'Southland' },
      { name: 'Taranaki' },
      { name: 'Waikato' },
      { name: 'Wellington' },
      { name: 'West Coast' },
      { name: 'Chatham Islands Territory' },
      { name: 'Gisborne District' },
      { name: 'Marlborough District' },
      { name: 'Nelson City' },
      { name: 'Tasman District' },
    ],
  },
  {
    name: 'Nicaragua',
    states: [
      { name: 'Atlántico Norte' },
      { name: 'Atlántico Sur' },
      { name: 'Boaco' },
      { name: 'Carazo' },
      { name: 'Chinandega' },
      { name: 'Chontales' },
      { name: 'Estelí' },
      { name: 'Granada' },
      { name: 'Jinotega' },
      { name: 'León' },
      { name: 'Madriz' },
      { name: 'Managua' },
      { name: 'Masaya' },
      { name: 'Matagalpa' },
      { name: 'Nueva Segovia' },
      { name: 'Rivas' },
      { name: 'Río San Juan' },
    ],
  },
  {
    name: 'Niger',
    states: [
      { name: 'Agadez' },
      { name: 'Diffa' },
      { name: 'Dosso' },
      { name: 'Maradi' },
      { name: 'Niamey' },
      { name: 'Tahoua' },
      { name: 'Tillabéri' },
      { name: 'Zinder' },
    ],
  },
  {
    name: 'Nigeria',
    states: [
      { name: 'Abia' },
      { name: 'Abuja Federal Capital Territory' },
      { name: 'Adamawa' },
      { name: 'Akwa Ibom' },
      { name: 'Anambra' },
      { name: 'Bauchi' },
      { name: 'Bayelsa' },
      { name: 'Benue' },
      { name: 'Borno' },
      { name: 'Cross River' },
      { name: 'Delta' },
      { name: 'Ebonyi' },
      { name: 'Edo' },
      { name: 'Ekiti' },
      { name: 'Enugu' },
      { name: 'Gombe' },
      { name: 'Imo' },
      { name: 'Jigawa' },
      { name: 'Kaduna' },
      { name: 'Kano' },
      { name: 'Katsina' },
      { name: 'Kebbi' },
      { name: 'Kogi' },
      { name: 'Kwara' },
      { name: 'Lagos' },
      { name: 'Nassarawa' },
      { name: 'Niger' },
      { name: 'Ogun' },
      { name: 'Ondo' },
      { name: 'Osun' },
      { name: 'Oyo' },
      { name: 'Plateau' },
      { name: 'Rivers' },
      { name: 'Sokoto' },
      { name: 'Taraba' },
      { name: 'Yobe' },
      { name: 'Zamfara' },
    ],
  },
  { name: 'Niue', states: [] },
  { name: 'Norfolk Island', states: [] },
  {
    name: 'North Korea',
    states: [
      { name: 'Chagang' },
      { name: 'Kangwon' },
      { name: 'North Hamgyong' },
      { name: 'North Hwanghae' },
      { name: 'North Pyongan' },
      { name: 'Pyongyang' },
      { name: 'Rason' },
      { name: 'Ryanggang' },
      { name: 'South Hamgyong' },
      { name: 'South Hwanghae' },
      { name: 'South Pyongan' },
    ],
  },
  { name: 'Northern Mariana Islands', states: [] },
  {
    name: 'Norway',
    states: [
      { name: 'Akershus' },
      { name: 'Aust-Agder' },
      { name: 'Buskerud' },
      { name: 'Finnmark' },
      { name: 'Hedmark' },
      { name: 'Hordaland' },
      { name: 'Jan Mayen' },
      { name: 'Møre og Romsdal' },
      { name: 'Nord-Trøndelag' },
      { name: 'Nordland' },
      { name: 'Oppland' },
      { name: 'Oslo' },
      { name: 'Rogaland' },
      { name: 'Sogn og Fjordane' },
      { name: 'Svalbard' },
      { name: 'Sør-Trøndelag' },
      { name: 'Telemark' },
      { name: 'Troms' },
      { name: 'Vest-Agder' },
      { name: 'Vestfold' },
      { name: 'Østfold' },
    ],
  },
  {
    name: 'Oman',
    states: [
      { name: 'Ad Dākhilīyah' },
      { name: 'Al Buraymī' },
      { name: 'Al Bāţinah' },
      { name: 'Al Wusţá' },
      { name: 'Ash Sharqīyah' },
      { name: 'Az̧ Z̧āhirah' },
      { name: 'Masqaţ' },
      { name: 'Musandam' },
      { name: 'Z̧ufār' },
    ],
  },
  {
    name: 'Pakistan',
    states: [
      { name: 'Azad Kashmir' },
      { name: 'Balochistan' },
      { name: 'Federally Administered Tribal Areas' },
      { name: 'Gilgit-Baltistan' },
      { name: 'Islamabad' },
      { name: 'Khyber Pakhtunkhwa' },
      { name: 'Punjab' },
      { name: 'Sindh' },
    ],
  },
  {
    name: 'Palau',
    states: [
      { name: 'Aimeliik' },
      { name: 'Airai' },
      { name: 'Angaur' },
      { name: 'Hatobohei' },
      { name: 'Kayangel' },
      { name: 'Koror' },
      { name: 'Melekeok' },
      { name: 'Ngaraard' },
      { name: 'Ngarchelong' },
      { name: 'Ngardmau' },
      { name: 'Ngatpang' },
      { name: 'Ngchesar' },
      { name: 'Ngeremlengui' },
      { name: 'Ngiwal' },
      { name: 'Peleliu' },
      { name: 'Sonsorol' },
    ],
  },
  {
    name: 'Palestine',
    states: [
      { name: 'Bethlehem' },
      { name: 'Deir El Balah' },
      { name: 'Gaza' },
      { name: 'Hebron' },
      { name: 'Jenin' },
      { name: 'Jericho – Al Aghwar' },
      { name: 'Jerusalem' },
      { name: 'Khan Yunis' },
      { name: 'Nablus' },
      { name: 'North Gaza' },
      { name: 'Qalqilya' },
      { name: 'Rafah' },
      { name: 'Ramallah' },
      { name: 'Salfit' },
      { name: 'Tubas' },
      { name: 'Tulkarm' },
    ],
  },
  {
    name: 'Panama',
    states: [
      { name: 'Bocas del Toro' },
      { name: 'Chiriquí' },
      { name: 'Coclé' },
      { name: 'Colón' },
      { name: 'Darién' },
      { name: 'Emberá' },
      { name: 'Herrera' },
      { name: 'Kuna Yala' },
      { name: 'Los Santos' },
      { name: 'Ngöbe-Buglé' },
      { name: 'Panamá' },
      { name: 'Panamá Oeste' },
      { name: 'Veraguas' },
    ],
  },
  {
    name: 'Papua New Guinea',
    states: [
      { name: 'Bougainville' },
      { name: 'Central' },
      { name: 'Chimbu' },
      { name: 'East New Britain' },
      { name: 'East Sepik' },
      { name: 'Eastern Highlands' },
      { name: 'Enga' },
      { name: 'Gulf' },
      { name: 'Madang' },
      { name: 'Manus' },
      { name: 'Milne Bay' },
      { name: 'Morobe' },
      { name: 'National Capital District' },
      { name: 'New Ireland' },
      { name: 'Northern' },
      { name: 'Sandaun' },
      { name: 'Southern Highlands' },
      { name: 'West New Britain' },
      { name: 'Western' },
      { name: 'Western Highlands' },
    ],
  },
  {
    name: 'Paraguay',
    states: [
      { name: 'Alto Paraguay' },
      { name: 'Alto Paraná' },
      { name: 'Amambay' },
      { name: 'Asunción' },
      { name: 'Boquerón' },
      { name: 'Caaguazú' },
      { name: 'Caazapá' },
      { name: 'Canindeyú' },
      { name: 'Central' },
      { name: 'Concepción' },
      { name: 'Cordillera' },
      { name: 'Guairá' },
      { name: 'Itapúa' },
      { name: 'Misiones' },
      { name: 'Paraguarí' },
      { name: 'Presidente Hayes' },
      { name: 'San Pedro' },
      { name: 'Ñeembucú' },
    ],
  },
  {
    name: 'Peru',
    states: [
      { name: 'Amazonas' },
      { name: 'Ancash' },
      { name: 'Apurímac' },
      { name: 'Arequipa' },
      { name: 'Ayacucho' },
      { name: 'Cajamarca' },
      { name: 'Cusco' },
      { name: 'El Callao' },
      { name: 'Huancavelica' },
      { name: 'Huánuco' },
      { name: 'Ica' },
      { name: 'Junín' },
      { name: 'La Libertad' },
      { name: 'Lambayeque' },
      { name: 'Lima' },
      { name: 'Loreto' },
      { name: 'Madre de Dios' },
      { name: 'Moquegua' },
      { name: 'Municipalidad Metropolitana de Lima' },
      { name: 'Pasco' },
      { name: 'Piura' },
      { name: 'Puno' },
      { name: 'San Martín' },
      { name: 'Tacna' },
      { name: 'Tumbes' },
      { name: 'Ucayali' },
    ],
  },
  {
    name: 'Philippines',
    states: [
      { name: 'Autonomous Region in Muslim Mindanao' },
      { name: 'Bicol' },
      { name: 'Cagayan Valley' },
      { name: 'Calabarzon' },
      { name: 'Caraga' },
      { name: 'Central Luzon' },
      { name: 'Central Visayas' },
      { name: 'Cordillera Administrative Region' },
      { name: 'Davao' },
      { name: 'Eastern Visayas' },
      { name: 'Ilocos' },
      { name: 'Mimaropa' },
      { name: 'National Capital Region' },
      { name: 'Northern Mindanao' },
      { name: 'Soccsksargen' },
      { name: 'Western Visayas' },
      { name: 'Zamboanga Peninsula' },
    ],
  },
  { name: 'Pitcairn Islands', states: [] },
  {
    name: 'Poland',
    states: [
      { name: 'Dolnośląskie' },
      { name: 'Kujawsko-pomorskie' },
      { name: 'Lubelskie' },
      { name: 'Lubuskie' },
      { name: 'Mazowieckie' },
      { name: 'Małopolskie' },
      { name: 'Opolskie' },
      { name: 'Podkarpackie' },
      { name: 'Podlaskie' },
      { name: 'Pomorskie' },
      { name: 'Warmińsko-mazurskie' },
      { name: 'Wielkopolskie' },
      { name: 'Zachodniopomorskie' },
      { name: 'Łódzkie' },
      { name: 'Śląskie' },
      { name: 'Świętokrzyskie' },
    ],
  },
  {
    name: 'Portugal',
    states: [
      { name: 'Aveiro' },
      { name: 'Beja' },
      { name: 'Braga' },
      { name: 'Bragança' },
      { name: 'Castelo Branco' },
      { name: 'Coimbra' },
      { name: 'Faro' },
      { name: 'Guarda' },
      { name: 'Leiria' },
      { name: 'Lisboa' },
      { name: 'Portalegre' },
      { name: 'Porto' },
      { name: 'Região Autónoma da Madeira' },
      { name: 'Região Autónoma dos Açores' },
      { name: 'Santarém' },
      { name: 'Setúbal' },
      { name: 'Viana do Castelo' },
      { name: 'Vila Real' },
      { name: 'Viseu' },
      { name: 'Évora' },
    ],
  },
  { name: 'Puerto Rico', states: [] },
  {
    name: 'Qatar',
    states: [
      { name: 'Ad Dawḩah' },
      { name: 'Al Khawr wa adh Dhakhīrah' },
      { name: 'Al Wakrah' },
      { name: 'Ar Rayyān' },
      { name: 'Ash Shamāl' },
      { name: 'Az̧ Za̧`āyin' },
      { name: 'Umm Şalāl' },
    ],
  },
  { name: 'Republic of Kosovo', states: [] },
  { name: 'Réunion', states: [] },
  {
    name: 'Romania',
    states: [
      { name: 'Alba' },
      { name: 'Arad' },
      { name: 'Argeș' },
      { name: 'Bacău' },
      { name: 'Bihor' },
      { name: 'Bistrița-Năsăud' },
      { name: 'Botoșani' },
      { name: 'Brașov' },
      { name: 'Brăila' },
      { name: 'București' },
      { name: 'Buzău' },
      { name: 'Caraș-Severin' },
      { name: 'Cluj' },
      { name: 'Constanța' },
      { name: 'Covasna' },
      { name: 'Călărași' },
      { name: 'Dolj' },
      { name: 'Dâmbovița' },
      { name: 'Galați' },
      { name: 'Giurgiu' },
      { name: 'Gorj' },
      { name: 'Harghita' },
      { name: 'Hunedoara' },
      { name: 'Ialomița' },
      { name: 'Iași' },
      { name: 'Ilfov' },
      { name: 'Maramureș' },
      { name: 'Mehedinți' },
      { name: 'Mureș' },
      { name: 'Neamț' },
      { name: 'Olt' },
      { name: 'Prahova' },
      { name: 'Satu Mare' },
      { name: 'Sibiu' },
      { name: 'Suceava' },
      { name: 'Sălaj' },
      { name: 'Teleorman' },
      { name: 'Timiș' },
      { name: 'Tulcea' },
      { name: 'Vaslui' },
      { name: 'Vrancea' },
      { name: 'Vâlcea' },
    ],
  },
  {
    name: 'Russia',
    states: [
      { name: "Amurskaya oblast'" },
      { name: "Arkhangel'skaya oblast'" },
      { name: "Astrakhanskaya oblast'" },
      { name: "Belgorodskaya oblast'" },
      { name: "Bryanskaya oblast'" },
      { name: "Chelyabinskaya oblast'" },
      { name: "Irkutskaya oblast'" },
      { name: "Ivanovskaya oblast'" },
      { name: "Kaliningradskaya oblast'" },
      { name: "Kaluzhskaya oblast'" },
      { name: "Kemerovskaya oblast'" },
      { name: "Kirovskaya oblast'" },
      { name: "Kostromskaya oblast'" },
      { name: "Kurganskaya oblast'" },
      { name: "Kurskaya oblast'" },
      { name: "Leningradskaya oblast'" },
      { name: "Lipetskaya oblast'" },
      { name: "Magadanskaya oblast'" },
      { name: "Moskovskaya oblast'" },
      { name: "Murmanskaya oblast'" },
      { name: "Nizhegorodskaya oblast'" },
      { name: "Novgorodskaya oblast'" },
      { name: "Novosibirskaya oblast'" },
      { name: "Omskaya oblast'" },
      { name: "Orenburgskaya oblast'" },
      { name: "Orlovskaya oblast'" },
      { name: "Penzenskaya oblast'" },
      { name: "Pskovskaya oblast'" },
      { name: "Rostovskaya oblast'" },
      { name: "Ryazanskaya oblast'" },
      { name: "Sakhalinskaya oblast'" },
      { name: "Samarskaya oblast'" },
      { name: "Saratovskaya oblast'" },
      { name: "Smolenskaya oblast'" },
      { name: "Sverdlovskaya oblast'" },
      { name: "Tambovskaya oblast'" },
      { name: "Tomskaya oblast'" },
      { name: "Tul'skaya oblast'" },
      { name: "Tverskaya oblast'" },
      { name: "Tyumenskaya oblast'" },
      { name: "Ul'yanovskaya oblast'" },
      { name: "Vladimirskaya oblast'" },
      { name: "Volgogradskaya oblast'" },
      { name: "Vologodskaya oblast'" },
      { name: "Voronezhskaya oblast'" },
      { name: "Yaroslavskaya oblast'" },
      { name: 'Altayskiy kray' },
      { name: 'Kamchatskiy kray' },
      { name: 'Khabarovskiy kray' },
      { name: 'Krasnodarskiy kray' },
      { name: 'Krasnoyarskiy kray' },
      { name: 'Permskiy kray' },
      { name: 'Primorskiy kray' },
      { name: "Stavropol'skiy kray" },
      { name: "Zabaykal'skiy kray" },
      { name: 'Moskva' },
      { name: 'Sankt-Peterburg' },
      { name: 'Chukotskiy avtonomnyy okrug' },
      { name: 'Khanty-Mansiyskiy avtonomnyy okrug-Yugra' },
      { name: 'Nenetskiy avtonomnyy okrug' },
      { name: 'Yamalo-Nenetskiy avtonomnyy okrug' },
      { name: "Yevreyskaya avtonomnaya oblast'" },
      { name: 'Adygeya, Respublika' },
      { name: 'Altay, Respublika' },
      { name: 'Bashkortostan, Respublika' },
      { name: 'Buryatiya, Respublika' },
      { name: 'Chechenskaya Respublika' },
      { name: 'Chuvashskaya Respublika' },
      { name: 'Dagestan, Respublika' },
      { name: 'Ingushetiya, Respublika' },
      { name: 'Kabardino-Balkarskaya Respublika' },
      { name: 'Kalmykiya, Respublika' },
      { name: 'Karachayevo-Cherkesskaya Respublika' },
      { name: 'Kareliya, Respublika' },
      { name: 'Khakasiya, Respublika' },
      { name: 'Komi, Respublika' },
      { name: 'Mariy El, Respublika' },
      { name: 'Mordoviya, Respublika' },
      { name: 'Sakha, Respublika' },
      { name: 'Severnaya Osetiya-Alaniya, Respublika' },
      { name: 'Tatarstan, Respublika' },
      { name: 'Tyva, Respublika' },
      { name: 'Udmurtskaya Respublika' },
    ],
  },
  {
    name: 'Rwanda',
    states: [
      { name: 'Est' },
      { name: 'Nord' },
      { name: 'Ouest' },
      { name: 'Sud' },
      { name: 'Ville de Kigali' },
    ],
  },
  { name: 'Saint Barthélemy', states: [] },
  {
    name: 'Saint Helena',
    states: [
      { name: 'Ascension' },
      { name: 'Saint Helena' },
      { name: 'Tristan da Cunha' },
    ],
  },
  {
    name: 'Saint Kitts and Nevis',
    states: [{ name: 'Nevis' }, { name: 'Saint Kitts' }],
  },
  {
    name: 'Saint Lucia',
    states: [
      { name: 'Anse la Raye' },
      { name: 'Castries' },
      { name: 'Choiseul' },
      { name: 'Dauphin' },
      { name: 'Dennery' },
      { name: 'Gros Islet' },
      { name: 'Laborie' },
      { name: 'Micoud' },
      { name: 'Praslin' },
      { name: 'Soufrière' },
      { name: 'Vieux Fort' },
    ],
  },
  { name: 'Saint Martin', states: [] },
  { name: 'Saint Pierre and Miquelon', states: [] },
  {
    name: 'Saint Vincent and the Grenadines',
    states: [
      { name: 'Charlotte' },
      { name: 'Grenadines' },
      { name: 'Saint Andrew' },
      { name: 'Saint David' },
      { name: 'Saint George' },
      { name: 'Saint Patrick' },
    ],
  },
  {
    name: 'Samoa',
    states: [
      { name: "A'ana" },
      { name: 'Aiga-i-le-Tai' },
      { name: 'Atua' },
      { name: "Fa'asaleleaga" },
      { name: "Gaga'emauga" },
      { name: 'Gagaifomauga' },
      { name: 'Palauli' },
      { name: "Satupa'itea" },
      { name: 'Tuamasaga' },
      { name: "Va'a-o-Fonoti" },
      { name: 'Vaisigano' },
    ],
  },
  {
    name: 'San Marino',
    states: [
      { name: 'Acquaviva' },
      { name: 'Borgo Maggiore' },
      { name: 'Chiesanuova' },
      { name: 'Domagnano' },
      { name: 'Faetano' },
      { name: 'Fiorentino' },
      { name: 'Montegiardino' },
      { name: 'San Marino' },
      { name: 'Serravalle' },
    ],
  },
  {
    name: 'São Tomé and Príncipe',
    states: [{ name: 'Príncipe' }, { name: 'São Tomé' }],
  },
  {
    name: 'Saudi Arabia',
    states: [
      { name: 'Al Bāḩah' },
      { name: 'Al Jawf' },
      { name: 'Al Madīnah' },
      { name: 'Al Qaşīm' },
      { name: 'Al Ḩudūd ash Shamālīyah' },
      { name: 'Ar Riyāḑ' },
      { name: 'Ash Sharqīyah' },
      { name: 'Jīzān' },
      { name: 'Makkah' },
      { name: 'Najrān' },
      { name: 'Tabūk' },
      { name: 'ٰĀsīr' },
      { name: "Ḩā'il" },
    ],
  },
  {
    name: 'Senegal',
    states: [
      { name: 'Dakar' },
      { name: 'Diourbel' },
      { name: 'Fatick' },
      { name: 'Kaffrine' },
      { name: 'Kaolack' },
      { name: 'Kolda' },
      { name: 'Kédougou' },
      { name: 'Louga' },
      { name: 'Matam' },
      { name: 'Saint-Louis' },
      { name: 'Sédhiou' },
      { name: 'Tambacounda' },
      { name: 'Thiès' },
      { name: 'Ziguinchor' },
    ],
  },
  {
    name: 'Serbia',
    states: [{ name: 'Kosovo-Metohija' }, { name: 'Vojvodina' }],
  },
  {
    name: 'Seychelles',
    states: [
      { name: 'Anse Boileau' },
      { name: 'Anse Etoile' },
      { name: 'Anse Royale' },
      { name: 'Anse aux Pins' },
      { name: 'Au Cap' },
      { name: 'Baie Lazare' },
      { name: 'Baie Sainte Anne' },
      { name: 'Beau Vallon' },
      { name: 'Bel Air' },
      { name: 'Bel Ombre' },
      { name: 'Cascade' },
      { name: 'English River' },
      { name: 'Glacis' },
      { name: 'Grand Anse Mahe' },
      { name: 'Grand Anse Praslin' },
      { name: 'La Digue' },
      { name: 'Les Mamelles' },
      { name: 'Mont Buxton' },
      { name: 'Mont Fleuri' },
      { name: 'Plaisance' },
      { name: 'Pointe Larue' },
      { name: 'Port Glaud' },
      { name: 'Roche Caiman' },
      { name: 'Saint Louis' },
      { name: 'Takamaka' },
    ],
  },
  {
    name: 'Sierra Leone',
    states: [
      { name: 'Eastern' },
      { name: 'Northern' },
      { name: 'Southern' },
      { name: 'Western Area' },
    ],
  },
  {
    name: 'Singapore',
    states: [
      { name: 'Central Singapore' },
      { name: 'North East' },
      { name: 'North West' },
      { name: 'South East' },
      { name: 'South West' },
    ],
  },
  { name: 'Sint Maarten', states: [] },
  {
    name: 'Slovakia',
    states: [
      { name: 'Banskobystrický kraj' },
      { name: 'Bratislavský kraj' },
      { name: 'Košický kraj' },
      { name: 'Nitriansky kraj' },
      { name: 'Prešovský kraj' },
      { name: 'Trenčiansky kraj' },
      { name: 'Trnavský kraj' },
      { name: 'Žilinský kraj' },
    ],
  },
  {
    name: 'Slovenia',
    states: [
      { name: 'Ajdovščina' },
      { name: 'Apače' },
      { name: 'Beltinci' },
      { name: 'Benedikt' },
      { name: 'Bistrica ob Sotli' },
      { name: 'Bled' },
      { name: 'Bloke' },
      { name: 'Bohinj' },
      { name: 'Borovnica' },
      { name: 'Bovec' },
      { name: 'Braslovče' },
      { name: 'Brda' },
      { name: 'Brezovica' },
      { name: 'Brežice' },
      { name: 'Cankova' },
      { name: 'Celje' },
      { name: 'Cerklje na Gorenjskem' },
      { name: 'Cerknica' },
      { name: 'Cerkno' },
      { name: 'Cerkvenjak' },
      { name: 'Cirkulane' },
      { name: 'Destrnik' },
      { name: 'Divača' },
      { name: 'Dobje' },
      { name: 'Dobrepolje' },
      { name: 'Dobrna' },
      { name: 'Dobrova–Polhov Gradec' },
      { name: 'Dobrovnik' },
      { name: 'Dol pri Ljubljani' },
      { name: 'Dolenjske Toplice' },
      { name: 'Domžale' },
      { name: 'Dornava' },
      { name: 'Dravograd' },
      { name: 'Duplek' },
      { name: 'Gorenja vas–Poljane' },
      { name: 'Gorišnica' },
      { name: 'Gorje' },
      { name: 'Gornja Radgona' },
      { name: 'Gornji Grad' },
      { name: 'Gornji Petrovci' },
      { name: 'Grad' },
      { name: 'Grosuplje' },
      { name: 'Hajdina' },
      { name: 'Hodoš' },
      { name: 'Horjul' },
      { name: 'Hoče–Slivnica' },
      { name: 'Hrastnik' },
      { name: 'Hrpelje-Kozina' },
      { name: 'Idrija' },
      { name: 'Ig' },
      { name: 'Ilirska Bistrica' },
      { name: 'Ivančna Gorica' },
      { name: 'Izola' },
      { name: 'Jesenice' },
      { name: 'Jezersko' },
      { name: 'Juršinci' },
      { name: 'Kamnik' },
      { name: 'Kanal' },
      { name: 'Kidričevo' },
      { name: 'Kobarid' },
      { name: 'Kobilje' },
      { name: 'Komen' },
      { name: 'Komenda' },
      { name: 'Koper' },
      { name: 'Kosanjevica na Krki' },
      { name: 'Kostel' },
      { name: 'Kozje' },
      { name: 'Kočevje' },
      { name: 'Kranj' },
      { name: 'Kranjska Gora' },
      { name: 'Križevci' },
      { name: 'Krško' },
      { name: 'Kungota' },
      { name: 'Kuzma' },
      { name: 'Laško' },
      { name: 'Lenart' },
      { name: 'Lendava' },
      { name: 'Litija' },
      { name: 'Ljubljana' },
      { name: 'Ljubno' },
      { name: 'Ljutomer' },
      { name: 'Log-Dragomer' },
      { name: 'Logatec' },
      { name: 'Lovrenc na Pohorju' },
      { name: 'Loška Dolina' },
      { name: 'Loški Potok' },
      { name: 'Lukovica' },
      { name: 'Luče' },
      { name: 'Majšperk' },
      { name: 'Makole' },
      { name: 'Maribor' },
      { name: 'Markovci' },
      { name: 'Medvode' },
      { name: 'Mengeš' },
      { name: 'Metlika' },
      { name: 'Mežica' },
      { name: 'Miklavž na Dravskem Polju' },
      { name: 'Miren–Kostanjevica' },
      { name: 'Mirna Peč' },
      { name: 'Mislinja' },
      { name: 'Mokronog–Trebelno' },
      { name: 'Moravske Toplice' },
      { name: 'Moravče' },
      { name: 'Mozirje' },
      { name: 'Murska Sobota' },
      { name: 'Muta' },
      { name: 'Naklo' },
      { name: 'Nazarje' },
      { name: 'Nova Gorica' },
      { name: 'Novo Mesto' },
      { name: 'Odranci' },
      { name: 'Oplotnica' },
      { name: 'Ormož' },
      { name: 'Osilnica' },
      { name: 'Pesnica' },
      { name: 'Piran' },
      { name: 'Pivka' },
      { name: 'Podlehnik' },
      { name: 'Podvelka' },
      { name: 'Podčetrtek' },
      { name: 'Poljčane' },
      { name: 'Polzela' },
      { name: 'Postojna' },
      { name: 'Prebold' },
      { name: 'Preddvor' },
      { name: 'Prevalje' },
      { name: 'Ptuj' },
      { name: 'Puconci' },
      { name: 'Radenci' },
      { name: 'Radeče' },
      { name: 'Radlje ob Dravi' },
      { name: 'Radovljica' },
      { name: 'Ravne na Koroškem' },
      { name: 'Razkrižje' },
      { name: 'Rače–Fram' },
      { name: 'Renče-Vogrsko' },
      { name: 'Rečica ob Savinji' },
      { name: 'Ribnica' },
      { name: 'Ribnica na Pohorju' },
      { name: 'Rogatec' },
      { name: 'Rogaška Slatina' },
      { name: 'Rogašovci' },
      { name: 'Ruše' },
      { name: 'Selnica ob Dravi' },
      { name: 'Semič' },
      { name: 'Sevnica' },
      { name: 'Sežana' },
      { name: 'Slovenj Gradec' },
      { name: 'Slovenska Bistrica' },
      { name: 'Slovenske Konjice' },
      { name: 'Sodražica' },
      { name: 'Solčava' },
      { name: 'Središče ob Dravi' },
      { name: 'Starše' },
      { name: 'Straža' },
      { name: 'Sveta Ana' },
      { name: 'Sveta Trojica v Slovenskih Goricah' },
      { name: 'Sveti Andraž v Slovenskih Goricah' },
      { name: 'Sveti Jurij' },
      { name: 'Sveti Jurij v Slovenskih Goricah' },
      { name: 'Sveti Tomaž' },
      { name: 'Tabor' },
      { name: 'Tišina' },
      { name: 'Tolmin' },
      { name: 'Trbovlje' },
      { name: 'Trebnje' },
      { name: 'Trnovska Vas' },
      { name: 'Trzin' },
      { name: 'Tržič' },
      { name: 'Turnišče' },
      { name: 'Velenje' },
      { name: 'Velika Polana' },
      { name: 'Velike Lašče' },
      { name: 'Veržej' },
      { name: 'Videm' },
      { name: 'Vipava' },
      { name: 'Vitanje' },
      { name: 'Vodice' },
      { name: 'Vojnik' },
      { name: 'Vransko' },
      { name: 'Vrhnika' },
      { name: 'Vuzenica' },
      { name: 'Zagorje ob Savi' },
      { name: 'Zavrč' },
      { name: 'Zreče' },
      { name: 'Črenšovci' },
      { name: 'Črna na Koroškem' },
      { name: 'Črnomelj' },
      { name: 'Šalovci' },
      { name: 'Šempeter–Vrtojba' },
      { name: 'Šentilj' },
      { name: 'Šentjernej' },
      { name: 'Šentjur' },
      { name: 'Šentrupert' },
      { name: 'Šenčur' },
      { name: 'Škocjan' },
      { name: 'Škofja Loka' },
      { name: 'Škofljica' },
      { name: 'Šmarje pri Jelšah' },
      { name: 'Šmarješke Toplice' },
      { name: 'Šmartno ob Paki' },
      { name: 'Šmartno pri Litiji' },
      { name: 'Šoštanj' },
      { name: 'Štore' },
      { name: 'Žalec' },
      { name: 'Železniki' },
      { name: 'Žetale' },
      { name: 'Žiri' },
      { name: 'Žirovnica' },
      { name: 'Žužemberk' },
    ],
  },
  {
    name: 'Solomon Islands',
    states: [
      { name: 'Capital Territory' },
      { name: 'Central' },
      { name: 'Choiseul' },
      { name: 'Guadalcanal' },
      { name: 'Isabel' },
      { name: 'Makira-Ulawa' },
      { name: 'Malaita' },
      { name: 'Rennell and Bellona' },
      { name: 'Temotu' },
      { name: 'Western' },
    ],
  },
  {
    name: 'Somalia',
    states: [
      { name: 'Awdal' },
      { name: 'Bakool' },
      { name: 'Banaadir' },
      { name: 'Bari' },
      { name: 'Bay' },
      { name: 'Galguduud' },
      { name: 'Gedo' },
      { name: 'Hiiraan' },
      { name: 'Jubbada Dhexe' },
      { name: 'Jubbada Hoose' },
      { name: 'Mudug' },
      { name: 'Nugaal' },
      { name: 'Sanaag' },
      { name: 'Shabeellaha Dhexe' },
      { name: 'Shabeellaha Hoose' },
      { name: 'Sool' },
      { name: 'Togdheer' },
      { name: 'Woqooyi Galbeed' },
    ],
  },
  {
    name: 'South Africa',
    states: [
      { name: 'Eastern Cape' },
      { name: 'Free State' },
      { name: 'Gauteng' },
      { name: 'KwaZulu-Natal' },
      { name: 'Limpopo' },
      { name: 'Mpumalanga' },
      { name: 'North West' },
      { name: 'Northern Cape' },
      { name: 'Western Cape' },
    ],
  },
  { name: 'South Georgia', states: [] },
  {
    name: 'South Korea',
    states: [
      { name: 'Busan-gwangyeoksi' },
      { name: 'Chungcheongbuk-do' },
      { name: 'Chungcheongnam-do' },
      { name: 'Daegu-gwangyeoksi' },
      { name: 'Daejeon-gwangyeoksi' },
      { name: 'Gangwon-do' },
      { name: 'Gwangju-gwangyeoksi' },
      { name: 'Gyeonggi-do' },
      { name: 'Gyeongsangbuk-do' },
      { name: 'Gyeongsangnam-do' },
      { name: 'Incheon-gwangyeoksi' },
      { name: 'Jeju-teukbyeoljachido' },
      { name: 'Jeollabuk-do' },
      { name: 'Jeollanam-do' },
      { name: 'Sejong' },
      { name: 'Seoul-teukbyeolsi' },
      { name: 'Ulsan-gwangyeoksi' },
    ],
  },
  {
    name: 'South Sudan',
    states: [
      { name: 'Central Equatoria' },
      { name: 'Eastern Equatoria' },
      { name: 'Jonglei' },
      { name: 'Lakes' },
      { name: 'Northern Bahr el Ghazal' },
      { name: 'Unity' },
      { name: 'Upper Nile' },
      { name: 'Warrap' },
      { name: 'Western Bahr el Ghazal' },
      { name: 'Western Equatoria' },
    ],
  },
  {
    name: 'Spain',
    states: [
      { name: 'A Coruña' },
      { name: 'Albacete' },
      { name: 'Alicante' },
      { name: 'Almería' },
      { name: 'Asturias' },
      { name: 'Badajoz' },
      { name: 'Balears' },
      { name: 'Barcelona' },
      { name: 'Burgos' },
      { name: 'Cantabria' },
      { name: 'Castellón' },
      { name: 'Ciudad Real' },
      { name: 'Cuenca' },
      { name: 'Cáceres' },
      { name: 'Cádiz' },
      { name: 'Córdoba' },
      { name: 'Girona' },
      { name: 'Granada' },
      { name: 'Guadalajara' },
      { name: 'Guipúzcoa' },
      { name: 'Huelva' },
      { name: 'Huesca' },
      { name: 'Jaén' },
      { name: 'La Rioja' },
      { name: 'Las Palmas' },
      { name: 'León' },
      { name: 'Lleida' },
      { name: 'Lugo' },
      { name: 'Madrid' },
      { name: 'Murcia' },
      { name: 'Málaga' },
      { name: 'Navarra' },
      { name: 'Ourense' },
      { name: 'Palencia' },
      { name: 'Pontevedra' },
      { name: 'Salamanca' },
      { name: 'Santa Cruz de Tenerife' },
      { name: 'Segovia' },
      { name: 'Sevilla' },
      { name: 'Soria' },
      { name: 'Tarragona' },
      { name: 'Teruel' },
      { name: 'Toledo' },
      { name: 'Valencia' },
      { name: 'Valladolid' },
      { name: 'Vizcaya' },
      { name: 'Zamora' },
      { name: 'Zaragoza' },
      { name: 'Álava' },
      { name: 'Ávila' },
      { name: 'Ceuta' },
      { name: 'Melilla' },
      { name: 'Andalucía' },
      { name: 'Aragón' },
      { name: 'Asturias, Principado de' },
      { name: 'Canarias' },
      { name: 'Cantabria' },
      { name: 'Castilla y León' },
      { name: 'Castilla-La Mancha' },
      { name: 'Catalunya' },
      { name: 'Extremadura' },
      { name: 'Galicia' },
      { name: 'Illes Balears' },
      { name: 'La Rioja' },
      { name: 'Madrid, Comunidad de' },
      { name: 'Murcia, Región de' },
      { name: 'Navarra, Comunidad Foral de' },
      { name: 'País Vasco' },
      { name: 'Valenciana, Comunidad' },
    ],
  },
  {
    name: 'Sri Lanka',
    states: [
      { name: 'Central Province' },
      { name: 'Eastern Province' },
      { name: 'North Central Province' },
      { name: 'North Western Province' },
      { name: 'Northern Province' },
      { name: 'Sabaragamuwa Province' },
      { name: 'Southern Province' },
      { name: 'Uva Province' },
      { name: 'Western Province' },
    ],
  },
  {
    name: 'Sudan',
    states: [
      { name: 'Al Baḩr al Aḩmar' },
      { name: 'Al Jazīrah' },
      { name: 'Al Kharţūm' },
      { name: 'Al Qaḑārif' },
      { name: 'An Nīl' },
      { name: 'An Nīl al Abyaḑ' },
      { name: 'An Nīl al Azraq' },
      { name: 'Ash Shamālīyah' },
      { name: 'Gharb Dārfūr' },
      { name: 'Janūb Dārfūr' },
      { name: 'Janūb Kurdufān' },
      { name: 'Kassalā' },
      { name: 'Shamāl Dārfūr' },
      { name: 'Shamāl Kurdufān' },
      { name: 'Sharq Dārfūr' },
      { name: 'Sinnār' },
      { name: 'Zalingei' },
    ],
  },
  {
    name: 'Suriname',
    states: [
      { name: 'Brokopondo' },
      { name: 'Commewijne' },
      { name: 'Coronie' },
      { name: 'Marowijne' },
      { name: 'Nickerie' },
      { name: 'Para' },
      { name: 'Paramaribo' },
      { name: 'Saramacca' },
      { name: 'Sipaliwini' },
      { name: 'Wanica' },
    ],
  },
  { name: 'Svalbard and Jan Mayen', states: [] },
  {
    name: 'Swaziland',
    states: [
      { name: 'Hhohho' },
      { name: 'Lubombo' },
      { name: 'Manzini' },
      { name: 'Shiselweni' },
    ],
  },
  {
    name: 'Sweden',
    states: [
      { name: 'Blekinge län' },
      { name: 'Dalarnas län' },
      { name: 'Gotlands län' },
      { name: 'Gävleborgs län' },
      { name: 'Hallands län' },
      { name: 'Jämtlands län' },
      { name: 'Jönköpings län' },
      { name: 'Kalmar län' },
      { name: 'Kronobergs län' },
      { name: 'Norrbottens län' },
      { name: 'Skåne län' },
      { name: 'Stockholms län' },
      { name: 'Södermanlands län' },
      { name: 'Uppsala län' },
      { name: 'Värmlands län' },
      { name: 'Västerbottens län' },
      { name: 'Västernorrlands län' },
      { name: 'Västmanlands län' },
      { name: 'Västra Götalands län' },
      { name: 'Örebro län' },
      { name: 'Östergötlands län' },
    ],
  },
  {
    name: 'Switzerland',
    states: [
      { name: 'Aargau' },
      { name: 'Appenzell Ausserrhoden' },
      { name: 'Appenzell Innerrhoden' },
      { name: 'Basel-Landschaft' },
      { name: 'Basel-Stadt' },
      { name: 'Bern' },
      { name: 'Fribourg' },
      { name: 'Genève' },
      { name: 'Glarus' },
      { name: 'Graubünden' },
      { name: 'Jura' },
      { name: 'Luzern' },
      { name: 'Neuchâtel' },
      { name: 'Nidwalden' },
      { name: 'Obwalden' },
      { name: 'Sankt Gallen' },
      { name: 'Schaffhausen' },
      { name: 'Schwyz' },
      { name: 'Solothurn' },
      { name: 'Thurgau' },
      { name: 'Ticino' },
      { name: 'Uri' },
      { name: 'Valais' },
      { name: 'Vaud' },
      { name: 'Zug' },
      { name: 'Zürich' },
    ],
  },
  {
    name: 'Syria',
    states: [
      { name: 'Al Lādhiqīyah' },
      { name: 'Al Qunayţirah' },
      { name: 'Al Ḩasakah' },
      { name: 'Ar Raqqah' },
      { name: "As Suwaydā'" },
      { name: 'Darٰā' },
      { name: 'Dayr az Zawr' },
      { name: 'Dimashq' },
      { name: 'Idlib' },
      { name: 'Rīf Dimashq' },
      { name: 'Ţarţūs' },
      { name: 'Ḩalab' },
      { name: 'Ḩamāh' },
      { name: 'Ḩimş' },
    ],
  },
  {
    name: 'Taiwan',
    states: [
      { name: 'Changhua' },
      { name: 'Chiayi' },
      { name: 'Chiayi' },
      { name: 'Hsinchu' },
      { name: 'Hsinchu' },
      { name: 'Hualien' },
      { name: 'Ilan' },
      { name: 'Kaohsiung' },
      { name: 'Kaohsiung' },
      { name: 'Keelung' },
      { name: 'Miaoli' },
      { name: 'Nantou' },
      { name: 'Penghu' },
      { name: 'Pingtung' },
      { name: 'Taichung' },
      { name: 'Taichung' },
      { name: 'Tainan' },
      { name: 'Tainan' },
      { name: 'Taipei' },
      { name: 'Taipei' },
      { name: 'Taitung' },
      { name: 'Taoyuan' },
      { name: 'Yunlin' },
    ],
  },
  {
    name: 'Tajikistan',
    states: [
      { name: 'Dushanbe' },
      { name: 'Khatlon' },
      { name: 'Kŭhistoni Badakhshon' },
      { name: 'Sughd' },
    ],
  },
  {
    name: 'Tanzania',
    states: [
      { name: 'Arusha' },
      { name: 'Dar es Salaam' },
      { name: 'Dodoma' },
      { name: 'Iringa' },
      { name: 'Kagera' },
      { name: 'Kaskazini Pemba' },
      { name: 'Kaskazini Unguja' },
      { name: 'Kigoma' },
      { name: 'Kilimanjaro' },
      { name: 'Kusini Pemba' },
      { name: 'Kusini Unguja' },
      { name: 'Lindi' },
      { name: 'Manyara' },
      { name: 'Mara' },
      { name: 'Mbeya' },
      { name: 'Mjini Magharibi' },
      { name: 'Morogoro' },
      { name: 'Mtwara' },
      { name: 'Mwanza' },
      { name: 'Pwani' },
      { name: 'Rukwa' },
      { name: 'Ruvuma' },
      { name: 'Shinyanga' },
      { name: 'Singida' },
      { name: 'Tabora' },
      { name: 'Tanga' },
    ],
  },
  {
    name: 'Thailand',
    states: [
      { name: 'Amnat Charoen' },
      { name: 'Ang Thong' },
      { name: 'Bueng Kan' },
      { name: 'Buri Ram' },
      { name: 'Chachoengsao' },
      { name: 'Chai Nat' },
      { name: 'Chaiyaphum' },
      { name: 'Chanthaburi' },
      { name: 'Chiang Mai' },
      { name: 'Chiang Rai' },
      { name: 'Chon Buri' },
      { name: 'Chumphon' },
      { name: 'Kalasin' },
      { name: 'Kamphaeng Phet' },
      { name: 'Kanchanaburi' },
      { name: 'Khon Kaen' },
      { name: 'Krabi' },
      { name: 'Krung Thep Maha Nakhon' },
      { name: 'Lampang' },
      { name: 'Lamphun' },
      { name: 'Loei' },
      { name: 'Lop Buri' },
      { name: 'Mae Hong Son' },
      { name: 'Maha Sarakham' },
      { name: 'Mukdahan' },
      { name: 'Nakhon Nayok' },
      { name: 'Nakhon Pathom' },
      { name: 'Nakhon Phanom' },
      { name: 'Nakhon Ratchasima' },
      { name: 'Nakhon Sawan' },
      { name: 'Nakhon Si Thammarat' },
      { name: 'Nan' },
      { name: 'Narathiwat' },
      { name: 'Nong Bua Lam Phu' },
      { name: 'Nong Khai' },
      { name: 'Nonthaburi' },
      { name: 'Pathum Thani' },
      { name: 'Pattani' },
      { name: 'Phangnga' },
      { name: 'Phatthalung' },
      { name: 'Phatthaya' },
      { name: 'Phayao' },
      { name: 'Phetchabun' },
      { name: 'Phetchaburi' },
      { name: 'Phichit' },
      { name: 'Phitsanulok' },
      { name: 'Phra Nakhon Si Ayutthaya' },
      { name: 'Phrae' },
      { name: 'Phuket' },
      { name: 'Prachin Buri' },
      { name: 'Prachuap Khiri Khan' },
      { name: 'Ranong' },
      { name: 'Ratchaburi' },
      { name: 'Rayong' },
      { name: 'Roi Et' },
      { name: 'Sa Kaeo' },
      { name: 'Sakon Nakhon' },
      { name: 'Samut Prakan' },
      { name: 'Samut Sakhon' },
      { name: 'Samut Songkhram' },
      { name: 'Saraburi' },
      { name: 'Satun' },
      { name: 'Si Sa Ket' },
      { name: 'Sing Buri' },
      { name: 'Songkhla' },
      { name: 'Sukhothai' },
      { name: 'Suphan Buri' },
      { name: 'Surat Thani' },
      { name: 'Surin' },
      { name: 'Tak' },
      { name: 'Trang' },
      { name: 'Trat' },
      { name: 'Ubon Ratchathani' },
      { name: 'Udon Thani' },
      { name: 'Uthai Thani' },
      { name: 'Uttaradit' },
      { name: 'Yala' },
      { name: 'Yasothon' },
    ],
  },
  {
    name: 'East Timor',
    states: [
      { name: 'Aileu' },
      { name: 'Ainaro' },
      { name: 'Baucau' },
      { name: 'Bobonaro' },
      { name: 'Cova Lima' },
      { name: 'Díli' },
      { name: 'Ermera' },
      { name: 'Lautem' },
      { name: 'Liquiça' },
      { name: 'Manatuto' },
      { name: 'Manufahi' },
      { name: 'Oecussi' },
      { name: 'Viqueque' },
    ],
  },
  {
    name: 'Togo',
    states: [
      { name: 'Centre' },
      { name: 'Kara' },
      { name: 'Maritime' },
      { name: 'Plateaux' },
      { name: 'Savannes' },
    ],
  },
  { name: 'Tokelau', states: [] },
  {
    name: 'Tonga',
    states: [
      { name: "'Eua" },
      { name: "Ha'apai" },
      { name: 'Niuas' },
      { name: 'Tongatapu' },
      { name: "Vava'u" },
    ],
  },
  {
    name: 'Trinidad and Tobago',
    states: [
      { name: 'Arima' },
      { name: 'Chaguanas' },
      { name: 'Couva-Tabaquite-Talparo' },
      { name: 'Diego Martin' },
      { name: 'Eastern Tobago' },
      { name: 'Penal-Debe' },
      { name: 'Point Fortin' },
      { name: 'Port of Spain' },
      { name: 'Princes Town' },
      { name: 'Rio Claro-Mayaro' },
      { name: 'San Fernando' },
      { name: 'San Juan-Laventille' },
      { name: 'Sangre Grande' },
      { name: 'Siparia' },
      { name: 'Tunapuna-Piarco' },
      { name: 'Western Tobago' },
    ],
  },
  {
    name: 'Tunisia',
    states: [
      { name: 'Ariana' },
      { name: 'Ben Arous' },
      { name: 'Bizerte' },
      { name: 'Béja' },
      { name: 'Gabès' },
      { name: 'Gafsa' },
      { name: 'Jendouba' },
      { name: 'Kairouan' },
      { name: 'Kasserine' },
      { name: 'Kebili' },
      { name: 'La Manouba' },
      { name: 'Le Kef' },
      { name: 'Mahdia' },
      { name: 'Medenine' },
      { name: 'Monastir' },
      { name: 'Nabeul' },
      { name: 'Sfax' },
      { name: 'Sidi Bouzid' },
      { name: 'Siliana' },
      { name: 'Sousse' },
      { name: 'Tataouine' },
      { name: 'Tozeur' },
      { name: 'Tunis' },
      { name: 'Zaghouan' },
    ],
  },
  {
    name: 'Turkey',
    states: [
      { name: 'Adana' },
      { name: 'Adıyaman' },
      { name: 'Afyonkarahisar' },
      { name: 'Aksaray' },
      { name: 'Amasya' },
      { name: 'Ankara' },
      { name: 'Antalya' },
      { name: 'Ardahan' },
      { name: 'Artvin' },
      { name: 'Aydın' },
      { name: 'Ağrı' },
      { name: 'Balıkesir' },
      { name: 'Bartın' },
      { name: 'Batman' },
      { name: 'Bayburt' },
      { name: 'Bilecik' },
      { name: 'Bingöl' },
      { name: 'Bitlis' },
      { name: 'Bolu' },
      { name: 'Burdur' },
      { name: 'Bursa' },
      { name: 'Denizli' },
      { name: 'Diyarbakır' },
      { name: 'Düzce' },
      { name: 'Edirne' },
      { name: 'Elazığ' },
      { name: 'Erzincan' },
      { name: 'Erzurum' },
      { name: 'Eskişehir' },
      { name: 'Gaziantep' },
      { name: 'Giresun' },
      { name: 'Gümüşhane' },
      { name: 'Hakkâri' },
      { name: 'Hatay' },
      { name: 'Isparta' },
      { name: 'Iğdır' },
      { name: 'Kahramanmaraş' },
      { name: 'Karabük' },
      { name: 'Karaman' },
      { name: 'Kars' },
      { name: 'Kastamonu' },
      { name: 'Kayseri' },
      { name: 'Kilis' },
      { name: 'Kocaeli' },
      { name: 'Konya' },
      { name: 'Kütahya' },
      { name: 'Kırklareli' },
      { name: 'Kırıkkale' },
      { name: 'Kırşehir' },
      { name: 'Malatya' },
      { name: 'Manisa' },
      { name: 'Mardin' },
      { name: 'Mersin' },
      { name: 'Muğla' },
      { name: 'Muş' },
      { name: 'Nevşehir' },
      { name: 'Niğde' },
      { name: 'Ordu' },
      { name: 'Osmaniye' },
      { name: 'Rize' },
      { name: 'Sakarya' },
      { name: 'Samsun' },
      { name: 'Siirt' },
      { name: 'Sinop' },
      { name: 'Sivas' },
      { name: 'Tekirdağ' },
      { name: 'Tokat' },
      { name: 'Trabzon' },
      { name: 'Tunceli' },
      { name: 'Uşak' },
      { name: 'Van' },
      { name: 'Yalova' },
      { name: 'Yozgat' },
      { name: 'Zonguldak' },
      { name: 'Çanakkale' },
      { name: 'Çankırı' },
      { name: 'Çorum' },
      { name: 'İstanbul' },
      { name: 'İzmir' },
      { name: 'Şanlıurfa' },
      { name: 'Şırnak' },
    ],
  },
  {
    name: 'Turkmenistan',
    states: [
      { name: 'Ahal' },
      { name: 'Aşgabat' },
      { name: 'Balkan' },
      { name: 'Daşoguz' },
      { name: 'Lebap' },
      { name: 'Mary' },
    ],
  },
  { name: 'Turks and Caicos Islands', states: [] },
  {
    name: 'Tuvalu',
    states: [
      { name: 'Funafuti' },
      { name: 'Nanumanga' },
      { name: 'Nanumea' },
      { name: 'Niutao' },
      { name: 'Nui' },
      { name: 'Nukufetau' },
      { name: 'Nukulaelae' },
      { name: 'Vaitupu' },
    ],
  },
  {
    name: 'Uganda',
    states: [
      { name: 'Central' },
      { name: 'Eastern' },
      { name: 'Northern' },
      { name: 'Western' },
    ],
  },
  {
    name: 'Ukraine',
    states: [
      { name: 'Avtonomna Respublika Krym' },
      { name: "Cherkas'ka Oblast'" },
      { name: "Chernihivs'ka Oblast'" },
      { name: "Chernivets'ka Oblast'" },
      { name: "Dnipropetrovs'ka Oblast'" },
      { name: "Donets'ka Oblast'" },
      { name: "Ivano-Frankivs'ka Oblast'" },
      { name: "Kharkivs'ka Oblast'" },
      { name: "Khersons'ka Oblast'" },
      { name: "Khmel'nyts'ka Oblast'" },
      { name: "Kirovohrads'ka Oblast'" },
      { name: 'Kyïv' },
      { name: "Kyïvs'ka Oblast'" },
      { name: "L'vivs'ka Oblast'" },
      { name: "Luhans'ka Oblast'" },
      { name: "Mykolaïvs'ka Oblast'" },
      { name: "Odes'ka Oblast'" },
      { name: "Poltavs'ka Oblast'" },
      { name: "Rivnens'ka Oblast'" },
      { name: "Sevastopol'" },
      { name: "Sums'ka Oblast'" },
      { name: "Ternopil's'ka Oblast'" },
      { name: "Vinnyts'ka Oblast'" },
      { name: "Volyns'ka Oblast'" },
      { name: "Zakarpats'ka Oblast'" },
      { name: "Zaporiz'ka Oblast'" },
      { name: "Zhytomyrs'ka Oblast'" },
    ],
  },
  {
    name: 'United Arab Emirates',
    states: [
      { name: "'Ajmān" },
      { name: 'Abū Z̧aby' },
      { name: 'Al Fujayrah' },
      { name: 'Ash Shāriqah' },
      { name: 'Dubayy' },
      { name: "Ra's al Khaymah" },
      { name: 'Umm al Qaywayn' },
    ],
  },
  {
    name: 'United Kingdom',
    states: [
      { name: 'Barking and Dagenham' },
      { name: 'Barnet' },
      { name: 'Bexley' },
      { name: 'Brent' },
      { name: 'Bromley' },
      { name: 'Camden' },
      { name: 'Croydon' },
      { name: 'Ealing' },
      { name: 'Enfield' },
      { name: 'Greenwich' },
      { name: 'Hackney' },
      { name: 'Hammersmith and Fulham' },
      { name: 'Haringey' },
      { name: 'Harrow' },
      { name: 'Havering' },
      { name: 'Hillingdon' },
      { name: 'Hounslow' },
      { name: 'Islington' },
      { name: 'Kensington and Chelsea' },
      { name: 'Kingston upon Thames' },
      { name: 'Lambeth' },
      { name: 'Lewisham' },
      { name: 'Merton' },
      { name: 'Newham' },
      { name: 'Redbridge' },
      { name: 'Richmond upon Thames' },
      { name: 'Southwark' },
      { name: 'Sutton' },
      { name: 'Tower Hamlets' },
      { name: 'Waltham Forest' },
      { name: 'Wandsworth' },
      { name: 'Westminster' },
      { name: 'England and Wales' },
      { name: 'Great Britain' },
      { name: 'United Kingdom' },
      { name: 'London, City of' },
      { name: 'Aberdeen City' },
      { name: 'Aberdeenshire' },
      { name: 'Angus' },
      { name: 'Argyll and Bute' },
      { name: 'Clackmannanshire' },
      { name: 'Dumfries and Galloway' },
      { name: 'Dundee City' },
      { name: 'East Ayrshire' },
      { name: 'East Dunbartonshire' },
      { name: 'East Lothian' },
      { name: 'East Renfrewshire' },
      { name: 'Edinburgh, City of' },
      { name: 'Eilean Siar' },
      { name: 'Falkirk' },
      { name: 'Fife' },
      { name: 'Glasgow City' },
      { name: 'Highland' },
      { name: 'Inverclyde' },
      { name: 'Midlothian' },
      { name: 'Moray' },
      { name: 'North Ayrshire' },
      { name: 'North Lanarkshire' },
      { name: 'Orkney Islands' },
      { name: 'Perth and Kinross' },
      { name: 'Renfrewshire' },
      { name: 'Scottish Borders, The' },
      { name: 'Shetland Islands' },
      { name: 'South Ayrshire' },
      { name: 'South Lanarkshire' },
      { name: 'Stirling' },
      { name: 'West Dunbartonshire' },
      { name: 'West Lothian' },
      { name: 'England' },
      { name: 'Scotland' },
      { name: 'Wales' },
      { name: 'Antrim' },
      { name: 'Ards' },
      { name: 'Armagh' },
      { name: 'Ballymena' },
      { name: 'Ballymoney' },
      { name: 'Banbridge' },
      { name: 'Belfast' },
      { name: 'Carrickfergus' },
      { name: 'Castlereagh' },
      { name: 'Coleraine' },
      { name: 'Cookstown' },
      { name: 'Craigavon' },
      { name: 'Derry' },
      { name: 'Down' },
      { name: 'Dungannon and South Tyrone' },
      { name: 'Fermanagh' },
      { name: 'Larne' },
      { name: 'Limavady' },
      { name: 'Lisburn' },
      { name: 'Magherafelt' },
      { name: 'Moyle' },
      { name: 'Newry and Mourne District' },
      { name: 'Newtownabbey' },
      { name: 'North Down' },
      { name: 'Omagh' },
      { name: 'Strabane' },
      { name: 'Barnsley' },
      { name: 'Birmingham' },
      { name: 'Bolton' },
      { name: 'Bradford' },
      { name: 'Bury' },
      { name: 'Calderdale' },
      { name: 'Coventry' },
      { name: 'Doncaster' },
      { name: 'Dudley' },
      { name: 'Gateshead' },
      { name: 'Kirklees' },
      { name: 'Knowsley' },
      { name: 'Leeds' },
      { name: 'Liverpool' },
      { name: 'Manchester' },
      { name: 'Newcastle upon Tyne' },
      { name: 'North Tyneside' },
      { name: 'Oldham' },
      { name: 'Rochdale' },
      { name: 'Rotherham' },
      { name: 'Salford' },
      { name: 'Sandwell' },
      { name: 'Sefton' },
      { name: 'Sheffield' },
      { name: 'Solihull' },
      { name: 'South Tyneside' },
      { name: 'St. Helens' },
      { name: 'Stockport' },
      { name: 'Sunderland' },
      { name: 'Tameside' },
      { name: 'Trafford' },
      { name: 'Wakefield' },
      { name: 'Walsall' },
      { name: 'Wigan' },
      { name: 'Wirral' },
      { name: 'Wolverhampton' },
      { name: 'Northern Ireland' },
      { name: 'Buckinghamshire' },
      { name: 'Cambridgeshire' },
      { name: 'Cumbria' },
      { name: 'Derbyshire' },
      { name: 'Devon' },
      { name: 'Dorset' },
      { name: 'East Sussex' },
      { name: 'Essex' },
      { name: 'Gloucestershire' },
      { name: 'Hampshire' },
      { name: 'Hertfordshire' },
      { name: 'Kent' },
      { name: 'Lancashire' },
      { name: 'Leicestershire' },
      { name: 'Lincolnshire' },
      { name: 'Norfolk' },
      { name: 'North Yorkshire' },
      { name: 'Northamptonshire' },
      { name: 'Nottinghamshire' },
      { name: 'Oxfordshire' },
      { name: 'Somerset' },
      { name: 'Staffordshire' },
      { name: 'Suffolk' },
      { name: 'Surrey' },
      { name: 'Warwickshire' },
      { name: 'West Sussex' },
      { name: 'Worcestershire' },
      { name: 'Bath and North East Somerset' },
      { name: 'Bedford' },
      { name: 'Blackburn with Darwen' },
      { name: 'Blackpool' },
      { name: 'Blaenau Gwent' },
      { name: 'Bournemouth' },
      { name: 'Bracknell Forest' },
      { name: 'Bridgend' },
      { name: 'Brighton and Hove' },
      { name: 'Bristol, City of' },
      { name: 'Caerphilly' },
      { name: 'Cardiff' },
      { name: 'Carmarthenshire' },
      { name: 'Central Bedfordshire' },
      { name: 'Ceredigion' },
      { name: 'Cheshire East' },
      { name: 'Cheshire West and Chester' },
      { name: 'Conwy' },
      { name: 'Cornwall' },
      { name: 'Darlington' },
      { name: 'Denbighshire' },
      { name: 'Derby' },
      { name: 'Durham, County' },
      { name: 'East Riding of Yorkshire' },
      { name: 'Flintshire' },
      { name: 'Gwynedd' },
      { name: 'Halton' },
      { name: 'Hartlepool' },
      { name: 'Herefordshire' },
      { name: 'Isle of Anglesey' },
      { name: 'Isle of Wight' },
      { name: 'Isles of Scilly' },
      { name: 'Kingston upon Hull' },
      { name: 'Leicester' },
      { name: 'Luton' },
      { name: 'Medway' },
      { name: 'Merthyr Tydfil' },
      { name: 'Middlesbrough' },
      { name: 'Milton Keynes' },
      { name: 'Monmouthshire' },
      { name: 'Neath Port Talbot' },
      { name: 'Newport' },
      { name: 'North East Lincolnshire' },
      { name: 'North Lincolnshire' },
      { name: 'North Somerset' },
      { name: 'Northumberland' },
      { name: 'Nottingham' },
      { name: 'Pembrokeshire' },
      { name: 'Peterborough' },
      { name: 'Plymouth' },
      { name: 'Poole' },
      { name: 'Portsmouth' },
      { name: 'Powys' },
      { name: 'Reading' },
      { name: 'Redcar and Cleveland' },
      { name: 'Rhondda, Cynon, Taff' },
      { name: 'Rutland' },
      { name: 'Shropshire' },
      { name: 'Slough' },
      { name: 'South Gloucestershire' },
      { name: 'Southampton' },
      { name: 'Southend-on-Sea' },
      { name: 'Stockton-on-Tees' },
      { name: 'Stoke-on-Trent' },
      { name: 'Swansea' },
      { name: 'Swindon' },
      { name: 'Telford and Wrekin' },
      { name: 'Thurrock' },
      { name: 'Torbay' },
      { name: 'Torfaen' },
      { name: 'Vale of Glamorgan, The' },
      { name: 'Warrington' },
      { name: 'West Berkshire' },
      { name: 'Wiltshire' },
      { name: 'Windsor and Maidenhead' },
      { name: 'Wokingham' },
      { name: 'Wrexham' },
      { name: 'York' },
    ],
  },
  {
    name: 'United States',
    states: [
      { name: 'District of Columbia' },
      { name: 'American Samoa' },
      { name: 'Guam' },
      { name: 'Northern Mariana Islands' },
      { name: 'Puerto Rico' },
      { name: 'United States Minor Outlying Islands' },
      { name: 'Virgin Islands, U.S.' },
      { name: 'Alabama' },
      { name: 'Alaska' },
      { name: 'Arizona' },
      { name: 'Arkansas' },
      { name: 'California' },
      { name: 'Colorado' },
      { name: 'Connecticut' },
      { name: 'Delaware' },
      { name: 'Florida' },
      { name: 'Georgia' },
      { name: 'Hawaii' },
      { name: 'Idaho' },
      { name: 'Illinois' },
      { name: 'Indiana' },
      { name: 'Iowa' },
      { name: 'Kansas' },
      { name: 'Kentucky' },
      { name: 'Louisiana' },
      { name: 'Maine' },
      { name: 'Maryland' },
      { name: 'Massachusetts' },
      { name: 'Michigan' },
      { name: 'Minnesota' },
      { name: 'Mississippi' },
      { name: 'Missouri' },
      { name: 'Montana' },
      { name: 'Nebraska' },
      { name: 'Nevada' },
      { name: 'New Hampshire' },
      { name: 'New Jersey' },
      { name: 'New Mexico' },
      { name: 'New York' },
      { name: 'North Carolina' },
      { name: 'North Dakota' },
      { name: 'Ohio' },
      { name: 'Oklahoma' },
      { name: 'Oregon' },
      { name: 'Pennsylvania' },
      { name: 'Rhode Island' },
      { name: 'South Carolina' },
      { name: 'South Dakota' },
      { name: 'Tennessee' },
      { name: 'Texas' },
      { name: 'Utah' },
      { name: 'Vermont' },
      { name: 'Virginia' },
      { name: 'Washington' },
      { name: 'West Virginia' },
      { name: 'Wisconsin' },
      { name: 'Wyoming' },
    ],
  },
  {
    name: 'Uruguay',
    states: [
      { name: 'Artigas' },
      { name: 'Canelones' },
      { name: 'Cerro Largo' },
      { name: 'Colonia' },
      { name: 'Durazno' },
      { name: 'Flores' },
      { name: 'Florida' },
      { name: 'Lavalleja' },
      { name: 'Maldonado' },
      { name: 'Montevideo' },
      { name: 'Paysandú' },
      { name: 'Rivera' },
      { name: 'Rocha' },
      { name: 'Río Negro' },
      { name: 'Salto' },
      { name: 'San José' },
      { name: 'Soriano' },
      { name: 'Tacuarembó' },
      { name: 'Treinta y Tres' },
    ],
  },
  {
    name: 'Uzbekistan',
    states: [
      { name: 'Andijon' },
      { name: 'Buxoro' },
      { name: 'Farg‘ona' },
      { name: 'Jizzax' },
      { name: 'Namangan' },
      { name: 'Navoiy' },
      { name: 'Qashqadaryo' },
      { name: 'Qoraqalpog‘iston Respublikasi' },
      { name: 'Samarqand' },
      { name: 'Sirdaryo' },
      { name: 'Surxondaryo' },
      { name: 'Toshkent' },
      { name: 'Toshkent' },
      { name: 'Xorazm' },
    ],
  },
  {
    name: 'Vanuatu',
    states: [
      { name: 'Malampa' },
      { name: 'Pénama' },
      { name: 'Sanma' },
      { name: 'Shéfa' },
      { name: 'Taféa' },
      { name: 'Torba' },
    ],
  },
  {
    name: 'Venezuela',
    states: [
      { name: 'Amazonas' },
      { name: 'Anzoátegui' },
      { name: 'Apure' },
      { name: 'Aragua' },
      { name: 'Barinas' },
      { name: 'Bolívar' },
      { name: 'Carabobo' },
      { name: 'Cojedes' },
      { name: 'Delta Amacuro' },
      { name: 'Dependencias Federales' },
      { name: 'Distrito Capital' },
      { name: 'Falcón' },
      { name: 'Guárico' },
      { name: 'Lara' },
      { name: 'Miranda' },
      { name: 'Monagas' },
      { name: 'Mérida' },
      { name: 'Nueva Esparta' },
      { name: 'Portuguesa' },
      { name: 'Sucre' },
      { name: 'Trujillo' },
      { name: 'Táchira' },
      { name: 'Vargas' },
      { name: 'Yaracuy' },
      { name: 'Zulia' },
    ],
  },
  {
    name: 'Vietnam',
    states: [
      { name: 'An Giang' },
      { name: 'Bà Rịa–Vũng Tàu' },
      { name: 'Bình Dương' },
      { name: 'Bình Phước' },
      { name: 'Bình Thuận' },
      { name: 'Bình Định' },
      { name: 'Bạc Liêu' },
      { name: 'Bắc Giang' },
      { name: 'Bắc Kạn' },
      { name: 'Bắc Ninh' },
      { name: 'Bến Tre' },
      { name: 'Cao Bằng' },
      { name: 'Cà Mau' },
      { name: 'Cần Thơ' },
      { name: 'Gia Lai' },
      { name: 'Hà Giang' },
      { name: 'Hà Nam' },
      { name: 'Hà Nội' },
      { name: 'Hà Tây' },
      { name: 'Hà Tĩnh' },
      { name: 'Hòa Bình' },
      { name: 'Hưng Yên' },
      { name: 'Hải Dương' },
      { name: 'Hải Phòng' },
      { name: 'Hậu Giang' },
      { name: 'Hồ Chí Minh' },
      { name: 'Khánh Hòa' },
      { name: 'Kiên Giang' },
      { name: 'Kon Tum' },
      { name: 'Lai Châu' },
      { name: 'Long An' },
      { name: 'Lào Cai' },
      { name: 'Lâm Đồng' },
      { name: 'Lạng Sơn' },
      { name: 'Nam Định' },
      { name: 'Nghệ An' },
      { name: 'Ninh Bình' },
      { name: 'Ninh Thuận' },
      { name: 'Phú Thọ' },
      { name: 'Phú Yên' },
      { name: 'Quảng Bình' },
      { name: 'Quảng Nam' },
      { name: 'Quảng Ngãi' },
      { name: 'Quảng Ninh' },
      { name: 'Quảng Trị' },
      { name: 'Sóc Trăng' },
      { name: 'Sơn La' },
      { name: 'Thanh Hóa' },
      { name: 'Thái Bình' },
      { name: 'Thái Nguyên' },
      { name: 'Thừa Thiên–Huế' },
      { name: 'Tiền Giang' },
      { name: 'Trà Vinh' },
      { name: 'Tuyên Quang' },
      { name: 'Tây Ninh' },
      { name: 'Vĩnh Long' },
      { name: 'Vĩnh Phúc' },
      { name: 'Yên Bái' },
      { name: 'Điện Biên' },
      { name: 'Đà Nẵng' },
      { name: 'Đắk Lắk' },
      { name: 'Đắk Nông' },
      { name: 'Đồng Nai' },
      { name: 'Đồng Tháp' },
    ],
  },
  { name: 'Wallis and Futuna', states: [] },
  { name: 'Western Sahara', states: [] },
  {
    name: 'Yemen',
    states: [
      { name: "'Adan" },
      { name: "'Amrān" },
      { name: 'Abyān' },
      { name: "Al Bayḑā'" },
      { name: 'Al Jawf' },
      { name: 'Al Mahrah' },
      { name: 'Al Maḩwīt' },
      { name: 'Al Ḩudaydah' },
      { name: "Aḑ Ḑāli'" },
      { name: 'Dhamār' },
      { name: 'Ibb' },
      { name: 'Laḩij' },
      { name: "Ma'rib" },
      { name: 'Raymah' },
      { name: 'Shabwah' },
      { name: 'Tā‘izz' },
      { name: "Şan‘ā'" },
      { name: "Şan‘ā'" },
      { name: 'Şā‘dah' },
      { name: 'Ḩajjah' },
      { name: 'Ḩaḑramawt' },
    ],
  },
  {
    name: 'Zambia',
    states: [
      { name: 'Central' },
      { name: 'Copperbelt' },
      { name: 'Eastern' },
      { name: 'Luapula' },
      { name: 'Lusaka' },
      { name: 'North-Western' },
      { name: 'Northern' },
      { name: 'Southern' },
      { name: 'Western' },
    ],
  },
  {
    name: 'Zimbabwe',
    states: [
      { name: 'Bulawayo' },
      { name: 'Harare' },
      { name: 'Manicaland' },
      { name: 'Mashonaland Central' },
      { name: 'Mashonaland East' },
      { name: 'Mashonaland West' },
      { name: 'Masvingo' },
      { name: 'Matabeleland North' },
      { name: 'Matabeleland South' },
      { name: 'Midlands' },
    ],
  },
];

const getStatesForCountry = (country: string): State[] =>
  countries.find(({ name }) => name === country)?.states ?? [];

export const getOptionsForCountry = (country: string): AutoCompleteOption[] =>
  getStatesForCountry(country).map(({ name }) => ({ value: name }));

export const countryOptions: AutoCompleteOption[] = countries.map(
  ({ name }) => ({ value: name })
);
