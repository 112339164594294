import { AppState } from '../../interfaces/redux/state/App';
import { selectAllItems, selectItem, selectCount } from './Generic';
import { Litigation } from '../../interfaces/entities/Litigation';

export const selectAllCases = ({ cases }: AppState): Litigation[] =>
  selectAllItems(cases).filter(
    ({ status }) => status.toLowerCase() !== 'archived'
  );

export const selectLitigation = (
  { cases }: AppState,
  id: number
): Litigation | null => selectItem(id, cases);

export const selectLitigationCount = ({ cases }: AppState): number =>
  selectCount(cases);
