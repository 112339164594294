import React, { useState } from 'react';
import { Litigation } from '../../interfaces/entities/Litigation';
import { Store } from 'antd/lib/form/interface';
import { convertMomentDateToString } from '../../utility/date';
import { makeDownloadRequest } from '../../utility/api';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../../redux/selectors/Auth';
import InvoiceFormContainer from './BaseContainer';

interface Props {
  litigation?: Litigation;
  successCallback: () => void;
}

const InvoiceForm: React.FC<Props> = ({ litigation, successCallback }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const authorization = useSelector(selectAuthToken);

  const formatRequest = (values: Store) => {
    const { dueDate } = values;
    return {
      ...values,
      id: litigation?.id,
      dueDate: convertMomentDateToString(dueDate),
    };
  };

  const handleFinish = (values: Store) => {
    values = formatRequest(values);
    setLoading(true);
    makeDownloadRequest({
      url: 'invoice/generate',
      values,
      mime: 'application/pdf',
      authorization,
      type: 'POST',
      filename: `${litigation?.suitNumber}.pdf`,
      successCallback: () => {
        setLoading(false);
        successCallback();
      },
    });
  };

  return (
    <InvoiceFormContainer handleFinish={handleFinish} isLoading={loading} />
  );
};

export default InvoiceForm;
