import { getGenericStateObject } from '../../utility/redux/state';
import { TaskState } from '../../interfaces/redux/state/App';
import { PersonalTaskAction } from '../../interfaces/redux/actions/PersonalTask';
import {
  ADD_PERSONAL_TASK,
  ADD_PERSONAL_TASKS,
  BULK_EDIT_PERSONAL_TASKS,
  CLEAR_PERSONAL_TASKS,
  EDIT_PERSONAL_TASK,
  REMOVE_PERSONAL_TASK,
  UPDATE_PERSONAL_TASKS
} from '../ActionTypes';
import { add, addMany, bulkUpdate, edit, remove, update } from '../../utility/redux/reducer';

const initialState: TaskState = getGenericStateObject();

const reducer = (
  state: TaskState = initialState,
  action: PersonalTaskAction
): TaskState => {
  switch (action.type) {
    case ADD_PERSONAL_TASKS:
      return addMany(state, action.payload);
    case ADD_PERSONAL_TASK:
      return add(state, action.payload);
    case EDIT_PERSONAL_TASK:
      return edit(state, action.payload);
    case REMOVE_PERSONAL_TASK:
      return remove(state, action.payload);
    case UPDATE_PERSONAL_TASKS:
      return update(state, action.payload);
    case BULK_EDIT_PERSONAL_TASKS:
      return bulkUpdate(state, action.payload);
    case CLEAR_PERSONAL_TASKS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
