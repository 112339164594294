import {
  AddPersonalTaskAction,
  ClearPersonalTasksAction,
  EditPersonalTaskAction,
  ImportPersonalTasksAction,
  RemovePersonalTaskAction,
  UpdatePersonalTasksAction
} from '../../interfaces/redux/actions/PersonalTask';
import {
  ADD_PERSONAL_TASK,
  ADD_PERSONAL_TASKS,
  BULK_EDIT_TASKS,
  CLEAR_PERSONAL_TASKS,
  EDIT_PERSONAL_TASK,
  REMOVE_PERSONAL_TASK,
  UPDATE_PERSONAL_TASKS
} from '../ActionTypes';
import { Task } from '../../interfaces/entities/Task';
import { BulkEditTasksAction } from '../../interfaces/redux/actions/Task';

const add = (task: Task): AddPersonalTaskAction => ({
  type: ADD_PERSONAL_TASK,
  payload: task
});

const addMany = (tasks: Task[]): ImportPersonalTasksAction => ({
  type: ADD_PERSONAL_TASKS,
  payload: tasks
});

const update = (tasks: Task[]): UpdatePersonalTasksAction => ({
  type: UPDATE_PERSONAL_TASKS,
  payload: tasks
});

const bulkUpdate = (tasks: Task[]): BulkEditTasksAction => ({
  type: BULK_EDIT_TASKS,
  payload: tasks
});

const edit = (task: Task): EditPersonalTaskAction => ({
  type: EDIT_PERSONAL_TASK,
  payload: task
});

const remove = (task: Task['id']): RemovePersonalTaskAction => ({
  type: REMOVE_PERSONAL_TASK,
  payload: task
});

const clear = (): ClearPersonalTasksAction => ({ type: CLEAR_PERSONAL_TASKS });

export default {
  add,
  addMany,
  update,
  clear,
  bulkUpdate,
  edit,
  remove
};
