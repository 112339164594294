import React, { useState } from 'react';
import { Badge, Layout, Menu } from 'antd';
import Icon, {
  EditOutlined,
  NotificationOutlined,
  PoweroffOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser, selectAuthenticatedUserOrganization } from '../../redux/selectors/Auth';
import { Organization } from '../../interfaces/entities/Organization';
import { Link } from 'react-router-dom';
import Logo from '../../media/dashboardIcon.png';
import { User } from '../../interfaces/entities/User';
import { isAdvancedUser } from '../../utility/user/privileges';
import { EDIT_ORGANIZATION, EDIT_USER } from '../../utility/routing/routes';
import { selectUnreadNotificationCount } from '../../redux/selectors/Notification';
import NotificationDrawer from '../appNotification/Drawer';

const { Header } = Layout;
const { SubMenu } = Menu;

interface HeaderProps {
  logout: () => void;
}

const DashboardHeader: React.FC<HeaderProps> = ({ logout }) => {
  const organization = useSelector(
    selectAuthenticatedUserOrganization
  ) as Organization;

  const unreadNotificationCount = useSelector(selectUnreadNotificationCount);

  let organizationLogo: string | null = null;
  if (organization && organization.logo) {
    organizationLogo = organization.logo;
  }

  const user = useSelector(selectAuthenticatedUser) as User;

  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);

  const toggleNotificationDrawerVisibility = () => {
    setShowNotificationDrawer(showNotificationDrawer => !showNotificationDrawer);
  };

  const closeDrawer = () => {
    setShowNotificationDrawer(false);
  };

  return (
    <>
      <Header
        className='header'
        style={{
          position: 'fixed',
          zIndex: 999,
          width: '100%',
          padding: '0px'
        }}
      >
        <Link to='/'>
          <div
            className='logo'
            style={{
              width: '120px',
              height: '60px',
              backgroundImage: `url(${Logo})`,
              margin: '5px 24px 16px 10px',
              float: 'left',
              backgroundPosition: 'left',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat'
            }}
          />
        </Link>
        <Menu
          theme='dark'
          mode='horizontal'
          style={{
            position: 'relative',
            display: 'flex',
            float: 'right',
            height: '30px'
          }}
        >
          <SubMenu
            key='sub1'
            title={
              <span>
              <Badge size='small' count={unreadNotificationCount} title='Notifications'>
                <NotificationOutlined
                  style={{ fontSize: '25px' }}
                />
              </Badge>
            </span>
            }
            style={{
              marginTop: '15px'
            }}
            onTitleClick={toggleNotificationDrawerVisibility}
          >
          </SubMenu>
          <SubMenu
            key='sub2'
            title={
              <span>
              {organizationLogo ? (
                <Icon
                  component={() => (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/logo/${organizationLogo}`}
                      alt={'Organization Logo'}
                      style={{
                        height: '40px',
                        margin: '8px 0px 0px 0px',
                        borderRadius: '50%'
                      }}
                    />
                  )}
                />
              ) : (
                <SettingOutlined
                  style={{ fontSize: '25px', marginTop: '30px' }}
                />
              )}
            </span>
            }
          >
            {isAdvancedUser(user) && organization?.id && (
              <Menu.Item key='1'>
                <Link to={`${EDIT_ORGANIZATION}/${organization.id}`}>
                <span>
                  <UserOutlined />
                  <span>Edit Organization</span>
                </span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key='2'>
              <Link to={`${EDIT_USER}/${user.id}`}>
              <span>
                <EditOutlined />
                <span>Edit Profile</span>
              </span>
              </Link>
            </Menu.Item>
            <Menu.Item key='3' onClick={logout}>
              <PoweroffOutlined />
              <span>Logout</span>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Header>
      <NotificationDrawer
        showNotificationDrawer={showNotificationDrawer}
        closeDrawer={closeDrawer}
      />
    </>
  );
};

export default DashboardHeader;
