import { Appeal } from '../../interfaces/entities/Appeal';
import {
  UpdateAppealsAction,
  AddAppealAction,
  ImportAppealsAction,
  BulkEditAppealsAction,
  RemoveAppealAction,
  EditAppealAction,
  ClearAppealsAction,
} from '../../interfaces/redux/actions/Appeal';
import {
  UPDATE_APPEALS,
  ADD_APPEAL,
  ADD_APPEALS,
  BULK_EDIT_APPEALS,
  REMOVE_APPEAL,
  UPDATE_APPEAL,
  CLEAR_APPEALS,
} from '../ActionTypes';

const update = (appeals: Appeal[]): UpdateAppealsAction => ({
  type: UPDATE_APPEALS,
  payload: appeals,
});

const add = (litigation: Appeal): AddAppealAction => ({
  type: ADD_APPEAL,
  payload: litigation,
});

const addMany = (appeals: Appeal[]): ImportAppealsAction => ({
  type: ADD_APPEALS,
  payload: appeals,
});

const editMany = (appeals: Appeal[]): BulkEditAppealsAction => ({
  type: BULK_EDIT_APPEALS,
  payload: appeals,
});

const remove = (litigation: Appeal['id']): RemoveAppealAction => ({
  type: REMOVE_APPEAL,
  payload: litigation,
});

const edit = (litigation: Appeal): EditAppealAction => ({
  type: UPDATE_APPEAL,
  payload: litigation,
});

const clear = (): ClearAppealsAction => ({ type: CLEAR_APPEALS });

export default { update, add, addMany, remove, edit, clear, editMany };
