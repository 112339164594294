import React, { useState } from 'react';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { Store } from 'antd/lib/form/interface';
import { handleAuthentication } from '../../utility/auth/authentication';
import EmailInput from '../form/EmailInput';
import PasswordInput from '../form/PasswordInput';
import SubmitButton from '../form/SubmitButton';

const LoginForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleFinish = (values: Store) => {
    setIsLoading(true);
    handleAuthentication({
      url: 'login',
      values,
      dispatch,
      failureCallback: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <Form onFinish={handleFinish}>
      <EmailInput name='username' showPrefix={true} />
      <PasswordInput showPrefix={true} />
      <SubmitButton isLoading={isLoading} buttonText='Log In' />
    </Form>
  );
};

export default LoginForm;
