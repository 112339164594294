import React from 'react';
import { useParams } from 'react-router-dom';
import { Store } from 'antd/lib/form/interface';
import { convertMomentDateToString } from '../../utility/date';
import BaseUpdateForm from './Base';
import { VIEW_ALL_APPEALS, VIEW_ALL_CASES } from '../../utility/routing/routes';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../interfaces/redux/state/App';
import { selectLitigation } from '../../redux/selectors/Litigation';
import LitigationActions from '../../redux/actions/Litigation';
import AppealActions from '../../redux/actions/Appeal';
import DocumentActions from '../../redux/actions/Document';
import { Litigation } from '../../interfaces/entities/Litigation';
import { Appeal } from '../../interfaces/entities/Appeal';
import { selectAppeal } from '../../redux/selectors/Appeal';

interface Props {
  type: 'appeal' | 'case';
}

const CaseUpdateForm: React.FC<Props> = ({ type }) => {
  const isAppeal = type === 'appeal';
  const { id } = useParams();

  const selectedCase: Litigation | Appeal = useSelector(
    (state) => {
      return isAppeal
        ? (selectAppeal(state as AppState, id) as Appeal)
        : (selectLitigation(state as AppState, id) as Litigation);
    });

  const dispatch = useDispatch();

  const formatRequest = (values: Store) => {
    const { hearingDate, nextHearingDate } = values;
    values.hearingDate = convertMomentDateToString(hearingDate);
    if (nextHearingDate) {
      values.nextHearingDate = convertMomentDateToString(nextHearingDate);
    } else {
      values.hearingDate = '';
    }
    if (isAppeal) {
      values.appeal = id;
    } else {
      values.case = id;
    }
    return values;
  };

  const onSuccess = (data: any) => {
    selectedCase?.updates.push(data);
    const { onlineDocuments, localDocuments } = data;
    const editAction = isAppeal ? AppealActions.edit(selectedCase as Appeal) :
      LitigationActions.edit(selectedCase as Litigation);
    batch(() => {
      dispatch(editAction);
      dispatch(DocumentActions.addMany(onlineDocuments));
      dispatch(DocumentActions.addMany(localDocuments));
    });
  };

  return (
    <BaseUpdateForm
      formatRequest={formatRequest}
      submitUrl={`${type}/update`}
      updateType='case'
      redirectUrl={isAppeal ? VIEW_ALL_APPEALS : VIEW_ALL_CASES}
      onSuccess={onSuccess}
    />
  );
};

export default CaseUpdateForm;
