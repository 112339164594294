import React, { useState } from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import {
  getOptionsForCountry,
  countryOptions,
} from '../../utility/constants/countries';
import { AutoCompleteOption } from '../../interfaces/components/form/AutoComplete';
import AutoCompleteInput from './AutoCompleteInput';

interface Props {
  isRequired?: boolean;
  initialAddressValue?: string;
  initialStateValue?: string;
  initialCityValue?: string;
  initialCountryValue?: string;
}

const AddressInput: React.FC<Props> = ({
  isRequired = true,
  initialAddressValue,
  initialStateValue,
  initialCityValue,
  initialCountryValue,
}) => {
  const [country, setCountry] = useState<string>(
    initialCountryValue ?? 'Nigeria'
  );
  const [stateOptions, setStateOptions] = useState<AutoCompleteOption[]>(
    getOptionsForCountry(country)
  );
  const [selectedState, setSelectedState] = useState<string>(
    initialStateValue ?? ''
  );
  return (
    <>
      <Form.Item
        name='address'
        initialValue={initialAddressValue}
        rules={[{ required: isRequired, message: 'Please provide an address' }]}
        label='Address'
      >
        <Input placeholder='First line of address' />
      </Form.Item>
      <Form.Item
        name='city'
        initialValue={initialCityValue}
        rules={[{ required: isRequired, message: 'Please provide a city' }]}
        label='City'
      >
        <Input placeholder='City' />
      </Form.Item>
      <AutoCompleteInput
        {...{
          required: !!isRequired,
          placeholder: 'State',
          name: 'state',
          requiredMessage: 'Please select a state',
          options: stateOptions,
          initialValue: selectedState,
          onSelect: (value: string) => {
            setSelectedState(value);
          },
          label: 'State',
        }}
      />
      <AutoCompleteInput
        {...{
          required: !!isRequired,
          placeholder: 'Country',
          name: 'country',
          requiredMessage: 'Please select a country',
          options: countryOptions,
          initialValue: country,
          label: 'Country',
          onSelect: (value: string) => {
            setCountry(value);
            setStateOptions(getOptionsForCountry(value));
            setSelectedState('');
          },
        }}
      />
    </>
  );
};

export default AddressInput;
