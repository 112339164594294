import React, { useState } from 'react';
import { Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useDispatch } from 'react-redux';
import { handleAuthentication } from '../../utility/auth/authentication';
import EmailInput from '../form/EmailInput';
import TokenInput from '../form/TokenInput';
import PasswordInput from '../form/PasswordInput';
import SubmitButton from '../form/SubmitButton';

interface ResetAccountProps {
  email: string;
  tokenPrefix: string;
}

const ResetPassword: React.FC<ResetAccountProps> = ({ email, tokenPrefix }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const handleFinish = (values: Store) => {
    setIsLoading(true);
    delete values.confirmPassword;
    handleAuthentication({
      url: 'auth/password/reset/complete',
      values,
      dispatch,
      failureCallback: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <Form initialValues={{ email, token: tokenPrefix }} onFinish={handleFinish}>
      <EmailInput disabled={true} showPrefix={true} />
      <TokenInput tokenPrefix={tokenPrefix} />
      <PasswordInput showConfirmation={true} showPrefix={true} />
      <SubmitButton isLoading={isLoading} buttonText={'Finish'} />
    </Form>
  );
};

export default ResetPassword;
