import React, { useState } from 'react';
import { TokenRequestProps } from '../../interfaces/components/authentication/Update';
import { Button, Checkbox, Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import ResetPassword from './Reset';
import SubmitButton from '../form/SubmitButton';
import EmailInput from '../form/EmailInput';
import DropdownInput from '../form/DropdownInput';
import PrivacyPolicy from '../privacy/PrivacyPolicy';

const TokenRequestForm: React.FC<TokenRequestProps> = ({
  showNotificationPreference,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [tokenPrefix, setTokenPrefix] = useState<string>('');
  const [showResetForm, setShowResetForm] = useState<boolean>(false);
  const [showPolicyModal, setShowPolicyModal] = useState<boolean>(true);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const validateCheckbox = (rule: any, value: string, callback: any) => {
    let errors = [];
    if (!isChecked) {
      errors.push(
        new Error('You must read and accept the terms of the privacy policy')
      );
    }
    callback(errors);
  };

  const handleFinish = (values: Store) => {
    makeRequest({
      url: 'auth/password/reset/initiate',
      values: { ...values, isInitialSetup: showNotificationPreference },
      successCallback: (response) => {
        showToast(response.message);
        setEmail(values.email);
        setTokenPrefix(response.prefix);
        setShowResetForm(true);
      },
      failureCallback: () => {
        setIsLoading(false);
      },
    });
    setIsLoading(true);
  };
  return showResetForm ? (
    <ResetPassword {...{ tokenPrefix, email }} />
  ) : (
    <Form onFinish={handleFinish}>
      <EmailInput showPrefix={true} />
      {showNotificationPreference && (
        <>
          <DropdownInput
            name='notificationPreference'
            options={[
              { name: 'SMS', value: 'sms' },
              { name: 'Email', value: 'email' },
            ]}
            placeholder='Notification Preference'
            message='Please select a notification preference'
          />

          <Form.Item
            rules={[
              {
                validator: validateCheckbox,
              },
            ]}
            name='privacy'
          >
            <Checkbox
              onChange={(e) => {
                setIsChecked(e.target.checked);
              }}
            >
              I have read the privacy policy and accept its terms
            </Checkbox>
            <br />
            <Button
              type='link'
              style={{ margin: '5px' }}
              onClick={() => {
                setShowPolicyModal(true);
              }}
            >
              Show Privacy Policy
            </Button>
          </Form.Item>
          <PrivacyPolicy
            showPolicy={showPolicyModal}
            updateShowPolicy={setShowPolicyModal}
          />
        </>
      )}
      <SubmitButton isLoading={isLoading} buttonText='Next' />
    </Form>
  );
};

export default TokenRequestForm;
