import React from 'react';
import { Litigation } from '../../interfaces/entities/Litigation';
import Timeline from 'antd/lib/timeline/Timeline';
import { makeDownloadRequest, makeRequest } from '../../utility/api';
import { Button } from 'antd';
import { showToast } from '../../utility/notification/toast';
import LitigationActions from '../../redux/actions/Litigation';
import { Dispatch } from 'redux';

const downloadCaseUpdateFiles = (id: number, authorization: string) => {
  makeDownloadRequest({
    url: `document/update/${id}`,
    type: 'GET',
    authorization,
    filename: `DCase_Update_Files.zip`,
    mime: 'application/octet-stream',
  });
};

const deleteCaseUpdate = (
  id: number,
  authorization: string,
  dispatch: Dispatch
) => {
  makeRequest({
    url: `case/update/${id}`,
    type: 'DELETE',
    authorization,
    successCallback: (response) => {
      const { data, message } = response;
      showToast(message);
      dispatch(LitigationActions.edit(data));
    },
  });
};

export const getDrawerContent = (
  litigation: Litigation,
  authorization: string,
  dispatch: Dispatch
): JSX.Element => (
  <>
    <p style={{ marginTop: '10px' }}>
      <b>Suit Name</b>
    </p>
    <p>{`${litigation.suitName}`}</p>
    <p style={{ marginTop: '10px' }}>
      <b>Court</b>
    </p>
    <p>{`${litigation.court}`}</p>
    {litigation.clients.length > 0 && (
      <p style={{ marginTop: '10px' }}>
        <b>Client</b>
      </p>
    )}
    <ol>
      {litigation.clients.map((client, index) => (
        <li key={index}>{`${client.name}`}</li>
      ))}
    </ol>
    <p style={{ marginTop: '10px' }}>
      <b>Filing Date</b>
    </p>
    <p>{`${litigation.filingDate}`}</p>
    <p style={{ marginTop: '10px' }}>
      <b>Updates</b>
    </p>
    {litigation.updates.length > 0 && (
      <Timeline mode='left'>
        {litigation.updates.map(
          (
            {
              date,
              description,
              nextHearing,
              privateDescription,
              id,
              title,
              localDocumentCount,
              onlineDocuments,
            },
            index
          ) => (
            <Timeline.Item label={date} key={index}>
              {description && (
                <>
                  <p style={{ marginTop: '10px' }}>
                    <b>Note to Client</b>
                  </p>
                  <p>{description}</p>
                </>
              )}
              {nextHearing && (
                <>
                  <p style={{ marginTop: '10px' }}>
                    <b>Next Hearing Date</b>
                  </p>
                  <p>{nextHearing}</p>
                </>
              )}
              {privateDescription && (
                <>
                  <b> Company Update</b>
                  <p style={{ marginTop: '10px' }}>{privateDescription}</p>
                </>
              )}
              {localDocumentCount > 0 && (
                <Button
                  type='link'
                  onClick={() => {
                    downloadCaseUpdateFiles(id as number, authorization);
                  }}
                >
                  Download Associated Documents
                </Button>
              )}
              {onlineDocuments.length > 0 && (
                <>
                  <b>Online Documents</b>
                  <ol>
                    {onlineDocuments.map((document: any, index) => (
                      <li key={index}>
                        <a href={document.url} target='__blank'>
                          {document.title}
                        </a>
                      </li>
                    ))}
                  </ol>
                </>
              )}
              <Button
                onClick={() => {
                  deleteCaseUpdate(id as number, authorization, dispatch);
                }}
              >
                Delete Update
              </Button>
            </Timeline.Item>
          )
        )}
      </Timeline>
    )}
  </>
);
