import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  RequestHeader,
  RequestConfiguration,
  APIRequest,
  ResponseBlob,
  DownloadRequest,
} from '../interfaces/utility/api';
import { showToast } from './notification/toast';

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  responseType: 'json',
});

const getConfig = ({
  accept,
  authorization,
  responseType = 'json',
}: RequestConfiguration): AxiosRequestConfig => {
  const headers: RequestHeader = {
    'Content-Type': 'application/json',
  };
  if (accept) headers.Accept = accept;
  if (authorization) headers.Authorization = `Bearer ${authorization}`;

  return { headers, responseType };
};

export const makeRequest = ({
  url,
  values,
  successCallback,
  failureCallback,
  authorization,
  accept,
  responseType,
  type = 'POST',
}: APIRequest) => {
  const config = getConfig({ accept, authorization, responseType });
  let promise: Promise<AxiosResponse>;
  switch (type) {
    case 'GET':
      promise = API.get(url, config);
      break;
    case 'POST':
      promise = API.post(url, values, config);
      break;
    case 'DELETE':
      config.data = values;
      promise = API.delete(url, config);
      break;
    case 'PATCH':
      promise = API.patch(url, values, config);
      break;
    default:
      return;
  }
  promise
    .then((response) => {
      const { data } = response;
      successCallback(data);
    })
    .catch((error) => {
      //check if there's a response message
      //if there's none use a generic one
      //log the error
      //send notification
      // console.log(error);
      if (error.response) {
        if (failureCallback) {
          failureCallback();
        }
        showToast(
          error.response.data?.message || 'An unknown error occurred',
          false
        );
      }
    });
};

const downloadResponse = ({ response, filename, mime }: ResponseBlob) => {
  const blob = new Blob([response], {
    type: mime,
  });

  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const makeDownloadRequest = ({
  url,
  values,
  type,
  authorization,
  mime = 'application/octet-stream',
  filename,
  successCallback,
  failureCallback,
}: DownloadRequest) => {
  makeRequest({
    url,
    values,
    type,
    responseType: 'blob',
    authorization,
    successCallback: (response: any) => {
      downloadResponse({ response, filename, mime });
      if (successCallback) {
        successCallback();
      }
    },
    failureCallback,
  });
};
