import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Litigation } from '../../interfaces/entities/Litigation';
import LitigationActions from '../../redux/actions/Litigation';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { selectAllClients } from '../../redux/selectors/Client';
import { makeRequest } from '../../utility/api';
import { getObjectId, getOptions } from '../../utility/array';
import { showToast } from '../../utility/notification/toast';
import AutoCompleteInput from '../form/AutoCompleteInput';
import SubmitButton from '../form/SubmitButton';

interface Props {
  litigation: Litigation;
  showForm: boolean;
  successCallback: () => void;
  cancel: () => void;
}

const AddClientForm: React.FC<Props> = ({
  litigation,
  showForm,
  successCallback,
  cancel,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authorization = useSelector(selectAuthToken);
  const clients = useSelector(selectAllClients);
  const [clientOptions, setClientOptions] = useState(getOptions(clients));
  const [showButton, setShowButton] = useState(true);

  const dispatch = useDispatch();

  const formatRequest = (values: Store) => {
    const { clients: submittedClients } = values;
    values.clients = submittedClients.map((client: string) => {
      return { id: getObjectId(client, clients) };
    });
    return values;
  };

  const handleFinish = (values: Store) => {
    setIsLoading(true);
    const { indexClient, clients } = values;
    if (!clients) {
      values.clients = [indexClient];
    } else {
      values.clients = [...clients, indexClient];
    }
    values.id = litigation.id;
    values = formatRequest(values);
    makeRequest({
      url: `case/client/add`,
      values,
      type: 'POST',
      authorization,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        dispatch(LitigationActions.edit(data));
        setIsLoading(false);
        successCallback();
      },
      failureCallback: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <Modal
      visible={showForm}
      onCancel={cancel}
      footer={null}
      title={`Add Clients`}
    >
      <Form onFinish={handleFinish}>
        <AutoCompleteInput
          name={`indexClient`}
          label='Associated Client'
          placeholder='Associated Client'
          options={clientOptions}
        />
        <Form.List name='clients'>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <Row key={field.key}>
                      <Col span={16}>
                        <AutoCompleteInput
                          name={`${index}`}
                          label='Associated Client'
                          placeholder='Associated Client'
                          options={clientOptions}
                          required={false}
                        />
                      </Col>
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add();
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                        <Button
                          onClick={() => {
                            remove(field.name);
                            if (index === 0) {
                              setShowButton(true);
                            }
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  block
                  style={{
                    display: showButton ? 'block' : 'none',
                    marginBottom: '10px',
                  }}
                  onClick={() => {
                    setShowButton(false);
                    add();
                  }}
                >
                  Add Client
                </Button>
              </>
            );
          }}
        </Form.List>
        <SubmitButton isLoading={isLoading} buttonText='Save' />
      </Form>
      <Button block onClick={cancel}>
        Cancel
      </Button>
    </Modal>
  );
};

export default AddClientForm;
