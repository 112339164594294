import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../interfaces/redux/state/App';
import NotPermitted from '../utility/NotPermitted';
import { VIEW_ALL_CONTACTS } from '../../utility/routing/routes';
import ContactForm from './Contact';
import { selectContact } from '../../redux/selectors/Contact';

const EditContactForm: React.FC = () => {
  const { id } = useParams();
  const contact = useSelector((appState) =>
    selectContact(appState as AppState, +id)
  );

  if (!contact) {
    return (
      <NotPermitted
        returnUrl={VIEW_ALL_CONTACTS}
        message='Could not find a contact with the specified ID'
      />
    );
  }

  return <ContactForm contact={contact} />;
};

export default EditContactForm;
