import React, { useEffect, useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import { useSelector } from 'react-redux';
import { selectAllClients } from '../../redux/selectors/Client';
import { filterByOrganization, getOptions } from '../../utility/array';
import { selectAllCases } from '../../redux/selectors/Litigation';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import { selectAuthenticatedUser } from '../../redux/selectors/Auth';
import { User } from '../../interfaces/entities/User';
import { Button, Col, Form, Row } from 'antd';
import AutoCompleteInput from '../form/AutoCompleteInput';
import TextInput from '../form/TextInput';
import EmailInput from '../form/EmailInput';
import PhoneNumberInput from '../form/PhoneNumberInput';
import { COURT_CATEGORIES } from '../../utility/constants/constants';
import SubmitButton from '../form/SubmitButton';
import { Contact } from '../../interfaces/entities/Contact';
import ModalForm from '../form/ModalForm';
import { ResourceType } from '../../interfaces/entities/Base';
import { Organization } from '../../interfaces/entities/Organization';
import TextAreaInput from '../form/TextAreaInput';

interface Props {
  isLoading: boolean;
  handleFinish: (values: Store) => void;
  contact: Contact | null;
}

const ContactFormContainer: React.FC<Props> = ({
                                                 isLoading,
                                                 contact,
                                                 handleFinish
                                               }) => {
  const clients = useSelector(selectAllClients);
  const savedCases = useSelector(selectAllCases);
  const organizations = useSelector(selectAllOrganizations);
  const authenticatedUser = useSelector(selectAuthenticatedUser) as User;
  const userIsSuperAdmin = authenticatedUser.isSuperAdmin;
  const [form] = Form.useForm();

  const [clientOptions, setClientOptions] = useState(getOptions(clients));

  const [caseOptions, setCaseOptions] = useState(getOptions(savedCases, true));
  const [renderModal, setRenderModal] = useState(false);
  const [formToRender, setFormToRender] = useState<ResourceType | ''>('');

  const organizationOptions = getOptions(organizations);
  const [selectedOrganization, setSelectedOrganization] = useState(
    (authenticatedUser.organization as Organization)?.name
  );
  useEffect(() => {
    updateOptions(selectedOrganization);
  }, [savedCases, clients]);

  const updateOptions = (selectedOrganization: string) => {
    setSelectedOrganization(selectedOrganization);
    const filteredCases = filterByOrganization(
      savedCases,
      selectedOrganization
    );
    const filteredClients = filterByOrganization(clients, selectedOrganization);
    setClientOptions(getOptions(filteredClients));
    setCaseOptions(getOptions(filteredCases, true));
    form.setFieldsValue({
      case: '',
      client: ''
    });
  };

  const showModalForm = (resourceType: ResourceType) => {
    setFormToRender(resourceType);
    setRenderModal(true);
  };

  const closeModal = () => {
    setRenderModal(false);
  };

  return (
    <>
      <ModalForm
        formToRender={formToRender as ResourceType}
        showModal={renderModal}
        showOrganizationDropdown={userIsSuperAdmin}
        successCallback={closeModal}
        cancel={closeModal}
      />
      <Form form={form} onFinish={handleFinish}>
        {userIsSuperAdmin && (
          <AutoCompleteInput
            name='organization'
            label='Organization'
            placeholder='Organization'
            options={organizationOptions}
            onSelect={updateOptions}
            initialValue={contact?.organization.name}
          />
        )}
        <TextInput
          name='firstName'
          placeholder='First Name'
          message="Please enter the contact's first name"
          label='First Name'
          initialValue={contact?.firstName}
        />
        <TextInput
          name='lastName'
          placeholder='Last Name'
          message="Please enter the contact's last name"
          label='Last Name'
          initialValue={contact?.lastName}
        />
        <EmailInput
          placeholder='Email Address'
          required={true}
          requiredMessage="Please enter the contact's email address"
          label='Email Address'
          initialValue={contact?.email}
        />
        <PhoneNumberInput
          isRequired={false}
          initialCountryCodeValue={contact?.countryCode}
          initialValue={contact?.phoneNumber}
        />
        <Row>
          <Col span={20}>
            <AutoCompleteInput
              name='client'
              label='Associated Client'
              placeholder='Associated Client'
              options={clientOptions}
              required={false}
              initialValue={contact?.client?.name}
            />
          </Col>
          <Col span={4}>
            <Button
              onClick={() => {
                showModalForm('client');
              }}
            >
              Add Client
            </Button>
          </Col>
        </Row>

        <Row>
          <Col span={20}>
            <AutoCompleteInput
              name='case'
              label='Associated Case'
              placeholder='Associated Case'
              options={caseOptions}
              required={false}
              initialValue={contact?.case?.suitNumber}
            />
          </Col>
          <Col span={4}>
            <Button
              onClick={() => {
                showModalForm('case');
              }}
            >
              Add Case
            </Button>
          </Col>
        </Row>
        <AutoCompleteInput
          name='court'
          label='Associated Court'
          placeholder='Associated Court'
          options={COURT_CATEGORIES.map((category) => ({ value: category }))}
          required={false}
          initialValue={contact?.court}
        />
        <TextAreaInput
          name={'notes'}
          placeholder={'Note'}
          label={'Note'}
          required={false}
          initialValue={contact?.notes}
        />
        <SubmitButton
          buttonText={contact ? 'Save' : 'Submit'}
          isLoading={isLoading}
        />
      </Form>
    </>
  );
};

export default ContactFormContainer;
