import { AppNotification } from '../../interfaces/entities/Notification';
import {
  ClearNotificationsAction,
  UpdateNotificationAction,
  UpdateNotificationsAction
} from '../../interfaces/redux/actions/Notification';

const update = (notifications: AppNotification[]): UpdateNotificationsAction => ({
  type: 'UPDATE_NOTIFICATIONS',
  payload: notifications
});

const edit = (notification: AppNotification): UpdateNotificationAction => ({
  type: 'UPDATE_NOTIFICATION',
  payload: notification
});

const clear = (): ClearNotificationsAction => ({ type: 'CLEAR_NOTIFICATIONS' });

export default { update, edit, clear };