import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../dashboard/Overview';
import {
  VIEW_ALL_ORGANIZATIONS,
  ADD_ORGANIZATION,
  EDIT_ORGANIZATION,
  VIEW_ALL_USERS,
  ADD_USER,
  EDIT_USER,
  IMPORT_USERS,
  VIEW_TASK_REPORT,
  VIEW_ALL_DOCUMENTS,
  ADD_DOCUMENT,
  VIEW_ALL_CONTACTS,
  EDIT_CONTACT,
  ADD_CONTACT,
  VIEW_ALL_CLIENTS,
  ADD_CLIENT,
  EDIT_CLIENT,
  IMPORT_CLIENTS,
  VIEW_ALL_CASES,
  VIEW_STARRED_CASES,
  VIEW_ARCHIVED_CASES,
  ADD_CASE,
  EDIT_CASE,
  IMPORT_CASES,
  VIEW_ALL_TASKS,
  VIEW_PERSONAL_TASKS,
  UPDATE_CASE,
  UPDATE_TASK,
  ADD_TASK,
  EDIT_TASK,
  IMPORT_TASKS,
  IMPORT_CONTACTS,
  VIEW_ORGANIZATION_REPORT,
  ADD_APPEAL,
  VIEW_ALL_APPEALS,
  EDIT_APPEAL,
  UPDATE_APPEAL,
  VIEW_DELETED_USERS, VIEW_CLIENT_REPORT, VIEW_USER_REPORT, VIEW_CASE_REPORT, VIEW_CONTACT_REPORT
} from '../../utility/routing/routes';

import AddOrganizationForm from '../organization/Organization';
import EditOrganizationForm from '../organization/Edit';
import OrganizationTable from '../organization/ViewAll';

import AddUserForm from '../user/User';
import EditUserForm from '../user/Edit';
import ImportUsersForm from '../user/Import';
import UserTable from '../user/ViewAll';
import DeletedUserTable from '../user/ViewDeleted';

import DocumentTable from '../document/ViewAll';
import AddDocumentForm from '../document/Document';

import AddContactForm from '../contact/Contact';
import EditContactForm from '../contact/Edit';
import ContactsTable from '../contact/ViewAll';

import AddClientForm from '../client/Client';
import EditClientForm from '../client/Edit';
import ClientsTable from '../client/ViewAll';
import ImportClientsForm from '../client/Import';

import ViewAllCases from '../litigation/ViewAll';
import AddCaseForm from '../litigation/Litigation';
import EditCaseForm from '../litigation/Edit';
import ImportCasesForm from '../litigation/Import';

import ViewAllTasks from '../task/ViewAll';
import AddTaskForm from '../task/Task';
import EditTaskForm from '../task/Edit';

import NotPermitted from '../utility/NotPermitted';
import CaseUpdateForm from '../update/Litigation';
import TaskUpdateForm from '../update/Task';
import ImportTasksForm from '../task/Import';
import Timetable from '../timetable/Timetable';
import ImportContactsForm from '../contact/Import';
import OrganizationReport from '../organization/Report';
import withRestriction from '../hoc/withRestriction';

import AppealForm from '../appeal/Appeal';
import ViewAllAppeals from '../appeal/ViewAll';
import EditAppealForm from '../appeal/Edit';

import TaskReportView from '../report/task/Report';
import ClientReportView from '../report/client/Report';
import UserReportView from '../report/user/Report';
import CaseReportView from '../report/case/Report';
import ContactReportView from '../report/contact/Report';

const Routes = () => (
  <Switch>
    <Route path='/' exact component={Home} />

    <Route
      path={VIEW_ALL_ORGANIZATIONS}
      component={withRestriction(OrganizationTable)}
    />
    <Route
      path={ADD_ORGANIZATION}
      component={withRestriction(AddOrganizationForm)}
    />
    <Route
      path={`${EDIT_ORGANIZATION}/:id`}
      component={withRestriction(EditOrganizationForm)}
    />
    <Route
      path={VIEW_ORGANIZATION_REPORT}
      component={withRestriction(OrganizationReport)}
    />

    <Route path={VIEW_ALL_USERS} component={withRestriction(UserTable)} />
    <Route
      path={VIEW_DELETED_USERS}
      component={withRestriction(DeletedUserTable)}
    />
    <Route path={ADD_USER} component={withRestriction(AddUserForm)} />
    <Route path={`${EDIT_USER}/:id`} component={EditUserForm} />
    <Route path={IMPORT_USERS} component={withRestriction(ImportUsersForm)} />

    <Route path={VIEW_USER_REPORT} component={withRestriction(UserReportView)} />
    <Route path={VIEW_TASK_REPORT} component={withRestriction(TaskReportView)} />
    <Route path={VIEW_CLIENT_REPORT} component={withRestriction(ClientReportView)} />
    <Route path={VIEW_CASE_REPORT} component={withRestriction(CaseReportView)} />
    <Route path={VIEW_CONTACT_REPORT} component={withRestriction(ContactReportView)} />
    <Route path={VIEW_ALL_DOCUMENTS} component={DocumentTable} />
    <Route path={ADD_DOCUMENT} component={AddDocumentForm} />

    <Route path={VIEW_ALL_CONTACTS} component={withRestriction(ContactsTable)} />
    <Route path={ADD_CONTACT} component={AddContactForm} />
    <Route path={IMPORT_CONTACTS} component={ImportContactsForm} />
    <Route path={`${EDIT_CONTACT}/:id`} component={EditContactForm} />

    <Route path={VIEW_ALL_CLIENTS} component={ClientsTable} />
    <Route path={ADD_CLIENT} component={AddClientForm} />
    <Route path={`${EDIT_CLIENT}/:id`} component={EditClientForm} />
    {/* <Route path={IMPORT_CLIENTS} component={ImportClientsForm} /> */}

    <Route path={VIEW_ALL_CASES} component={ViewAllCases} />
    <Route path={ADD_CASE} component={AddCaseForm} />
    <Route path={`${EDIT_CASE}/:id`} component={EditCaseForm} />
    <Route path={IMPORT_CASES} component={ImportCasesForm} />

    <Route path={ADD_APPEAL} component={AppealForm} />
    <Route path={`${EDIT_APPEAL}/:id`} component={EditAppealForm} />
    <Route path={VIEW_ALL_APPEALS} component={ViewAllAppeals} />

    <Route
      path={VIEW_STARRED_CASES}
      render={(props) => <ViewAllCases {...props} category='starred' />}
    />

    <Route
      path={VIEW_ARCHIVED_CASES}
      render={(props) => <ViewAllCases {...props} category='archived' />}
    />

    <Route
      path={`${UPDATE_CASE}/:id`}
      render={(props) => <CaseUpdateForm {...props} type='case' />}
    />

    <Route
      path={`${UPDATE_APPEAL}/:id`}
      render={(props) => <CaseUpdateForm {...props} type='appeal' />}
    />

    <Route path={`${UPDATE_TASK}/:id`} component={TaskUpdateForm} />

    <Route path={VIEW_ALL_TASKS} component={ViewAllTasks} />

    <Route
      path={VIEW_PERSONAL_TASKS}
      render={(props) => <ViewAllTasks {...props} showPersonalTasks={true} />}
    />

    <Route path={ADD_TASK} component={withRestriction(AddTaskForm)} />
    <Route
      path={`${EDIT_TASK}/:id`}
      component={withRestriction(EditTaskForm)}
    />
    {/* <Route path={IMPORT_TASKS} component={withRestriction(ImportTasksForm)} /> */}

    <Route path='/calendar' component={Timetable} />

    <Route
      path='*'
      render={() => (
        <NotPermitted
          message='Could not find the requested page'
          returnUrl='/'
        />
      )}
    />
  </Switch>
);

export default Routes;
