import React, { useState } from 'react';
import { Contact } from '../../interfaces/entities/Contact';
import { Typography, Space, Tooltip, Button, Drawer } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import TableDrawer from '../table/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAuthToken,
  selectAuthenticatedUser,
} from '../../redux/selectors/Auth';
import { makeRequest } from '../../utility/api';
import { showToast, showDeleteWarning } from '../../utility/notification/toast';
import ContactActions from '../../redux/actions/Contact';
import { Link } from 'react-router-dom';
import { EDIT_CONTACT } from '../../utility/routing/routes';
import { User } from '../../interfaces/entities/User';
import { userIsGrantedPrivilege } from '../../utility/user/privileges';

interface ActionColumnProps {
  record: Contact;
}

const { Paragraph } = Typography;

const ViewAllContactsActionColumn: React.FC<ActionColumnProps> = ({
  record,
}) => {
  const [shouldShowDrawer, setShouldShowDrawer] = useState<boolean>(false);
  const [shouldShowChildDrawer, setShouldShowChildDrawer] = useState<boolean>(
    false
  );
  const dispatch = useDispatch();
  const authorization = useSelector(selectAuthToken);
  const phoneNumber = `${record.countryCode}${record.phoneNumber}`;
  const user = useSelector(selectAuthenticatedUser) as User;

  const deleteContact = () => {
    const { id } = record;
    const confirmCallback = () => {
      makeRequest({
        url: `contact/delete/${id}`,
        type: 'DELETE',
        successCallback: (response) => {
          showToast(response.message);
          dispatch(ContactActions.remove(id));
        },
        authorization,
      });
    };
    showDeleteWarning(`Delete ${record.name}?`, confirmCallback);
  };

  const openDrawer = () => {
    setShouldShowDrawer(true);
  };

  const closeDrawer = () => {
    setShouldShowDrawer(false);
  };

  const drawerContent = (
    <>
      {record.phoneNumber && (
        <>
          <p style={{ marginTop: '10px' }}>
            <b>Phone Number</b>
          </p>
          <Paragraph copyable={{ text: phoneNumber }}>
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </Paragraph>
        </>
      )}
      {record.email && (
        <>
          <p style={{ marginTop: '10px' }}>
            <b>Email Address</b>
          </p>
          <Paragraph copyable={{ text: record.email }}>
            <a href={`mailto:${record.email}`}>{`${record.email}`}</a>
          </Paragraph>
        </>
      )}
      {record.court && (
        <>
          <p style={{ marginTop: '10px' }}>
            <b>Associated Court</b>
          </p>
          <p>{`${record.court}`}</p>
        </>
      )}
      {record.client && (
        <>
          <p style={{ marginTop: '10px' }}>
            <b>Associated Client</b>
          </p>
          <p>{`${record.client.name}`}</p>
          <Button
            type='link'
            onClick={() => {
              setShouldShowChildDrawer(true);
            }}
          >
            View Client
          </Button>
          <Drawer
            title={record.client.name}
            width={320}
            closable={false}
            onClose={() => {
              setShouldShowChildDrawer(false);
            }}
            visible={shouldShowChildDrawer}
          >
            <>
              <p style={{ marginTop: '10px' }}>
                <b>Client Type</b>
              </p>
              <p>{`${
                record.client.type === 'IND' ? 'Individual' : 'Corporate'
              }`}</p>
              <p style={{ marginTop: '10px' }}>
                <b>Unique Reference</b>
              </p>
              <p>{`${record.client.reference}`}</p>
              {record.client.dateOfBirth && (
                <>
                  <p style={{ marginTop: '10px' }}>
                    <b>Date of Birth</b>
                  </p>
                  <p>{`${record.client.dateOfBirth}`}</p>
                </>
              )}
              {record.client.address && (
                <>
                  <p style={{ marginTop: '10px' }}>
                    <b>Address</b>
                  </p>
                  <p>{`${record.client.address}`}</p>
                </>
              )}
              {record.phoneNumber && (
                <>
                  <p style={{ marginTop: '10px' }}>
                    <b>Phone Number</b>
                  </p>
                  <Paragraph copyable={{ text: phoneNumber }}>
                    <a href={`tel:${phoneNumber}`}>{`${phoneNumber}`}</a>
                  </Paragraph>
                </>
              )}
              {record.client.emailAddress && (
                <>
                  <p style={{ marginTop: '10px' }}>
                    <b>Email Address</b>
                  </p>
                  <Paragraph copyable={{ text: record.client.emailAddress }}>
                    <a
                      href={`mailto:${record.client.emailAddress}`}
                    >{`${record.client.emailAddress}`}</a>
                  </Paragraph>
                </>
              )}
              <p style={{ marginTop: '10px' }}>
                <b>Organization</b>
              </p>
              <p>{`${record.organization.name}`}</p>
            </>
          </Drawer>
        </>
      )}
      {record.case && (
        <>
          <p style={{ marginTop: '10px' }}>
            <b>Associated Case</b>
          </p>
          <p>{`${record.case.suitName}`}</p>
        </>
      )}
      {record.notes && (
        <>
          <p style={{ marginTop: '10px' }}>
            <b>Notes</b>
          </p>
          <p>{`${record.notes}`}</p>
        </>
      )}
    </>
  );
  return (
    <>
      <Space size='middle'>
        <Tooltip placement='bottom' title={'View Contact'}>
          <Button type='text' onClick={openDrawer}>
            <EyeOutlined />
          </Button>
        </Tooltip>
        <Tooltip placement='bottom' title={'Edit Contact'}>
          <span>
            <Link to={`${EDIT_CONTACT}/${record.id}`}>
              <EditOutlined />
            </Link>
          </span>
        </Tooltip>
        {!(
          userIsGrantedPrivilege(user, 'assistant') ||
          userIsGrantedPrivilege(user, 'default')
        ) && (
          <Tooltip placement='bottom' title='Delete Contact'>
            <Button
              type='text'
              onClick={() => {
                deleteContact();
              }}
            >
              <DeleteOutlined />
            </Button>
          </Tooltip>
        )}
      </Space>
      <TableDrawer
        title={`${record.name}`}
        shouldShowDrawer={shouldShowDrawer}
        closeDrawer={closeDrawer}
        content={drawerContent}
        width={520}
      />
    </>
  );
};

export default ViewAllContactsActionColumn;
