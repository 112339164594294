import { BaseObject } from '../../interfaces/entities/Base';
import { GenericState } from '../../interfaces/redux/state/Generic';

export const selectAllItems = <O extends BaseObject>(
  genericState: GenericState<O>
): O[] => Object.values(genericState.byIds);

export const selectItem = <O extends BaseObject>(
  id: O['id'],
  genericState: GenericState<O>
): O | null => genericState.byIds[id];

export const selectCount = <O extends BaseObject>(
  genericState: GenericState<O>
): number => genericState.allIds.length;
