import React, { useState } from 'react';
import { Contact } from '../../interfaces/entities/Contact';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAuthToken,
  selectAuthenticatedUser,
} from '../../redux/selectors/Auth';
import { getObjectId } from '../../utility/array';
import { selectAllClients } from '../../redux/selectors/Client';
import { selectAllCases } from '../../redux/selectors/Litigation';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import { User } from '../../interfaces/entities/User';
import { Organization } from '../../interfaces/entities/Organization';
import ContactActions from '../../redux/actions/Contact';
import { Store } from 'antd/lib/form/interface';
import { makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import ContactFormContainer from './BaseContainer';
import {
  formatPhoneNumber,
  formatSelectedCountryCode,
} from '../../utility/constants/phoneNumber';

interface Props {
  contact?: Contact;
  successCallback: () => void;
}

const BaseContactForm: React.FC<Props> = ({
  contact = null,
  successCallback,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const authorization = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const clients = useSelector(selectAllClients);
  const savedCases = useSelector(selectAllCases);
  const organizations = useSelector(selectAllOrganizations);
  const userOrganization = (useSelector(selectAuthenticatedUser) as User)
    .organization as Organization;

  const formatRequest = (input: any) => {
    if (contact) {
      input.id = contact.id;
    }
    if (input.client) {
      input.client = getObjectId(input.client, clients);
    }
    if (input.case) {
      input.case = getObjectId(input.case, savedCases, 'suitNumber');
    }

    if (input.organization) {
      input.organization = getObjectId(input.organization, organizations);
    } else {
      input.organization = userOrganization.id;
    }
    if (input.countryCode) {
      const newCode = formatSelectedCountryCode(input.countryCode);
      input.countryCode = newCode;
    }
    if (input.phoneNumber) {
      const formattedPhoneNumber = formatPhoneNumber(input.phoneNumber);
      input.phoneNumber = formattedPhoneNumber;
    }
    return input;
  };

  const updateContactState = (data: Contact) => {
    dispatch(contact ? ContactActions.edit(data) : ContactActions.add(data));
  };

  const handleFinish = (values: Store) => {
    setIsLoading(true);
    values = formatRequest(values);
    const additionalRoute = contact ? `edit/${contact.id}` : 'add';

    makeRequest({
      url: `contact/${additionalRoute}`,
      values,
      type: 'POST',
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateContactState(data);
        successCallback();
      },
      authorization,
      failureCallback: () => {
        setIsLoading(false);
      },
    });
  };
  return <ContactFormContainer {...{ isLoading, handleFinish, contact }} />;
};

export default BaseContactForm;
