import React from 'react';
import Form from 'antd/lib/form/Form';
import { User } from '../../interfaces/entities/User';
import { Store } from 'antd/lib/form/interface';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from '../../redux/selectors/Auth';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import AutoCompleteInput from '../form/AutoCompleteInput';
import { Organization } from '../../interfaces/entities/Organization';
import TextInput from '../form/TextInput';
import EmailInput from '../form/EmailInput';
import PhoneNumberInput from '../form/PhoneNumberInput';
import DropdownInput from '../form/DropdownInput';
import SubmitButton from '../form/SubmitButton';
import {
  getUserPrivilegeOptions,
  getPrivilege,
} from '../../utility/user/privileges';

interface ContainerProps {
  user: User | null;
  isLoading: boolean;
  handleFinish: (values: Store) => void;
  canEditPrivileges: boolean;
}

const BaseUserFormContainer: React.FC<ContainerProps> = ({
  user,
  isLoading,
  handleFinish,
  canEditPrivileges,
}) => {
  const userIsSuperAdmin = (useSelector(selectAuthenticatedUser) as User)
    .isSuperAdmin;

  const organizations = useSelector(selectAllOrganizations).map(({ name }) => ({
    value: name,
  }));

  const userOrganization = user?.organization as Organization;

  return (
    <Form onFinish={handleFinish}>
      {userIsSuperAdmin && (
        <AutoCompleteInput
          name='organization'
          label='Organization Name'
          options={organizations}
          initialValue={userOrganization?.name}
          placeholder='User Organization'
          required={true}
          requiredMessage={'Please select an organization'}
        />
      )}
      <TextInput
        label='First Name'
        initialValue={user?.firstName}
        name='firstName'
        message="Please enter the user's first name"
        placeholder='First Name'
      />
      <TextInput
        label='Last Name'
        initialValue={user?.lastName}
        name='lastName'
        message="Please enter the user's last name"
        placeholder='Last Name'
      />
      {(!user || userIsSuperAdmin) && (
        <EmailInput
          label='Email Address'
          initialValue={user?.emailAddress}
          name='emailAddress'
          requiredMessage="Please enter the user's last name"
          placeholder='Email Address'
        />
      )}
      <PhoneNumberInput
        label='Phone Number'
        initialValue={user?.phoneNumber}
        initialCountryCodeValue={user?.countryCode}
      />
      {canEditPrivileges && (
        <DropdownInput
          label='User Privilege'
          name='privilege'
          options={getUserPrivilegeOptions(userIsSuperAdmin)}
          placeholder='User Privilege'
          initialValue={getPrivilege(user?.privilege)}
        />
      )}
      <SubmitButton
        buttonText={user ? 'Save' : 'Submit'}
        isLoading={isLoading}
      />
    </Form>
  );
};

export default BaseUserFormContainer;
