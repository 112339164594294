import React, { useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import {
  selectAuthenticatedUser,
  selectAuthToken,
} from '../../redux/selectors/Auth';
import { useSelector, useDispatch } from 'react-redux';
import { getObjectId } from '../../utility/array';
import { User } from '../../interfaces/entities/User';
import { convertMomentDateToString } from '../../utility/date';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import { selectAllClients } from '../../redux/selectors/Client';
import { selectAllUsers } from '../../redux/selectors/User';
import { makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import CaseBaseFormContainer from './BaseContainer';
import { Appeal } from '../../interfaces/entities/Appeal';
import { selectAllCases } from '../../redux/selectors/Litigation';
import AppealActions from '../../redux/actions/Appeal';

interface Props {
  appeal?: Appeal;
  successCallback: () => void;
}

const BaseAppealForm: React.FC<Props> = ({ appeal, successCallback }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authorization = useSelector(selectAuthToken);
  const authenticatedUser = useSelector(selectAuthenticatedUser) as User;
  const organizations = useSelector(selectAllOrganizations);
  const cases = useSelector(selectAllCases);
  const users = useSelector(selectAllUsers);
  const clients = useSelector(selectAllClients);
  const dispatch = useDispatch();

  const formatRequest = (values: Store) => {
    const {
      organization,
      litigation,
      client,
      filingDate,
      users: assignedUsers,
    } = values;
    if (appeal) values.id = appeal.id;
    values.organization = authenticatedUser.isSuperAdmin
      ? getObjectId(organization, organizations)
      : authenticatedUser.organization?.id;
    values.litigation = getObjectId(litigation, cases, 'suitNumber');
    values.client = getObjectId(client, clients);
    values.users = assignedUsers
      ? assignedUsers.map((user: string) => {
          return {
            id: getObjectId(user, users),
          };
        })
      : null;
    values.filingDate = convertMomentDateToString(filingDate);
    return values;
  };

  const updateAppealState = (data: Appeal) => {
    dispatch(appeal ? AppealActions.edit(data) : AppealActions.add(data));
  };

  const handleFinish = (values: Store) => {
    values = formatRequest(values);
    setIsLoading(true);
    makeRequest({
      url: `appeal/${appeal ? 'edit' : 'add'}`,
      values,
      type: 'POST',
      authorization,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateAppealState(data);
        setIsLoading(false);
        successCallback();
      },
      failureCallback: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <CaseBaseFormContainer
      {...{
        handleFinish,
        appeal,
        cases,
        authenticatedUser,
        authorization,
        organizations,
        clients,
        users,
        isLoading,
      }}
    />
  );
};

export default BaseAppealForm;
