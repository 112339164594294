import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { selectAllUsers } from '../../redux/selectors/User';
import { User } from '../../interfaces/entities/User';
import UserActions from '../../redux/actions/User';
import { showToast } from '../../utility/notification/toast';
import { Button, Tag } from 'antd';
import ViewAllUsersActionColumn from './ViewAllAction';
import TableView from '../table/ViewAll';
import { makeDownloadRequest, makeRequest } from '../../utility/api';

const ViewAllUsers: React.FC = () => {
  const authorization = useSelector(selectAuthToken);
  const users = useSelector(selectAllUsers).map((user) => {
    if (user.organization) {
      user.organizationName = user.organization.name;
    } else {
      user.organizationName = '';
    }
    return user;
  });

  const dispatch = useDispatch();

  const updateIsEnabled = ({ id, isEnabled }: User) => {
    const values = {
      id,
      isEnabled: !isEnabled,
    };
    makeRequest({
      url: 'admin/user/enabled/status/update',
      values,
      successCallback: (response) => {
        showToast(response.message);
        dispatch(UserActions.edit(response.data));
      },
      authorization,
    });
  };

  const downloadReport = () => {
    makeDownloadRequest({
      url: '/organization/report/download',
      type: 'GET',
      mime: 'application/vnd.ms-excel',
      filename: 'User Schedule.xlsx',
      authorization,
    });
  };

  const bulkUpdateUsers = (userArray: User['id'][]) => {
    const values = {
      users: userArray.map((user) => ({
        id: user,
        isEnabled: !users.find(({ id }) => id === user)?.isEnabled,
      })),
    };
    makeRequest({
      url: 'admin/users/enabled/status/update',
      values,
      authorization,
      successCallback: (response) => {
        showToast(response.message);
        dispatch(UserActions.bulkEdit(response.data));
      },
    });
  };

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      shouldSearch: true,
      shouldSort: true,
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
      indexName: 'email address',
      shouldSearch: true,
      shouldSort: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      indexName: 'phone number',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Organization',
      dataIndex: 'organizationName',
      indexName: 'organization',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      shouldSearch: true,
      shouldSort: true,
      render: (text: any, record: User) => (
        <Tag color={record.isEnabled ? 'green' : 'volcano'}>
          {record.status}
        </Tag>
      ),
    },
  ];
  return (
    <TableView
      actionColumn={{
        render: (text: any, record: User) => (
          <ViewAllUsersActionColumn {...{ record, updateIsEnabled }} />
        ),
      }}
      additionalMenuItem={
        <Button type='primary' ghost onClick={downloadReport}>
          Export Users
        </Button>
      }
      columns={tableColumns}
      data={users}
      bulkAction={bulkUpdateUsers}
      resourceType='user'
      bulkActionText='Enable/Disable Selection'
    />
  );
};

export default ViewAllUsers;
