import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../../../redux/selectors/Auth';
import { makeDownloadRequest, makeRequest } from '../../../utility/api';
import { Button, Card, Descriptions, Table, Tabs } from 'antd';
import { capitalize } from '../../../utility/stringFormatter';
import { selectAllUsers } from '../../../redux/selectors/User';
import ViewAllUsersActionColumn from '../../user/ViewAllAction';
import { User } from '../../../interfaces/entities/User';

const { TabPane } = Tabs;

const UserReportView = () => {

  const [summary, setSummary] = useState<any>({});
  const [sortedUsers, setSortedUsers] = useState<any>([]);
  const authorization = useSelector(selectAuthToken);
  const users = useSelector(selectAllUsers);

  const sortedUserTableColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Role',
      dataIndex: 'role',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, record: any) => {
        const selectedCase = users.find(({ id }) => id === record.id);
        return <ViewAllUsersActionColumn record={selectedCase as User} />;
      }
    }
  ];
  const downloadReport = () => {
    makeDownloadRequest({
      url: 'admin/reports/user/download',
      type: 'GET',
      mime: 'application/vnd.ms-excel',
      filename: 'User Report.xlsx',
      authorization
    });
  };

  const downloadButton = (
    <Button type='primary' ghost onClick={downloadReport}>
      Download
    </Button>
  );

  useEffect(() => {
    makeRequest({
      url: 'admin/reports/user',
      type: 'GET',
      successCallback: response => {
        const { summary, sortedUsers } = response.data;

        setSummary(summary);
        setSortedUsers(sortedUsers);
      },
      authorization
    });
  }, [authorization]);

  return (<Card>
      <Tabs
        defaultActiveKey='1'
        size='middle'
        tabBarExtraContent={downloadButton}
      >
        <TabPane tab='Summary' key='1'>
          <Descriptions bordered>
            {
              Object.keys(summary).map((status, index) => {
                return (
                  <Descriptions.Item
                    label={`${capitalize(status)} Users`}
                    span={3}
                    key={index}
                  >
                    {summary[status]}
                  </Descriptions.Item>
                );
              })
            }
          </Descriptions>
        </TabPane>
        {
          Object.keys(sortedUsers).map((status, index) => {
            return (
              <TabPane tab={`${capitalize(status)} Users`} key={2 + index}>
                <Table
                  bordered
                  rowKey='id'
                  columns={sortedUserTableColumn}
                  dataSource={sortedUsers[status]}
                />
              </TabPane>
            );
          })
        }
      </Tabs>
    </Card>
  );

};

export default UserReportView;