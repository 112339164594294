import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import { Organization } from '../../interfaces/entities/Organization';
import { Tag } from 'antd';
import TableView from '../table/ViewAll';
import ViewAllOrganizationsActionColumn from './ViewAllAction';
import { showToast } from '../../utility/notification/toast';
import OrganizationActions from '../../redux/actions/Organization';
import { makeRequest } from '../../utility/api';

const ViewAllOrganizations: React.FC = () => {
  const authorization = useSelector(selectAuthToken);
  const organizations = useSelector(selectAllOrganizations);
  const dispatch = useDispatch();

  const bulkUpdateOrganizations = (organizationArray: Organization['id'][]) => {
    const values = {
      organizations: organizationArray.map((organization) => ({
        id: organization,
        isEnabled: !(
          organizations.find(({ id }) => id === organization)?.status ===
          'Enabled'
        ),
      })),
    };
    makeRequest({
      url: 'organization/bulk/status/enabled/update',
      values,
      successCallback: (response) => {
        showToast(response.message);
        dispatch(OrganizationActions.bulkEdit(response.data));
      },
      authorization,
    });
  };

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      shouldSort: true,
      shouldSearch: true,
      width: 200,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      indexName: 'phone number',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
      indexName: 'email address',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      shouldSearch: true,
      shouldSort: true,
      render: (text: any, record: Organization) => (
        <Tag color={record.status === 'Enabled' ? 'green' : 'volcano'}>
          {record.status}
        </Tag>
      ),
    },
  ];

  return (
    <TableView
      actionColumn={{
        render: (text: any, record: any) => (
          <ViewAllOrganizationsActionColumn record={record} />
        ),
      }}
      columns={tableColumns}
      data={organizations}
      bulkAction={bulkUpdateOrganizations}
      resourceType={'organization'}
      bulkActionText='Enable/Disable Selection'
    />
  );
};

export default ViewAllOrganizations;
