import React, { useState } from 'react';
import { Task } from '../../interfaces/entities/Task';
import { selectAllCases } from '../../redux/selectors/Litigation';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthenticatedUser, selectAuthToken } from '../../redux/selectors/Auth';
import { User } from '../../interfaces/entities/User';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import { selectAllUsers } from '../../redux/selectors/User';
import { Store } from 'antd/lib/form/interface';
import { getObjectId } from '../../utility/array';
import { Organization } from '../../interfaces/entities/Organization';
import { convertMomentDateToString } from '../../utility/date';
import TaskActions from '../../redux/actions/Task';
import { makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import TaskBaseFormContainer from './BaseContainer';
import useFileInput from '../../hooks/useFileInput';

interface Props {
  task?: Task;
  successCallback: () => void;
}

const BaseTaskForm: React.FC<Props> = ({ task, successCallback }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authorization = useSelector(selectAuthToken);
  const authenticatedUser = useSelector(selectAuthenticatedUser) as User;
  const organizations = useSelector(selectAllOrganizations);
  const users = useSelector(selectAllUsers);
  const cases = useSelector(selectAllCases);
  const dispatch = useDispatch();
  const [uploadMedium,
    updateUploadMedium,
    removeSelectedFile,
    addSelectedFile,
    formatFilesInRequest
  ] = useFileInput();

  const formatRequest = (values: Store) => {
    const { organization, assignedUsers, dueDate, additionalInformation } = values;
    if (task) values.id = task.id;
    values.litigation = getObjectId(values.litigation, cases, 'suitName');
    values.organization = authenticatedUser.isSuperAdmin
      ? getObjectId(organization, organizations)
      : (authenticatedUser.organization as Organization).id;
    values.assignedUsers = assignedUsers.filter(Boolean).map((user: string) => getObjectId(user, users));
    values.dueDate = convertMomentDateToString(dueDate);
    if (typeof additionalInformation === 'undefined') {
      values.additionalInformation = '';
    }
    return formatFilesInRequest(values);
  };

  const updateTaskState = (data: Task) => {
    dispatch(task ? TaskActions.edit(data) : TaskActions.add(data));
  };

  const handleFinish = (values: Store) => {
    values = formatRequest(values);
    setIsLoading(true);
    makeRequest({
      url: `task/${task ? 'edit' : 'add'}`,
      values,
      type: 'POST',
      authorization,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateTaskState(data);
        setIsLoading(false);
        successCallback();
      },
      failureCallback: () => {
        setIsLoading(false);
      }
    });
  };

  return (
    <TaskBaseFormContainer
      {...{
        isLoading,
        handleFinish,
        task,
        organizations,
        cases,
        users,
        authenticatedUser,
        uploadMedium,
        updateUploadMedium,
        removeSelectedFile,
        addSelectedFile
      }}
    />
  );
};

export default BaseTaskForm;
