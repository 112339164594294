import { shuffle } from '../array';

export const COURT_CATEGORIES: string[] = [
  'Magistrate Court',
  'State High Court',
  'Federal High Court',
  'Court of Appeal',
  'National Industrial Court',
  'Supreme Court of Nigeria',
];

export const APPEAL_COURT_CATEGORIES: string[] = [
  'Court of Appeal',
  'Sharia Court of Appeal',
  'Customary Court of Appeal',
  'Supreme Court of Nigeria',
];

export const NIGERIAN_STATES: string[] = [
  'Abia',
  'Abuja - FCT',
  'Adamawa',
  'Anambra',
  'Akwa Ibom',
  'Bauchi',
  'Bayelsa',
  'Benue',
  'Borno',
  'Cross River',
  'Delta',
  'Ebonyi',
  'Enugu',
  'Edo',
  'Ekiti',
  'Gombe',
  'Imo',
  'Jigawa',
  'Kaduna',
  'Kano',
  'Katsina',
  'Kebbi',
  'Kogi',
  'Kwara',
  'Lagos',
  'Nasarawa',
  'Niger',
  'Ogun',
  'Ondo',
  'Osun',
  'Oyo',
  'Plateau',
  'Rivers',
  'Sokoto',
  'Taraba',
  'Yobe',
  'Zamfara',
];

const COLOURS: string[] = [
  '#3300CC',
  '#FF0000',
  '#00CC33',
  '#660099',
  '#FF6600',
  '#000033',
  '#990033',
];

export const getColours = (): string[] => shuffle(COLOURS).slice(0, 7);

export const TOKEN_REFRESH_INTERVAL: number = 3000 * 1000;

export const USER_ACTIVITY_EVENTS: string[] = [
  'mousedown',
  'mousemove',
  'keypress',
  'scroll',
  'touchstart',
];
