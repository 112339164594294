import { AppState } from '../../interfaces/redux/state/App';
import { AuthState } from '../../interfaces/redux/state/Auth';
import { User } from '../../interfaces/entities/User';
import { Organization } from '../../interfaces/entities/Organization';

export const selectAuthState = (appState: AppState): AuthState => appState.auth;

export const selectAuthToken = (appState: AppState): string =>
  selectAuthState(appState).token;

export const selectRefreshToken = (appState: AppState): string =>
  selectAuthState(appState).refreshToken;

export const selectAuthenticatedUser = (appState: AppState): User | {} =>
  selectAuthState(appState).user;

export const selectAuthenticatedUserOrganization = (
  appState: AppState
): Organization | null => (selectAuthState(appState).user as User).organization;
export const selectLastUpdateTime = (appState: AppState): string =>
  selectAuthState(appState).lastUpdatedTime;

export const selectIsAuthenticated = (appState: AppState): boolean =>
  !!selectAuthToken(appState);
