import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllClients } from '../../redux/selectors/Client';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { Client } from '../../interfaces/entities/Client';
import { showDeleteWarning, showToast } from '../../utility/notification/toast';
import { makeRequest, makeDownloadRequest } from '../../utility/api';
import ClientActions from '../../redux/actions/Client';
import TableView from '../table/ViewAll';
import ViewAllClientsActionColumn from './ViewAllAction';
import Button from 'antd/lib/button';

const ViewAllClients: React.FC = () => {
  const clients = useSelector(selectAllClients);
  const authorization = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const downloadReport = () => {
    makeDownloadRequest({
      url: '/client/export',
      type: 'GET',
      mime: 'application/vnd.ms-excel',
      filename: 'Client Schedule.xlsx',
      authorization,
    });
  };

  const bulkDeleteClients = (selectedClientIds: Client['id'][]) => {
    const confirmCallback = () => {
      makeRequest({
        url: 'client/delete/bulk',
        values: {
          clients: selectedClientIds.map((input) => ({
            id: +input,
          })),
        },
        type: 'DELETE',
        successCallback: (response) => {
          showToast(response.message);
          dispatch(ClientActions.removeMany(selectedClientIds));
        },
        authorization,
      });
    };
    showDeleteWarning(`Delete Selected Clients?`, confirmCallback);
  };

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      indexName: 'phone number',
      shouldSearch: true,
      shouldSort: true,
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
      shouldSearch: true,
      shouldSort: false,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      shouldSearch: true,
      shouldSort: true,
    },
    {
      title: 'Unique Identifier',
      dataIndex: 'reference',
      shouldSort: true,
      shouldSearch: true,
    },
  ];

  return (
    <TableView
      actionColumn={{
        render: (text: any, record: Client) => (
          <ViewAllClientsActionColumn record={record} />
        ),
      }}
      additionalMenuItem={
        <Button type='primary' ghost onClick={downloadReport}>
          Export Clients
        </Button>
      }
      columns={tableColumns}
      data={clients}
      bulkAction={bulkDeleteClients}
      resourceType={'client'}
      bulkActionText='Delete Selection'
    />
  );
};

export default ViewAllClients;
