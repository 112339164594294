import React from 'react';
import { Button, Drawer, Space } from 'antd';
import { useSelector } from 'react-redux';
import { selectUnreadNotifications } from '../../redux/selectors/Notification';
import NotificationItem from './Item';

interface NotificationDrawerProps {
  showNotificationDrawer: boolean;
  closeDrawer: () => void;
}

const NotificationDrawer: React.FC<NotificationDrawerProps> = ({ showNotificationDrawer, closeDrawer }) => {

  const unreadNotifications = useSelector(selectUnreadNotifications);

  return (
    <Drawer
      title='Notifications'
      placement={'right'}
      closable={false}
      onClose={closeDrawer}
      visible={showNotificationDrawer}
      width={736}
      // footer={
      //   <Space>
      //     <Button type='primary' onClick={closeDrawer}>
      //       View All Notifications
      //     </Button>
      //   </Space>
      // }
    >
      {
        unreadNotifications.map(unreadNotification => (
          <NotificationItem
            key={unreadNotification.id}
            notification={unreadNotification}
          />
        ))
      }
    </Drawer>
  );

};

export default NotificationDrawer;