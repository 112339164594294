import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllTasks } from '../../redux/selectors/Task';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { selectAllPersonalTasks } from '../../redux/selectors/PersonalTask';
import PersonalTaskActions from '../../redux/actions/PersonalTask';
import TaskActions from '../../redux/actions/Task';
import { Task, TaskStatus } from '../../interfaces/entities/Task';
import { makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import TableView from '../table/ViewAll';
import ViewAllTasksActionColumn from './ViewAllAction';
import { Tag } from 'antd';
import { getTagColour } from '../../utility/task/tag';
import { User } from '../../interfaces/entities/User';

interface Props {
  showPersonalTasks?: boolean;
}

const ViewAllTasks: React.FC<Props> = ({ showPersonalTasks }) => {
  const tasks = useSelector(
    showPersonalTasks ? selectAllPersonalTasks : selectAllTasks
  );
  const authorization = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const updateTaskState = (data: Task[]) => {
    dispatch(PersonalTaskActions.addMany(data));
    dispatch(TaskActions.bulkUpdate(data));
  };

  const assignMultipleCasesToSelf = (taskArray: Task['id'][]) => {
    const values = {
      tasks: taskArray.map((id) => ({ id: +id })),
    };
    makeRequest({
      url: 'task/assign/bulk',
      type: 'POST',
      values,
      authorization,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateTaskState(data);
      },
    });
  };

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      shouldSearch: true,
      shouldSort: true,
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      indexName: 'due date',
      shouldSearch: false,
      shouldSort: true,
    },
    {
      title: 'Assignee(s)',
      dataIndex: 'assignedUsers',
      shouldSearch: true,
      shouldSort: false,
      render:(text: any, record: Task) => <ol>{record.assignedUsers.map(({ name }) => <li>{name}</li>)}</ol>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      shouldSearch: true,
      shouldSort: true,
      render: (text: any, record: Task) => (
        <Tag color={getTagColour(record.status as TaskStatus)}>
          {record.status}
        </Tag>
      ),
    },
  ];

  return (
    <TableView
      actionColumn={{
        render: (text: any, record: Task) => (
          <ViewAllTasksActionColumn record={record} />
        ),
      }}
      resourceType='task'
      data={tasks}
      columns={tableColumns}
      bulkAction={assignMultipleCasesToSelf}
      bulkActionText='Assign To Self'
    />
  );
};

export default ViewAllTasks;
