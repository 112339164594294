import React from 'react';
import { Form, AutoComplete, Input, Select } from 'antd';
import { AutoCompleteOption } from '../../interfaces/components/form/AutoComplete';
import { DownOutlined } from '@ant-design/icons';

interface AutoCompleteInputProps {
  required?: boolean;
  placeholder?: string;
  name: string;
  requiredMessage?: string;
  label: string;
  initialValue?: string;
  options: AutoCompleteOption[];
  onSelect?: (value: string) => void;
}
const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
  required = true,
  placeholder = 'Please select an option',
  name,
  requiredMessage = 'Please select an option',
  label,
  initialValue,
  options,
  onSelect,
}) => {
  const sortedOptions = options.sort((optionA, optionB) =>
    optionA.value.localeCompare(optionB.value)
  );
  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      label={label}
      rules={[{ required, message: requiredMessage }]}
    >
      <AutoComplete
        placeholder={placeholder}
        onSelect={onSelect}
        options={sortedOptions}
        filterOption={(inputValue, option) =>
          option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      />
    </Form.Item>
  );
};

export default AutoCompleteInput;
