import React, { useState } from 'react';
import readXlsxFile from 'read-excel-file';
import { Button, Upload, Card, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import DeleteWarning from '../utility/DeleteWarning';
import { getObjectId } from '../../utility/array';
import {
  FileExcelOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  FileAddOutlined,
  FileFilled,
} from '@ant-design/icons';
import { showToast } from '../../utility/notification/toast';
import {
  selectAuthenticatedUser,
  selectAuthToken,
  selectAuthenticatedUserOrganization,
} from '../../redux/selectors/Auth';
import { User } from '../../interfaces/entities/User';
import Form from 'antd/lib/form/Form';
import AutoCompleteInput from './AutoCompleteInput';
import { ImportedItem } from '../../interfaces/components/form/FileUpload';
import { makeRequest } from '../../utility/api';
import { Organization } from '../../interfaces/entities/Organization';
import { Action } from 'redux';

interface TableColumn {
  title: string;
  dataIndex?: string;
  render?: (text: any, record: ImportedItem) => JSX.Element;
}

interface FileUploadProps {
  formatFileUpload: (importedItems: any[]) => ImportedItem[];
  tableColumns: TableColumn[];
  importedItemName: string;
  successCallback: () => void;
  successAction: (data: any) => Action;
  url: string;
  preFormatRequest?: (values: any) => object;
}

const ButtonGroup = Button.Group;

const ImportInput: React.FC<FileUploadProps> = ({
  formatFileUpload,
  tableColumns,
  importedItemName,
  successCallback,
  url,
  successAction,
  preFormatRequest: preFormat,
}) => {
  const dispatch = useDispatch();
  const [importedItems, setImportedItems] = useState<ImportedItem[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const organizations = useSelector(selectAllOrganizations);
  const organizationOptions = organizations.map(({ name }) => ({
    value: name,
  }));
  const authorization = useSelector(selectAuthToken);
  const userIsSuperAdmin = (useSelector(selectAuthenticatedUser) as User)
    .isSuperAdmin;
  const authenticatedOrganization = useSelector(
    selectAuthenticatedUserOrganization
  ) as Organization;
  const deleteUpload = ({ id }: ImportedItem) => {
    setImportedItems((importedItems) =>
      [...importedItems].filter(({ id: itemId }) => +id !== +itemId)
    );
  };

  const deleteAction = {
    title: '',
    render: (text: string, record: ImportedItem) => (
      <DeleteWarning
        onConfirm={() => {
          deleteUpload(record);
        }}
      />
    ),
  };

  const uploadItems = () => {
    const organization = userIsSuperAdmin
      ? selectedOrganization
      : (authenticatedOrganization as Organization).id;
    let values: any = { organization };
    values[`${importedItemName.toLowerCase()}`] = importedItems;
    if (preFormat) {
      values = preFormat(values);
    }
    makeRequest({
      url,
      values,

      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        dispatch(successAction(data));
        successCallback();
      },
      failureCallback: () => {
        setIsLoading(false);
      },
      authorization,
    });
  };

  const updateSelectedOrganization = (organization: string) => {
    setSelectedOrganization(getObjectId(organization, organizations));
  };

  const handleFileUpload = ({ file }: any) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (event) => {
      const bstr = event.target?.result;
      readXlsxFile(bstr).then((result: any[]) => {
        setImportedItems(formatFileUpload(result.slice(1)));
      });
    };
    fileReader.readAsBinaryString(file);
  };

  const hasUploadedItems = importedItems.length !== 0;

  const handleSubmit = () => {
    if (!hasUploadedItems) {
      showToast('Empty Schedule Provided', false);
      return;
    }

    if (userIsSuperAdmin && !selectedOrganization) {
      showToast('Please select an organization', false);
      return;
    }
    setIsLoading(true);
    uploadItems();
  };

  const clearUpload = () => {
    setImportedItems([]);
  };

  return (
    <Card title={`Import ${importedItemName}`} bordered>
      <Form>
        {userIsSuperAdmin && (
          <AutoCompleteInput
            name='organization'
            label='Organization'
            placeholder='Organization'
            options={organizationOptions}
            onSelect={(value) => {
              updateSelectedOrganization(value);
            }}
          />
        )}
        {importedItems.length > 0 ? (
          <Table
            dataSource={importedItems}
            columns={[...tableColumns, deleteAction]}
            rowKey='id'
          />
        ) : (
          <>
            <Upload.Dragger
              name='files'
              multiple={false}
              accept='.xlsx'
              customRequest={handleFileUpload}
            >
              <p className='ant-upload-drag-icon'>
                <FileFilled />
              </p>
              <p className='ant-upload-text'>
                Click or drag file to this area to upload
              </p>
            </Upload.Dragger>
            <a
              href={`${
                process.env.REACT_APP_API_URL
              }/schedule/${importedItemName.toLowerCase()}`}
            >
              Download Template
            </a>
          </>
        )}
      </Form>
      <div style={{ width: '60%', margin: 'auto' }}>
        <ButtonGroup>
          <Button
            type='primary'
            danger
            style={{ width: '48%', marginRight: '0.5em' }}
            onClick={clearUpload}
          >
            <CaretLeftOutlined />
            Cancel
          </Button>
          <Button
            type='primary'
            style={{ width: '48%' }}
            onClick={handleSubmit}
            loading={isLoading}
          >
            Upload
            <CaretRightOutlined />
          </Button>
        </ButtonGroup>
      </div>
    </Card>
  );
};

export default ImportInput;
