import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAllClients } from '../../redux/selectors/Client';
import { selectAllCases } from '../../redux/selectors/Litigation';
import { getObjectId } from '../../utility/array';
import { Redirect } from 'react-router-dom';
import { VIEW_ALL_CONTACTS } from '../../utility/routing/routes';
import ImportInput from '../form/ImportInput';
import Contact from '../../redux/actions/Contact';
import { ImportedItem } from '../../interfaces/components/form/FileUpload';

const ImportContactsForm: React.FC = () => {
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const clients = useSelector(selectAllClients);
  const cases = useSelector(selectAllCases);

  const preFormatContact = (input: any) => {
    const { client, case: litigation } = input;
    if (client) {
      input.client = getObjectId(client, clients, 'reference');
    }
    if (litigation) {
      input.case = getObjectId(litigation, cases, 'suitNumber');
    }
    return input;
  };

  const preFormatRequest = (input: any) => {
    const { contacts } = input;
    input.contacts = contacts.map((contact: any) => preFormatContact(contact));
    return input;
  };

  const successCallback = () => {
    setShouldRedirect(true);
  };

  const getContactFromArray = (array: any[]) => ({
    firstName: array[0],
    lastName: array[1],
    countryCode: array[2] || null,
    phoneNumber: array[3] || null,
    emailAddress: array[4] || null,
    client: array[5] || null,
    case: array[6] || null,
    court: array[7] || null,
  });

  const formatFileUpload = (importedItems: any[]): ImportedItem[] =>
    importedItems.map((importedItem, index) => ({
      ...getContactFromArray(importedItem),
      id: index,
    }));

  const tableColumns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
    },
    {
      title: 'Country Code',
      dataIndex: 'countryCode',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
    },
  ];

  return shouldRedirect ? (
    <Redirect to={VIEW_ALL_CONTACTS} />
  ) : (
    <ImportInput
      url='contact/import'
      importedItemName='Contacts'
      {...{
        formatFileUpload,
        tableColumns,
        successCallback,
        preFormatRequest,
        successAction: Contact.addMany,
      }}
    />
  );
};

export default ImportContactsForm;
