import React from 'react';
import { useSelector } from 'react-redux';
import TableView from '../table/ViewAll';
import { selectAuthenticatedUser } from '../../redux/selectors/Auth';
import ViewAllAppealsActionColumn from './ViewAllAction';
import NotPermitted from '../utility/NotPermitted';
import { User } from '../../interfaces/entities/User';
import { isAdvancedUser } from '../../utility/user/privileges';
import { Appeal } from '../../interfaces/entities/Appeal';
import { selectAllAppeals } from '../../redux/selectors/Appeal';

const ViewAllAppeals: React.FC = () => {
  const appeals = useSelector(selectAllAppeals);
  const authenticatedUser = useSelector(selectAuthenticatedUser) as User;
  const hasExtraPrivileges = isAdvancedUser(authenticatedUser);

  const tableColumns = [
    {
      title: 'Appeal Name',
      dataIndex: 'name',
      shouldSort: true,
      indexName: 'appeal name',
      shouldSearch: true,
    },
    {
      title: 'Appeal Number',
      dataIndex: 'number',
      shouldSort: true,
      indexName: 'appeal number',
      shouldSearch: true,
    },
    {
      title: 'Filing Date',
      dataIndex: 'date',
      indexName: 'filing date',
      shouldSort: false,
      shouldSearch: true,
    },
  ];

  if (!hasExtraPrivileges) {
    return (
      <NotPermitted
        returnUrl='/'
        status='403'
        message='You are not authorized to view this page.'
      />
    );
  }

  return (
    <TableView
      actionColumn={{
        render: (text: any, record: Appeal) => (
          <ViewAllAppealsActionColumn record={record} />
        ),
      }}
      resourceType='appeal'
      data={appeals}
      columns={tableColumns}
    />
  );
};

export default ViewAllAppeals;
