import React, { useState, useEffect } from 'react';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generateCalendar from 'antd/es/calendar/generateCalendar';
import 'antd/es/calendar/style';
import { Badge } from 'antd';
import {
  CalendarItem,
  CalendarItemType,
} from '../../interfaces/entities/CalendarItem';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { makeRequest } from '../../utility/api';
import Button from 'antd/es/button';
import TimeTableDrawer from './Drawer';

const Timetable: React.FC = () => {
  const [importantDates, setImportantDates] = useState([]);
  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const [drawerTitle, setDrawerTitle] = useState<string>('');
  const [resourceType, setResourceType] = useState<CalendarItemType>('task');
  const [resourceId, setResourceId] = useState<number>(0);
  const authorization = useSelector(selectAuthToken);

  useEffect(() => {
    makeRequest({
      authorization,
      url: 'dashboard/timetable',
      type: 'GET',
      successCallback: (response) => {
        setImportantDates(response.data.timetable);
      },
    });
  }, [authorization]);

  const Calendar = generateCalendar<Dayjs>(dayjsGenerateConfig);

  const getListData = (value: any) => {
    if (!importantDates[value.month()]) return [];
    return importantDates[value.month()][value.date()] ?? [];
  };

  const dateCellRender = (value: any) => {
    const listData = getListData(value);
    const eventType = getEventTypeForDate(value.date(), value.month());
    return (
      <ul className='events' style={{ listStyle: 'none' }}>
        {listData.map((item: CalendarItem, index) => (
          <li key={index}>
            <Button
              type='text'
              onClick={() => {
                setDrawerVisibility(true);
                setResourceId(item.id);
                setResourceType(item.type);
                setDrawerTitle(item.title);
              }}
            >
              <Badge status={eventType} text={item.title} />
            </Button>
          </li>
        ))}
      </ul>
    );
  };

  const getEventTypeForDate = (eventDay: any, eventMonth: any) => {
    const currentTime = new Date();
    const [currentDay, currentMonth] = [
      currentTime.getDate(),
      currentTime.getMonth(),
    ];
    if (eventMonth - currentMonth >= 1) return 'success';
    if (eventDay - currentDay >= 7) return 'warning';
    return 'error';
  };

  const getEventsForMonth = (month: any, shouldGetTasks = true) => {
    if (!importantDates[month]) return 0;
    let numberOfEvents = 0;
    [...Object.values(importantDates[month])].forEach((element: any) => {
      numberOfEvents += element.filter(
        (importantDate: CalendarItem) =>
          importantDate.type === (shouldGetTasks ? 'task' : 'hearing')
      ).length;
    });
    return numberOfEvents;
  };

  const monthCellRender = (value: any) => (
    <div className='notes-month'>
      <section>{getEventsForMonth(value.month())}</section>
      <span>Tasks</span>
      <section>{getEventsForMonth(value.month(), false)}</section>
      <span>Hearings</span>
    </div>
  );

  return (
    <>
      <Calendar
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
      />
      {drawerVisibility && (
        <TimeTableDrawer
          {...{
            resourceId,
            resourceType,
            title: drawerTitle,
            visibility: drawerVisibility,
            closeDrawer: () => {
              setDrawerVisibility(false);
            },
          }}
        />
      )}
    </>
  );
};

export default Timetable;
