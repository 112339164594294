import React from 'react';
import { ResourceType } from '../../interfaces/entities/Base';
import BaseOrganizationForm from '../organization/Base';
import Modal from 'antd/lib/modal/Modal';
import { Button } from 'antd';
import BaseUserForm from '../user/Base';
import BaseDocumentForm from '../document/Base';
import BaseContactForm from '../contact/Base';
import BaseClientForm from '../client/Base';
import InvoiceForm from '../invoice/Base';
import BaseCaseForm from '../litigation/Base';
import BaseTaskForm from '../task/Base';
import BaseAppealForm from '../appeal/Base';

interface ModalFormProps {
  showModal: boolean;
  formToRender: ResourceType | 'invoice';
  successCallback: () => void;
  showOrganizationDropdown: boolean;
  cancel: () => void;
  resource?: any;
}

const getForm = (
  resourceType: ResourceType | 'invoice',
  successCallback: () => void,
  resource: any
) => {
  switch (resourceType) {
    case 'organization':
      return <BaseOrganizationForm successCallback={successCallback} />;
    case 'user':
      return <BaseUserForm successCallback={successCallback} />;
    case 'document':
      return <BaseDocumentForm successCallback={successCallback} />;
    case 'contact':
      return <BaseContactForm successCallback={successCallback} />;
    case 'client':
      return <BaseClientForm successCallback={successCallback} />;
    case 'case':
      return <BaseCaseForm successCallback={successCallback} />;
    case 'task':
      return <BaseTaskForm successCallback={successCallback} />;
    case 'appeal':
      return <BaseAppealForm successCallback={successCallback} />;
    case 'invoice':
      return (
        <InvoiceForm litigation={resource} successCallback={successCallback} />
      );
  }
};

const ModalForm: React.FC<ModalFormProps> = ({
  showModal,
  formToRender,
  successCallback,
  cancel,
  resource,
}) => {
  return (
    <Modal
      visible={showModal}
      onCancel={cancel}
      footer={null}
      title={`Create New ${formToRender
        .charAt(0)
        .toUpperCase()}${formToRender.slice(1)}`}
    >
      {getForm(formToRender, successCallback, resource)}
      <Button block onClick={cancel}>
        Cancel
      </Button>
    </Modal>
  );
};

export default ModalForm;
