import { UploadMedium } from '../components/form/FileInput';
import { useState } from 'react';

const useFileInput = () => {
  const [uploadMedium, setUploadMedium] = useState<UploadMedium>('upload');
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const updateUploadMedium = (value: UploadMedium) => {
    setUploadMedium(value);
    if (value === 'online') {
      setSelectedFiles([]);
    }
  };

  const removeSelectedFile = (file: any) => {
    setSelectedFiles((currentSelectedFiles) => {
      const index = currentSelectedFiles.indexOf(file);
      const newFileList = currentSelectedFiles.slice();
      newFileList.splice(index, 1);
      return newFileList;
    });
  };

  const addSelectedFile = (file: any) => {
    setSelectedFiles((currentSelectedFiles) => [...currentSelectedFiles, file]);
  };

  const formatFilesInRequest = (input: any) => {

    input.type = uploadMedium;
    if (selectedFiles.length > 0) {
      const data = new FormData();
      selectedFiles.forEach((selectedFile) => {
        data.append('documents[]', selectedFile);
      });

      for (let [key, value] of Object.entries(input)) {
        if (Array.isArray(value)) {
          value.forEach(itemValue => {
            data.append(`${key}[]`, itemValue);
          });
        } else {
          data.append(key, value as string | Blob);
        }
      }
      setSelectedFiles([]);
      return data;
    }
    return input;
  };

  return [
    uploadMedium,
    updateUploadMedium,
    removeSelectedFile,
    addSelectedFile,
    formatFilesInRequest
  ] as const;
};


export default useFileInput;