import { AppState } from '../../interfaces/redux/state/App';
import { selectAllItems, selectItem } from './Generic';
import { Task } from '../../interfaces/entities/Task';

export const selectAllPersonalTasks = ({ personalTasks }: AppState): Task[] =>
  selectAllItems(personalTasks);

export const selectPersonalTask = (
  { personalTasks }: AppState,
  id: number
): Task | null => selectItem(id, personalTasks);

const selectPersonalTasks = ({ personalTasks }: AppState): Task[] =>
  selectAllItems(personalTasks).filter(({ isCompleted }) => !isCompleted);

export const selectPersonalTaskCount = (appState: AppState): number =>
  selectPersonalTasks(appState).length;
