import React, { useState } from 'react';
import { ImportedItem } from '../../interfaces/components/form/FileUpload';
import { Redirect } from 'react-router-dom';
import { VIEW_ALL_CASES } from '../../utility/routing/routes';
import ImportInput from '../form/ImportInput';
import Litigation from '../../redux/actions/Litigation';
import { useSelector } from 'react-redux';
import { selectAllClients } from '../../redux/selectors/Client';
import { selectAllUsers } from '../../redux/selectors/User';
import { getObjectId } from '../../utility/array';

const ImportCasesForm: React.FC = () => {
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const clients = useSelector(selectAllClients);
  const users = useSelector(selectAllUsers);

  const preFormatRequest = (input: any) => {
    const { cases } = input;
    input.cases = cases.map((litigation: any) => {
      const { client, user } = litigation;
      litigation.client = getObjectId(client, clients, 'reference');
      litigation.user = getObjectId(user, users, 'emailAddress');
      delete litigation.id;
      return litigation;
    });
    return input;
  };

  const successCallback = () => {
    setShouldRedirect(true);
  };

  const getCaseFromArray = (array: any[]) => ({
    suitName: array[0],
    suitNumber: array[1],
    court: array[2],
    filingDate: array[3],
    filingState: array[4],
    statedFirm: array[5],
    claimant: array[6],
    defendant: array[7],
    presidingJudge: array[8],
    firmLawyer: array[9],
    plaintiffAttorney: array[10],
    representing: array[11],
    nextHearingDate: array[12],
  });

  const formatFileUpload = (importedItems: any[]): ImportedItem[] =>
    importedItems.map((importedItem, index) => ({
      ...getCaseFromArray(importedItem),
      id: index,
    }));

  const tableColumns = [
    {
      title: 'Suit Name',
      dataIndex: 'suitName',
    },
    {
      title: 'Case Number',
      dataIndex: 'suitNumber',
    },
    {
      title: 'Client',
      dataIndex: 'client',
    },
    {
      title: 'Filing Date',
      dataIndex: 'filingDate',
    },
  ];
  return shouldRedirect ? (
    <Redirect to={VIEW_ALL_CASES} />
  ) : (
    <ImportInput
      url='case/import'
      importedItemName='Cases'
      {...{
        formatFileUpload,
        tableColumns,
        successCallback,
        successAction: Litigation.addMany,
        preFormatRequest,
      }}
    />
  );
};

export default ImportCasesForm;
