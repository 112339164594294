import React, { useState } from 'react';
import IndividualClientForm from './form/Individual';
import CorporateClientForm from './form/Corporate';
import { Client, ClientType } from '../../interfaces/entities/Client';
import { Store } from 'antd/lib/form/interface';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from '../../redux/selectors/Auth';
import { User } from '../../interfaces/entities/User';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import { Form, Radio } from 'antd';
import AutoCompleteInput from '../form/AutoCompleteInput';
import { getOptions } from '../../utility/array';
import SubmitButton from '../form/SubmitButton';
import EmailInput from '../form/EmailInput';
import PhoneNumberInput from '../form/PhoneNumberInput';
import AddressInput from '../form/AddressInput';

interface Props {
  isLoading: boolean;
  handleFinish: (values: Store) => void;
  client?: Client;
}
const ClientBaseFormContainer: React.FC<Props> = ({
  client,
  isLoading,
  handleFinish,
}) => {
  const [clientType, setClientType] = useState<ClientType | null>(null);
  const [form] = Form.useForm();
  const userIsSuperAdmin = (useSelector(selectAuthenticatedUser) as User)
    .isSuperAdmin;
  const organizations = useSelector(selectAllOrganizations);

  const shouldShowClientForm = (type: ClientType) => {
    if (client) {
      return client.type === type;
    }
    return clientType === type;
  };

  return (
    <Form
      form={form}
      onFinish={(values) => {
        values.type = client ? client.type : clientType;
        handleFinish(values);
      }}
    >
      {userIsSuperAdmin && (
        <AutoCompleteInput
          name='organization'
          label='Organization'
          placeholder='Organization'
          initialValue={client?.organization.name}
          options={getOptions(organizations)}
        />
      )}
      {!client && (
        <Form.Item
          rules={[{ required: true, message: 'Please select client type' }]}
        >
          <Radio.Group>
            <Radio.Button
              value='IND'
              onClick={() => {
                setClientType('IND');
              }}
            >
              Add Individual Client
            </Radio.Button>
            <Radio.Button
              value='COR'
              onClick={() => {
                setClientType('COR');
              }}
            >
              Add Corporate Client
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
      )}
      {shouldShowClientForm('IND') && <IndividualClientForm client={client} />}
      {shouldShowClientForm('COR') && <CorporateClientForm client={client} />}
      {(clientType || client) && (
        <>
          <AddressInput
            isRequired={false}
            initialAddressValue={client?.address}
            initialCityValue={client?.city}
            initialStateValue={client?.state}
            initialCountryValue={client?.country}
          />
          <EmailInput
            name='emailAddress'
            label='Email Address'
            placeholder='Email Address'
            initialValue={client?.emailAddress}
            required={true}
            requiredMessage="Please enter the client's email address"
          />
          <PhoneNumberInput
            isRequired={false}
            initialValue={client?.phoneNumber}
            initialCountryCodeValue={client?.countryCode}
          />
        </>
      )}
      <SubmitButton
        isLoading={isLoading}
        buttonText={client ? 'Save' : 'Submit'}
      />
    </Form>
  );
};

export default ClientBaseFormContainer;
