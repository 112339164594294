export const ADD_TASK = '/task/add';
export const VIEW_ALL_TASKS = '/task/all';
export const VIEW_PERSONAL_TASKS = '/task/personal/all';
export const IMPORT_TASKS = '/task/import';
export const VIEW_TASK = '/task/';
export const EDIT_TASK = '/task/edit';
export const UPDATE_TASK = '/task/update';

export const ADD_CASE = '/case/add';
export const VIEW_ALL_CASES = '/case/all';
export const IMPORT_CASES = '/case/import';
export const VIEW_STARRED_CASES = '/case/starred/all';
export const VIEW_ARCHIVED_CASES = '/case/archived/all';
export const VIEW_CASE = '/case/';
export const EDIT_CASE = '/case/edit';
export const UPDATE_CASE = '/case/update';

export const ADD_APPEAL = '/appeal/add';
export const VIEW_ALL_APPEALS = '/appeal/all';
export const EDIT_APPEAL = '/appeal/edit';
export const UPDATE_APPEAL = '/appeal/update';

export const ADD_CLIENT = '/client/add';
export const VIEW_ALL_CLIENTS = '/client/all';
export const IMPORT_CLIENTS = '/client/import';
export const VIEW_CLIENT = '/client/';
export const EDIT_CLIENT = '/client/edit';

export const ADD_USER = '/user/add';
export const VIEW_ALL_USERS = '/user/all';
export const VIEW_DELETED_USERS = '/user/deleted/all';
export const IMPORT_USERS = '/user/import';
export const VIEW_USER = '/user/';
export const EDIT_USER = '/user/edit';

export const ADD_ORGANIZATION = '/organization/add';
export const VIEW_ALL_ORGANIZATIONS = '/organization/all';
export const IMPORT_ORGANIZATIONS = '/organization/import';
export const EDIT_ORGANIZATION = '/organization/edit';

export const VIEW_CALENDAR = '/calendar';

export const VIEW_CASE_REPORT = '/report/case';
export const VIEW_TASK_REPORT = '/report/task';
export const VIEW_USER_REPORT = '/report/user';
export const VIEW_CLIENT_REPORT = '/report/client';
export const VIEW_CONTACT_REPORT = '/report/contact';
export const VIEW_ORGANIZATION_REPORT = '/report/organization';

export const VIEW_ALL_DOCUMENTS = '/document/all';
export const ADD_DOCUMENT = '/document/add';

export const ADD_CONTACT = '/contact/add';
export const IMPORT_CONTACTS = '/contact/import';

export const VIEW_ALL_CONTACTS = '/contact/all';
export const EDIT_CONTACT = '/contact/edit';
