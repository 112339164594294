import { AppState } from '../../interfaces/redux/state/App';
import { selectAllItems, selectItem, selectCount } from './Generic';
import { Appeal } from '../../interfaces/entities/Appeal';

export const selectAllAppeals = ({ appeals }: AppState): Appeal[] =>
  selectAllItems(appeals);

export const selectAppeal = (
  { appeals }: AppState,
  id: number
): Appeal | null => selectItem(id, appeals);

export const selectAppealCount = ({ appeals }: AppState): number =>
  selectCount(appeals);
