import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../interfaces/redux/state/App';
import NotPermitted from '../utility/NotPermitted';
import { VIEW_ALL_CLIENTS } from '../../utility/routing/routes';
import ClientForm from './Client';
import { selectClient } from '../../redux/selectors/Client';

const EditClientForm: React.FC = () => {
  const { id } = useParams();
  const client = useSelector((appState) =>
    selectClient(appState as AppState, +id)
  );

  if (!client) {
    return (
      <NotPermitted
        returnUrl={VIEW_ALL_CLIENTS}
        message='Could not find a client with the specified ID'
      />
    );
  }

  return <ClientForm client={client} />;
};

export default EditClientForm;
