import React from 'react';
import { Table } from 'antd';
import { Task } from '../../../interfaces/entities/Task';

interface TaskViewProps {
  tasks: Task[];
  isCompleted: boolean;
}
const TaskView: React.FC<TaskViewProps> = ({ tasks, isCompleted }) => {
  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
    },
    isCompleted
      ? {
          title: 'Completion Date',
          dataIndex: 'completionDate',
        }
      : {
          title: 'Due Date',
          dataIndex: 'dueDate',
        },
    {
      title: 'Assignee(s)',
      dataIndex: 'assignedUsers',
      render: (text: any, record: Task) => (
        <ol>
          {record.assignedUsers.map(({ name }) => (
            <li>{name}</li>
          ))}
        </ol>
      ),
    },
  ];

  return <Table dataSource={tasks} columns={tableColumns} rowKey={'id'} />;
};

export default TaskView;
