import React from 'react';
import { Store } from 'antd/lib/form/interface';
import Form from 'antd/lib/form';
import SubmitButton from '../form/SubmitButton';
import CaseUpdateComponent from './form/Case';
import TaskUpdateComponent from './form/Task';
import TextAreaInput from '../form/TextAreaInput';
import FileInput from '../form/FileInput';

type UploadMedium = 'upload' | 'online';
type UpdateType = 'case' | 'task';

interface Props {
  updateType: UpdateType;
  uploadMedium: UploadMedium;
  isLoading: boolean;
  updateUploadMedium: (uploadMedium: UploadMedium) => void;
  removeSelectedFile: (file: any) => void;
  addSelectedFile: (file: any) => void;
  handleFinish: (values: Store) => void;
}

const getUpdateForm = (updateType: UpdateType) =>
  updateType === 'case' ? <CaseUpdateComponent /> : <TaskUpdateComponent />;

const BaseUpdateFormContainer: React.FC<Props> = ({
                                                    updateType,
                                                    uploadMedium,
                                                    isLoading,
                                                    updateUploadMedium,
                                                    removeSelectedFile,
                                                    addSelectedFile,
                                                    handleFinish
                                                  }) => {
  const labelText = `${updateType === 'case' ? 'Client' : ''} Update`;

  return (
    <Form onFinish={handleFinish}>
      <TextAreaInput
        name='description'
        label={labelText}
        placeholder={labelText}
        message='Please provide an update'
        required={updateType !== 'case'}
      />
      {getUpdateForm(updateType)}

      <FileInput
        updateUploadMedium={updateUploadMedium}
        addSelectedFile={addSelectedFile}
        uploadMedium={uploadMedium}
        removeSelectedFile={removeSelectedFile}
      />

      <SubmitButton buttonText='Submit' isLoading={isLoading} />
    </Form>
  );
};

export default BaseUpdateFormContainer;
