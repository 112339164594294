import React, { useEffect, useState } from 'react';
import { makeDownloadRequest, makeRequest } from '../../../utility/api';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../../../redux/selectors/Auth';
import { Button, Card, Descriptions, Table, Tabs } from 'antd';
import { capitalize } from '../../../utility/stringFormatter';
import { selectAllCases } from '../../../redux/selectors/Litigation';
import ViewAllCasesActionColumn from '../../litigation/ViewAllAction';
import { Litigation } from '../../../interfaces/entities/Litigation';

const { TabPane } = Tabs;


const ReportView: React.FC = () => {
  const [summary, setSummary] = useState<any>({});
  const [sortedCases, setSortedCases] = useState<any>([]);
  const authorization = useSelector(selectAuthToken);

  const cases = useSelector(selectAllCases);

  const sortedCaseTableColumn = [
    {
      title: 'Suit Name',
      dataIndex: 'suitName',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Suit Number',
      dataIndex: 'suitNumber',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Court',
      dataIndex: 'court',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Next Hearing Date',
      dataIndex: 'nextHearingDate',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, record: any) => {
        const selectedCase = cases.find(({ id }) => id === record.id);
        return <ViewAllCasesActionColumn record={selectedCase as Litigation} />;
      }
    }
  ];

  const downloadReport = () => {
    makeDownloadRequest({
      url: 'admin/reports/case/download',
      type: 'GET',
      mime: 'application/vnd.ms-excel',
      filename: 'Case Report.xlsx',
      authorization
    });
  };

  useEffect(() => {
    makeRequest({
      url: 'admin/reports/case',
      type: 'GET',
      successCallback: response => {
        const { summary, sortedCases } = response.data;
        setSummary(summary);
        setSortedCases(sortedCases);
      },
      authorization
    });
  }, [authorization]);

  const downloadButton = (
    <Button type='primary' ghost onClick={downloadReport}>
      Download
    </Button>
  );

  return (<Card>
      <Tabs
        defaultActiveKey='1'
        size='middle'
        tabBarExtraContent={downloadButton}
      >
        <TabPane tab='Summary' key='1'>
          <Descriptions bordered>
            {
              Object.keys(summary).map((status, index) => (
                <Descriptions.Item label={capitalize(status)} span={3} key={index}>
                  {summary[status]}
                </Descriptions.Item>
              ))
            }
          </Descriptions>
        </TabPane>
        {
          Object.keys(sortedCases).map((status, index) => (
            <TabPane tab={status} key={index + 2}>
              <Table
                bordered
                rowKey='id'
                columns={sortedCaseTableColumn}
                dataSource={sortedCases[status]}
              />
            </TabPane>))
        }
      </Tabs>
    </Card>
  );
};

export default ReportView;