import React from 'react';
import { Popconfirm, Button } from 'antd';

interface DeleteWarningProps {
  title?: string;
  onConfirm: () => void;
}

const DeleteWarning: React.FC<DeleteWarningProps> = ({
  title = 'Delete?',
  onConfirm,
}) => {
  return (
    <Popconfirm title={title} onConfirm={onConfirm}>
      <Button type='primary' danger ghost>
        Delete
      </Button>
    </Popconfirm>
  );
};

export default DeleteWarning;
