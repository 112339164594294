import React, { useEffect, useState } from 'react';
import { User } from '../../interfaces/entities/User';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from '../../redux/selectors/Auth';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import Search from './Search';
import { Row, Col, Button, Table } from 'antd';
import { ResourceType, Resource } from '../../interfaces/entities/Base';
import ModalForm from '../form/ModalForm';
import { capitalize } from '../../utility/stringFormatter';

interface Column {
  title: string;
  dataIndex: string;
  shouldSort: boolean;
  shouldSearch: boolean;
  indexName?: string;
  fixed?: string;
  width?: number;
  render?: (text: any, record: any) => {};
}

interface TableViewProps {
  actionColumn?: object;
  columns: Column[];
  data: Resource[];
  bulkAction?: (items: []) => void;
  resourceType: ResourceType;
  bulkActionText?: string;
  isLoading?: boolean;
  additionalMenuItem?: JSX.Element;
}

const TableView: React.FC<TableViewProps> = ({
  actionColumn = null,
  columns,
  data,
  bulkAction,
  resourceType,
  bulkActionText,
  isLoading = false,
  additionalMenuItem,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [renderModal, setRenderModal] = useState<boolean>(false);
  const [searchedColumn, setSearchColumn] = useState<string>('');
  const [selectedRowKeys, setSelectedKeys] = useState<[]>([]);

  const isSuperAdmin =
    (useSelector(selectAuthenticatedUser) as User).isSuperAdmin ?? false;

  const alphabeticOrderComparator = (a: string, b: string) =>
    a.localeCompare(b);

  const closeModal = () => {
    setRenderModal(false);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const handleRowSelection = (selectedRowKeys: any) => {
    setSelectedKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleRowSelection,
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const hasSelectedRow = selectedRowKeys.length > 0;

  const getColumnSearchProps = (dataIndex: string, indexName = dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <Search
        clearFilters={clearFilters}
        confirm={confirm}
        dataIndex={dataIndex}
        handleReset={handleReset}
        handleSearch={handleSearch}
        indexName={indexName}
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
      />
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const getColumnSortProps = (propertyName: any) => ({
    sorter: (a: any, b: any) =>
      alphabeticOrderComparator(a[propertyName], b[propertyName]),
    sortDirections: ['ascend', 'descend'],
  });

  const tableColumns = [
    ...columns.map((columnInput) => {
      const {
        title,
        dataIndex,
        indexName = dataIndex,
        shouldSort,
        shouldSearch,
        render = null,
      } = columnInput;
      return {
        title,
        dataIndex,
        ...(shouldSort && getColumnSortProps(dataIndex)),
        ...(shouldSearch && getColumnSearchProps(dataIndex, indexName)),
        render,
      };
    }),
    actionColumn && { title: 'Actions', key: 'action', ...actionColumn },
  ] as any;

  const handleBulkAction = () => {
    bulkAction && bulkAction(selectedRowKeys);
    setSelectedKeys([]);
  };

  return (
    <div>
      <Row>
        <div style={{ marginBottom: 16 }}>
          <ModalForm
            showModal={renderModal}
            formToRender={resourceType}
            showOrganizationDropdown={isSuperAdmin}
            successCallback={closeModal}
            cancel={closeModal}
          />
          {bulkAction && (
            <>
              <Button
                style={{ margin: 10 }}
                danger
                type='primary'
                onClick={() => {
                  handleBulkAction();
                }}
                disabled={!hasSelectedRow}
                loading={isLoading}
              >
                {`${bulkActionText}`}
              </Button>
              <Button
                type='primary'
                onClick={() => {
                  setSelectedKeys([]);
                }}
                disabled={!hasSelectedRow}
              >
                Clear Selection
              </Button>
            </>
          )}
          <Button
            style={{ margin: 10 }}
            onClick={() => {
              setRenderModal(true);
            }}
          >
            {`Add New ${capitalize(resourceType)}`}
          </Button>
          {additionalMenuItem || <></>}
        </div>
      </Row>
      <Row>
        <Col style={{ width: '100%', padding: '10px' }}>
          <Table
            bordered
            dataSource={data}
            columns={tableColumns}
            rowKey='id'
            rowSelection={rowSelection}
            scroll={{ x: 100 }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TableView;
