import { UploadOutlined } from '@ant-design/icons';
import { Divider, Row, Col, Upload, Button } from 'antd';
import React from 'react';
import TextInput from './TextInput';

export type UploadMedium = 'upload' | 'online';

interface FileInputProps {
  uploadMedium: UploadMedium;
  updateUploadMedium: (uploadMedium: UploadMedium) => void;
  removeSelectedFile: (file: any) => void;
  addSelectedFile: (file: any) => void;
}

const FileInput: React.FC<FileInputProps> = ({
  uploadMedium,
  updateUploadMedium,
  removeSelectedFile,
  addSelectedFile,
}) => {
  return (
    <>
      <Divider type='horizontal' />
      {
        <Row gutter={16} justify='center' style={{ margin: '10px' }}>
          <Col>
            <Upload
              multiple={true}
              onRemove={removeSelectedFile}
              beforeUpload={(file) => {
                addSelectedFile(file);
                return false;
              }}
            >
              <Button
                onClick={() => {
                  updateUploadMedium('upload');
                }}
              >
                <UploadOutlined />
                Add Local Files
              </Button>
            </Upload>
          </Col>
          <Col>
            <Button
              onClick={() => {
                updateUploadMedium('online');
              }}
            >
              Add Online Files
            </Button>
          </Col>
        </Row>
      }

      {uploadMedium === 'online' && (
        <div style={{ margin: '10px' }}>
          <TextInput
            name='fileUrl'
            label='File URL'
            placeholder='File URL'
            message='Please enter the URL to the document'
          />
          <TextInput
            name='documentTitle'
            label='Document Title'
            placeholder='Document Title'
            message='Please enter the title of the document'
          />
          <Button
            onClick={() => {
              updateUploadMedium('upload');
            }}
          >
            Cancel
          </Button>
        </div>
      )}
    </>
  );
};

export default FileInput;
