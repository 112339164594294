import { LitigationState } from '../../interfaces/redux/state/App';
import { getGenericStateObject } from '../../utility/redux/state';
import { LitigationAction } from '../../interfaces/redux/actions/Litigation';
import {
  UPDATE_CASES,
  ADD_CASE,
  REMOVE_CASE,
  UPDATE_CASE,
  CLEAR_CASES,
  ADD_CASES,
  BULK_EDIT_CASES,
} from '../ActionTypes';
import {
  update,
  add,
  remove,
  edit,
  addMany,
  bulkUpdate,
} from '../../utility/redux/reducer';

const initialState: LitigationState = getGenericStateObject();

const reducer = (
  state: LitigationState = initialState,
  action: LitigationAction
): LitigationState => {
  switch (action.type) {
    case UPDATE_CASES:
      return update(state, action.payload);
    case ADD_CASE:
      return add(state, action.payload);
    case REMOVE_CASE:
      return remove(state, action.payload);
    case UPDATE_CASE:
      return edit(state, action.payload);
    case ADD_CASES:
      return addMany(state, action.payload);
    case BULK_EDIT_CASES:
      return bulkUpdate(state, action.payload);
    case CLEAR_CASES:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
