import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OrganizationForm from './Organization';
import { selectOrganization } from '../../redux/selectors/Organization';
import { AppState } from '../../interfaces/redux/state/App';
import NotPermitted from '../utility/NotPermitted';
import { VIEW_ALL_ORGANIZATIONS } from '../../utility/routing/routes';

const EditOrganizationForm: React.FC = () => {
  const { id } = useParams();
  const organization = useSelector((appState) =>
    selectOrganization(appState as AppState, +id)
  );

  if (!organization) {
    return (
      <NotPermitted
        returnUrl={VIEW_ALL_ORGANIZATIONS}
        message='Could not find an organization with the specified ID'
      />
    );
  }

  return <OrganizationForm organization={organization} />;
};

export default EditOrganizationForm;
