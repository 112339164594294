import { LitigationState } from '../../interfaces/redux/state/App';
import { getGenericStateObject } from '../../utility/redux/state';
import { StarredCasesAction } from '../../interfaces/redux/actions/StarredCase';
import { update } from '../../utility/redux/reducer';
import { UPDATE_STARS, CLEAR_STARS } from '../ActionTypes';

const initialState: LitigationState = getGenericStateObject();

const reducer = (
  state: LitigationState = initialState,
  action: StarredCasesAction
): LitigationState => {
  switch (action.type) {
    case UPDATE_STARS:
      return update(state, action.payload);
    case CLEAR_STARS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
