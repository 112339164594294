import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from '../../redux/selectors/Auth';
import { User } from '../../interfaces/entities/User';
import NotPermitted from '../utility/NotPermitted';
import { useLocation } from 'react-router-dom';
import { canAccessSpecialRoute } from '../../utility/routing/specialRoutes';

const withRestriction = <P extends object>(
  Component: React.FC<P>
): React.FC<P> => (props) => {
  const user = useSelector(selectAuthenticatedUser) as User;
  const { pathname } = useLocation();

  return canAccessSpecialRoute(pathname, user) ? (
    <Component {...(props as P)} />
  ) : (
    <NotPermitted
      returnUrl='/'
      status='403'
      message='You are not authorized to view this page.'
    />
  );
};

export default withRestriction;
