import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAuthToken,
  selectAuthenticatedUser,
} from '../../redux/selectors/Auth';
import { makeRequest } from '../../utility/api';
import { getColours } from '../../utility/constants/constants';
import { Row, Col, Typography, Button, Card, Divider, Table, Tag } from 'antd';
import NumberCard from './NumberCard';
import { selectClientCount } from '../../redux/selectors/Client';
import { selectLitigationCount } from '../../redux/selectors/Litigation';
import { selectStarredCaseCount } from '../../redux/selectors/StarredCase';
import { selectTaskCount } from '../../redux/selectors/Task';
import { selectPersonalTaskCount } from '../../redux/selectors/PersonalTask';
import {
  TASK_TABLE_COLUMNS,
  CASE_TABLE_COLUMNS,
} from '../../utility/constants/tableColumns';
import Timetable from '../timetable/Timetable';
import { Link } from 'react-router-dom';
import {
  VIEW_ALL_CLIENTS,
  VIEW_ALL_CASES,
  VIEW_STARRED_CASES,
  VIEW_PERSONAL_TASKS,
  VIEW_ARCHIVED_CASES,
  VIEW_ALL_TASKS,
} from '../../utility/routing/routes';
import { selectArchivedCaseCount } from '../../redux/selectors/ArchivedCase';
import { getTagColour } from '../../utility/task/tag';
import { Task, TaskStatus } from '../../interfaces/entities/Task';
import { User } from '../../interfaces/entities/User';
import { isAdvancedUser } from '../../utility/user/privileges';

const { Title } = Typography;

const Home: React.FC = () => {
  const authorization = useSelector(selectAuthToken);
  const [tasksForToday, setTasksForToday] = useState<[]>([]);
  const [casesForToday, setCasesForToday] = useState<[]>([]);
  const [colours, setColours] = useState<string[]>([]);
  const clients = useSelector(selectClientCount);
  const cases = useSelector(selectLitigationCount);
  const starredCases = useSelector(selectStarredCaseCount);
  const archivedCases = useSelector(selectArchivedCaseCount);
  const tasks = useSelector(selectTaskCount);
  const personalTasks = useSelector(selectPersonalTaskCount);
  const user = useSelector(selectAuthenticatedUser) as User;
  const hasAdvancedPrivileges = isAdvancedUser(user);

  useEffect(() => {
    makeRequest({
      authorization,
      url: 'dashboard',
      type: 'GET',
      successCallback: (response) => {
        const { data } = response;
        setTasksForToday(data.tasksForTheDay);
        setCasesForToday(data.casesForTheDay);
      },
    });
    setColours(getColours());
  }, []);

  const getTaskColumns = () => {
    const columns = TASK_TABLE_COLUMNS;
    const statusColumn: any = columns.find(({ title }) => title === 'Status');
    statusColumn.render = (text: any, record: Task) => (
      <Tag color={getTagColour(record.status as TaskStatus)}>
        {record.status}
      </Tag>
    );
    return columns;
  };

  return (
    <div>
      <Row justify='center' align='middle'>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Link to={VIEW_ALL_CLIENTS}>
            <NumberCard
              title='Clients'
              colour={colours[0]}
              number={clients}
              icon='team'
            />
          </Link>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Link to={VIEW_ALL_CASES}>
            <NumberCard
              title='Cases'
              colour={colours[1]}
              number={cases}
              icon='folder-open'
            />
          </Link>
        </Col>
        {hasAdvancedPrivileges && (
          <>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Link to={VIEW_STARRED_CASES}>
                <NumberCard
                  title='Starred Cases'
                  colour={colours[2]}
                  number={starredCases}
                  icon='star'
                />
              </Link>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Link to={VIEW_ARCHIVED_CASES}>
                <NumberCard
                  title='Archived Cases'
                  colour={colours[3]}
                  number={archivedCases}
                  icon='delete'
                />
              </Link>
            </Col>
          </>
        )}
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Link to={VIEW_ALL_TASKS}>
            <NumberCard
              title='Tasks'
              colour={colours[4]}
              number={tasks}
              icon='issues-close'
            />
          </Link>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <Link to={VIEW_PERSONAL_TASKS}>
            <NumberCard
              title='My Tasks'
              colour={colours[5]}
              number={personalTasks}
              icon='inbox'
            />
          </Link>
        </Col>
      </Row>
      <Row style={{marginTop:'30px'}}>
        <Timetable />
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Card bordered={false}>
            <Title level={4}>This Week's Tasks</Title>
            <Divider />
            <Table
              dataSource={tasksForToday}
              columns={getTaskColumns()}
              style={{ marginBottom: '10px' }}
              pagination={{ pageSize: 5 }}
              rowKey='id'
            />
            <div style={{ textAlign: 'center' }}>
              <Link to={VIEW_PERSONAL_TASKS}>
                <Button type='primary'>View My Tasks</Button>
              </Link>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Card bordered={false}>
            <Title level={4}>This Week's Hearings</Title>
            <Divider />
            <Table
              dataSource={casesForToday}
              columns={CASE_TABLE_COLUMNS}
              style={{ marginBottom: '10px' }}
              pagination={{ pageSize: 5 }}
              rowKey='id'
            />
            <div style={{ textAlign: 'center' }}>
              <Link to={VIEW_ALL_CASES}>
                <Button type='primary' danger>
                  View All Cases
                </Button>
              </Link>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
