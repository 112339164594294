import { User } from '../../interfaces/entities/User';
import {
  Token,
  UpdateTokenAction,
  UpdateUserAction,
  ClearUserAction,
} from '../../interfaces/redux/actions/Auth';
import { UPDATE_TOKEN, SET_LOGGED_IN_USER, CLEAR_USER } from '../ActionTypes';

const updateToken = (token: Token): UpdateTokenAction => ({
  type: UPDATE_TOKEN,
  payload: token,
});

const updateUser = (user: User): UpdateUserAction => ({
  type: SET_LOGGED_IN_USER,
  payload: user,
});

const logout = (): ClearUserAction => ({
  type: CLEAR_USER,
});

export default {
  updateToken,
  updateUser,
  logout,
};
