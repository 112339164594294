import React from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
interface TextAreaProps {
  label?: string;
  name: string;
  required?: boolean;
  message?: string;
  placeholder: string;
  initialValue?: string;
}
const TextAreaInput: React.FC<TextAreaProps> = ({
  label,
  name,
  required = true,
  message,
  placeholder,
  initialValue,
}) => {
  return (
    <Form.Item
      {...{ label, name, rules: [{ required, message }], initialValue }}
    >
      <Input.TextArea
        placeholder={placeholder}
        autoSize={{ minRows: 4, maxRows: 10 }}
      />
    </Form.Item>
  );
};

export default TextAreaInput;
