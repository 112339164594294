import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAllDocuments } from '../../redux/selectors/Document';
import TableView from '../table/ViewAll';
import ViewAllDocumentsActionColumn from './ViewAllAction';
import { Document } from '../../interfaces/entities/Document';
import { makeDownloadRequest } from '../../utility/api';
import { selectAuthToken } from '../../redux/selectors/Auth';

const ViewAllDocuments: React.FC = () => {
  const authorization = useSelector(selectAuthToken);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const documents = useSelector(selectAllDocuments);

  const tableColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      shouldSort: true,
      shouldSearch: true
    },
    {
      title: 'Suit Number',
      dataIndex: 'number',
      shouldSort: true,
      shouldSearch: true
    },
    {
      title: 'Suit Name',
      dataIndex: 'caseTitle',
      shouldSearch: true,
      shouldSort: true
    },
    {
      title: 'Suit Type',
      dataIndex: 'type',
      shouldSearch: true,
      shouldSort: true
    }
  ];

  const bulkDownloadDocuments = (documentArray: Document['id'][]) => {
    const values = {
      documents: documentArray.map((id) => ({ id }))
    };
    setIsDownloading(true);
    makeDownloadRequest({
      url: 'document/download/bulk',
      values,
      filename: `Bulk Download_${new Date().valueOf()}.zip`,
      authorization,
      successCallback: () => {
        setIsDownloading(false);
      },
      failureCallback: () => {
        setIsDownloading(false);
      }
    });
  };

  return (
    <TableView
      actionColumn={{
        render: (text: any, record: Document) => (
          <ViewAllDocumentsActionColumn record={record} />
        )
      }}
      columns={tableColumns}
      data={documents}
      bulkAction={bulkDownloadDocuments}
      resourceType={'document'}
      bulkActionText='Download Selection'
      isLoading={isDownloading}
    />
  );
};

export default ViewAllDocuments;
