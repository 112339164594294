import { User } from '../../interfaces/entities/User';

const privilegeOptions = [
  {
    name: 'Case Assistant',
    value: 'assistant',
  },
  {
    name: 'Regular User',
    value: 'default',
  },
  {
    name: 'Advanced User',
    value: 'advanced',
  },
  {
    name: 'Organization Administrator',
    value: 'admin',
  },
];

export const getUserPrivilegeOptions = (isSuperAdmin: boolean) => {
  if (isSuperAdmin) {
    return [
      ...privilegeOptions,
      { name: 'Global Administrator', value: 'super' },
    ];
  }
  return privilegeOptions;
};

export const getPrivilege = (privilege: string | undefined) => {
  if (!privilege) return undefined;
  if (privilege === 'Global Administrator') return 'super';
  return privilegeOptions.find(({ name }) => name === privilege)?.value;
};

export const isAdvancedUser = (user: User) =>
  ['super', 'admin', 'advanced'].includes(getPrivilege(user.privilege) || '');

export const userIsGrantedPrivilege = (
  user: User,
  privilege: string
): boolean => {
  return getPrivilege(user.privilege) === privilege;
};
