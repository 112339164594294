import React, { useState } from 'react';
import { User } from '../../interfaces/entities/User';
import { Redirect } from 'react-router-dom';
import { VIEW_ALL_USERS } from '../../utility/routing/routes';
import BaseUserForm from './Base';
import { Card } from 'antd';

interface UserFormProps {
  user?: User;
}

const UserForm: React.FC<UserFormProps> = ({ user }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const successCallback = () => {
    setShouldRedirect(true);
  };

  return shouldRedirect ? (
    <Redirect to={VIEW_ALL_USERS} />
  ) : (
    <Card title={user ? `Edit ${user.name}` : 'Add New User'} bordered>
      <BaseUserForm {...{ user, successCallback }} />
    </Card>
  );
};

export default UserForm;
