import React from 'react';

export const getPolicyContent = () => (
  <>
    <h1>Privacy Policy of Website or Application</h1>

    <p>
      The Privacy Policy of Website/Mobile Application is a document informing
      website/application users about the use, management and disclosure of
      their personal information. Within this Privacy Policy, the operator will
      be able to choose whether the document should apply to a website or mobile
      application. Personal information includes name, email address, bank
      details, post on social network websites, medical information, computer IP
      address of users, etc.
    </p>

    <p>
      This document explains how a user's information is gathered, (whether it
      is active collection (such as the asking the user to enter its personal
      details) or passive information (through the use of cookies)) stored and
      used. Cookies are small text files stored in the browser of the user when
      the user visits a website. They are used to store the users' preferences
      so that the web server does not have to repeatedly request the information
      every time users visit the website or application. They are commonly used
      to store the users' information (which may be personal or other type of
      information) such as the users' name, username, address, password, email
      address, the contents of a shopping cart, etc. This helps the operator
      provide a personalized user experience to their users. The users are not
      required to sign this document. However, they are deemed to have given
      consent by the continuing to use the website/application, thus creating a
      binding contract between the users of the website/application and the
      operator.
    </p>
    <h2>How to use this document</h2>

    <p>
      In this document, the information to be outlined by the operator of the
      website/application include, but not limited to the following:
      <ul>
        <li> The type of information to be collected;</li>
        <li>
          {' '}
          How the information will be collected (whether passive or active
          collection will be utilized);
        </li>
        <li>
          {' '}
          The manner in which the information collected will be used or
          utilized;
        </li>
        <li>
          {' '}
          Whether the information collected will be disclosed to third parties
          or not;
        </li>
        <li>
          {' '}
          The rights of the users in relation to the information collected;
        </li>
        <li>
          {' '}
          The minimum age of persons who will be allowed to use the
          website/application;
        </li>
        <li>
          {' '}
          How the information of children will be handled (if applicable);
        </li>
        <li> How long the information will be retained.</li>
      </ul>
      This document must be used by any party that operates a website or mobile
      application which will be accessible to users. After filling the form, it
      should be published on a separate page on the website or application. Some
      websites or applications simply make the Privacy Policy available on a
      separate page, usually accessible via a hyperlink that appears on the same
      page. In this case, the end user may not review the document before
      accepting the contract. This is called the browse wrap agreement. Other
      websites or application make the users take positive steps to confirm that
      the user has read the Privacy Policy. For example, the site or application
      may have a pop up box showing the terms and conditions where the user will
      be required to scroll all the way through the Privacy Policy before
      checking the box saying "I understand the terms and conditions of this
      website and have agreed to be bound by them". This is called a click wrap
      agreement. This Privacy Policy will go hand in hand with a Terms and
      Conditions of Use and Cookie Policy which are also available for sale.
    </p>

    <h2>Applicable laws</h2>

    <p>
      On May 25, 2018, the European Union (EU) General Data Protection
      Regulation (GDPR) came into effect to ensure the protection of personal
      data. It addresses the export of personal data outside the European Union.
      This regulation is applicable to Nigerian businesses that offer products
      and/or services for sale in the EU or if they engage in the processing of
      personal data of citizens of the EU. In 2019, the Information Technology
      Development Agency (NITDA) developed the Nigeria Data Protection
      Regulation (NDPR) 2019, a replica of the General Data Protection
      Regulation (GDPR), which contains guidelines to control the use of data in
      Nigeria. Other laws that apply to this document include: • Cybercrimes
      (Prohibition, Prevention, etc.) Act, 2015; • Freedom of Information Act,
      2011; • Child's rights Act, 2003, which protects the privacy rights of
      children; • the Constitution of the Federal Republic of Nigeria, 1999,
      which provides for the right of privacy and family life of Nigerians.
    </p>
  </>
);
