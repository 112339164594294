import { TaskStatus } from '../../interfaces/entities/Task';

export const getTagColour = (taskStatus: TaskStatus): string => {
  switch (taskStatus) {
    case 'Unassigned':
      return 'warning';
    case 'In Progress':
      return 'processing';
    case 'Completed':
      return 'success';
    case 'Overdue':
      return 'volcano';
    default:
      return 'volcano';
  }
};
