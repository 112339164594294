import React from 'react';
import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from '../../redux/selectors/Auth';
import { User } from '../../interfaces/entities/User';
import {
  DashboardOutlined,
  FolderOpenOutlined,
  IssuesCloseOutlined,
  TeamOutlined,
  SmileOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  CalendarOutlined,
  BankOutlined,
  ContactsOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  VIEW_ALL_ORGANIZATIONS,
  ADD_ORGANIZATION,
  VIEW_ALL_USERS,
  ADD_USER,
  IMPORT_USERS,
  VIEW_ALL_DOCUMENTS,
  ADD_DOCUMENT,
  VIEW_ALL_CONTACTS,
  ADD_CONTACT,
  VIEW_ALL_CLIENTS,
  ADD_CLIENT,
  IMPORT_CLIENTS,
  VIEW_ALL_CASES,
  ADD_CASE,
  IMPORT_CASES,
  ADD_TASK,
  VIEW_ALL_TASKS,
  IMPORT_TASKS,
  VIEW_CALENDAR,
  IMPORT_CONTACTS,
  VIEW_ORGANIZATION_REPORT,
  VIEW_ALL_APPEALS,
  ADD_APPEAL,
  VIEW_DELETED_USERS,
  VIEW_CASE_REPORT,
  VIEW_TASK_REPORT,
  VIEW_CONTACT_REPORT,
  VIEW_CLIENT_REPORT,
  VIEW_USER_REPORT,
} from '../../utility/routing/routes';
import { userIsGrantedPrivilege } from '../../utility/user/privileges';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface SidebarProps {
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
}

const DashboardSidebar: React.FC<SidebarProps> = ({
  collapsed,
  onCollapse,
}) => {
  const user = useSelector(selectAuthenticatedUser) as User;

  const canViewUserMenu = !(
    userIsGrantedPrivilege(user, 'assistant') ||
    userIsGrantedPrivilege(user, 'default')
  );

  const isDefaultUser = userIsGrantedPrivilege(user, 'default');

  return (
    <Sider
      style={{
        overflow: 'auto',
        // width: '250px',
        marginTop: '10px',
      }}
      breakpoint='md'
      collapsible
      defaultCollapsed={true}
      collapsed={collapsed}
      onCollapse={onCollapse}
      theme='dark'
    >
      <div className='logo' />
      <Menu
        theme='dark'
        defaultSelectedKeys={['1']}
        mode='inline'
        style={{ marginTop: '17px' }}
      >
        <Menu.Item key='1'>
          <Link to='/'>
            <span>
              <DashboardOutlined />
              <span>Dashboard</span>
            </span>
          </Link>
        </Menu.Item>
        <SubMenu
          key='sub1'
          title={
            <span>
              <FolderOpenOutlined />
              <span>Cases</span>
            </span>
          }
        >
          <Menu.Item key='5'>
            <Link to={VIEW_ALL_CASES}>
              <span>View Cases</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='4'>
            <Link to={ADD_CASE}>
              <span>Add Case</span>
            </Link>
          </Menu.Item>
          <SubMenu key='sub2' title='Appeals'>
            <Menu.Item key='3'>
              <Link to={VIEW_ALL_APPEALS}>
                <span>View Appeals</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='2'>
              <Link to={ADD_APPEAL}> Add Appeal</Link>
            </Menu.Item>
          </SubMenu>
          {!isDefaultUser && (
            <Menu.Item key='6'>
              <Link to={IMPORT_CASES}>
                <span>Upload Cases</span>
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
        <SubMenu
          key='sub3'
          title={
            <span>
              <IssuesCloseOutlined />
              <span>Tasks</span>
            </span>
          }
        >
          {canViewUserMenu && (
            <Menu.Item key='7'>
              <Link to={ADD_TASK}>
                <span>Add Task</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key='8'>
            <Link to={VIEW_ALL_TASKS}>
              <span>View Tasks</span>
            </Link>
          </Menu.Item>
          {/* <Menu.Item key='9'>
            <Link to={IMPORT_TASKS}>
              <span>Upload Tasks</span>
            </Link>
          </Menu.Item> */}
        </SubMenu>
        <Menu.Item key='27'>
          <Link to={VIEW_CALENDAR}>
            <span>
              <CalendarOutlined />
              <span>Calendar</span>
            </span>
          </Link>
        </Menu.Item>
        {canViewUserMenu && (
          <SubMenu
            key='sub4'
            title={
              <span>
                <TeamOutlined />
                <span>Users</span>
              </span>
            }
          >
            <Menu.Item key='10'>
              <Link to={ADD_USER}>
                <span>Add User</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='11'>
              <Link to={VIEW_ALL_USERS}>
                <span>View Users</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='12'>
              <Link to={VIEW_DELETED_USERS}>
                <span>View Deleted Users</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='13'>
              <Link to={IMPORT_USERS}>
                <span>Upload Users</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
        <SubMenu
          key='sub5'
          title={
            <span>
              <SmileOutlined />
              <span>Clients</span>
            </span>
          }
        >
          {!isDefaultUser && (
            <Menu.Item key='14'>
              <Link to={ADD_CLIENT}>
                <span>Add Client</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key='15'>
            <Link to={VIEW_ALL_CLIENTS}>
              <span>View Clients</span>
            </Link>
          </Menu.Item>
          {/* {!isDefaultUser && (
            <Menu.Item key='16'>
              <Link to={IMPORT_CLIENTS}>
                <span> Upload Clients </span>
              </Link>
            </Menu.Item>
          )} */}
        </SubMenu>
        <SubMenu
          key='sub6'
          title={
            <span>
              <ContactsOutlined />
              <span>Firm Contacts</span>
            </span>
          }
        >
          <Menu.Item key='17'>
            <Link to={ADD_CONTACT}>
              <span>Add Contact</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='18'>
            <Link to={VIEW_ALL_CONTACTS}>
              <span>View Contacts</span>
            </Link>
          </Menu.Item>
          {!isDefaultUser && (
            <Menu.Item key='19'>
              <Link to={IMPORT_CONTACTS}>
                <span> Upload Contacts </span>
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
        <SubMenu
          key='sub7'
          title={
            <span>
              <FileWordOutlined />
              <span>Documents</span>
            </span>
          }
        >
          <Menu.Item key='20'>
            <Link to={ADD_DOCUMENT}>
              <span>Add Document</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='21'>
            <Link to={VIEW_ALL_DOCUMENTS}>
              <span>View Documents</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key={'sub8'}
          title={
            <span>
              <FileExcelOutlined />
              <span>Reports</span>
            </span>
          }
        >
          <Menu.Item key='22'>
            <Link to={VIEW_CASE_REPORT}>
              <span>Case Report</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='23'>
            <Link to={VIEW_TASK_REPORT}>
              <span>Task Report</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='24'>
            <Link to={VIEW_USER_REPORT}>
              <span>User Report</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='25'>
            <Link to={VIEW_CLIENT_REPORT}>
              <span>Client Report</span>
            </Link>
          </Menu.Item>
          <Menu.Item key='26'>
            <Link to={VIEW_CONTACT_REPORT}>
              <span>Contact Report</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        {user.isSuperAdmin && (
          <SubMenu
            key='sub9'
            title={
              <span>
                <BankOutlined />
                <span>Organization</span>
              </span>
            }
          >
            <Menu.Item key='28'>
              <Link to={ADD_ORGANIZATION}>
                <span>Add Organization</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='29'>
              <Link to={VIEW_ALL_ORGANIZATIONS}>
                <span>View Organizations</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='30'>
              <Link to={VIEW_ORGANIZATION_REPORT}>
                <span>Organization Report</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>
    </Sider>
  );
};

export default DashboardSidebar;
