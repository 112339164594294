import React from 'react';
import { useParams } from 'react-router-dom';
import NotPermitted from '../utility/NotPermitted';
import { VIEW_ALL_TASKS } from '../../utility/routing/routes';
import { useSelector } from 'react-redux';
import { selectTask } from '../../redux/selectors/Task';
import { AppState } from '../../interfaces/redux/state/App';
import TaskForm from './Task';

const EditTaskForm: React.FC = () => {
  const { id } = useParams();
  const task = useSelector((appState) => selectTask(appState as AppState, +id));

  if (!task) {
    return (
      <NotPermitted
        returnUrl={VIEW_ALL_TASKS}
        message='Could not find a case with the specified ID'
      />
    );
  }
  return <TaskForm task={task} />;
};

export default EditTaskForm;
