import React, { useEffect, useState } from 'react';
import { Litigation } from '../../interfaces/entities/Litigation';
import { Store } from 'antd/lib/form/interface';
import { Organization } from '../../interfaces/entities/Organization';
import { Client } from '../../interfaces/entities/Client';
import { User } from '../../interfaces/entities/User';
import { useForm } from 'antd/lib/form/Form';
import Form from 'antd/lib/form';
import {
  getOptions,
  filterByOrganization,
  getGenericOptions,
} from '../../utility/array';
import { useSelector } from 'react-redux';
import { selectAllCases } from '../../redux/selectors/Litigation';
import { AutoCompleteOption } from '../../interfaces/components/form/AutoComplete';
import AutoCompleteInput from '../form/AutoCompleteInput';
import TextInput from '../form/TextInput';
import TextAreaInput from '../form/TextAreaInput';
import DatePickerInput from '../form/DatePickerInput';
import {
  NIGERIAN_STATES,
  COURT_CATEGORIES,
} from '../../utility/constants/constants';
import moment from 'moment';
import { formatAPIDate } from '../../utility/date';
import ModalForm from '../form/ModalForm';
import { ResourceType } from '../../interfaces/entities/Base';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import SubmitButton from '../form/SubmitButton';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

interface Props {
  isLoading: boolean;
  handleFinish: (values: Store) => void;
  getStatedFirmOptions: (
    cases: Litigation[],
    selectedOrganization: string
  ) => AutoCompleteOption[];
  litigation?: Litigation;
  organizations: Organization[];
  clients: Client[];
  users: User[];
  authenticatedUser: User;
}

const CaseBaseFormContainer: React.FC<Props> = ({
  litigation,
  isLoading,
  handleFinish,
  organizations,
  clients,
  users,
  authenticatedUser,
  getStatedFirmOptions,
}) => {
  const [form] = useForm();
  const savedCases = useSelector(selectAllCases);
  const userIsSuperAdmin = authenticatedUser.isSuperAdmin;
  const [selectedOrganization, setSelectedOrganization] = useState(
    litigation
      ? (litigation.organization as Organization).name
      : (authenticatedUser.organization as Organization)?.name
  );
  const [assignedUsers, setAssignedUsers] = useState(
    litigation?.assignedUsers.map(({ name }) => name)
  );
  const [selectedClients, setSelectedClients] = useState(
    litigation?.clients.map(({ name }) => name)
  );

  const [renderModal, setRenderModal] = useState(false);
  const [formToRender, setFormToRender] = useState('');

  const [clientOptions, setClientOptions] = useState(getOptions(clients));

  useEffect(() => {
    updateOptions(selectedOrganization);
  }, [clients]);

  const [userOptions, setUserOptions] = useState(getOptions(users));

  const [statedFirmOptions, setStatedFirmOptions] = useState(
    getStatedFirmOptions(savedCases, selectedOrganization)
  );

  const [attorneyLabel, setAttorneyLabel] = useState<string>(
    "Plaintiff's Attorney"
  );

  const [showAddUserButton, setShowAddUserButton] = useState<boolean>(true);
  const [showAddClientButton, setShowAddClientButton] = useState<boolean>(true);

  const updateAttorneyLabel = (e: RadioChangeEvent) => {
    const { value } = e.target;
    setAttorneyLabel(
      `${value === 'Defendant' ? 'Plaintiff' : 'Defendant'}'s Attorney`
    );
  };

  const getInitialFilingDate = (litigation: Litigation | undefined) => {
    if (litigation && litigation.filingDate) {
      return moment(formatAPIDate(litigation.filingDate));
    }
  };

  const getInitialNextHearingDate = (Litigation: Litigation | undefined) => {
    if (litigation && litigation.nextHearingDate) {
      return moment(formatAPIDate(litigation.nextHearingDate));
    }
  };

  const updateOptions = (selectedOrganization: string) => {
    setSelectedOrganization(selectedOrganization);
    setClientOptions(
      getOptions(filterByOrganization(clients, selectedOrganization))
    );
    setUserOptions(
      getOptions(filterByOrganization(users, selectedOrganization))
    );
    setStatedFirmOptions(
      getStatedFirmOptions(
        filterByOrganization(savedCases, selectedOrganization),
        selectedOrganization
      )
    );

    form.setFieldsValue({
      client: '',
      user: '',
      statedFirm: selectedOrganization,
    });
  };

  const showModalForm = (resourceType: ResourceType) => {
    setFormToRender(resourceType);
    setRenderModal(true);
  };

  const closeModal = () => {
    setRenderModal(false);
  };

  const removeAssignedUser = (name: string) => {
    setAssignedUsers((assignedUsers) =>
      assignedUsers?.filter((user) => name !== user)
    );
  };

  const updateAssignedUser = (name: string, index: number) => {
    setAssignedUsers((assignedUsers) => {
      if (assignedUsers) {
        assignedUsers[index] = name;
      }
      return assignedUsers;
    });
  };

  const updateAssociatedClient = (name: string, index: number) => {
    setSelectedClients((selectedClients) => {
      if (selectedClients) {
        selectedClients[index] = name;
      }
      return selectedClients;
    });
  };

  const removeAssociatedClient = (name: string) => {
    setSelectedClients((selectedClients) =>
      selectedClients?.filter((client) => name !== client)
    );
  };

  return (
    <>
      <ModalForm
        formToRender={formToRender as ResourceType}
        showModal={renderModal}
        showOrganizationDropdown={userIsSuperAdmin}
        successCallback={closeModal}
        cancel={closeModal}
      />
      <Form
        form={form}
        onFinish={(values) => {
          const updatedUsers = assignedUsers || [];
          const updatedClients = selectedClients || [];

          const { users, clients } = values;

          if (users) {
            values.users = [...updatedUsers, ...values.users];
          } else {
            values.users = assignedUsers;
          }
          if (clients) {
            values.clients = [...updatedClients, ...values.clients];
          } else {
            values.clients = updatedClients;
          }
          handleFinish(values);
        }}
      >
        {userIsSuperAdmin && (
          <AutoCompleteInput
            name='organization'
            label='Organization'
            placeholder='Organization'
            options={getOptions(organizations)}
            onSelect={updateOptions}
            initialValue={(litigation?.organization as Organization)?.name}
          />
        )}
        <AutoCompleteInput
          name='court'
          label='Court'
          placeholder='Court'
          options={getGenericOptions(COURT_CATEGORIES)}
          initialValue={litigation?.court}
        />
        <TextInput
          name='suitNumber'
          label='Suit Number'
          placeholder='Suit Number'
          message='Please enter the suit number'
          initialValue={litigation?.suitNumber}
        />
        <TextAreaInput
          name='suitName'
          label='Suit Name'
          placeholder='Suit Name'
          initialValue={litigation?.suitName}
          message='Please enter the suit name'
        />
        <DatePickerInput
          name='filingDate'
          label='Filing Date'
          initialValue={getInitialFilingDate(litigation)}
          placeholder='Filing Date'
        />
        <AutoCompleteInput
          name='filingState'
          label='Filing State'
          placeholder='Filing State'
          options={getGenericOptions(NIGERIAN_STATES)}
          initialValue={litigation?.filingState}
        />
        {selectedClients &&
          selectedClients.map((name, index) => {
            return (
              <Row key={index}>
                <Col span={16}>
                  <AutoCompleteInput
                    name={`${index}`}
                    label='Associated Client'
                    placeholder='Associated Client'
                    options={clientOptions}
                    onSelect={(values) => {
                      updateAssociatedClient(values, index);
                    }}
                    required={false}
                    initialValue={(litigation?.clients[index] as Client)?.name}
                  />
                </Col>
                <Col span={4}>
                  <Button
                    onClick={() => {
                      removeAssociatedClient(name);
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </Col>
              </Row>
            );
          })}
        <Form.List name='clients'>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <Row key={field.key}>
                      <Col span={16}>
                        <AutoCompleteInput
                          name={`${index}`}
                          label='Associated Client'
                          placeholder='Associated Client'
                          options={clientOptions}
                          required={false}
                        />
                      </Col>
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            showModalForm('client');
                          }}
                        >
                          Create Client
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add();
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                        <Button
                          onClick={() => {
                            remove(field.name);
                            if (index === 0) {
                              setShowAddClientButton(true);
                            }
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Col>
                    </Row>
                  );
                })}
                <Button
                  block
                  style={{
                    display: showAddClientButton ? 'block' : 'none',
                    marginBottom: '10px',
                  }}
                  onClick={() => {
                    setShowAddClientButton(false);
                    add();
                  }}
                >
                  Add Client
                </Button>
              </>
            );
          }}
        </Form.List>
        <Form.Item name='representing'>
          <Radio.Group onChange={updateAttorneyLabel}>
            <Radio value='Claimant'>Client is Claimant</Radio>
            <Radio value='Defendant'>Client is Defendant</Radio>
          </Radio.Group>
        </Form.Item>
        {/* {showCostInput && (
          <NumberInput
            name='cost'
            label='Cost'
            placeholder='Cost'
            required={false}
            formatter={(value) =>
              value === ''
                ? ''
                : `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/[a-zA-Z\W,]+/g, '')}
          />
        )} */}
        {/* <AutoCompleteInput
          name='statedFirm'
          label='Stated Firm'
          placeholder='Stated Firm'
          options={statedFirmOptions}
          required={false}
          initialValue={litigation?.statedFirm}
        /> */}
        <TextAreaInput
          name='claimant'
          label='Claimant(s)'
          placeholder='Claimant(s)'
          required={false}
          initialValue={litigation?.claimant}
        />
        <TextAreaInput
          name='defendant'
          label='Defendant(s)'
          placeholder='Defendant(s)'
          required={false}
          initialValue={litigation?.defendant}
        />
        <TextInput
          name='presidingJudge'
          label='Presiding Judge'
          placeholder='Presiding Judge'
          required={false}
          initialValue={litigation?.presidingJudge}
        />
        {/* <TextInput
          name='firmLawyer'
          label='Firm Lawyer'
          placeholder='Firm Lawyer'
          required={false}
          initialValue={litigation?.firmLawyer}
        /> */}
        <TextInput
          name='registrarNumber'
          label='Registrar Number'
          placeholder='Registrar Number'
          required={false}
          initialValue={litigation?.registrarNumber}
        />
        <TextInput
          name='plaintiffAttorney'
          label={attorneyLabel}
          placeholder={attorneyLabel}
          required={false}
          initialValue={litigation?.plaintiffAttorney}
        />
        <DatePickerInput
          name='nextHearingDate'
          label='Next Hearing Date'
          initialValue={getInitialNextHearingDate(litigation)}
          placeholder='Next Hearing Date'
          disableFutureDates={false}
          required={false}
        />
        {assignedUsers &&
          assignedUsers.map((name, index) => {
            return (
              <Row key={index}>
                <Col span={16}>
                  <AutoCompleteInput
                    name={`${index}`}
                    label='Assigned User'
                    placeholder='Assigned User'
                    options={userOptions}
                    onSelect={(values) => {
                      updateAssignedUser(values, index);
                    }}
                    required={false}
                    initialValue={
                      (litigation?.assignedUsers[index] as User)?.name
                    }
                  />
                </Col>
                <Col span={4}>
                  <Button
                    onClick={() => {
                      removeAssignedUser(name);
                    }}
                  >
                    Remove User
                  </Button>
                </Col>
              </Row>
            );
          })}
        <Form.List name='users'>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <Row key={field.key}>
                      <Col span={16}>
                        <AutoCompleteInput
                          name={`${index}`}
                          label='Assigned User'
                          placeholder='Assigned User'
                          options={userOptions}
                          required={false}
                        />
                      </Col>
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            remove(field.name);
                            if (index === 0) {
                              setShowAddUserButton(true);
                            }
                          }}
                        >
                          Remove User
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add();
                          }}
                        >
                          Add Assigned User
                        </Button>
                      </Col>
                    </Row>
                  );
                })}

                <Button
                  block
                  style={{
                    display: showAddUserButton ? 'block' : 'none',
                    marginBottom: '10px',
                  }}
                  onClick={() => {
                    setShowAddUserButton(false);
                    add();
                  }}
                >
                  Assign User
                </Button>
              </>
            );
          }}
        </Form.List>
        <SubmitButton
          isLoading={isLoading}
          buttonText={litigation ? 'Save' : 'Submit'}
        />
      </Form>
    </>
  );
};

export default CaseBaseFormContainer;
