import { Organization } from '../../interfaces/entities/Organization';
import {
  UpdateOrganizationsAction,
  AddOrganizationAction,
  EditOrganizationAction,
  ClearOrganizationsAction,
  RemoveOrganizationAction,
  BulkEditOrganizationAction,
} from '../../interfaces/redux/actions/Organization';
import {
  UPDATE_ORGANIZATIONS,
  ADD_ORGANIZATION,
  UPDATE_ORGANIZATION,
  REMOVE_ORGANIZATION,
  CLEAR_ORGANIZATIONS,
  BULK_EDIT_ORGANIZATIONS,
} from '../ActionTypes';

const update = (organizations: Organization[]): UpdateOrganizationsAction => ({
  type: UPDATE_ORGANIZATIONS,
  payload: organizations,
});

const add = (organization: Organization): AddOrganizationAction => ({
  type: ADD_ORGANIZATION,
  payload: organization,
});

const edit = (organization: Organization): EditOrganizationAction => ({
  type: UPDATE_ORGANIZATION,
  payload: organization,
});

const bulkEdit = (
  organizations: Organization[]
): BulkEditOrganizationAction => ({
  type: BULK_EDIT_ORGANIZATIONS,
  payload: organizations,
});

const remove = (id: Organization['id']): RemoveOrganizationAction => ({
  type: REMOVE_ORGANIZATION,
  payload: id,
});

const clear = (): ClearOrganizationsAction => ({
  type: CLEAR_ORGANIZATIONS,
});

export default {
  update,
  add,
  remove,
  edit,
  clear,
  bulkEdit,
};
