import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { VIEW_ALL_APPEALS } from '../../utility/routing/routes';
import Card from 'antd/lib/card';
import BaseAppealForm from './Base';
import { Appeal } from '../../interfaces/entities/Appeal';

interface Props {
  appeal?: Appeal;
}

const AppealForm: React.FC<Props> = ({ appeal }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const successCallback = () => {
    setShouldRedirect(true);
  };

  return shouldRedirect ? (
    <Redirect to={VIEW_ALL_APPEALS} />
  ) : (
    <Card title={appeal ? `Edit ${appeal.number}` : 'Add New Appeal'} bordered>
      <BaseAppealForm successCallback={successCallback} appeal={appeal} />
    </Card>
  );
};

export default AppealForm;
