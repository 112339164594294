import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../../../redux/selectors/Auth';
import { Button, Card, Descriptions, Tabs, Pagination, Table } from 'antd';
import { makeDownloadRequest, makeRequest } from '../../../utility/api';
import { selectAllCases } from '../../../redux/selectors/Litigation';
import ViewAllCasesActionColumn from '../../litigation/ViewAllAction';
import { Litigation } from '../../../interfaces/entities/Litigation';

const { TabPane } = Tabs;

const ClientReportView = () => {

  const [totalClients, setTotalClients] = useState(0);
  const [totalCases, setTotalCases] = useState(0);
  const [clients, setClients] = useState([]);
  const authorization = useSelector(selectAuthToken);

  const cases = useSelector(selectAllCases);

  const sortedClientTableColumn = [
    {
      title: 'Client Name',
      dataIndex: 'name'
    },
    {
      title: 'Number of Cases',
      dataIndex: 'totalCases'
    }
  ];

  const sortedCaseTableColumn = [
    {
      title: 'Suit Name',
      dataIndex: 'suitName',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Suit Number',
      dataIndex: 'suitNumber',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Court',
      dataIndex: 'court',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Next Hearing Date',
      dataIndex: 'nextHearingDate',
      shouldSort: false,
      shouldSearch: false
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, record: any) => {
        const selectedCase = cases.find(({ id }) => id === record.id);
        return <ViewAllCasesActionColumn record={selectedCase as Litigation} />;
      }
    }
  ];

  const downloadReport = () => {
    makeDownloadRequest({
      url: 'admin/reports/client/download',
      type: 'GET',
      mime: 'application/vnd.ms-excel',
      filename: 'Client Report.xlsx',
      authorization
    });
  };

  const downloadButton = (
    <Button type='primary' ghost onClick={downloadReport}>
      Download
    </Button>
  );

  useEffect(() => {
    makeRequest({
      url: 'admin/reports/client',
      type: 'GET',
      successCallback: response => {
        const { totalClients, totalCases, clients } = response.data;
        setTotalClients(totalClients);
        setTotalCases(totalCases);
        setClients(clients);
      },
      authorization
    });
  }, [authorization]);
  return (<Card>
      <Tabs
        defaultActiveKey='1'
        size='middle'
        tabBarExtraContent={downloadButton}
      >
        <TabPane tab='Summary' key='1'>
          <Descriptions bordered>
            <Descriptions.Item label='Total Clients' span={3}>
              {totalClients}
            </Descriptions.Item>
            <Descriptions.Item label='Total Cases' span={3}>
              {totalCases}
            </Descriptions.Item>
          </Descriptions>
        </TabPane>
        <TabPane tab='Client/Case Breakdown' key='2'>
          <Table
            bordered
            rowKey='id'
            columns={sortedClientTableColumn}
            dataSource={clients}
            expandable={{
              expandedRowRender: (record: any) => (
                <Table
                  bordered
                  rowKey='id'
                  columns={sortedCaseTableColumn}
                  dataSource={record.cases}
                  pagination={false}
                />
              ),
            }}
          />
        </TabPane>
      </Tabs>
    </Card>
  );

};

export default ClientReportView;