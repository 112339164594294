import React, { useState } from 'react';
import { User } from '../../interfaces/entities/User';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAuthToken,
  selectAuthenticatedUser,
} from '../../redux/selectors/Auth';
import { Store } from 'antd/lib/form/interface';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import { Organization } from '../../interfaces/entities/Organization';
import UserActions from '../../redux/actions/User';
import { makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import BaseUserFormContainer from './BaseContainer';
import {
  formatPhoneNumber,
  formatSelectedCountryCode,
} from '../../utility/constants/phoneNumber';

interface UserProps {
  user?: User;
  successCallback: () => void;
}
const BaseUserForm: React.FC<UserProps> = ({
  user = null,
  successCallback,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authenticatedUser = useSelector(selectAuthenticatedUser) as User;
  const authorization = useSelector(selectAuthToken);
  const organizations = useSelector(selectAllOrganizations);
  const dispatch = useDispatch();
  const canEditPrivileges = authenticatedUser.isSuperAdmin || authenticatedUser.isOrganizationAdmin;

  const formatRequest = (values: Store) => {
    const { organization, countryCode, phoneNumber } = values;
    if (user) values.id = user.id;
    values.organization = authenticatedUser.isSuperAdmin
      ? organizations.find(({ name }) => name === organization)?.id
      : (authenticatedUser.organization as Organization).id;
    values.countryCode = formatSelectedCountryCode(countryCode);
    values.phoneNumber = formatPhoneNumber(phoneNumber);
    return values;
  };

  const updateUserState = (data: User) => {
    dispatch(user ? UserActions.edit(data) : UserActions.add(data));
  };

  const handleFinish = (values: Store) => {
    setIsLoading(true);
    values = formatRequest(values);
    console.log(values);
    makeRequest({
      url: `admin/user/${user ? 'edit' : 'add'}`,
      values,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateUserState(data);
        successCallback();
      },

      authorization,
      failureCallback: () => {
        setIsLoading(false);
      },
    });
  };

  return (
    <BaseUserFormContainer
      {...{
        user,
        isLoading,
        handleFinish,
        canEditPrivileges
      }}
    />
  );
};

export default BaseUserForm;
