import React from 'react';
import { Input, Form } from 'antd';
import { LockOutlined } from '@ant-design/icons';

interface PasswordInputProps {
  showConfirmation?: boolean;
  showPrefix?: boolean;
}
const getPasswordInput = (
  showPrefix: boolean,
  placeholder: string = 'Password'
) => (
  <Input.Password
    prefix={
      showPrefix ? <LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} /> : null
    }
    placeholder={placeholder}
  />
);
const PasswordInput: React.FC<PasswordInputProps> = ({
  showPrefix = false,
  showConfirmation = false,
}) => {
  return (
    <>
      <Form.Item
        name='password'
        rules={[{ required: true, message: 'Please provide your password' }]}
      >
        {getPasswordInput(showPrefix)}
      </Form.Item>
      {showConfirmation && (
        <Form.Item
          name='confirmPassword'
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please provide your password confirmation',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The two passwords that you entered do not match'
                );
              },
            }),
          ]}
        >
          {getPasswordInput(showPrefix, 'Password Confirmation')}
        </Form.Item>
      )}
    </>
  );
};

export default PasswordInput;
