import React from 'react';
import { Card } from 'antd';
import {
  TeamOutlined,
  FolderOpenOutlined,
  DeleteOutlined,
  InboxOutlined,
  IssuesCloseOutlined,
  StarOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { NumberCardIcon } from '../../interfaces/components/statistics/NumberCard';

interface NumberCardProps {
  title: string;
  number: number;
  icon: NumberCardIcon;
  colour: string;
}
const getIcon = (icon: NumberCardIcon) => {
  switch (icon) {
    case 'team':
      return <TeamOutlined className='number-icon' />;
    case 'folder-open':
      return <FolderOpenOutlined className='number-icon' />;
    case 'delete':
      return <DeleteOutlined className='number-icon' />;
    case 'inbox':
      return <InboxOutlined className='number-icon' />;
    case 'issues-close':
      return <IssuesCloseOutlined className='number-icon' />;
    case 'star':
      return <StarOutlined className='number-icon' />;
    default:
      return <UnlockOutlined className='number-icon' />;
  }
};
const NumberCard: React.FC<NumberCardProps> = ({
  title,
  number,
  icon,
  colour,
}) => {
  return (
    <Card.Grid className='jumpstart-box' style={{ background: colour }}>
      {getIcon(icon)}
      <div className='content'>
        <p className='title'>{title}</p>
        <p className='number'>
          <b>{number}</b>
        </p>
      </div>
    </Card.Grid>
  );
};

export default NumberCard;
