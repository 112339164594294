import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthenticatedUser, selectAuthToken } from '../../redux/selectors/Auth';
import { makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import DocumentActions from '../../redux/actions/Document';
import { selectAllOrganizations } from '../../redux/selectors/Organization';
import { selectAllCases } from '../../redux/selectors/Litigation';
import { User } from '../../interfaces/entities/User';
import { Store } from 'antd/lib/form/interface';
import DocumentFormContainer from './BaseContainer';
import { Organization } from '../../interfaces/entities/Organization';
import { Litigation } from '../../interfaces/entities/Litigation';
import { filterByOrganization, getObjectId, getOptions } from '../../utility/array';
import useFileInput from '../../hooks/useFileInput';

interface Props {
  document?: Document;
  successCallback: () => void;
}

const BaseDocumentForm: React.FC<Props> = ({ document, successCallback }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadMedium,
    updateUploadMedium,
    removeSelectedFile,
    addSelectedFile,
    formatFilesInRequest
  ] = useFileInput();

  const authorization = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const organizations = useSelector(selectAllOrganizations);
  const savedCases = useSelector(selectAllCases);
  const authenticatedUser = useSelector(selectAuthenticatedUser) as User;
  const userIsSuperAdmin = authenticatedUser.isSuperAdmin;

  const formatRequestParameters = (input: any) => {
    input.type = uploadMedium;
    const { litigation, organization } = input;
    input.litigation = getObjectId(
      litigation,
      savedCases,
      'suitName');
    input.organization = organizations.find(
      ({ name }) => organization === name
    )?.id;
    if (!userIsSuperAdmin) {
      input.organization = (authenticatedUser.organization as Organization).id;
    }
    return input;
  };

  const submitRequest = (values: object) => {
    makeRequest({
      url: 'document/add',
      values,
      authorization,
      type: 'POST',
      successCallback: (response) => {
        const { message, data } = response;
        showToast(message);
        dispatch(DocumentActions.addMany(data));
        successCallback();
      }
    });
  };

  const handleFinish = (values: Store) => {
    setIsLoading(true);
    let formattedRequest = formatRequestParameters(values);
    formattedRequest = formatFilesInRequest(formattedRequest);
    submitRequest(formattedRequest);
  };

  const organizationOptions = getOptions(organizations);

  const prepareCaseOptions = (input: Litigation[]) => getOptions(input, true);

  const filterCasesByOrganization = (organization: string) =>
    filterByOrganization(savedCases, organization);

  return (
    <DocumentFormContainer
      {...{
        document,
        savedCases,
        organizations,
        handleFinish,
        updateUploadMedium,
        uploadMedium,
        removeSelectedFile,
        addSelectedFile,
        userIsSuperAdmin,
        isLoading,
        organizationOptions,
        prepareCaseOptions,
        filterCasesByOrganization
      }}
    />
  );
};

export default BaseDocumentForm;
