import React from 'react';
import { Form, InputNumber } from 'antd';

interface NumberInputProps {
  label: string;
  name: string;
  required?: boolean;
  requiredMessage?: string;
  placeholder: string;
  max?: number;
  step?: number;
  initialValue?: number;
  formatter?: (values: any) => string;
  parser?: (values: any) => number;
}
const NumberInput: React.FC<NumberInputProps> = ({
  label,
  name,
  required = true,
  requiredMessage = 'Please enter a number',
  placeholder,
  max,
  step,
  initialValue,
  parser,
  formatter,
}) => {
  return (
    <Form.Item
      label={label}
      initialValue={initialValue}
      name={name}
      rules={[
        {
          required,
          message: requiredMessage,
        },
        {
          type: 'number',
          message: 'Please enter a valid number',
        },
      ]}
    >
      <InputNumber
        min={0}
        max={max}
        step={step}
        style={{ width: '100%' }}
        placeholder={placeholder}
        parser={parser}
        formatter={formatter}
      />
    </Form.Item>
  );
};

export default NumberInput;
