import React, { useState } from 'react';
import { Store } from 'antd/lib/form/interface';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import { Redirect } from 'react-router-dom';
import BaseUpdateFormContainer from './BaseContainer';
import useFileInput from '../../hooks/useFileInput';

interface Props {
  formatRequest: (values: Store) => Store;
  submitUrl: string;
  updateType: 'case' | 'task';
  redirectUrl: string;
  onSuccess: (data: any) => void;
}

const BaseUpdateForm: React.FC<Props> = (
  {
    formatRequest,
    submitUrl,
    updateType,
    redirectUrl,
    onSuccess
  }) => {

  const [uploadMedium,
    updateUploadMedium,
    removeSelectedFile,
    addSelectedFile,
    formatFilesInRequest
  ] = useFileInput();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authorization = useSelector(selectAuthToken);

  const submitRequest = (values: object) => {
    makeRequest({
      url: submitUrl,
      values,
      authorization,
      type: 'POST',
      successCallback: (response) => {
        showToast(response.message);
        onSuccess(response.data);
        setShouldRedirect(true);
      },
      failureCallback: () => {
        setIsLoading(false);
      }
    });
  };

  const handleFinish = (values: Store) => {
    setIsLoading(true);
    let formattedRequest = formatRequest(values);
    formattedRequest = formatFilesInRequest(formattedRequest);
    submitRequest(formattedRequest);
  };
  return shouldRedirect ? (
    <Redirect to={redirectUrl} />
  ) : (
    <BaseUpdateFormContainer
      {...{
        addSelectedFile,
        handleFinish,
        isLoading,
        removeSelectedFile,
        updateType,
        updateUploadMedium,
        uploadMedium
      }}
    />
  );
};

export default BaseUpdateForm;
