import React, { useState } from 'react';
import { Contact } from '../../interfaces/entities/Contact';
import { Redirect } from 'react-router-dom';
import { VIEW_ALL_CONTACTS } from '../../utility/routing/routes';
import { Card } from 'antd';
import BaseContactForm from './Base';

interface Props {
  contact?: Contact;
}

const ContactForm: React.FC<Props> = ({ contact }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const successCallback = () => {
    setShouldRedirect(true);
  };

  return shouldRedirect ? (
    <Redirect to={VIEW_ALL_CONTACTS} />
  ) : (
    <Card title={contact ? `Edit ${contact.name}` : 'Add New Contact'} bordered>
      <BaseContactForm contact={contact} successCallback={successCallback} />
    </Card>
  );
};

export default ContactForm;
