import React from 'react';
import { selectAllContacts } from '../../redux/selectors/Contact';
import { useSelector, useDispatch } from 'react-redux';
import TableView from '../table/ViewAll';
import ViewAllContactsActionColumn from './ViewAllAction';
import { selectAuthToken } from '../../redux/selectors/Auth';
import { makeDownloadRequest, makeRequest } from '../../utility/api';
import { Contact } from '../../interfaces/entities/Contact';
import { showToast, showDeleteWarning } from '../../utility/notification/toast';
import ContactActions from '../../redux/actions/Contact';
import Button from 'antd/lib/button';

const ViewAllContacts: React.FC = () => {
  const contacts = useSelector(selectAllContacts);
  const authorization = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const downloadReport = () => {
    makeDownloadRequest({
      url: '/contact/export',
      type: 'GET',
      mime: 'application/vnd.ms-excel',
      filename: 'Contact Schedule.xlsx',
      authorization,
    });
  };

  const bulkDeleteContacts = (selectedContactIds: Contact['id'][]) => {
    const confirmCallback = () => {
      makeRequest({
        url: 'contact/bulk/delete',
        values: {
          contacts: selectedContactIds.map((input) => ({
            id: +input,
          })),
        },
        type: 'DELETE',
        successCallback: (response) => {
          showToast(response.message);
          dispatch(ContactActions.removeMany(selectedContactIds));
        },
        authorization,
      });
    };
    showDeleteWarning(`Delete Selected Clients?`, confirmCallback);
  };

  const tableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Court',
      dataIndex: 'court',
      shouldSort: false,
      shouldSearch: false,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      shouldSort: false,
      shouldSearch: false,
      render: (text: any, record: Contact) => {
        if (record.countryCode !== undefined) {
          return <p>{`${record.countryCode}${record.phoneNumber}`}</p>;
        }
        return <></>;
      },
    },
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
      shouldSort: false,
      shouldSearch: false,
    },
  ];

  return (
    <TableView
      actionColumn={{
        render: (text: any, record: any) => (
          <ViewAllContactsActionColumn record={record} />
        ),
      }}
      additionalMenuItem={
        <Button type='primary' ghost onClick={downloadReport}>
          Export Contact List
        </Button>
      }
      columns={tableColumns}
      data={contacts}
      resourceType={'contact'}
      bulkAction={bulkDeleteContacts}
      bulkActionText='Delete Selection'
    />
  );
};

export default ViewAllContacts;
