import {
  createStore,
  applyMiddleware,
  Middleware,
  MiddlewareAPI,
  Dispatch,
  Action,
} from 'redux';
import rootReducer from './reducers/Index';
import { loadState, saveState } from './LocalStorage';

const persistedState = loadState();

const persistState: Middleware = ({ getState }: MiddlewareAPI) => {
  return (next: Dispatch) => (action: Action): Action => {
    const newValue = next(action);
    saveState(getState());
    return newValue;
  };
};

const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(persistState)
);

export default store;
