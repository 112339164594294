import React from 'react';
import { User } from '../../interfaces/entities/User';
import Modal from 'antd/lib/modal/Modal';
import { List, Typography } from 'antd';

interface Props {
  users: User[];
  isVisible: boolean;
  organizationName: string;
  close: () => void;
}

const ViewOrganizationUsersModal: React.FC<Props> = ({
  organizationName,
  users,
  isVisible,
  close,
}) => {
  return (
    <Modal
      title={organizationName}
      visible={isVisible}
      onCancel={close}
      onOk={close}
      centered={true}
    >
      <List
        dataSource={users}
        bordered
        renderItem={(user: User) => (
          <List.Item>
            <Typography.Text>{user.name}</Typography.Text>
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default ViewOrganizationUsersModal;
