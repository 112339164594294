import { Task } from '../../interfaces/entities/Task';
import {
  ADD_TASK,
  ADD_TASKS,
  REMOVE_TASK,
  UPDATE_TASK,
  UPDATE_TASKS,
  CLEAR_TASKS,
  BULK_EDIT_TASKS,
} from '../ActionTypes';
import {
  ClearTasksAction,
  EditTaskAction,
  RemoveTaskAction,
  ImportTasksAction,
  AddTaskAction,
  UpdateTasksAction,
  BulkEditTasksAction,
} from '../../interfaces/redux/actions/Task';

const update = (tasks: Task[]): UpdateTasksAction => ({
  type: UPDATE_TASKS,
  payload: tasks,
});

const add = (task: Task): AddTaskAction => ({
  type: ADD_TASK,
  payload: task,
});

const addMany = (tasks: Task[]): ImportTasksAction => ({
  type: ADD_TASKS,
  payload: tasks,
});

const remove = (task: Task['id']): RemoveTaskAction => ({
  type: REMOVE_TASK,
  payload: task,
});

const edit = (task: Task): EditTaskAction => ({
  type: UPDATE_TASK,
  payload: task,
});

const clear = (): ClearTasksAction => ({
  type: CLEAR_TASKS,
});

const bulkUpdate = (tasks: Task[]): BulkEditTasksAction => ({
  type: BULK_EDIT_TASKS,
  payload: tasks,
});
export default {
  update,
  add,
  addMany,
  remove,
  edit,
  clear,
  bulkUpdate,
};
