import { message as toast, Modal } from 'antd';

const { confirm } = Modal;

export const showToast = (message: string, isSuccess: boolean = true) => {
  if (isSuccess) {
    toast.success(message, 2);
  } else {
    toast.error(message, 2);
  }
};

export const showDeleteWarning = (
  title: string,
  confirmCallback: () => void
) => {
  confirm({
    title,
    content: 'You will not be able to reverse this action',
    okText: 'Delete',
    okType: 'danger',
    cancelText: 'Cancel',
    onOk() {
      confirmCallback();
    },
    onCancel() {},
  });
};
