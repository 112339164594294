import {
  UPDATE_USER,
  ADD_USER,
  ADD_USERS,
  REMOVE_USER,
  UPDATE_USERS,
  CLEAR_USERS,
  BULK_EDIT_USERS,
} from '../ActionTypes';
import { User } from '../../interfaces/entities/User';
import {
  EditUserAction,
  ClearUsersAction,
  AddUserAction,
  RemoveUserAction,
  UpdateUsersAction,
  ImportUsersAction,
  BulkEditUsersAction,
} from '../../interfaces/redux/actions/User';

const edit = (user: User): EditUserAction => ({
  type: UPDATE_USER,
  payload: user,
});

const clear = (): ClearUsersAction => ({
  type: CLEAR_USERS,
});

const add = (user: User): AddUserAction => ({
  type: ADD_USER,
  payload: user,
});

const addMany = (users: User[]): ImportUsersAction => ({
  type: ADD_USERS,
  payload: users,
});

const remove = (user: User['id']): RemoveUserAction => ({
  type: REMOVE_USER,
  payload: user,
});

const update = (users: User[]): UpdateUsersAction => ({
  type: UPDATE_USERS,
  payload: users,
});

const bulkEdit = (users: User[]): BulkEditUsersAction => ({
  type: BULK_EDIT_USERS,
  payload: users,
});

export default {
  update,
  add,
  addMany,
  remove,
  edit,
  clear,
  bulkEdit,
};
