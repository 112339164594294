import { AppState } from '../../interfaces/redux/state/App';
import { selectAllItems, selectItem, selectCount } from './Generic';
import { User } from '../../interfaces/entities/User';

export const selectAllUsers = ({ users }: AppState): User[] =>
  selectAllItems(users);

export const selectUser = ({ users }: AppState, id: number): User | null =>
  selectItem(id, users);

export const selectUserCount = ({ users }: AppState): number =>
  selectCount(users);
