import React, { useEffect, useState } from 'react';
import { Litigation } from '../../interfaces/entities/Litigation';
import { Store } from 'antd/lib/form/interface';
import { Organization } from '../../interfaces/entities/Organization';
import { User } from '../../interfaces/entities/User';
import { useForm } from 'antd/lib/form/Form';
import Form from 'antd/lib/form';
import {
  getOptions,
  filterByOrganization,
  getGenericOptions,
} from '../../utility/array';
import AutoCompleteInput from '../form/AutoCompleteInput';
import TextInput from '../form/TextInput';
import TextAreaInput from '../form/TextAreaInput';
import DatePickerInput from '../form/DatePickerInput';
import {
  NIGERIAN_STATES,
  APPEAL_COURT_CATEGORIES,
} from '../../utility/constants/constants';
import moment from 'moment';
import { formatAPIDate } from '../../utility/date';
import ModalForm from '../form/ModalForm';
import { ResourceType } from '../../interfaces/entities/Base';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';
import Radio from 'antd/lib/radio';
import SubmitButton from '../form/SubmitButton';
import { Appeal } from '../../interfaces/entities/Appeal';
import { Client } from '../../interfaces/entities/Client';

interface Props {
  isLoading: boolean;
  handleFinish: (values: Store) => void;
  appeal?: Appeal;
  organizations: Organization[];
  cases: Litigation[];
  clients: Client[];
  users: User[];
  authenticatedUser: User;
}

const CaseBaseFormContainer: React.FC<Props> = ({
  appeal,
  isLoading,
  handleFinish,
  organizations,
  cases,
  users,
  clients,
  authenticatedUser,
}) => {
  const [form] = useForm();
  const userIsSuperAdmin = authenticatedUser.isSuperAdmin;
  const [selectedOrganization, setSelectedOrganization] = useState(
    appeal
      ? appeal.organization.name
      : (authenticatedUser.organization as Organization)?.name
  );
  const [assignedUsers, setAssignedUsers] = useState(
    appeal?.assignedUsers.map(({ name }) => name)
  );

  const [renderModal, setRenderModal] = useState(false);
  const [formToRender, setFormToRender] = useState('');
  const [showAppellantAttorneyField, setShowAppellantAttorneyField] = useState(true);
  const [caseOptions, setCaseOptions] = useState(getOptions(cases, true));
  const [userOptions, setUserOptions] = useState(getOptions(users));
  const [clientOptions, setClientOptions] = useState(getOptions(clients));

  const [showAddUserButton, setShowAddUserButton] = useState<boolean>(true);

  const getInitialFilingDate = (appeal: Appeal | undefined) => {
    if (appeal && appeal.date) {
      return moment(formatAPIDate(appeal.date));
    }
  };

  useEffect(() => {
    updateOptions(selectedOrganization);
  }, [clients, cases]);

  const updateOptions = (selectedOrganization: string) => {
    setSelectedOrganization(selectedOrganization);
    setCaseOptions(
      getOptions(filterByOrganization(cases, selectedOrganization), true)
    );
    setUserOptions(
      getOptions(filterByOrganization(users, selectedOrganization))
    );
    setClientOptions(
      getOptions(filterByOrganization(clients, selectedOrganization))
    );

    form.setFieldsValue({
      client: '',
      user: '',
      litigation: '',
    });
  };

  const showModalForm = (resourceType: ResourceType) => {
    setFormToRender(resourceType);
    setRenderModal(true);
  };

  const closeModal = () => {
    setRenderModal(false);
  };

  const removeAssignedUser = (name: string) => {
    setAssignedUsers((assignedUsers) =>
      assignedUsers?.filter((user) => name !== user)
    );
  };

  const updateAssignedUser = (name: string, index: number) => {
    setAssignedUsers((assignedUsers) => {
      if (assignedUsers) {
        assignedUsers[index] = name;
      }
      return assignedUsers;
    });
  };

  const onRadioSelected = (isAppellant: boolean) => {
    setShowAppellantAttorneyField
(!isAppellant);
    form.setFieldsValue({
      appellantCounsel: isAppellant ? selectedOrganization : '',
      respondentCounsel: !isAppellant ? selectedOrganization : '',
    });
  };

  return (
    <>
      <ModalForm
        formToRender={formToRender as ResourceType}
        showModal={renderModal}
        showOrganizationDropdown={userIsSuperAdmin}
        successCallback={closeModal}
        cancel={closeModal}
      />
      <Form form={form} onFinish={handleFinish}>
        {userIsSuperAdmin && (
          <AutoCompleteInput
            name='organization'
            label='Organization'
            placeholder='Organization'
            options={getOptions(organizations)}
            onSelect={updateOptions}
            initialValue={(appeal?.organization as Organization)?.name}
          />
        )}
        <AutoCompleteInput
          name='court'
          label='Court'
          placeholder='Court'
          options={getGenericOptions(APPEAL_COURT_CATEGORIES)}
          initialValue={appeal?.court}
        />
        <TextInput
          name='number'
          label='Appeal Number'
          placeholder='Appeal Number'
          message='Please enter the appeal number'
          initialValue={appeal?.number}
        />
        <TextAreaInput
          name='name'
          label='Appeal Name'
          placeholder='Appeal Name'
          initialValue={appeal?.name}
          message='Please enter the appeal name'
        />
        <DatePickerInput
          name='filingDate'
          label='Filing Date'
          initialValue={getInitialFilingDate(appeal)}
          placeholder='Filing Date'
        />
        <AutoCompleteInput
          name='filingState'
          label='Filing State'
          placeholder='Filing State'
          options={getGenericOptions(NIGERIAN_STATES)}
          initialValue={appeal?.state}
        />
        <Row>
          <Col span={20}>
            <AutoCompleteInput
              name='litigation'
              label='Associated Case'
              placeholder='Associated Case'
              options={caseOptions}
              required={false}
              initialValue={(appeal?.litigation as Litigation)?.suitNumber}
            />
          </Col>
          <Col span={4}>
            <Button
              onClick={() => {
                showModalForm('case');
              }}
            >
              Add Case
            </Button>
          </Col>
        </Row>

        <Row>
          <Col span={20}>
            <AutoCompleteInput
              name='client'
              label='Client '
              placeholder='Client'
              options={clientOptions}
              initialValue={(appeal?.client as Client)?.name}
            />
          </Col>
          <Col span={4}>
            <Button
              onClick={() => {
                showModalForm('client');
              }}
            >
              Add Client
            </Button>
          </Col>
        </Row>
        <Form.Item name='representing'>
          <Radio.Group>
            <Radio
              value='Appellant'
              onClick={() => {
                onRadioSelected(true);
              }}
            >
              Client is Appellant
            </Radio>
            <Radio
              value='Respondent'
              onClick={() => {
                onRadioSelected(false);
              }}
            >
              Client is Respondent
            </Radio>
          </Radio.Group>
        </Form.Item>
        <TextAreaInput
          name='appellant'
          label='Appellant(s)'
          placeholder='Appellant(s)'
          required={false}
          initialValue={appeal?.appellant ?? undefined}
        />
        <TextAreaInput
          name='respondent'
          label='Respondent(s)'
          placeholder='Respondent(s)'
          required={false}
          initialValue={appeal?.respondent ?? undefined}
        />
        {showAppellantAttorneyField
   && (<TextInput
          name='appellantCounsel'
          label='Appellant Attorney'
          placeholder='Appellant Attorney'
          required={false}
          initialValue={appeal?.appellantCounsel ?? undefined}
        />)}
        {!showAppellantAttorneyField
   && (<TextInput
          name='respondentCounsel'
          label='Respondent Attorney'
          placeholder='Respondent Attorney'
          required={false}
          initialValue={appeal?.respondentCounsel ?? undefined}
        />)}
        {assignedUsers &&
          assignedUsers.map((name, index) => {
            return (
              <Row key={index}>
                <Col span={16}>
                  <AutoCompleteInput
                    name={`${index}`}
                    label='Assigned User'
                    placeholder='Assigned User'
                    options={userOptions}
                    onSelect={(values) => {
                      updateAssignedUser(values, index);
                    }}
                    required={false}
                    initialValue={(appeal?.assignedUsers[index] as User)?.name}
                  />
                </Col>
                <Col span={4}>
                  <Button
                    onClick={() => {
                      removeAssignedUser(name);
                    }}
                  >
                    Remove User
                  </Button>
                </Col>
              </Row>
            );
          })}
        <Form.List name='users'>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <Row key={field.key}>
                      <Col span={16}>
                        <AutoCompleteInput
                          name={`${index}`}
                          label='Assigned User'
                          placeholder='Assigned User'
                          options={userOptions}
                          required={false}
                          initialValue={
                            (appeal?.assignedUsers[index] as User)?.name
                          }
                        />
                      </Col>
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            remove(field.name);
                            if (index === 0) {
                              setShowAddUserButton(true);
                            }
                          }}
                        >
                          Remove User
                        </Button>
                      </Col>
                      <Col span={4}>
                        <Button
                          onClick={() => {
                            add();
                          }}
                        >
                          Add Assigned User
                        </Button>
                      </Col>
                    </Row>
                  );
                })}

                <Button
                  block
                  style={{
                    display: showAddUserButton ? 'block' : 'none',
                    marginBottom: '10px',
                  }}
                  onClick={() => {
                    setShowAddUserButton(false);
                    add();
                  }}
                >
                  Assign User
                </Button>
              </>
            );
          }}
        </Form.List>
        <SubmitButton
          isLoading={isLoading}
          buttonText={appeal ? 'Save' : 'Submit'}
        />
      </Form>
    </>
  );
};

export default CaseBaseFormContainer;
