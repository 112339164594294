import { AppState } from '../../interfaces/redux/state/App';
import { AppNotification } from '../../interfaces/entities/Notification';
import { selectAllItems } from './Generic';

export const selectAllNotifications = ({ notifications }: AppState): AppNotification[] => selectAllItems(notifications);

export const selectUnreadNotifications = (appState: AppState): AppNotification[] =>
  selectAllNotifications(appState).filter(({ isRead }) => !isRead);

export const selectUnreadNotificationCount = (appState: AppState): number =>
  selectUnreadNotifications(appState).length;