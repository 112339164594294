import React from 'react';
import { Form, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';

interface EmailInputProps {
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  showPrefix?: boolean;
  name?: string;
  requiredMessage?: string;
  label?: string;
  initialValue?: string;
}
const EmailInput: React.FC<EmailInputProps> = ({
  required = true,
  disabled = false,
  placeholder = 'Email Address',
  showPrefix = false,
  name = 'email',
  requiredMessage = 'Please provide your email address',
  label,
  initialValue,
}) => {
  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      label={label}
      rules={[
        { required, message: requiredMessage },
        { type: 'email', message: 'Please enter a valid email address' },
      ]}
    >
      <Input
        disabled={disabled}
        prefix={
          showPrefix ? (
            <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
          ) : null
        }
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default EmailInput;
