import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import { getPolicyContent } from './PolicyBody';

interface Props {
  showPolicy: boolean;
  updateShowPolicy: (shouldShow: boolean) => void;
}
const PrivacyPolicy: React.FC<Props> = ({ showPolicy, updateShowPolicy }) => {
  return (
    <Modal
      title='DCase Privacy Policy'
      style={{ top: 20 }}
      width={1000}
      visible={showPolicy}
      onOk={() => updateShowPolicy(false)}
      onCancel={() => updateShowPolicy(false)}
    >
      {getPolicyContent()}
    </Modal>
  );
};

export default PrivacyPolicy;
