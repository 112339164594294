import { Document } from '../../interfaces/entities/Document';
import {
  AddDocumentAction,
  UpdateDocumentsAction,
  ImportDocumentsAction,
  RemoveDocumentAction,
  EditDocumentAction,
  ClearDocumentsAction,
} from '../../interfaces/redux/actions/Document';
import {
  ADD_DOCUMENT,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENTS,
  ADD_DOCUMENTS,
  REMOVE_DOCUMENT,
  CLEAR_DOCUMENTS,
} from '../ActionTypes';

const add = (document: Document): AddDocumentAction => ({
  type: ADD_DOCUMENT,
  payload: document,
});

const update = (documents: Document[]): UpdateDocumentsAction => ({
  type: UPDATE_DOCUMENTS,
  payload: documents,
});

const addMany = (documents: Document[]): ImportDocumentsAction => ({
  type: ADD_DOCUMENTS,
  payload: documents,
});

const remove = (document: Document['id']): RemoveDocumentAction => ({
  type: REMOVE_DOCUMENT,
  payload: document,
});

const edit = (document: Document): EditDocumentAction => ({
  type: UPDATE_DOCUMENT,
  payload: document,
});

const clear = (): ClearDocumentsAction => ({
  type: CLEAR_DOCUMENTS,
});

export default {
  add,
  update,
  addMany,
  remove,
  clear,
  edit,
};
