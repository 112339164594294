import React from 'react';
import { Form, Input } from 'antd';

interface TextInputProps {
  label?: string;
  name: string;
  required?: boolean;
  message?: string;
  placeholder: string;
  initialValue?: string;
}
const TextInput: React.FC<TextInputProps> = ({
  label,
  name,
  required = true,
  message,
  placeholder,
  initialValue,
}) => {
  return (
    <Form.Item
      label={label}
      initialValue={initialValue}
      name={name}
      rules={[
        {
          required,
          message,
        },
      ]}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  );
};

export default TextInput;
