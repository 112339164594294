import React from 'react';
import { Form, Select } from 'antd';

const { Option } = Select;

interface DropdownOption {
  name: string;
  value: string;
}

interface DropdownInputProps {
  name: string;
  options: DropdownOption[];
  placeholder: string;
  label?: string;
  required?: boolean;
  message?: string;
  initialValue?: string;
}

const getOptions = (options: DropdownOption[]) =>
  options.map(({ name, value }, index) => (
    <Option value={value} key={index}>
      {name}
    </Option>
  ));

const DropdownInput: React.FC<DropdownInputProps> = ({
  name,
  required = true,
  message = 'Please select an option',
  placeholder,
  options,
  initialValue,
  label,
}) => {
  const sortedOptions = options.sort((optionA, optionB) =>
    optionA.name.localeCompare(optionB.name)
  );
  return (
    <Form.Item
      name={name}
      rules={[
        {
          required,
          message,
        },
      ]}
      label={label}
      initialValue={initialValue}
    >
      <Select placeholder={placeholder}>{getOptions(sortedOptions)}</Select>
    </Form.Item>
  );
};

export default DropdownInput;
