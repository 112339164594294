import React from 'react';
import { selectAllCases } from '../../redux/selectors/Litigation';
import {
  LitigationCategory,
  Litigation,
} from '../../interfaces/entities/Litigation';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllStarredCases } from '../../redux/selectors/StarredCase';
import { selectAllArchivedCases } from '../../redux/selectors/ArchivedCase';
import { makeDownloadRequest, makeRequest } from '../../utility/api';
import { showToast } from '../../utility/notification/toast';
import TableView from '../table/ViewAll';
import {
  selectAuthToken,
  selectAuthenticatedUser,
} from '../../redux/selectors/Auth';
import ViewAllCasesActionColumn from './ViewAllAction';
import StarredCase from '../../redux/actions/StarredCase';
import NotPermitted from '../utility/NotPermitted';
import { User } from '../../interfaces/entities/User';
import { isAdvancedUser } from '../../utility/user/privileges';
import { Button } from 'antd';

interface Props {
  category?: LitigationCategory;
}

const getSelector = (category: LitigationCategory | undefined) => {
  if (!category) {
    return selectAllCases;
  }
  return category === 'starred'
    ? selectAllStarredCases
    : selectAllArchivedCases;
};

const ViewAllCases: React.FC<Props> = ({ category }) => {
  const authenticatedUser = useSelector(selectAuthenticatedUser) as User;
  const hasExtraPrivileges = isAdvancedUser(authenticatedUser);

  const cases = useSelector(getSelector(category));
  const authorization = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const updateStarredCaseState = (data: Litigation[]) => {
    dispatch(StarredCase.update(data));
  };

  const starMultipleCases = (caseArray: Litigation['id'][]) => {
    const values = {
      cases: caseArray.map((id) => ({ id: +id })),
    };
    makeRequest({
      url: 'case/star/bulk',
      type: 'POST',
      values,
      authorization,
      successCallback: (response) => {
        const { data, message } = response;
        showToast(message);
        updateStarredCaseState(data);
      },
    });
  };

  const downloadFile = (url: string, filename: string) => {
    makeDownloadRequest({
      url,
      type: 'GET',
      mime: 'application/vnd.ms-excel',
      filename,
      authorization,
    });
  };

  const downloadReport = () => {
    downloadFile('/case/export', 'Case Schedule.xlsx');
  };

  const downloadCaseHistory = () => {
    downloadFile('/case/history', 'Case History.xlsx');
  };

  const tableColumns = [
    {
      title: 'Suit Name',
      dataIndex: 'suitName',
      shouldSort: true,
      indexName: 'suit name',
      shouldSearch: true,
    },
    {
      title: 'Suit Number',
      dataIndex: 'suitNumber',
      shouldSort: true,
      indexName: 'suit number',
      shouldSearch: true,
    },
    {
      title: 'Court',
      dataIndex: 'court',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Next Hearing Date',
      dataIndex: 'nextHearing',
      indexName: 'hearing date',
      shouldSort: true,
      shouldSearch: true,
    },
    {
      title: 'Filing Date',
      dataIndex: 'filingDate',
      indexName: 'filing date',
      shouldSort: false,
      shouldSearch: true,
    },
  ];

  if (
    (category === 'archived' || category === 'starred') &&
    !hasExtraPrivileges
  ) {
    return (
      <NotPermitted
        returnUrl='/'
        status='403'
        message='You are not authorized to view this page.'
      />
    );
  }

  return (
    <TableView
      actionColumn={{
        render: (text: any, record: Litigation) => (
          <ViewAllCasesActionColumn record={record} />
        ),
      }}
      additionalMenuItem={
        <>
          <Button type='primary' ghost onClick={downloadReport}>
            Export Cases
          </Button>
          <Button
            danger
            ghost
            onClick={downloadCaseHistory}
            style={{ margin: 10 }}
          >
            Download Case History
          </Button>
        </>
      }
      resourceType='case'
      data={cases}
      columns={tableColumns}
      bulkAction={starMultipleCases}
      bulkActionText='Star/Unstar'
    />
  );
};

export default ViewAllCases;
