import {
  AuthenticationRequest,
  HydrateStateRequest,
  HydrateStateParameters,
  TokenRefreshParameters,
  UpdateAuthenticationParameters,
} from '../../interfaces/utility/authentication';
import { makeRequest } from '../api';
import Auth from '../../redux/actions/Auth';
import { batch } from 'react-redux';
import Litigation from '../../redux/actions/Litigation';
import Client from '../../redux/actions/Client';
import Contact from '../../redux/actions/Contact';
import Document from '../../redux/actions/Document';
import Organization from '../../redux/actions/Organization';
import PersonalTask from '../../redux/actions/PersonalTask';
import StarredCase from '../../redux/actions/StarredCase';
import Task from '../../redux/actions/Task';
import User from '../../redux/actions/User';
import ArchivedCase from '../../redux/actions/ArchivedCase';
import Appeal from '../../redux/actions/Appeal';
import Notification from '../../redux/actions/Notification';

export const handleAuthentication = ({
  url,
  values,
  dispatch,
  failureCallback,
}: AuthenticationRequest) => {
  makeRequest({
    url,
    values,
    successCallback: (response) => {
      handleSuccessfulAuthentication({ dispatch, response });
      makeStateHydrationRequest({ token: response.token, dispatch });
    },
    failureCallback,
  });
};

const handleSuccessfulAuthentication = ({
  dispatch,
  response,
}: UpdateAuthenticationParameters) => {
  const updateTime = new Date();
  dispatch(
    Auth.updateToken({
      ...response,
      updateTime: updateTime.toISOString(),
    })
  );
};

const makeStateHydrationRequest = ({
  token,
  dispatch,
}: HydrateStateRequest) => {
  makeRequest({
    url: 'dashboard/hydrate',
    successCallback: (response) => {
      hydrateState({ dispatch, response: response.data });
    },
    type: 'GET',
    authorization: token,
  });
};

const hydrateState = ({ response, dispatch }: HydrateStateParameters) => {
  const {
    archivedCases,
    cases,
    clients,
    contacts,
    documents,
    organizations,
    personalTasks,
    starredCases,
    tasks,
    user,
    appeals,
    users,
    notifications
  } = response;

  batch(() => {
    dispatch(ArchivedCase.update(archivedCases));
    dispatch(Auth.updateUser(user));
    dispatch(Litigation.update(cases));
    dispatch(Client.update(clients));
    dispatch(Contact.update(contacts));
    dispatch(Document.update(documents));
    dispatch(Organization.update(organizations));
    dispatch(PersonalTask.update(personalTasks));
    dispatch(StarredCase.update(starredCases));
    dispatch(Task.update(tasks));
    dispatch(User.update(users));
    dispatch(Appeal.update(appeals));
    dispatch(Notification.update(notifications))
  });
};

export const refreshToken = ({
  refresh_token,
  dispatch,
}: TokenRefreshParameters) => {
  makeRequest({
    url: 'token/refresh',
    values: {
      refresh_token,
    },
    successCallback: (response) => {
      handleSuccessfulAuthentication({ dispatch, response });
    },
  });
};
