import React, { useState } from 'react';
import { Button } from 'antd';
import LoginForm from './Login';
import TokenRequestForm from './TokenRequest';
import '../../css/authentication.css';
import Logo from '../../media/logo.jpg';
import LoginBackground from '../../media/login.jpg';

const Authentication: React.FC = () => {
  const [showUpdateForm, setShowUpdateForm] = useState<boolean>(false);
  const [showNotificationPreference, setShowNotificationPreference] = useState<
    boolean
  >(false);

  return (
    <div className='limiter'>
      <div
        className='container-login100'
        style={{ backgroundImage: `url(${LoginBackground})` }}
      >
        <div className='wrap-login100'>
          <span className='login100-form-logo' style={{ marginBottom: '40px' }}>
            <img
              src={Logo}
              style={{
                height: '150px',
                margin: '40px',
                borderRadius: '20%',
                marginBottom: '20px',
              }}
              alt='DCase Logo'
            />
          </span>
          <div>
            {showUpdateForm ? (
              <TokenRequestForm {...{ showNotificationPreference }} />
            ) : (
              <LoginForm />
            )}
          </div>
          {showUpdateForm ? (
            <Button
              block
              onClick={() => {
                setShowUpdateForm(false);
              }}
            >
              Cancel
            </Button>
          ) : (
            <div className='helperButtonsWrapper'>
              <div className='leftButton'>
                <Button
                  type='link'
                  onClick={() => {
                    setShowUpdateForm(true);
                    setShowNotificationPreference(false);
                  }}
                >
                  Reset Password
                </Button>
              </div>
              <div className='rightButton'>
                <Button
                  style={{ float: 'right' }}
                  type='link'
                  onClick={() => {
                    setShowUpdateForm(true);
                    setShowNotificationPreference(true);
                  }}
                >
                  New User
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Authentication;
