import React from 'react';
import { Task } from '../../interfaces/entities/Task';

export const getDrawerContent = (task: Task): JSX.Element => (
  <>
    <p style={{ marginTop: '10px' }}>
      <b>Additional Information</b>
    </p>
    <p>{`${task.additionalInformation}`}</p>
    <p style={{ marginTop: '10px' }}>
      <b>Associated Case</b>
    </p>
    <p style={{ marginTop: '10px' }}>
      <b>Suit Name</b>
    </p>
    <p>{`${task.case.suitName}`}</p>
    <p style={{ marginTop: '10px' }}>
      <b>Suit Number</b>
    </p>
    <p>{`${task.case.suitNumber}`}</p>
    {task.assignedUsers.map((user, index) => (
      <>
        <p style={{ marginTop: '10px' }}>
          <b>{`Assignee ${index + 1}`}</b>
        </p>
        <p>{`${user.name}`}</p>
      </>)
    )}
    <p style={{ marginTop: '10px' }}>
      <b>Due Date</b>
    </p>
    <p>{`${task.dueDate}`}</p>
  </>
);
