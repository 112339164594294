import React from 'react';
import Timeline from 'antd/lib/timeline/Timeline';
import { Appeal } from '../../interfaces/entities/Appeal';

export const getDrawerContent = (appeal: Appeal): JSX.Element => (
  <>
    <p style={{ marginTop: '10px' }}>
      <b>Suit Name</b>
    </p>
    <p>{`${appeal.name}`}</p>
    <p style={{ marginTop: '10px' }}>
      <b>Court</b>
    </p>
    <p>{`${appeal.court}`}</p>
    {/* {appeal.client && (
      <>
        <p style={{ marginTop: '10px' }}>
          <b>Client</b>
        </p>
        <p>{`${appeal.client.name}`}</p>
      </>
    )} */}
    <p style={{ marginTop: '10px' }}>
      <b>Filing Date</b>
    </p>
    <p>{`${appeal.date}`}</p>
    <p style={{ marginTop: '10px' }}>
      <b>Updates</b>
    </p>
    <Timeline mode='left'>
      {appeal.updates.map(
        ({ date, description, nextHearing, privateDescription }, index) => (
          <Timeline.Item label={date} key={index}>
            <p style={{ marginTop: '10px' }}>
              <b>Description</b>
            </p>
            <p>{description}</p>
            {nextHearing && (
              <>
                <p style={{ marginTop: '10px' }}>
                  <b>Next Hearing Date</b>
                </p>
                <p>{nextHearing}</p>
              </>
            )}
            {privateDescription && (
              <>
                <b> Company Update</b>
                <p style={{ marginTop: '10px' }}>{privateDescription}</p>
              </>
            )}
          </Timeline.Item>
        )
      )}
    </Timeline>
  </>
);
