import React from 'react';
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

interface SearchProps {
  clearFilters: () => void;
  confirm: () => void;
  dataIndex: string;
  handleReset: (clearFilters: () => void) => void;
  handleSearch: (selectedKeys: any, confirm: any, dataIndex: string) => void;
  indexName: string;
  setSelectedKeys: (keys: any) => void;
  selectedKeys: any[];
}
const Search: React.FC<SearchProps> = ({
  clearFilters,
  confirm,
  dataIndex,
  handleReset,
  handleSearch,
  indexName,
  setSelectedKeys,
  selectedKeys,
}) => {
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${indexName}`}
        value={selectedKeys[0]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type='primary'
        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size='small'
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        onClick={() => handleReset(clearFilters)}
        size='small'
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  );
};

export default Search;
