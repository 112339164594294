import React, { useState } from 'react';
import { Litigation } from '../../interfaces/entities/Litigation';
import { Redirect } from 'react-router-dom';
import { VIEW_ALL_CASES } from '../../utility/routing/routes';
import Card from 'antd/lib/card';
import BaseCaseForm from './Base';

interface Props {
  litigation?: Litigation;
}

const CaseForm: React.FC<Props> = ({ litigation }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const successCallback = () => {
    setShouldRedirect(true);
  };

  return shouldRedirect ? (
    <Redirect to={VIEW_ALL_CASES} />
  ) : (
    <Card
      title={
        litigation ? `Edit ${litigation.suitNumber}` : 'Add New Litigation'
      }
      bordered
    >
      <BaseCaseForm successCallback={successCallback} litigation={litigation} />
    </Card>
  );
};

export default CaseForm;
